<template>
  <div
    :style="iconContainerClass"
    class="flex items-center select-none"
    :class="buttonClass"
    data-testid="icon-container"
  >
    <p
      class="material-symbols-outlined leading-none"
      :class="[iconSizeClass, props.loadingSpinner && 'animate-spin']"
      data-testid="icon"
    >
      {{ name }}
    </p>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  weight: {
    type: Number,
    required: false,
    default: 300,
  },
  filled: {
    type: Boolean,
    required: false,
    default: false,
  },
  mini: {
    type: Boolean,
    required: false,
    default: false,
  },
  small: {
    type: Boolean,
    required: false,
    default: false,
  },
  large: {
    type: Boolean,
    required: false,
    default: false,
  },
  button: {
    type: Boolean,
    required: false,
    default: false,
  },
  buttonColor: {
    type: String,
    required: false,
  },
  loadingSpinner: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const buttonClass = computed(() => {
  if (props.button) {
    const bgColor = props.buttonColor ? `hover:bg-${props.buttonColor}` : "";
    return `cursor-pointer rounded-full ${bgColor}`;
  }
  return "";
});

const iconSizeClass = computed(() => {
  if (props.mini) return "text-base";
  else if (props.small) return "text-xl";
  else if (props.large) return "text-4xl";
  return "text-2xl";
});

const iconContainerClass = computed(
  () =>
    `font-variation-settings: 'FILL' ${props.filled ? 1 : 0}, 'wght' ${
      props.weight
    }`
);
</script>
