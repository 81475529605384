import { ref } from "vue";
import { defineStore } from "pinia";

export const useHighlightStore = defineStore("highlights", () => {
  const items = ref([
    {
      text: "<b>Boost productivity</b> and take back control with our powerful <b>Google Admin Automation toolset</b>",
      image: {
        src: "https://florbs.io/wp-content/uploads/2023/01/MacBook-Pro-Mockup-Light-3-7-1.png",
        alt: "Workflows",
      },
      bullets: [
        {
          text: "Save time",
          icon: "schedule",
        },
        {
          text: "Secure",
          icon: "verified_user",
        },
        {
          text: "Expert support",
          icon: "contact_support",
        },
      ],
    },
    {
      text: "<b>Boost productivity</b> and take back control with our powerful <b>Google Admin Automation toolset</b>",
      image: {
        src: "https://florbs.io/wp-content/uploads/2023/01/high-res.png",
        alt: "Drive Audit",
      },
      bullets: [
        {
          text: "Save time",
          icon: "schedule",
        },
        {
          text: "Secure",
          icon: "verified_user",
        },
        {
          text: "Expert support",
          icon: "contact_support",
        },
      ],
    },
  ]);

  const getRandomHighlight = async () => {
    const index = Math.floor(Math.random() * items.value.length);
    return items.value[index];
  };

  return {
    getRandomHighlight,
  };
});
