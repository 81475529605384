<template>
  <div class="bg-white p-4 border border-gray-50 rounded-xl">
    <div v-if="hasContent" class="h-full">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { useSlots } from "vue";

const slots = useSlots();

const hasContent = !!slots.default;
</script>
