<template>
  <div
    class="border-gray-25 px-2 fixed z-20 mt-8 max-h-96 overflow-y-scroll rounded border bg-white shadow shadow-google-blue-100"
    :style="{
      minWidth: props.width,
      right: dropdownPosition,
      top: dropdownTop,
    }"
    ref="optionsElement"
  >
    <div class="py-1">
      <slot name="options">
        <div v-if="props.items.length > 0">
          <ul>
            <li
              v-for="(item, index) in props.items"
              :key="index"
              :class="[
                'relative cursor-default select-none py-2 px-3 -mr-2 my-1 hover:bg-google-blue-200 rounded-lg',
                isSelected(item) && 'bg-google-blue-100',
                useArrayHelper().getItemProperty(item, props.itemDisabled)
                  ? 'text-gray-200 bg-gray-25 cursor-not-allowed hover:bg-gray-50'
                  : 'hover:bg-google-blue-200',
                props.loadingItems && ' blur',
              ]"
              @click="itemClicked(item)"
            >
              <div class="flex">
                <VIcon
                  v-if="
                    props.itemIcon &&
                    useArrayHelper().getItemProperty(item, props.itemIcon)
                  "
                  :name="useArrayHelper().getItemProperty(item, props.itemIcon)"
                />
                <div :class="['block max-w-md overflow-clip mr-8']">
                  <p>
                    {{
                      props.itemText
                        ? useArrayHelper().getItemProperty(item, props.itemText)
                        : item
                    }}
                  </p>
                  <p
                    v-if="props.itemSubText"
                    class="text-xs font-light text-text-othergrey"
                  >
                    {{
                      useArrayHelper().getItemProperty(item, props.itemSubText)
                    }}
                  </p>
                </div>
                <div
                  v-if="isSelected(item)"
                  :class="[
                    'absolute inset-y-0 right-0 flex items-center pr-4 text-google-blue-700',
                  ]"
                >
                  <VIcon name="Check" class="h-5 w-5" aria-hidden="true" />
                </div>
              </div>
            </li>
          </ul>
          <div v-if="props.loadingItems">
            <div
              class="-mt-20 -py-6 -my-2 text-sm max-w-[300px] mx-auto h-full min-h-[80px] align-middle flex justify-center items-center"
            >
              {{ props.loadingItemsText }}
            </div>
          </div>
        </div>
        <div v-else-if="!props.loadingItems" class="py-4 text-center">
          <div v-if="props.hasInputLimit">
            Please enter at least three characters
          </div>
          <slot v-else-if="loadedState === 'loaded'" name="noItems">
            {{ props.noItemsText }}
          </slot>
        </div>
        <div v-else-if="props.loadingItems">
          <slot name="loadingItems">
            {{ props.loadingItemsText }}
          </slot>
        </div>
      </slot>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch, watchEffect } from "vue";
import { onClickOutside } from "@vueuse/core";
import { useInfiniteScroll } from "@vueuse/core";
import { useArrayHelper } from "@/composables/array-helper";

const props = defineProps({
  modelValue: { type: Array, default: () => [] },
  items: { type: Array, default: () => [] },
  itemText: { type: String, default: null },
  itemSubText: { type: String, default: null },
  itemValue: { type: String, default: null },
  itemKey: { type: String, default: null }, // used for if you want the value to return the whole object
  itemIcon: { type: String, default: null },
  itemDisabled: { type: String, default: null },
  multiple: { type: Boolean, default: false },
  clearable: { type: Boolean, default: true },
  width: { type: [String, Number], default: "auto" },
  noItemsText: { type: String, default: null },
  loadingItemsText: { type: String, default: null },
  loadingItems: { type: Boolean, default: false },
  hasInputLimit: { type: Boolean, default: false },
  position: {
    type: String,
    default: "none",
    validator: (value) => {
      const validVariants = ["right", "left", "none"];
      if (!validVariants.includes(value)) {
        console.warn(`Invalid position: ${value}. Defaulting to "none".`);
        return false;
      }
      return true;
    },
  },
});

const selection = ref(props.modelValue);
// eslint-disable-next-line sonarjs/no-duplicate-string
const emit = defineEmits(["update:modelValue", "close", "scrollEnd"]);
const optionsElement = ref(null);
const loadedState = ref("none");

useInfiniteScroll(
  optionsElement,
  () => {
    emit("scrollEnd");
  },
  { distance: 10 }
);

const itemClicked = (item) => {
  if (item[props.itemDisabled]) {
    return;
  }

  const value = props.itemValue
    ? useArrayHelper().getItemProperty(item, props.itemValue)
    : item;
  isSelected(item) ? removeSelection(value) : select(value);
};

const select = (item) => {
  if (props.multiple) {
    selection.value.push(item);
  } else {
    selection.value = [item];
    emit("close");
  }
  emit("update:modelValue", selection.value);
};
const removeSelection = (itemValue) => {
  if (props.multiple) {
    selection.value = selection.value.filter((i) => i !== itemValue);
  } else {
    if (props.clearable) {
      selection.value = [];
    }
    emit("close");
  }
  emit("update:modelValue", selection.value);
};
const isSelected = (item) => {
  const hasItemValue = props.itemValue && selection.value.length > 0;

  return selection.value.includes(
    hasItemValue
      ? useArrayHelper().getItemProperty(item, props.itemValue)
      : item
  );
};

const cancel = () => {
  emit("close");
};

// =====================================================
// ===== Positioning & Click outside functionality =====
// =====================================================
const parentWidth = ref(0);

const inputFieldRect = computed(() =>
  optionsElement.value.parentElement.getBoundingClientRect()
);
const optionsElementRect = computed(() =>
  optionsElement.value.getBoundingClientRect()
);
const hasEnoughSpaceOnRight = computed(
  () =>
    window.innerWidth - inputFieldRect.value.right >=
    optionsElementRect.value.width
);

const dropdownPosition = computed(() => {
  if (!optionsElement.value) {
    return "0px";
  }

  if (
    (hasEnoughSpaceOnRight.value && props.position === "none") ||
    props.position === "right"
  ) {
    // Align right side of the options to the right side of the parent
    return `${window.innerWidth - inputFieldRect.value.right}px`;
  } else {
    // Align left side of the options to the left side of the parent
    return `${
      window.innerWidth -
      inputFieldRect.value.left -
      optionsElementRect.value.width
    }px`;
  }
});

const dropdownTop = computed(() => {
  if (!optionsElement.value) {
    return "0px";
  }

  const isOverflowingBottom =
    inputFieldRect.value.bottom + optionsElementRect.value.height >
    window.innerHeight;

  if (isOverflowingBottom) {
    return `${
      inputFieldRect.value.bottom - optionsElementRect.value.height - 75
    }px`;
  } else {
    return `${inputFieldRect.value.bottom - 25}px`;
  }
});

// watch optionsElement for changes because it is not available on initial render
watch(
  optionsElement,
  (newVal) => {
    if (newVal) {
      // set inputfield as parent element of the options element
      const inputField = newVal.parentElement;
      parentWidth.value = inputField.offsetWidth;
      onClickOutside(inputField, () => {
        cancel();
      });
    }
  },
  { immediate: true, deep: true }
);

watch(
  [props],
  () => {
    const conditions = [
      { condition: props.loadingItems, value: "loading" },
      { condition: !props.hasInputLimit, value: "waiting" },
      { condition: loadedState.value === "loading", value: "loaded" },
      { condition: true, value: "none" },
    ];

    for (const { condition, value } of conditions) {
      if (condition) {
        loadedState.value = value;
        break;
      }
    }
  },
  { deep: true }
);
</script>
