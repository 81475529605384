import { defineStore } from "pinia";
import {
  useErrorStore,
  useCustomerStore,
  useNotificationStore,
} from "@/stores";
import { useAPIWrapper } from "@/composables/api-wrapper";
import { BillingService } from "@/apis/app-management";
// import { CheckoutRequest } from "@/models/app-management/CheckoutRequest";
const storeId = "billing-store";

// import { useRoutesHelper } from "@/composables/routes-helper";

export const useBillingStore = defineStore(storeId, () => {
  // ===================== //
  // ======= INIT ======== //
  // ===================== //
  const errorStore = useErrorStore();
  const notificationStore = useNotificationStore();

  const customerStore = useCustomerStore();

  const createCheckoutSession = errorStore.withErrorHandling(
    async (productNames, quantity, billingPeriod, successUrl, failUrl) => {
      try {
        return await BillingService.createCheckoutSession(
          customerStore.activeCustomerId,
          { productNames, quantity, billingPeriod, successUrl, failUrl }
        );
      } catch (error) {
        if (
          error.body.detail ==
          "We are currently counting the users you need to subscribe for. Please try again later."
        ) {
          notificationStore.showToast(error.body.detail, "info");
        }
        return error.status;
      }
    },
    storeId,
    "createCheckoutSession",
    "Failed to create checkout session",
    [409]
  );

  const createPortalSession = errorStore.withErrorHandling(
    async (returnUrl) => {
      return await BillingService.createPortalSession(
        customerStore.activeCustomerId,
        { returnUrl }
      );
    },
    storeId,
    "createPortalSession",
    "Failed to create portal session",
    [409]
  );

  return {
    createCheckoutSession,
    createPortalSession,
  };
});
