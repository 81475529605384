import { defineStore } from "pinia";
import { BigQueryJobsService } from "@/apis/security-auditing/services/BigQueryJobsService";
import { useAPIWrapper } from "@/composables/api-wrapper";
import { ReportsService } from "@/apis/security-auditing";
import { useErrorStore } from "@/stores";
import { watch } from "vue";

const storeId = "security-auditing-report-label-stats-org-units-store";

export const useReportLabelStatsOrgUnitsStore = defineStore(storeId, () => {
  // ===================== //
  // ======= INIT ======== //
  // ===================== //
  const errorStore = useErrorStore();

  const errorMessages = {
    listItems: `Failed to retrieve your organizational units. ${errorStore.defaultErrorMessage}`,
    getQueryJobStatus: `Failed to retrieve your organizational units. ${errorStore.defaultErrorMessage}`,
  };

  const services = {
    listItemsService: ReportsService.listOrgunitStats,
    getQueryJobStatusService: BigQueryJobsService.pollJobResult,
  };

  const returnObjectKey = "rows";

  const listServiceParams = ["customerId", "query", "sortBy", "sortOrder"];

  // set the values to determine if the filtering and pagination should be done on the client or server side
  const clientSideFiltering = true;
  const clientSidePagination = true;

  // orgUnitFilter is used to determine if the orgUnit filter should be used
  const useOrgUnitFilter = {
    id: "orgUnitId",
  };

  const useIncludeOrgUnitsFilter = {
    id: "includeSubOrgUnits",
  };

  // ===================== //
  // ======= INIT ======== //
  // ===================== //

  // use the API wrapper composable to handle filtering, sorting, pagination and listing of items.value
  const {
    // filters
    activeFilters,
    activeAdvancedFilters,
    clearAllFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    removeFilter,
    updateFilter,
    // list
    items,
    // listItems,
    listItems,
    totalItems,
    pageToken,
    // general
    resetStore,
    reset,
    loading,
    // crud
    getItem,
  } = useAPIWrapper(
    storeId,
    services,
    returnObjectKey,
    clientSideFiltering,
    clientSidePagination,
    listServiceParams,
    useOrgUnitFilter,
    useIncludeOrgUnitsFilter,
    errorMessages
  );

  let hasBeenInitiallySorted = false;

  watch(
    items,
    (newItems, oldItems) => {
      if (!hasBeenInitiallySorted && !oldItems.length && newItems.length) {
        hasBeenInitiallySorted = true;
        listItems({
          sortBy: "filesStats.labeled",
          sortOrder: "desc",
        });
      } else if (hasBeenInitiallySorted && !newItems.length) {
        hasBeenInitiallySorted = false;
      }
    },
    { deep: true }
  );

  return {
    // filters
    activeFilters,
    useOrgUnitFilter,
    activeAdvancedFilters,
    clearAllFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    removeFilter,
    updateFilter,

    // list
    items,
    listItems,
    totalItems,
    pageToken,
    clientSidePagination,

    // general
    resetStore,
    reset,
    loading,

    // crud
    getItem,
  };
});
