/* eslint-disable sonarjs/no-duplicate-string */
import { ref } from "vue";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";

const ENV_MODES = {
  DEVELOPMENT: "development",
  LOCALDEV: "localdev",
  PRODUCTION: "production",
  STAGING: "staging",
};

const errors = ref([]);

export const useErrorStore = defineStore("error", () => {
  const toast = useToast();
  const lastExecutionTimestamps = new Map();

  const supportEmail = "support@florbs.io";
  const defaultErrorMessage = `Please refresh the page and try again later. If the error persists, please contact ${supportEmail}`;

  const defaultErrorTypeHandling = {
    LOGIN_ERROR: {
      errorType: "LOGIN_ERROR",
      userMessage: `There was an error logging in. ${defaultErrorMessage} (errorcode: 101)`,
    },
    FIREBASE_AUTH_ERROR: {
      errorType: "FIREBASE_AUTH_ERROR",
      userMessage: `There was an error logging in. ${defaultErrorMessage} (errorcode: 102)`,
    },
    GOOGLE_LOGIN_ERROR: {
      errorType: "GOOGLE_LOGIN_ERROR",
      userMessage: `There was an error logging in. ${defaultErrorMessage} (errorcode: 103)`,
    },

    "ApiError-400": {
      apiMessage: "Bad Request",
      userMessage: `There was an error. ${defaultErrorMessage}`,
    },
    "ApiError-401": {
      apiMessage: "Unauthorized",
      userMessage: `There was an authentication error. ${defaultErrorMessage}`,
    },
    "ApiError-403": {
      apiMessage: "Forbidden",
      userMessage: `There was a permission error. ${defaultErrorMessage}`,
    },
    "ApiError-404": {
      apiMessage: "Not Found",
      userMessage: `A resource was not found. ${defaultErrorMessage}`,
    },
    "ApiError-409": {
      apiMessage: "Conflict - Resource outdated",
      userMessage: `There was a resource error. ${defaultErrorMessage}`,
    },
    "ApiError-422": {
      apiMessage: "Validation Error",
      userMessage: `A resource is outdated. ${defaultErrorMessage}`,
    },
    "ApiError-500": {
      apiMessage: "Internal Server Error",
      userMessage: `There was an error. ${defaultErrorMessage}`,
    },
    GLOBAL: {
      errorType: "GLOBAL",
      userMessage: `There was an error. ${defaultErrorMessage}`,
    },
    COMPONENT_ERROR: {
      errorType: "COMPONENT_ERROR",
      userMessage: `There was a component error. ${defaultErrorMessage}`,
    },
    UnknownError: {
      errorType: "UnknownError",
      userMessage: `There was an unidentified error. ${defaultErrorMessage}`,
    },
    MissingServiceError: {
      errorType: "MissingServiceError",
      userMessage: `There was a service error. ${defaultErrorMessage}`,
    },
  };

  const clearErrors = () => {
    errors.value = [];
  };

  const isDevOrLocal = () =>
    import.meta.env.MODE === ENV_MODES.DEVELOPMENT ||
    import.meta.env.MODE === ENV_MODES.LOCALDEV;

  const logError = (error, errorContext) => {
    if (isDevOrLocal()) {
      console.error(
        "❗️",
        errorContext.errorType,
        "==>",
        errorContext.source,
        "==>",
        errorContext.function
      );
      console.error(errorContext.message, errorContext.data || "");
      console.error(error);
      console.error("❕");
    }
  };

  const isDuplicateError = (errorType, message) => {
    const key = `${errorType}-${message}`;
    const currentTimestamp = Date.now();
    const lastExecutionTimestamp = lastExecutionTimestamps.get(key) || 0;

    if (currentTimestamp - lastExecutionTimestamp < 2000) {
      return true;
    }

    lastExecutionTimestamps.set(key, currentTimestamp);
    return false;
  };

  /**
   * @param {Error} error // the error object
   * @param {Object} errorContext //! required - the error context contains information about the error
   * @param {string} errorContext.errorType //! required
   * @param {string} errorContext.message //! required
   * @param {string} errorContext.source // the source of the error, e.g. the store or component name
   * @param {string} errorContext.function // the function where the error occured
   * @param {Object} errorContext.data // additional data (for example API data)
   *
   * @param {string} userMessage // the message to show to the user, if null or undefined, no message or a default message will be shown
   */
  const addError = ({ error, errorContext, userMessage }) => {
    try {
      if (!errorContext.errorType) {
        errorContext.errorType = "UnknownError";
      }
      if (isDuplicateError(errorContext.errorType, errorContext.message))
        return;

      if (isDevOrLocal()) {
        logError(error, errorContext);
      }

      handle(error, errorContext, userMessage);
      errors.value.push({ error }, errorContext);
    } catch (error) {
      console.error(error);
    }
  };

  const handle = (error, errorContext, userMessage) => {
    if (!errorContext.errorType) {
      errorContext.errorType = "GLOBAL";
    }

    if (errorContext.errorType === "ApiError") {
      if (errorContext.data?.status) {
        errorContext.errorType = `${errorContext.errorType}-${errorContext.data.status}`;
      }
      userMessage =
        userMessage ||
        userMessage[errorContext.data?.status] ||
        userMessage.default ||
        defaultErrorTypeHandling[errorContext.errorType].userMessage;
    }

    const apiError = defaultErrorTypeHandling[errorContext.errorType];

    const customErrorMessage =
      !apiError || apiError.apiMessage !== errorContext.message
        ? errorContext.message
        : null;

    userMessage = customErrorMessage || userMessage || apiError?.userMessage;

    if (userMessage) {
      showToast(userMessage);
    }
  };

  const withErrorHandling = (
    fn,
    storeId,
    functionName,
    userMessage = "Generic error",
    errorReturnList = []
  ) => {
    return async (...args) => {
      try {
        return await fn(...args);
      } catch (error) {
        // if error in the errorReturnList, return the error
        if (errorReturnList?.includes(error.status)) {
          return error;
        }
        const errorContext = {
          errorType: error.name,
          message: error.message,
          source: storeId,
          function: functionName,
        };

        addError({
          error,
          errorContext,
          userMessage,
        });

        throw Error(userMessage);
      }
    };
  };

  const showToast = (userMessage) => {
    try {
      toast.error(userMessage, {
        timeout: 3000,
      });
    } catch (toastError) {
      console.error("Error while showing toast:", toastError);
    }
  };

  return {
    errors,
    addError,
    withErrorHandling,
    defaultErrorMessage,
    clearErrors,
  };
});
