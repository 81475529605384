import { useToast } from "vue-toastification";
const toast = useToast();

/* eslint-disable sonarjs/no-duplicate-string */
const DEBUG = false; // Set this to false to disable all debug logs

const DEV_ENV_MODES = {
  development: true,
  localdev: true,
};

const SKIP_FILE_CHECK = true; // Set this to true to skip the file check and log everything

const DEBUG_FILES = {
  // router
  "index.js": false,
  // stores
  "auth-store.js": false,
  "customer-store.js": false,
  "user-preference-store.js": true,
  // components
  "LoginForm.vue": false,
  "DriveAuditFileSettingsPermissionManagerModal.vue": true,
  "DriveAuditFile.vue": true,
  // helpers
  "intercom.js": false,
};

const SHOW_LOG_TYPES = {
  log: true,
  file: true,
  function: true,
  error: true,
  warning: true,
  success: true,
  info: true,
  debug: true,
  performance: true,
  network: true,
  security: true,
};

const SHOW_FOLDERS = {
  src: true,
  helpers: true,
  views: true,
  router: true,
  assets: true, // when build, all files are in the assets folder
  layouts: true,
  login: true,
  composables: true,
  DriveAuditFile: true,
  development: true,
  // STORES
  stores: true,
  "app-management": true,
  "security-auditing": true,
};

const FOLDER_ICONS = {
  // STORES
  stores: "🏬",
  "app-management": "🏬",
  "security-auditing": "🏬",
  //
  composables: "🔧",
  views: "👀",
  helpers: "helpers",
  router: "🚦",
  src: "📁",
  assets: "assets",
  layouts: "layouts",
};

const log = (message, ...optionalParams) => {
  if (!SHOW_LOG_TYPES.log) {
    return;
  }
  const stackInfo = new Error().stack.split("\n")[2].trim();
  log.print("log", stackInfo, message, ...optionalParams);
};

log.print = (logType, stackInfo, message, ...optionalParams) => {
  try {
    if (!DEBUG || !DEV_ENV_MODES[import.meta.env.MODE]) {
      return;
    }
    const fileMatch = /at (?:.*? \()?(.*?):(\d+):(\d+)(?:\))?$/g.exec(
      stackInfo
    );

    const functionMatch = /at (.*?) /.exec(stackInfo);

    let functionName = "";
    if (functionMatch) {
      functionName = functionMatch[1];
    }
    let folderName = "";
    if (fileMatch) {
      const filePathParts = fileMatch[1].split("/");
      folderName = filePathParts[filePathParts.length - 2]; // Gets the folder name
      const fileName = fileMatch[1].split("/").pop().split("?")[0];
      const fileInfo = `${fileName}:${fileMatch[2]}`;

      if (!SHOW_FOLDERS[folderName]) {
        console.log("Skipping logging for folder", folderName);
        return;
      }

      if (!SKIP_FILE_CHECK && !DEBUG_FILES[fileName]) {
        if (DEBUG_FILES[fileName] === undefined) {
          console.log("Skipping logging for", fileName);
        }
        return;
      }

      switch (logType) {
        case "file":
          printFileLog(folderName, fileInfo, message);
          break;
        case "function":
          printFunctionLog(fileInfo, functionName, message);
          break;
        case "error":
          printErrorLog(fileInfo, functionName, message, ...optionalParams);
          break;
        case "warning":
          printWarningLog(fileInfo, functionName, message, ...optionalParams);
          break;
        case "success":
          printSuccessLog(fileInfo, functionName, message, ...optionalParams);
          break;
        case "info":
          printInfoLog(fileInfo, functionName, message, ...optionalParams);
          break;
        case "debug":
          printDebugLog(fileInfo, functionName, message, ...optionalParams);
          break;
        case "performance":
          printPerformanceLog(
            fileInfo,
            functionName,
            message,
            ...optionalParams
          );
          break;
        case "network":
          printNetworkLog(fileInfo, functionName, message, ...optionalParams);
          break;
        case "security":
          printSecurityLog(fileInfo, functionName, message, ...optionalParams);
          break;

        default:
          log.printDefaultLog(
            logType,
            fileInfo,
            functionName,
            message,
            ...optionalParams
          );
          break;
      }
    }
  } catch (error) {
    console.error(error);
  }
};

log.printDefaultLog = (
  logType,
  fileInfo,
  functionName,
  message,
  ...optionalParams
) => {
  const fileInfoStyle = "color: orange;";
  const defaultMessageStyle = "color: light-gray;";
  const functionNameStyle = "color: green;";

  console.log(
    `%c${fileInfo} >> %c${functionName} %c\n${message}`,
    fileInfoStyle,
    functionNameStyle,
    defaultMessageStyle,
    ...optionalParams
  );
};

const printFileLog = (folderName, fileInfo, message) => {
  const fileInfoStyle = "color: orange;";
  console.log(
    `📄==>${FOLDER_ICONS[folderName]}%c/${fileInfo}${
      message ? " - " + message : ""
    }`,
    fileInfoStyle
  );
};

const printFunctionLog = (fileInfo, functionName, message) => {
  const fileInfoStyle = "color: orange;";
  const functionNameStyle = "color: green;";
  console.log(
    `🔧%c${fileInfo} >> %c${functionName}${message ? message : ""}`,
    fileInfoStyle,
    functionNameStyle
  );
};

const printErrorLog = (error, metaData, ...optionalParams) => {
  const fileInfoStyle = "color: orange;";
  const functionNameStyle = "color: green;";
  const defaultMessageStyle = "color: red;";
  console.log(
    `❌ %c${metaData.fileName} >> %c${metaData.functionName} %c\n${error.message}`,
    fileInfoStyle,
    functionNameStyle,
    defaultMessageStyle,
    ...optionalParams
  );
  console.error(error);
  console.log(`❌ %c========================= `, fileInfoStyle);
};

const printWarningLog = (
  fileInfo,
  functionName,
  message,
  ...optionalParams
) => {
  const fileInfoStyle = "color: orange;";
  const functionNameStyle = "color: green;";
  const defaultMessageStyle = "color: darkorange;";
  console.log(
    `⚠️%c${fileInfo} >> %c${functionName} %c\n${message}`,
    fileInfoStyle,
    functionNameStyle,
    defaultMessageStyle,
    ...optionalParams
  );
};

const printSuccessLog = (
  fileInfo,
  functionName,
  message,
  ...optionalParams
) => {
  const fileInfoStyle = "color: orange;";
  const functionNameStyle = "color: green;";
  const defaultMessageStyle = "color: green;";
  console.log(
    `✅%c${fileInfo} >> %c${functionName} %c\n${message}`,
    fileInfoStyle,
    functionNameStyle,
    defaultMessageStyle,
    ...optionalParams
  );
};

const printInfoLog = (fileInfo, functionName, message, ...optionalParams) => {
  const fileInfoStyle = "color: orange;";
  const functionNameStyle = "color: green;";
  const defaultMessageStyle = "color: lightblue;";
  console.log(
    `ℹ️%c${fileInfo} >> %c${functionName} %c\n${message}`,
    fileInfoStyle,
    functionNameStyle,
    defaultMessageStyle,
    ...optionalParams
  );
};

const printDebugLog = (fileInfo, functionName, message, ...optionalParams) => {
  const fileInfoStyle = "color: orange;";
  const functionNameStyle = "color: green;";
  const defaultMessageStyle = "color: light-gray;";
  console.log(
    `🐛%c${fileInfo} >> %c${functionName} %c\n${message}`,
    fileInfoStyle,
    functionNameStyle,
    defaultMessageStyle,
    ...optionalParams
  );
};

const printPerformanceLog = (
  fileInfo,
  functionName,
  message,
  ...optionalParams
) => {
  const fileInfoStyle = "color: orange;";
  const functionNameStyle = "color: green;";
  const defaultMessageStyle = "color: purple;";
  console.log(
    `⏱️%c${fileInfo} >> %c${functionName} %c\n${message}`,
    fileInfoStyle,
    functionNameStyle,
    defaultMessageStyle,
    ...optionalParams
  );
};

const printNetworkLog = (
  fileInfo,
  functionName,
  message,
  ...optionalParams
) => {
  const fileInfoStyle = "color: orange;";
  const functionNameStyle = "color: green;";
  const defaultMessageStyle = "color: blue;";
  console.log(
    `🌐%c${fileInfo} >> %c${functionName} %c\n${message}`,
    fileInfoStyle,
    functionNameStyle,
    defaultMessageStyle,
    ...optionalParams
  );
};

const printSecurityLog = (
  fileInfo,
  functionName,
  message,
  ...optionalParams
) => {
  const fileInfoStyle = "color: orange;";
  const functionNameStyle = "color: green;";
  const defaultMessageStyle = "color: red;";
  console.log(
    `🔒%c${fileInfo} >> %c${functionName} %c\n${message}`,
    fileInfoStyle,
    functionNameStyle,
    defaultMessageStyle,
    ...optionalParams
  );
};

log.file = (message) => {
  if (!SHOW_LOG_TYPES.file) {
    return;
  }
  const stackInfo = new Error().stack.split("\n")[2].trim();
  log.print("file", stackInfo, message);
};

log.function = (message) => {
  if (!SHOW_LOG_TYPES.function) {
    return;
  }
  const stackInfo = new Error().stack.split("\n")[2].trim();
  log.print("function", stackInfo, message);
};

const getErrorMetaData = (error) => {
  const meta = {
    errorType: error.name,
    message: error.message,
  };

  const line = error.stack.split("\n")[1].trim();

  const fileMatch = /at (?:.*? \()?(.*?):(\d+):(\d+)(?:\))?$/g.exec(line);

  meta.functionMatch = /at (.*?) /.exec(error.stack);

  if (meta.functionMatch) {
    meta.functionName = meta.functionMatch[1];
  }

  if (fileMatch) {
    const filePathParts = fileMatch[1].split("/");
    meta.folderName = filePathParts[filePathParts.length - 2]; // Gets the folder name
    meta.fileName = fileMatch[1].split("/").pop().split("?")[0];
  }

  return meta;
};

log.error = (error, optionalParams = {}) => {
  // if it is an error, send it to the error store

  if (!SHOW_LOG_TYPES.error) {
    return;
  }

  const metaData = getErrorMetaData(error);

  if (String(metaData.message).toLowerCase().includes("unexpected token")) {
    localStorage.clear();
    window.location.reload();
  }

  if (DEV_ENV_MODES[import.meta.env.MODE]) {
    printErrorLog(error, metaData, optionalParams);
  }

  if (optionalParams.body && optionalParams.body.detail) {
    showToast(optionalParams.body.detail);
  } else if (optionalParams.userMessage) {
    showToast(optionalParams.userMessage);
  }
};

log.warning = (message, ...optionalParams) => {
  if (!SHOW_LOG_TYPES.warning) {
    return;
  }
  const stackInfo = new Error().stack.split("\n")[2].trim();
  log.print("warning", stackInfo, message, ...optionalParams);
};

log.success = (message, ...optionalParams) => {
  if (!SHOW_LOG_TYPES.success) {
    return;
  }
  const stackInfo = new Error().stack.split("\n")[2].trim();
  log.print("success", stackInfo, message, ...optionalParams);
};

log.info = (message, ...optionalParams) => {
  if (!SHOW_LOG_TYPES.info) {
    return;
  }
  const stackInfo = new Error().stack.split("\n")[2].trim();
  log.print("info", stackInfo, message, ...optionalParams);
};

log.debug = (message, ...optionalParams) => {
  if (!SHOW_LOG_TYPES.debug) {
    return;
  }
  const stackInfo = new Error().stack.split("\n")[2].trim();
  log.print("debug", stackInfo, message, ...optionalParams);
};

log.performance = (message, ...optionalParams) => {
  if (!SHOW_LOG_TYPES.performance) {
    return;
  }
  const stackInfo = new Error().stack.split("\n")[2].trim();
  log.print("performance", stackInfo, message, ...optionalParams);
};

log.network = (message, ...optionalParams) => {
  if (!SHOW_LOG_TYPES.network) {
    return;
  }
  const stackInfo = new Error().stack.split("\n")[2].trim();
  log.print("network", stackInfo, message, ...optionalParams);
};

log.security = (message, ...optionalParams) => {
  if (!SHOW_LOG_TYPES.security) {
    return;
  }
  const stackInfo = new Error().stack.split("\n")[2].trim();
  log.print("security", stackInfo, message, ...optionalParams);
};

const showToast = (userMessage) => {
  try {
    toast.error(userMessage, {
      timeout: 3000,
    });
  } catch (toastError) {
    console.error("Error while showing toast:", toastError);
  }
};

export { log };
