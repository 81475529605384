<template>
  <div>
    <slot> </slot>
  </div>
</template>

<script setup>
import { ref, provide } from "vue";

const inputFields = ref({});

// function to add a subscriber to the inputFields object
const addSubscriber = (inputfield) => {
  if (inputFields.value[inputfield.label]) {
    console.warn(
      `Input field ==== ${inputfield.label} ==== already exists, choose a unique label for each input field`
    );
  }
  inputFields.value[inputfield.label] = inputfield;
};

// We need to update the inputFields object when the input field changes so the form knows if it is valid or not
const updateSubscriber = (inputField) => {
  if (
    inputField.validInput !== inputFields.value[inputField.label].validInput
  ) {
    inputFields.value[inputField.label].validInput = inputField.validInput;
    inputFields.value[inputField.label].inputReceived =
      inputField.inputReceived;
    checkFormValid();
  }
};

// we provide these functions to the inputfields
provide("formValidation", {
  addSubscriber,
  updateSubscriber,
});

const formValid = ref(true);

// check if the form is valid by checking if all the input fields are valid
const checkFormValid = (formSubmit) => {
  let isValid = true;
  //  for each key in inputFields.value, call the validate method
  Object.keys(inputFields.value).forEach((inputField) => {
    // only check the input for inputfields that have received input or when we submit the form
    if (formSubmit || inputFields.value[inputField].inputReceived) {
      inputFields.value[inputField].validate();
      if (!inputFields.value[inputField].validInput) {
        isValid = false;
      }
    }
  });
  formValid.value = isValid;
};

// when we click the submit button, we check if the form is valid
const submit = () => {
  const formSubmit = true;
  checkFormValid(formSubmit);
};

defineExpose({
  formValid,
  submit,
});
</script>
