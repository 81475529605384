<template>
  <div :class="[props.label ? 'h-15' : height.box, width]">
    <label
      v-if="props.label"
      for="name"
      class="inline-block px-1 focus:ring-0"
      :class="[color.label, height.text]"
      >{{ props.label }}</label
    >
    <div class="flex">
      <div
        class="my-auto -mr-0.5 ml-2 pl-1 pr-1.5 rounded-l-md border-l border-y"
        :class="[
          height.box,
          color.border,
          props.disabled && 'cursor-not-allowed border-gray-25',
          props.small ? 'pt-1' : 'pt-[7px]',
        ]"
      >
        <VIcon
          :class="[!props.disabled && 'cursor-pointer', color.icon]"
          :small="props.small"
          name="remove"
          @click.stop="subtract"
        />
      </div>
      <div
        class="w-full border focus-within:border-google-blue-500 focus-within:ring-2 focus-within:ring-google-blue-500/20"
        :class="[
          color.box,
          color.border,
          props.area ? ' h-auto' : height.box,
          props.disabled && 'cursor-not-allowed border-gray-25',
          validation
            ? 'border-error focus-within:!border-error focus-within:!ring-error-500/20'
            : '',
        ]"
      >
        <div class="flex">
          <div class="my-auto ml-2" v-if="hasPrepend">
            <p v-if="props.prependText">{{ props.prependText }}</p>
          </div>
          <!-- === INPUT === -->
          <input
            :type="'text'"
            v-model="inputValue"
            :placeholder="placeholder"
            class="text-center m-0.5 px-2 py-2 block w-full placeholder-gray-200 focus:outline-none disabled:cursor-not-allowed disabled:bg-white disabled:shadow-none disabled:placeholder-gray-50"
            :class="[height.inputField, height.text, color.inputField]"
            @input="onInput"
            @focusout="focusOut"
            @focusin="focusIn"
            @keydown="onKeydown($event)"
            :disabled="props.disabled"
          />
          <!-- === INPUT END === -->
          <slot name="appendIcon">
            <div class="my-auto mr-2 -ml-0.5" v-if="hasAppend">
              <p v-if="props.appendText">{{ props.appendText }}</p>
            </div>
          </slot>
        </div>
      </div>
      <div
        class="my-auto -ml-0.5 mr-2 pr-1 pl-1.5 rounded-r-md border-r border-y"
        :class="[
          height.box,
          color.border,
          props.disabled && 'cursor-not-allowed border-gray-25',
          props.small ? 'pt-1' : 'pt-[7px]',
        ]"
      >
        <VIcon
          :class="[!props.disabled && 'cursor-pointer', color.icon]"
          :small="props.small"
          name="add"
          @click.stop="add"
        />
      </div>
    </div>
    <!-- === HINT === -->
    <div
      v-if="props.hint /* || !validation || validation === true */"
      class="mt-0.5 h-5 text-xs text-gray-200"
    >
      <slot name="hint">
        <div class="flex justify-between px-1">
          <div class="h-5">
            <span v-if="props.hint">{{ props.hint }}</span>
          </div>
        </div>
      </slot>
    </div>
    <!-- === HINT END === -->
    <!-- === ERROR === -->
    <InputError
      v-if="props.rules"
      :inputValue="inputValue"
      :validation="validation"
      :counter="counter"
    />
    <!-- === ERROR END === -->
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useFormInput } from "@/composables/form-input";
import InputError from "@/components/helpers/InputError.vue";
// eslint-disable-next-line sonarjs/no-duplicate-string
const emit = defineEmits(["update:modelValue", "update", "focusOut", "clear"]);

const props = defineProps({
  modelValue: { type: String, default: null }, // v-model
  label: { type: String, default: "" }, // label, shown above input
  placeholder: { type: String, default: "" }, // placeholder, shown inside input when empty
  hint: { type: String, default: null }, // hint, shown below input
  disabled: { type: Boolean, default: false }, // disabled state
  icon: { type: String, default: "" }, // icon to show before input
  prependText: { type: String, default: "" }, // show text before input
  appendText: { type: String, default: "" }, // show text after input
  rules: { type: Array, default: null }, // validation rules
  small: { type: Boolean, default: false }, // small version
  color: { type: String, default: null }, // color
  digitsWidth: { type: Number, default: 3 }, // width based on digits
  maxValue: { type: Number, default: 999 }, // max value
  minValue: { type: Number, default: 0 }, // min value
});

// use validation from composable
const { inputValue, validation, validateInput, counter } = useFormInput(props);

const hasPrepend = props.prependText || props.prependIcon;
const hasAppend = props.appendText || props.appendIcon;

const widthMappings = [
  { threshold: 8, baseWidth: 56, smallWidth: 44 },
  { threshold: 6, baseWidth: 44, smallWidth: 40 },
  { threshold: 4, baseWidth: 40, smallWidth: 36 },
  { threshold: 2, baseWidth: 36, smallWidth: 32 },
  { threshold: 1, baseWidth: 32, smallWidth: 28 },
];

const getWidth = (digitsWidth, small) => {
  for (const mapping of widthMappings) {
    if (digitsWidth > mapping.threshold) {
      return small ? mapping.smallWidth : mapping.baseWidth;
    }
  }
  return 28; // Default width
};

const adjustWidth = (baseWidth, appendText, prependText) => {
  return appendText || prependText ? baseWidth + 4 : baseWidth;
};

const width = computed(() => {
  const baseWidth = getWidth(props.digitsWidth, props.small);
  return `w-${adjustWidth(baseWidth, props.appendText, props.prependText)}`;
});

const onInput = (event) => {
  // Only allow numbers and one negative sign
  let value = event.target.value.replace(/[^0-9-]/g, "");
  // Only allow one negative sign, at the beginning
  if (value.charAt(0) === "-") {
    value = "-" + value.slice(1).replace(/-/g, "");
  } else {
    value = value.replace(/-/g, "");
  }
  // Only allow numbers between min and max
  if (value > props.maxValue) value = props.maxValue;
  if (value < props.minValue) value = props.minValue;

  inputValue.value = value;
  validateInput();
};

const onKeydown = ($event) => {
  if ($event.key === "ArrowUp") {
    add();
  } else if ($event.key === "ArrowDown") {
    subtract();
  }
};

const updateInputValue = (newValue) => {
  if (props.disabled) return;

  if (!newValue && newValue !== 0) {
    newValue = props.minValue;
  }

  if (props.minValue <= newValue && newValue <= props.maxValue) {
    inputValue.value = newValue;
    validateInput();
  }
};

const add = () => {
  updateInputValue(parseInt(inputValue.value) + 1);
};

const subtract = () => {
  updateInputValue(parseInt(inputValue.value) - 1);
};

watch(
  () => inputValue.value,
  (value) => {
    emit("update:modelValue", value);
  }
);

// watch modelValue and update input value
watch(
  () => props.modelValue,
  (value) => {
    inputValue.value = value;
  }
);

const atFocusValue = ref("");

const focusIn = () => {
  atFocusValue.value = inputValue.value;
};

const focusOut = () => {
  if (inputValue.value === "") return;
  if (atFocusValue.value === inputValue.value) return;
  validateInput();
  emit("focusOut", inputValue.value);
};

// styling
const height = computed(() => {
  return {
    text: props.small ? "text-xs" : "text-sm",
    box: props.small ? "h-7" : "h-10",
    inputField: props.small ? "h-6" : "h-9",
  };
});

const color = computed(() => {
  if (!props.color)
    return {
      label: props.disabled ? "text-gray-50" : "text-gray-500",
      // eslint-disable-next-line sonarjs/no-duplicate-string
      box: props.disabled ? "bg-disabled" : "bg-white",
      inputField: props.disabled ? "bg-white" : "bg-white text-gray-900",
      border: !props.disabled && !validation ? "border-gray-50" : "",
      icon: props.disabled ? "text-gray-50" : "text-gray-500",
    };
  return {
    label: props.disabled ? "text-gray-50" : `text-${props.color}-500`,
    box: props.disabled ? "bg-disabled" : `bg-${props.color}-100`,
    inputField: props.disabled ? "bg-disabled" : `bg-${props.color}-100`,
    border:
      !props.disabled && !validation
        ? `border-gray-50`
        : `border-${props.color}-300`,
    icon: `text-${props.color}-500`,
  };
});
</script>
