// by convention, composable function names start with "use"

export function useDateTimeHelper() {
  // constants
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthNamesShort = monthNames.map((month) => month.substring(0, 3));

  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const hours = Array.from(Array(24).keys()).map((hour) =>
    hour.toString().padStart(2, "0")
  );

  // Helper function to pad single-digit numbers with leading zero
  const padNumber = (num) => {
    return num.toString().padStart(2, "0");
  };

  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  // get all days of a month and include previous month days until last monday and next month days until first sunday
  const getDaysForCalendar = (month, year) => {
    // get datestring from date with 2 digits for month and day
    const dateToDateString = (date) => {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      return `${date.getFullYear()}-${month}-${day}`;
    };

    const days = [];
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);

    const lastMonday = new Date(firstDay);
    if (firstDay.getDay() !== 0) {
      lastMonday.setDate(firstDay.getDate() - firstDay.getDay() + 1);
    } else {
      lastMonday.setDate(firstDay.getDate() - 6);
    }

    // get all days between last monday and the first day of the month
    while (lastMonday < firstDay) {
      days.push({
        date: dateToDateString(new Date(lastMonday)),
        isSelected: false,
        isCurrentMonth: false,
      });
      lastMonday.setDate(lastMonday.getDate() + 1);
    }

    // get all days between the first day of the month and the last day of the month
    while (firstDay <= lastDay) {
      const isToday =
        firstDay.getDate() === new Date().getDate() &&
        firstDay.getMonth() === new Date().getMonth() &&
        firstDay.getFullYear() === new Date().getFullYear();

      days.push({
        date: dateToDateString(new Date(firstDay)),
        isSelected: false,
        isCurrentMonth: true,
        isToday,
      });
      firstDay.setDate(firstDay.getDate() + 1);
    }

    // get the first sunday of the next month
    const firstSunday = new Date(lastDay);

    firstSunday.setDate(firstSunday.getDate() + 6 - firstSunday.getDay());
    // get all days between the last day of the month and the first sunday of the next month
    while (lastDay <= firstSunday) {
      lastDay.setDate(lastDay.getDate() + 1);

      days.push({
        date: dateToDateString(new Date(lastDay)),
        isSelected: false,
        isCurrentMonth: false,
      });
    }
    return days;
  };

  // return a readable date with day and month
  const getDayAndDate = (dateTime) => {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    const day = dateTime.getDate().toString().padStart(2, "0");

    if (getDate(today) === getDate(dateTime)) {
      return "Today";
    } else if (getDate(tomorrow) === getDate(dateTime)) {
      return "Tomorrow";
    } else {
      return `${dayNames[dateTime.getDay()]}, ${
        monthNames[dateTime.getMonth()]
      } ${day}`;
    }
  };

  const getAgoDate = (dateString) => {
    if (!dateString) return null;

    const date = new Date(dateString);
    const now = new Date();

    const seconds = Math.floor((now - date) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (seconds < 60) {
      return "just now";
    } else if (minutes < 60) {
      return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours === 1 ? "" : "s"} ago`;
    } else if (days < 30) {
      return `${days} day${days === 1 ? "" : "s"} ago`;
    } else if (months < 12) {
      return `${months} month${months === 1 ? "" : "s"} ago`;
    } else {
      return `${years} year${years === 1 ? "" : "s"} ago`;
    }
  };

  const getTimeDifference = (startDate, endDate, isAbs = false) => {
    const startTimestamp = new Date(startDate).getTime();
    const endTimestamp = new Date(endDate).getTime();

    const pureDifference = endTimestamp - startTimestamp;
    const differenceFromAbs = isAbs ? Math.abs(pureDifference) : pureDifference;
    const minutes = Math.floor(differenceFromAbs / (1000 * 60));
    const seconds = Math.floor((differenceFromAbs % (1000 * 60)) / 1000);

    return { minutes, seconds };
  };

  const getTime = (dateTime) => {
    const hours = dateTime.getHours().toString().padStart(2, "0");
    const minutes = dateTime.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const getDate = (dateTime) => {
    const day = dateTime.getDate().toString().padStart(2, "0");
    const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
    return `${dateTime.getFullYear()}-${month}-${day}`;
  };

  const formatDate = (date, format) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = d.getMonth();

    // Get the month name from the monthNames array
    const monthName = monthNames[month];

    // Replace format placeholders with corresponding date values
    format = format.replace("YYYY", year);
    if (!format.includes("MMM")) {
      format = format.replace("MM", padNumber(month + 1));
    } else {
      format = format.replace("MMM", monthName.substring(0, 3));
    }
    format = format.replace("DD", padNumber(d.getDate()));

    return format;
  };

  // round time to next hour
  const roundTimeToFullHour = (date, plusHours = 0) => {
    const d = new Date(date);
    let hour = "" + d.getHours();
    let minute = "" + d.getMinutes();

    if (minute > 0) {
      hour = parseInt(hour) + 1 + plusHours;
    }

    if (hour.length < 2) hour = "0" + hour;

    return [hour, "00"].join(":");
  };

  // create date from datestring
  const createDate = (dateString, time) => {
    let date = new Date(dateString);
    let timeArray = time.split(":");
    date.setHours(timeArray[0]);
    date.setMinutes(timeArray[1]);
    return date;
  };

  const convertToLocaleDateString = (dateTimeString) => {
    // check if timestamp is valid
    if (!dateTimeString) return null;

    const date = new Date(dateTimeString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hour = date.getHours().toString().padStart(2, "0");
    const minute = date.getMinutes().toString().padStart(2, "0");

    // returns YYYY-MM-DD HH:MM
    return `${year}-${month}-${day} ${hour}:${minute}`;
  };

  const convertTimestampToLocaleDateString = (timestamp) => {
    // check if timestamp is valid
    if (!timestamp) return null;

    const date = new Date(timestamp);
    return date.toLocaleDateString();
  };

  const convertTimestampToLocaleDateTimeString = (timestamp) => {
    // check if timestamp is valid
    if (!timestamp) return null;

    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  // Adjusts the given date by a specified number of months ago, handling changes in the month and year if necessary.
  const adjustDateByMonths = (date, amountOfMonthsAgo) => {
    const newDate = new Date(date.getTime());

    let currentMonth = newDate.getMonth();
    let currentYear = newDate.getFullYear();

    let newMonth = currentMonth - amountOfMonthsAgo;
    let newYear = currentYear;

    while (newMonth < 0) {
      newMonth += 12;
      newYear -= 1;
    }

    newDate.setMonth(newMonth);
    newDate.setFullYear(newYear);

    return newDate;
  };

  const getAllDatesInRange = (startDate, endDate) => {
    const days = [];
    const current = new Date(startDate);
    const last = new Date(endDate);

    while (current <= last) {
      days.push(current.toISOString().split("T")[0]);
      current.setDate(current.getDate() + 1);
    }

    return days;
  };

  const getDaysLeftBetweenDates = (start_date, end_date, zero_as_lowest) => {
    const start = new Date(start_date);
    const end = new Date(end_date);
    const diff = end - start;
    let days_left = Math.ceil(diff / (1000 * 60 * 60 * 24));

    if (zero_as_lowest) {
      days_left = days_left < 0 ? 0 : days_left;
    }

    return days_left;
  };

  const isSecondDateBeforeFirstDate = (firstDate, secondDate) =>
    new Date(secondDate) < new Date(firstDate);

  const convertMonthNamesShortToIndex = (monthNameShort) =>
    monthNamesShort.indexOf(monthNameShort) + 1;

  const convertMonthNamesToIndex = (monthName) =>
    monthNames.indexOf(monthName) + 1;

  // expose managed state as return value
  return {
    // constants
    monthNames,
    monthNamesShort,
    dayNames,
    hours,
    // functions
    convertMonthNamesShortToIndex,
    convertMonthNamesToIndex,
    getDaysForCalendar,
    getDaysInMonth,
    convertToLocaleDateString,
    getDayAndDate,
    getTimeDifference,
    getTime,
    getDate,
    formatDate,
    roundTimeToFullHour,
    createDate,
    convertTimestampToLocaleDateString,
    convertTimestampToLocaleDateTimeString,
    adjustDateByMonths,
    getAllDatesInRange,
    isSecondDateBeforeFirstDate,
    getAgoDate,
    getDaysLeftBetweenDates,
  };
}
