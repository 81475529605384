<template>
  <VRow
    class="h-14 shrink-0 grow-0 justify-between items-center bg-google-blue-100 items-center gap-x-3 py-2 px-3 text-left leading-6 text-google-blue-800"
    :class="[
      isOpen ? 'rounded-t-lg ' : 'rounded-lg',
      props.persistent ? '' : 'cursor-pointer',
    ]"
    @click="toggleOpen"
  >
    <VRow gap="2">
      <VCol class="space-y-1">
        <div class="text-lg leading-none">{{ props.title }}</div>
        <div class="text-[10px] leading-none text-gray-100">
          {{ props.subtitle }}
        </div>
      </VCol>
      <VTooltip v-if="props.tooltip">
        <template #tooltip>
          <div class="max-w-[500px]">
            {{ props.tooltip }}
          </div>
        </template>
        <template #default>
          <VIcon :class="props.tooltipColor" :name="props.tooltipIcon" small />
        </template>
      </VTooltip>
    </VRow>
    <div class="ml-auto h-6 w-6 shrink-0">
      <VIcon
        v-if="!props.persistent"
        :class="isOpen ? 'rotate-90 text-gray-500' : 'text-gray-400'"
        name="chevron_right"
      />
    </div>
  </VRow>
  <div
    class="pb-3 rounded-b-xl bg-background border-google-blue-100"
    :class="{
      'h-0 overflow-hidden': !isOpen,
      'h-auto overflow-y-scroll': props.scroll,
      'flex-grow': props.grow,
      'px-0': props.noPadding,
      'px-3': !props.noPadding,
    }"
    v-show="isOpen"
  >
    <slot name="content"> </slot>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  subtitle: {
    type: String,
    default: "",
  },
  defaultOpen: {
    type: Boolean,
    default: false,
  },
  persistent: {
    type: Boolean,
    default: false,
  },
  grow: {
    type: Boolean,
    default: false,
  },
  scroll: {
    type: Boolean,
    default: false,
  },
  noPadding: {
    type: Boolean,
    default: false,
  },
  tooltip: {
    type: String,
    default: null,
  },
  tooltipIcon: {
    type: String,
    default: "info",
  },
  tooltipColor: {
    type: String,
    default: "text-google-blue",
  },
});

const isOpen = ref(props.defaultOpen);

const toggleOpen = () => {
  if (props.persistent) return;
  isOpen.value = !isOpen.value;
};

watch(
  () => props.defaultOpen,
  (val) => {
    isOpen.value = val;
  }
);
</script>
