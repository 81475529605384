<template>
  <TransitionRoot as="template" :show="props.modelValue">
    <Dialog
      as="div"
      class="relative z-10"
      @close="() => !props.persistent && close()"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-opacity-100 bg-gray-900 opacity-30 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full justify-center text-center items-center p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 translate-y-0 scale-95"
            enter-to="opacity-100 translate-y-0 scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 scale-100"
            leave-to="opacity-0 translate-y-4 translate-y-0 scale-95"
          >
            <div class="relative" :class="calculatedWidth">
              <!-- ACTIONS -->
              <VRow
                class="absolute right-0 top-0 space-x-1 p-2 bg-white rounded-t-lg"
                data-testid="base-modal-actions"
                v-if="hasActions"
              >
                <slot name="actions"></slot>
              </VRow>
              <!-- END ACTIONS -->
              <DialogPanel
                class="relative min-w-[560px] rounded-lg bg-white text-left shadow-xl transition-all my-8 w-full p-4"
              >
                <VRow class="items-start" gap="0">
                  <!-- ICON -->
                  <div class="mr-2 mt-1" data-testid="base-modal-icon">
                    <slot name="icon"></slot>
                  </div>
                  <!-- END ICON -->
                  <div class="mt-3 w-full pr-2 text-left">
                    <!-- TITLE -->
                    <DialogTitle
                      class="mb-4 text-lg font-normal leading-6 text-gray-900"
                      data-testid="base-modal-title"
                    >
                      <slot name="title">{{ props.title }}</slot>
                    </DialogTitle>
                    <!-- END TITLE -->
                    <!-- DESCRIPTION -->
                    <div class="my-2">
                      <p
                        class="font-body text-sm font-light text-gray-500"
                        data-testid="base-modal-description"
                      >
                        <slot name="description">{{ props.description }}</slot>
                      </p>
                    </div>
                    <!-- END DESCRIPTION -->
                    <!-- BODY -->
                    <div class="mt-4 mb-2" data-testid="base-modal-body">
                      <slot></slot>
                    </div>
                    <!-- END BODY -->
                  </div>
                </VRow>
                <!-- FOOTER -->
                <VRow
                  class="mt-4 space-x-2 justify-end"
                  data-testid="base-modal-footer"
                >
                  <slot name="footer"></slot>
                </VRow>
                <!-- END FOOTER -->
              </DialogPanel>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { useSlots, computed } from "vue";

const emit = defineEmits(["close", "confirm"]);
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  title: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
  persistent: {
    type: Boolean,
    default: false,
  },
  width: {
    type: Number,
    default: null,
  },
});

const calculatedWidth = computed(() => {
  return props.width ? `max-w-[${props.width}px]` : "";
});

const slots = useSlots();
const hasActions = slots.actions()[0].children.length;

// emit close event
const close = () => {
  emit("close");
};
</script>
