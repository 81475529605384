<template>
  <div class="inline-flex">
    <VCol>
      <VRow
        gap="0"
        class="py-auto items-center justify-center select-none"
        :class="[
          chipColor,
          chipClearable,
          chipRounded,
          chipSize,
          chipLabelSize,
          // chipWidth,
        ]"
        data-testid="vchip-container"
      >
        <VCol>
          <slot>
            <span>
              {{ props.label }}
            </span>
          </slot>
          <div v-if="props.rounded">
            <slot name="subLabel">
              <p class="text-[8px] leading-[7px] -mb-[1px] mr-1">
                {{ props.subLabel }}
              </p>
            </slot>
          </div>
        </VCol>
        <div
          v-if="icon"
          :class="[chipIconClass, 'my-auto']"
          data-testid="vchip-icon"
        >
          <VIcon
            class="mt-0.5 inline-flex flex-shrink-0 items-center justify-center mr-1"
            :name="icon"
            :small="!large"
          />
        </div>
        <VIcon
          button
          @click.stop="() => onClear()"
          v-if="clearable"
          class="ml-1 inline-flex flex-shrink-0 items-center justify-center"
          name="close"
          :small="!large"
          :bg-color="color"
          data-testid="vchip-clearable"
        />
      </VRow>
      <div v-if="!props.rounded && props.subLabel">
        <slot name="subLabel">
          <VRow
            :class="[chipSubLabelColor, chipSubLabelSize]"
            class="p-1 rounded-b-md items-center justify-center"
          >
            <p>
              {{ props.subLabel }}
            </p>
          </VRow>
        </slot>
      </div>
    </VCol>
  </div>
</template>

<script setup>
import { computed } from "vue";

const emit = defineEmits(["clear"]);

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  subLabel: {
    type: String,
    default: "",
  },
  iconTrailing: {
    type: Boolean,
    default: false,
  },
  large: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: "",
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: "primary",
  },
  rounded: {
    type: Boolean,
    default: true,
  },
  outlined: {
    type: Boolean,
    default: false,
  },
  bold: {
    type: Boolean,
    default: false,
  },
});

const onClear = () => {
  emit("clear");
};

const chipColor = computed(() => {
  if (props.outlined) {
    if (props.color === "gray") {
      return "border-gray-200 border";
    }
    // if color starts with #
    if (props.color[0] === "#") {
      return `border border-[${props.color}]`;
    }
    return `border border-${props.color}-500`;
  }
  // if color starts with #
  if (props.color[0] === "#") {
    return `border bg-[${props.color}]`;
  }
  if (props.color === "gray") {
    return "bg-gray-25 text-${props.color}-700";
  }
  if (props.bold) {
    return `bg-${props.color}-100 text-${props.color}-800`;
  }
  return `bg-${props.color}-100 text-${props.color}-700`;
});

const chipSubLabelColor = computed(() => {
  if (props.outlined) {
    if (props.color === "gray") {
      return "border-gray-200 border";
    }
    // if color starts with #
    if (props.color[0] === "#") {
      return `border border-[${props.color}]`;
    }
    return `border border-${props.color}-300`;
  }
  // if color starts with #
  if (props.color[0] === "#") {
    return `border bg-[${props.color}]`;
  }
  if (props.bold) {
    return `bg-${props.color}-50 text-${props.color}-900`;
  }
  return `bg-${props.color}-50 text-${props.color}-800`;
});

const chipClearable = computed(() =>
  props.clearable ? "pr-1 pl-1" : "pr-2 pl-2"
);
const chipRounded = computed(() => {
  if (props.rounded) return "rounded-full";
  else if (props.subLabel) return "rounded-t-md";
  return "rounded-md";
});
const chipSize = computed(() => {
  if (props.large) {
    return "h-9";
  }
  if (props.small) {
    return "h-6";
  }
  return "h-7";
});

const chipWidth = computed(() => {
  const subLabelLengthPenalty = props.rounded ? 0.7 : 1;
  const longestTextLength =
    props.label.length > props.subLabel.length * subLabelLengthPenalty
      ? props.label.length
      : props.subLabel.length;

  return `w-[${Math.ceil(longestTextLength / 10) * 75}px]`;
});

const chipLabelSize = computed(() => {
  if (props.large) {
    return "text-md";
  }
  if (props.small) {
    return "text-xs";
  }
  return "text-xs";
});

const chipSubLabelSize = computed(() => {
  if (props.large) {
    return "text-sm leading-4";
  }
  if (props.small) {
    return "text-[8px] leading-3";
  }
  return "text-[12px] leading-3";
});

const chipIconClass = computed(() => {
  if (props.iconTrailing) {
    return "ml-0.5";
  } else if (props.label) {
    return "-order-1 mr-0.5";
  }
  return "-order-1";
});
</script>
