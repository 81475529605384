<template>
  <Line
    v-if="data"
    :data="data"
    :options="options"
    :plugins="plugins"
    data-testid="vchartline-bar"
  />
  <ChartLegend
    v-if="props.separateLegend && data"
    :data="data"
    origin="line"
    @click="props.isLegendInteractive && handleLegendClick"
    :donut-default-data-text="props.donutDefaultDataText"
  />
</template>

<script setup>
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import { computed, onMounted, ref, watch } from "vue";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ChartLegend from "@/components/helpers/ChartLegend.vue";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const props = defineProps({
  /*
   ** Data structure:
   ** {
   **    label: String,
   **    color: String,
   **    active: String,
   **    data: {
   **             label: String,
   **             amount: Number,
   **          }
   ** }
   */
  data: {
    type: Array,
    required: true,
  },
  pinpointColorHover: {
    type: String,
    default: undefined,
  },
  borderWidth: {
    type: Number,
    default: 3,
  },
  showGrid: {
    type: Boolean,
    default: false,
  },
  showFixedValues: {
    type: Boolean,
    default: false,
  },
  showTooltips: {
    type: Boolean,
    default: true,
  },
  showLines: {
    type: Boolean,
    default: true,
  },
  stepped: {
    type: Boolean,
    default: false,
  },
  tension: {
    type: Number,
    default: 0,
  },
  legendPosition: {
    type: String,
    default: "bottom",
  },
  separateLegend: {
    type: Boolean,
    default: true,
  },
  isLegendInteractive: {
    type: Boolean,
    default: true,
  },
  donutDefaultDataText: {
    type: String,
    default: "Other",
  },
});

const plugins = [props.showFixedValues && ChartDataLabels];
const options = computed(() => ({
  scales: {
    x: {
      grid: {
        display: props.showGrid,
      },
    },
    y: {
      grid: {
        display: props.showGrid,
      },
    },
  },
  plugins: {
    tooltip: {
      enabled: props.showTooltips,
    },
    legend: {
      display: !props.separateLegend,
      position: props.legendPosition ?? "bottom",
      align: "start",
      onHover,
    },
  },
}));

const propsData = ref(props.data);
const data = ref(null);

const onHover = (e) => {
  e.native.target.style.cursor = "pointer";
};

const generateData = () => {
  const labels = [
    ...new Set(
      props.data.flatMap((dataValue) =>
        dataValue.data.flatMap((childData) => childData.label)
      )
    ),
  ];
  const datasets = props.data
    .filter(({ active }) => active)
    .map((dataValue) => ({
      label: dataValue.label,
      backgroundColor: dataValue.color,
      hoverBackgroundColor: props.pinpointColorHover,
      tension: props.tension,
      borderColor: dataValue.color,
      showLine: props.showLines,
      stepped: props.stepped ? "middle" : false,
      borderWidth: props.borderWidth,
      datalabels: props.showFixedValues
        ? {
            anchor: "end",
            align: "top",
            font: {
              weight: "bold",
            },
          }
        : {},
      data: dataValue.data.map(({ amount }) => amount),
    }));

  data.value = { labels, datasets };
};

const handleLegendClick = (index) => {
  propsData.value[index].active = !propsData.value[index].active;
  generateData();
};

watch([props], generateData);

onMounted(generateData);
</script>
