<template>
  <div class="flex text-center">
    <div class="mt-4 w-full">
      <div
        class="hover:bg-primary-25 group cursor-pointer rounded border-2 border-gray-100 bg-white py-4 px-8 hover:border-primary-100"
        :class="isOverDropZone ? 'bg-primary-25 border-primary-100' : ''"
        @click="res.open()"
        ref="dropZoneRef"
        @drop.prevent="onDrop($event)"
      >
        <div
          class="mx-auto h-14 w-14 rounded-full bg-gray-50 pt-2 group-hover:bg-primary-50"
          :class="isOverDropZone ? '!bg-primary-50' : ''"
        >
          <div
            class="mx-auto h-10 w-10 rounded-full bg-gray-200 pt-2 pl-2 text-gray-700 group-hover:bg-primary-100 group-hover:text-primary-700"
            :class="isOverDropZone ? '!bg-primary-100 !text-primary-700' : ''"
          >
            <VIcon name="cloud_upload" />
          </div>
        </div>
        <div
          class="mt-4 font-medium text-gray-900 group-hover:text-primary-700"
          :class="isOverDropZone ? '!text-primary-700' : ''"
        >
          Click to upload
          <span
            class="text-gray-400 group-hover:text-primary-600"
            :class="isOverDropZone ? '!text-primary-600' : ''"
            >or drag and drop</span
          >
        </div>
        <div
          class="mt-2 mb-4 text-sm text-gray-400 group-hover:text-primary-600"
          :class="isOverDropZone ? '!text-primary-600' : ''"
        >
          <slot name="message"> {{ message }} </slot>
        </div>
        <slot name="template" v-if="props.templateLink" class="mb-2 mt-2">
          <div>
            <VButton
              :label="templateLinkLabel"
              icon="file_open"
              class="inline-flex uppercase"
              @click.stop="openLink()"
            />
          </div>
        </slot>
      </div>
      <div
        v-if="selectedFiles.length > 0"
        class="mt-2 rounded border-2 border-gray-100 bg-white py-4 px-8"
      >
        <div
          v-for="file of selectedFiles"
          :key="file.name"
          class="flex justify-between space-y-2"
        >
          {{ file.name }} -{{ file.data }}
          <div>
            <VIcon
              name="trash"
              class="px-auto cursor-pointer"
              small
              button
              @click="removeFile(file)"
            ></VIcon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useFileSystemAccess, useDropZone } from "@vueuse/core";
import { log } from "@/components/helpers/logger-helper";
// eslint-disable-next-line sonarjs/no-duplicate-string
const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  message: {
    type: String,
    default: "",
  },
  templateLink: {
    type: String,
    default: "",
  },
  templateLinkLabel: {
    type: String,
    default: "TEMPLATE",
  },
});

const dropZoneRef = ref(null);

const selectedFiles = ref(() => []);

const fileData = ref(null);

// turn the raw text into an array of arrays
const getDataRows = (csv) => {
  const rows = csv.split("\r\n");
  const dataRows = [];
  rows.forEach((row) => {
    dataRows.push(row.split(","));
  });
  return dataRows;
};

const openLink = () => {
  window.open(props.templateLink, "_blank");
};

const removeFile = (file) => {
  selectedFiles.value = selectedFiles.value.filter((f) => f.name != file.name);
  fileData.value = null;
  updateModelValue();
};

const updateModelValue = () => {
  emit("update:modelValue", { data: fileData.value });
};

// ===================================
// ========== FILE SYSTEM ============
// ===================================

const dataType = ref("Text");
const res = useFileSystemAccess({
  dataType,
  types: [
    {
      description: "text",
      accept: {
        "text/plain": [".csv"],
      },
    },
  ],
  excludeAcceptAllOption: true,
});

// ===================================
// ========== DRAG AND DROP ==========
// ===================================
// read the file and return the data
const readFile = (file, callback) => {
  if (file) {
    let reader = new FileReader();
    reader.readAsText(file, "UTF-8");

    reader.onload = function (evt) {
      callback(evt.target.result);
    };

    reader.onerror = function () {};
  }
};

const onDrop = (e) => {
  const droppedFile = e.dataTransfer.files[0];
  if (droppedFile.type != "text/csv") {
    const errorContext = {
      errorType: "COMPONENT_ERROR",
      message: "File must be a CSV (comma separated)",
      source: "VDropbox.vue",
      function: "onDrop",
      data: {
        file: droppedFile,
        type: droppedFile.type,
      },
    };
    log.error(e, {
      userMessage: errorContext.message,
      context: errorContext,
    });
    return;
  }
  selectedFiles.value = [droppedFile];
  readFile(droppedFile, (result) => {
    fileData.value = getDataRows(result);
  });
};

const { isOverDropZone } = useDropZone(dropZoneRef);

// ===================================
// ======== UPDATE THE DATE ==========
// ===================================
watch(
  () => res.data.value,
  (data) => {
    fileData.value = getDataRows(data);
    selectedFiles.value = [res.file.value];
  }
);

watch(
  () => fileData.value,
  (value) => {
    emit("update:modelValue", { data: value });
  }
);
// const str = JSON.stringify(
//   reactive({
//     isSupported: res.isSupported,
//     file: res.file,
//     fileName: res.fileName,
//     fileMIME: res.fileMIME,
//     fileSize: res.fileSize,
//     fileLastModified: res.fileLastModified,
//   })
// );
</script>
