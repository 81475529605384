<template>
  <div
    ref="wrapper"
    class="relative"
    data-testid="vtooltip-wrapper"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <slot></slot>
    <teleport to="body">
      <div
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
        class="tooltip-absolute bg-info-100 w-max p-1 px-2 rounded-lg border-2 border-info-200 z-[9999]"
        :class="tooltipClasses"
        ref="tooltip"
        v-show="props.show"
        data-testid="vtooltip-container"
      >
        <div>
          <div
            ref="arrow"
            class="absolute flex h-2 w-2 rotate-45 bg-info-100 border-info-200"
            :class="arrowBorder"
            data-testid="vtooltip-arrow"
          ></div>
          <div
            data-testid="vtooltip-content"
            class="text-google-blue-800 text-sm max-w-[300px]"
            @click="handleTooltipArticle"
          >
            <slot name="tooltip">{{ props.tooltip }}</slot>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, onUnmounted } from "vue";
import { useIntercom } from "@/composables/intercom";

const props = defineProps({
  tooltip: {
    type: String,
    default: "",
  },
  tooltipArticle: {
    type: String,
    default: "",
  },
  position: {
    type: String,
    default: "bottom",
    validator: (value) => ["top", "right", "bottom", "left"].includes(value),
  },
  forceRecalculatePositionOnMouseEnter: {
    type: Boolean,
    default: false,
  },
  show: {
    type: Boolean,
    default: true,
  },
});

const wrapper = ref(null);
const arrow = ref(null);
const tooltip = ref(null);
const showTooltip = ref(false);
const positionCalculated = ref(false);
let hideTimeout = null;

const getAbsolutePosition = (element) => {
  const rect = element.getBoundingClientRect();
  return {
    top: rect.top + window.scrollY,
    left: rect.left + window.scrollX,
  };
};

const calculateTooltipPosition = () => {
  const child = wrapper.value.children[0];
  if (!child) return;

  const childPosition = getAbsolutePosition(child);
  const childWidth = child.offsetWidth;
  const childHeight = child.offsetHeight;
  const tooltipWidth = tooltip.value.offsetWidth;
  const tooltipHeight = tooltip.value.offsetHeight;
  const arrowWidth = arrow.value.offsetWidth;
  const arrowHeight = arrow.value.offsetHeight;

  let tooltipTop = 0;
  let tooltipLeft = 0;
  let arrowTop = 0;
  let arrowLeft = 0;
  let arrowRight = 0;
  let arrowBottom = 0;

  switch (props.position) {
    case "left":
      tooltipTop = childPosition.top + childHeight / 2 - tooltipHeight / 2;
      arrowTop = tooltipHeight / 2 - arrowHeight / 2;
      arrowRight = -(arrowWidth / 2 + 2);
      tooltipLeft = childPosition.left - tooltipWidth - 5;

      arrow.value.style.top = `${arrowTop}px`;
      arrow.value.style.right = `${arrowRight}px`;
      break;

    case "right":
      tooltipTop = childPosition.top + childHeight / 2 - tooltipHeight / 2;
      tooltipLeft = childPosition.left + childWidth + 5;
      arrowTop = tooltipHeight / 2 - arrowHeight / 2;
      arrowLeft = -(arrowWidth / 2 + 2);

      arrow.value.style.top = `${arrowTop}px`;
      arrow.value.style.left = `${arrowLeft}px`;
      break;

    case "top":
      tooltipLeft = childPosition.left - tooltipWidth / 2 + childWidth / 2;
      tooltipTop = childPosition.top - tooltipHeight - 5;
      arrowBottom = -(arrowHeight / 2 + 2);
      arrowLeft = tooltipWidth / 2 - arrowWidth / 2;

      arrow.value.style.bottom = `${arrowBottom}px`;
      arrow.value.style.left = `${arrowLeft}px`;
      break;

    case "bottom":
    default:
      tooltipLeft = childPosition.left - tooltipWidth / 2 + childWidth / 2;
      tooltipTop = childPosition.top + childHeight + 5;
      arrowTop = -(arrowHeight / 2 + 2);
      arrowLeft = tooltipWidth / 2 - arrowWidth / 2;

      arrow.value.style.top = `${arrowTop}px`;
      arrow.value.style.left = `${arrowLeft}px`;
      break;
  }

  tooltip.value.style.top = `${tooltipTop}px`;
  tooltip.value.style.left = `${tooltipLeft}px`;
};

const setInitialPosition = () => {
  // Center the tooltip on the screen (to avoid scrollbars)
  const tooltipWidth = tooltip.value.offsetWidth;
  const tooltipHeight = tooltip.value.offsetHeight;
  const centeredTop = (window.innerHeight - tooltipHeight) / 2;
  const centeredLeft = (window.innerWidth - tooltipWidth) / 2;

  tooltip.value.style.top = `${centeredTop}px`;
  tooltip.value.style.left = `${centeredLeft}px`;
};

const handleMouseEnter = () => {
  if (!positionCalculated.value || props.forceRecalculatePositionOnMouseEnter) {
    calculateTooltipPosition();
    positionCalculated.value = true;
  }

  if (hideTimeout) {
    clearTimeout(hideTimeout);
    hideTimeout = null;
  }

  showTooltip.value = true;
};

const handleMouseLeave = () => {
  hideTimeout = setTimeout(() => {
    showTooltip.value = false;
  }, 100);
};

// FIXME: This is not working as expected
// const handleScroll = () => {
//   console.log("scrolling");
//   if (showTooltip.value) {
//     calculateTooltipPosition();
//   }
// };

onMounted(() => {
  setInitialPosition();
});

const tooltipClasses = computed(() => ({
  "tooltip-visible": showTooltip.value,
  "tooltip-hidden": !showTooltip.value,
}));

const arrowBorder = computed(() => {
  switch (props.position) {
    case "left":
      return "border-r-2 border-t-2";
    case "right":
      return "border-l-2 border-b-2";
    case "top":
      return "border-r-2 border-b-2";
    case "bottom":
      return "border-l-2 border-t-2";
  }
});

const handleTooltipArticle = () => {
  if (props.tooltipArticle) {
    useIntercom().showArticle(props.tooltipArticle);
  }
};
</script>

<style>
.tooltip-visible {
  opacity: 1;
  pointer-events: auto;
}

.tooltip-hidden {
  opacity: 0;
  pointer-events: none;
}

.tooltip-absolute {
  position: absolute;
}
</style>
