<template>
  <div class="inline-flex">
    <VRow
      gap="0"
      class="py-auto px-3 items-center justify-center font-medium rounded-full text-nowrap select-none"
      :class="[chipColor, chipSize, chipLabelSize]"
      data-testid="vstatuschip-container"
    >
      <div v-if="icon" class="my-auto mr-1" data-testid="vstatuschip-icon">
        <VIcon
          class="mt-0.5 inline-flex flex-shrink-0 items-center justify-center"
          :name="icon"
          :small="!large"
        />
      </div>
      <VCol>
        {{ statusLabel }}
      </VCol>
    </VRow>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  status: {
    type: String,
    default: "",
  },
  large: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
});

const colorMappings = {
  waitingForUserInteraction: "bg-google-blue-200 text-google-blue-900",
  running: "bg-google-yellow-200 text-google-yellow-900",
  success: "bg-google-green-50 text-google-green-800",
  error: "bg-google-red-100 text-google-red-900",
  canceled: "bg-google-purple-200 text-google-purple-900",
  default: "bg-gray-25 text-gray-500",
};

const statusMappings = {
  cancelled: {
    label: "Canceled",
    icon: "cancel",
    color: colorMappings.canceled,
  },
  canceled: {
    label: "Canceled",
    icon: "cancel",
    color: colorMappings.canceled,
  },
  pending_analysis_approval: {
    label: "Pending approval",
    icon: "task_alt",
    color: colorMappings.waitingForUserInteraction,
  },
  completed: {
    label: "Completed",
    icon: "check",
    color: colorMappings.success,
  },
  finished: {
    label: "Finished",
    icon: "check",
    color: colorMappings.success,
  },
  active: {
    label: "Active",
    icon: "check",
    color: colorMappings.success,
  },
  subscribed: {
    label: "Subscribed",
    icon: "check",
    color: colorMappings.success,
  },
  inactive: {
    label: "Inactive",
    icon: "close",
    color: colorMappings.error,
  },
  removed: {
    label: "Removed",
    icon: "close",
    color: colorMappings.error,
  },
  deleted: {
    label: "Deleted",
    icon: "close",
    color: colorMappings.error,
  },
  failed: {
    label: "Failed",
    icon: "close",
    color: colorMappings.error,
  },
  skipped: {
    label: "Skipped",
    icon: "skip_next",
    color: colorMappings.canceled,
  },
  archived: {
    label: "Archived",
    icon: "archive",
    color: colorMappings.canceled,
  },
  invited: {
    label: "Invited",
    icon: "mail",
    color: colorMappings.waitingForUserInteraction,
  },
  draft: {
    label: "Draft",
    icon: "edit",
    color: colorMappings.default,
  },
  paused: {
    label: "Paused",
    icon: "pause",
    color: colorMappings.waitingForUserInteraction,
  },
  pending_run: {
    label: "In progress",
    icon: "sync",
    color: colorMappings.running,
  },
  in_progress: {
    label: "In progress",
    icon: "sync",
    color: colorMappings.running,
  },
  pending_analysis: {
    label: "Analyzing impact",
    icon: "sync",
    color: colorMappings.running,
  },
  analyzing: {
    label: "Analyzing impact",
    icon: "sync",
    color: colorMappings.running,
  },
  enabled: {
    label: "Active",
    icon: "play_arrow",
    color: colorMappings.success,
  },
};

const getNormalizedStatus = () => props.status.toLowerCase();

const statusLabel = computed(() => {
  const status = getNormalizedStatus();
  return (
    statusMappings[status]?.label ||
    status.charAt(0).toUpperCase() + status.slice(1).split("_").join(" ")
  );
});

const icon = computed(() => {
  const status = getNormalizedStatus();
  return statusMappings[status]?.icon || "pending";
});

const chipColor = computed(() => {
  const status = getNormalizedStatus();
  return statusMappings[status]?.color || colorMappings.default;
});

const chipSize = computed(() => {
  if (props.large) {
    return "h-9";
  }
  if (props.small) {
    return "h-6";
  }
  return "h-7";
});

const chipLabelSize = computed(() => {
  if (props.large) {
    return "text-md";
  }
  if (props.small) {
    return "text-xs";
  }
  return "text-xs";
});
</script>
