<template>
  <div>
    <slot></slot>
  </div>
</template>

<script setup>
import { ref, provide, onMounted, watch, onUpdated } from "vue";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: Array,
  },
  reset: {
    type: Boolean,
    default: false,
  },
  selectAll: {
    type: Boolean,
    default: false,
  },
});

const subscribers = ref({});
const forceSelectAll = ref(props.selectAll);

const getCheckedValues = () =>
  Object.values(subscribers.value)
    .filter((subscriber) => subscriber.checkedStatus && !subscriber.selectAll)
    .map((subscriber) => subscriber.value);

let checkedValuesSaved = ref(getCheckedValues());

const triggerEmit = () => {
  const newCheckedValues = getCheckedValues();

  if (newCheckedValues !== checkedValuesSaved.value) {
    checkedValuesSaved.value = newCheckedValues;
    forceSelectAll.value = false;
    emit("update:modelValue", checkedValuesSaved.value);
  }
};

// function to add a subscriber to the subscribers object
const addSubscriber = (component) => {
  if (subscribers.value[component.value]) {
    console.warn(
      `Checkbox with value '${component.value}' already exists in this CheckboxGroup. Choose a unique value for each checkbox in a group.`
    );
  } else {
    subscribers.value[component.value] = component;
  }
};

// We need to update the subscriber object when the component changes
const updateSubscriber = (component) => {
  subscribers.value[component.value].checkedStatus = component.checkedStatus;
  if (!component.selectAll) {
    updateGroupStatus();
  } else {
    updateSubscribers(component.checkedStatus === "ALL");
  }
  triggerEmit();
};

// We need to update the subscriber object when the component changes
const removeSubscriber = (component) => {
  delete subscribers.value[component.value];
};

const resetSubscribers = () => {
  subscribers.value = {};
};

const updateSubscribers = (status) => {
  Object.values(subscribers.value).forEach((subscriber) => {
    subscriber.checkedStatus = status;
    (!subscriber.selectAll || forceSelectAll.value) &&
      subscriber.update(status);
  });
};

// when one of the subscribers changes, we need to update the group status
const updateGroupStatus = () => {
  // loop through the subscribers and check if all of them are checked, none of them are checked or some of them are checked
  // we skip the subscriber with the selectAll: true value
  let allChecked = true;
  let noneChecked = true;
  Object.values(subscribers.value).forEach((subscriber) => {
    if (subscriber.selectAll) {
      return;
    }
    if (subscriber.checkedStatus) {
      noneChecked = false;
    } else {
      allChecked = false;
    }
  });
  Object.values(subscribers.value).forEach((subscriber) => {
    if (subscriber.selectAll) {
      if (allChecked) {
        subscriber.update("ALL");
      } else if (noneChecked) {
        subscriber.update("NONE");
      } else {
        subscriber.update("INDETERMINATE");
      }
    }
  });
};

const updateForceSelectAll = () => {
  forceSelectAll.value = props.selectAll;
};

const setup = () => {
  Object.values(subscribers.value).forEach((subscriber) => {
    if (subscriber.selectAll) {
      return;
    }
    let isChecked =
      forceSelectAll.value || props.modelValue?.includes(subscriber.value);

    subscriber.checkedStatus = isChecked;
    subscriber.update(isChecked);
  });
  updateGroupStatus();
};

watch(() => props.reset, resetSubscribers);
watch(() => props.selectAll, updateForceSelectAll);

onMounted(setup);
onUpdated(setup);

provide("group", {
  subscribers,
  addSubscriber,
  updateSubscriber,
  removeSubscriber,
  resetSubscribers,
});
</script>
