import { defineStore } from "pinia";
import { BigQueryJobsService } from "@/apis/security-auditing/services/BigQueryJobsService";
import { useAPIWrapper } from "@/composables/api-wrapper";
import { ReportsService } from "@/apis/security-auditing";
import { ref, watch } from "vue";
import { useErrorStore } from "@/stores";

const storeId = "security-auditing-report-label-stats-store";

export const useReportLabelStatsStore = defineStore(storeId, () => {
  // ===================== //
  // ======= INIT ======== //
  // ===================== //
  const errorStore = useErrorStore();

  const errorMessages = {
    listItems: `Failed to retrieve your labels. ${errorStore.defaultErrorMessage}`,
    getQueryJobStatus: `Failed to retrieve your labels. ${errorStore.defaultErrorMessage}`,
  };

  const services = {
    listItemsService: ReportsService.listLabelStats,
    getQueryJobStatusService: BigQueryJobsService.pollJobResult,
  };

  const returnObjectKey = "rows";

  const listServiceParams = ["customerId", "query", "sortBy", "sortOrder"];

  // set the values to determine if the filtering and pagination should be done on the client or server side
  const clientSideFiltering = true;
  const clientSidePagination = true;

  // orgUnitFilter is used to determine if the orgUnit filter should be used
  const useOrgUnitFilter = {
    id: "orgUnitId",
  };

  const useIncludeOrgUnitsFilter = {
    id: "includeSubOrgUnits",
  };
  // ===================== //
  // ======= INIT ======== //
  // ===================== //

  // use the API wrapper composable to handle filtering, sorting, pagination and listing of items
  const {
    // filters
    activeFilters,
    activeAdvancedFilters,
    clearAllFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    removeFilter,
    updateFilter,

    // list
    listItems,
    items,
    totalItems,
    pageToken,

    // general
    resetStore,
    reset,
    loading,
  } = useAPIWrapper(
    storeId,
    services,
    returnObjectKey,
    clientSideFiltering,
    clientSidePagination,
    listServiceParams,
    useOrgUnitFilter,
    useIncludeOrgUnitsFilter,
    errorMessages
  );

  const sortedItems = ref();

  const processedItems = ref();
  const processedItemsWithDisabled = ref();
  const showDisabled = ref(false);

  const getLabels = () => {
    if (!processedItems.value[0].orgUnitId) return processedItems.value;
    const labels = processedItems.value.reduce(
      (list, { labelStats, orgUnitName, orgUnitId }) => {
        labelStats?.map((labelStats) => {
          list.push({ ...labelStats, orgUnitName, orgUnitId });
        });
        return list;
      },
      []
    );

    return [...new Set(labels)];
  };

  const groupLabels = () =>
    processedItems.value.reduce((list, item) => {
      if (list.map(({ title }) => title).includes(item.title)) {
        const listItemIndex = list.findIndex(({ id }) => id === item.id);
        list[listItemIndex] = Object.entries(list[listItemIndex])
          .map(([key, value]) => {
            if (typeof value === "number") {
              return {
                [key]: value + item[key],
              };
            }
            return {
              [key]: value,
            };
          })
          .reduce((list, obj) => {
            return { ...list, ...obj };
          }, {});
      } else {
        list.push(item);
      }
      return list;
    }, []);

  const formatItems = () => {
    processedItems.value.sort((item) => (item.title === "Unlabeled" ? -1 : 1));

    sortedItems.value = processedItems.value.map(({ item }) => ({ ...item }));
  };

  const getFilteredLabelsByName = () => {
    const filter = activeFilters.value.find(
      ({ id }) => id === "labelStats.title"
    );

    return processedItems.value.filter(({ title }) =>
      title.toLowerCase().includes(filter.value.toLowerCase())
    );
  };

  const applyItemsFromProcessed = () => {
    if (!showDisabled.value) {
      items.value = processedItems.value;
    } else {
      items.value = processedItemsWithDisabled.value;
    }
  };

  watch(
    [loading],
    async () => {
      if (
        !loading.value &&
        items.value[0] &&
        items.value !== sortedItems.value &&
        items.value !== processedItems.value &&
        items.value !== processedItemsWithDisabled.value
      ) {
        processedItems.value = [...items.value];
        processedItems.value = getLabels();
        processedItems.value = groupLabels();
        if (activeFilters.value?.find(({ id }) => id === "labelStats.title")) {
          processedItems.value = getFilteredLabelsByName();
        } else {
          formatItems();
        }

        processedItemsWithDisabled.value = processedItems.value;

        processedItems.value = processedItems.value.filter(
          (item) => item.isDisabled !== true
        );

        applyItemsFromProcessed();
      }
    },
    { deep: true }
  );

  watch(showDisabled, applyItemsFromProcessed);

  return {
    // filters
    activeFilters,
    useOrgUnitFilter,
    activeAdvancedFilters,
    clearAllFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    removeFilter,
    updateFilter,

    // list
    items,
    listItems,
    totalItems,
    pageToken,
    clientSidePagination,

    showDisabled,

    // general
    resetStore,
    reset,
    loading,
  };
});
