<template>
  <div
    v-if="large"
    class="py-auto inline-flex min-w-fit items-center justify-center font-medium"
    :class="[chipColor, chipClearable, chipRounded, chipSize]"
    data-testid="vchip-container"
  >
    <VCol>
      <VRow gap="1" v-if="props.label || props.sublabel">
        <span class="max-w-[200px] truncate font-bold">
          {{ props.label }}
        </span>
        <VCopyButton
          v-if="props.copyLabel"
          class="mr-1.5 ml-2 text-gray-200"
          mini
          :content="props.label"
          @copy="onCopy"
        />
      </VRow>
      <VRow gap="1" v-if="props.sublabel">
        <span class="max-w-[200px] text-xs text-gray-400 truncate">
          {{ props.sublabel }}
        </span>
        <VCopyButton
          v-if="props.copySublabel"
          class="mr-1.5 ml-2 text-gray-200"
          mini
          :content="props.sublabel"
          @copy="onCopy"
        />
      </VRow>
    </VCol>
    <div v-if="icon" :class="[chipIconClass, 'my-auto']">
      <img
        v-if="props.picture"
        alt="User profile picture"
        class="mx-2.5 -mt-[3px] -ml-2.5 bg-white border-2 h-12 w-12 rounded-full"
        :src="props.picture"
        :class="[chipColor, iconColor]"
      />

      <VIcon
        v-else
        class="mx-2.5 -mt-[3px] -ml-2.5 border bg-white rounded-full h-12 w-12 flex-shrink-0 justify-center"
        :class="[chipColor, iconColor]"
        :name="icon"
        large
      />
    </div>

    <VIcon
      button
      @click.stop="() => onClear()"
      v-if="clearable"
      class="ml-1 inline-flex flex-shrink-0 items-center justify-center"
      name="close"
      :small="!large"
      :bg-color="color"
      data-testid="vchip-clearable"
    />
  </div>

  <div
    v-else
    class="py-auto inline-flex min-w-fit items-center justify-center font-medium"
    :class="[chipColor, chipClearable, chipRounded, chipSize]"
    data-testid="vchip-container"
  >
    <VRow gap="1" v-if="props.label || props.sublabel">
      <span class="max-w-[200px] truncate">
        {{ props.label || props.sublabel }}
      </span>
      <VCopyButton
        v-if="props.copyLabel || props.copySublabel"
        class="ml-2 text-gray-200"
        mini
        :content="props.label || props.sublabel"
        @copy="onCopy"
      />
    </VRow>
    <div v-if="icon" :class="[chipIconClass, 'my-auto']">
      <VIcon
        class="mr-1 inline-flex flex-shrink-0 items-center justify-center"
        :name="icon"
        :class="[iconColor]"
      />
    </div>

    <VIcon
      button
      @click.stop="() => onClear()"
      v-if="clearable"
      class="ml-1 inline-flex flex-shrink-0 items-center justify-center"
      name="close"
      :small="!large"
      :bg-color="color"
      data-testid="vchip-clearable"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";

const emit = defineEmits(["clear", "copy"]);

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  sublabel: {
    type: String,
    default: "",
  },
  picture: {
    type: String,
    default: null,
  },
  iconTrailing: {
    type: Boolean,
    default: false,
  },
  large: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: "",
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  copyLabel: {
    type: Boolean,
    default: false,
  },
  copySublabel: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: "primary",
  },
  rounded: {
    type: Boolean,
    default: true,
  },
  outlined: {
    type: Boolean,
    default: true,
  },
});

const chipColor = computed(() => {
  if (props.outlined) {
    if (props.color === "gray") {
      return "border-gray-300 border";
    }
    return `border border-${props.color}-500`;
  }
  return `bg-${props.color}-100 text-${props.color}-700`;
});

const iconColor = computed(() => {
  if (props.outlined) {
    if (props.color === "gray") {
      return "text-gray-300";
    }
    return `text-${props.color}-500`;
  }
  return `text-${props.color}-700`;
});

const chipClearable = computed(() =>
  props.clearable ? "pr-1 pl-1" : "pr-2 pl-2"
);
const chipRounded = computed(() =>
  props.rounded ? "rounded-full" : "rounded-md"
);
const chipSize = computed(() => {
  if (props.large) {
    return "text-xs h-11";
  }
  return "text-xs h-7";
});

const chipIconClass = computed(() => {
  if (props.iconTrailing) {
    return "ml-0.5";
  } else if (props.label) {
    return "-order-1 mr-0.5";
  }
  return "-order-1";
});

const onClear = () => {
  emit("clear");
};

const onCopy = () => {
  emit("copy");
};
</script>
