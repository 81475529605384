<template>
  <div
    class="rounded border p-4"
    :class="alertClass['card']"
    v-if="isOpen"
    data-testid="valert-card"
  >
    <div class="flex items-center">
      <div class="flex-shrink-0">
        <VIcon
          :large="iconLarge"
          :name="iconName"
          :class="alertClass['icon']"
          aria-hidden="true"
          data-testid="valert-icon"
        />
      </div>
      <VRow class="ml-5 flex-1 justify-between space-y-4">
        <div class="mt-1">
          <div
            class="font-semibold text-sm"
            :class="alertClass['title']"
            v-if="props.title || $slots.title"
            data-testid="valert-title"
          >
            <template v-if="$slots.title">
              <slot name="title"></slot>
            </template>
            <template v-else>
              {{ props.title }}
            </template>
          </div>
          <div
            :class="[
              alertClass['message'],
              (props.title || $slots.title) && 'mt-4',
            ]"
            v-if="props.message || $slots.message"
            data-testid="valert-message"
            class="text-sm"
          >
            <template v-if="$slots.message">
              <slot name="message"></slot>
            </template>
            <template v-else>
              {{ props.message }}
            </template>
          </div>
          <div v-if="$slots.actions" class="pt-5">
            <slot name="actions"></slot>
          </div>
        </div>
        <VCol class="items-end justify-between ml-6 !mt-0">
          <VButton
            variant="tertiary"
            v-if="closable"
            class="w-fit h-fit"
            @click="handleOpen"
            data-testid="valert-close-button"
          >
            <VIcon
              name="close"
              small
              :class="!props.outlined ? 'text-white' : 'text-gray-400'"
            />
          </VButton>
          <p class="text-sm" v-if="props.link">
            <router-link
              :to="props.link"
              class="whitespace-nowrap font-medium"
              :class="alertClass['link']"
              data-testid="valert-link"
            >
              {{ props.linkLabel }}
              <span aria-hidden="true"> &rarr;</span>
            </router-link>
          </p>
        </VCol>
      </VRow>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";

const props = defineProps({
  alertType: {
    type: String,
    default: "info",
    validator: (value) => {
      const validAlertTypes = ["info", "success", "warning", "error"];
      if (!validAlertTypes.includes(value)) {
        console.warn(`Invalid alertType: ${value}. Defaulting to "info".`);
        return false;
      }
      return true;
    },
  },
  title: {
    type: String,
    default: "",
  },
  message: {
    type: String,
    default: "",
  },
  link: {
    type: String,
    default: null,
  },
  linkLabel: {
    type: String,
    default: "Details",
  },
  closable: {
    type: Boolean,
    default: false,
  },
  outlined: {
    type: Boolean,
    default: false,
  },
  iconLarge: {
    type: Boolean,
    default: false,
  },
  filled: {
    type: Boolean,
    default: true,
  },
});

const isOpen = ref(true);

const iconName = computed(() => {
  switch (props.alertType) {
    case "success":
      return "check_circle";
    case "warning":
      return "warning";
    case "error":
      return "dangerous";
    default:
      return "info";
  }
});

const alertClass = computed(() => {
  const cardColor = props.filled
    ? !props.outlined
      ? `border-none bg-${props.alertType}-600`
      : `border-${props.alertType}-300 bg-${props.alertType}-100`
    : " border-none";
  const linkColor = !props.outlined
    ? "text-white"
    : `text-${props.alertType}-700 hover:text-${props.alertType}-800`;
  const iconColor = !props.outlined
    ? "text-white"
    : props.filled
    ? `text-${props.alertType}-800`
    : `text-${props.alertType}-600`;
  const titleColor = !props.outlined ? "text-white" : `text-${props.alertType}-800`;
  const messageColor = !props.outlined ? "text-white" : `text-${props.alertType}-800`;

  return {
    card: cardColor,
    link: linkColor,
    icon: iconColor,
    title: titleColor,
    message: messageColor,
  };
});

const handleOpen = () => (isOpen.value = false);
</script>
