<template>
  <div>
    <slot></slot>
  </div>
</template>

<script setup>
import { ref, provide, watch, onMounted } from "vue";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: String,
  },
});

const subscribers = ref({});

// function to add a subscriber to the subscribers object
const addSubscriber = (component) => {
  if (subscribers.value[component.value]) {
    console.warn(
      `Radio with value ${component.value} already exists in this RadioGroup, choose an unique value for each radio in a group`
    );
  }
  subscribers.value[component.value] = component;
};

// We need to update the subscriber object when the component changes
const updateSubscriber = (component) => {
  if (component.checkedStatus) {
    checkedValue.value = component.value;
    Object.values(subscribers.value).forEach((subscriber) => {
      subscriber.update(subscriber.value === checkedValue.value);
    });
  }
};

const checkedValue = ref(props.modelValue ?? null);

watch(
  checkedValue,
  (newValues) => {
    emit("update:modelValue", newValues);
  },
  { deep: true }
);

onMounted(() => {
  Object.values(subscribers.value).forEach((subscriber) => {
    subscriber.update(subscriber.value === props.modelValue);
  });
});

provide("group", {
  addSubscriber,
  updateSubscriber,
});
</script>
