<template>
  <div v-if="customersStore.list.length > 1">
    <div
      class="flex-shrink-0 h-9 w-10 rounded bg-primary text-center text-white text-lg align-middle pt-1 cursor-pointer"
      @click="toggleCustomersModal"
    >
      {{ customerInitials }}
    </div>
    <div class="sr-only">
      <p>{{ activeCustomer?.name }}</p>
      <p>Customer select</p>
    </div>
    <Teleport to="body">
      <VModal
        v-model="showCustomerSelectModal"
        title="Select Customer"
        @close="showCustomerSelectModal = false"
        @confirm="setActiveCustomer"
      >
        <VSingleValueGroup v-model="selectedCustomer">
          <VRadioButton
            xl
            class="mb-2"
            v-for="customer in customersStore.list"
            :key="customer.customerId"
            :value="customer.customerId"
            :label="customer.name"
            :description="
              !checkCustomerActiveStatus(customer)
                ? 'Please activate this account via the link in your email'
                : null
            "
            :disabled="!checkCustomerActiveStatus(customer)"
          />
        </VSingleValueGroup>
      </VModal>
    </Teleport>
  </div>
</template>

<script setup>
import { ref, watchEffect } from "vue";

import { useCustomerStore, useAuthStore } from "@/stores";
const customersStore = useCustomerStore();
const authStore = useAuthStore();
const selectedCustomer = ref(customersStore.activeCustomer?.customerId);
const customerInitials = ref(
  customersStore.activeCustomer?.name.substring(0, 3)
);

watchEffect(() => {
  selectedCustomer.value = customersStore.activeCustomer?.customerId;
  customerInitials.value = customersStore.activeCustomer?.name.substring(0, 3);
});

const setActiveCustomer = () => {
  authStore.setActiveCustomer(selectedCustomer.value).then((response) => {
    const customerTrials = response.customerTrials;
    customersStore.setActiveCustomer(selectedCustomer.value, customerTrials);

    showCustomerSelectModal.value = false;
    window.location.reload();
  });
};

const activeCustomer = ref(customersStore.activeCustomer);

const showCustomerSelectModal = ref(false);
const toggleCustomersModal = () => {
  showCustomerSelectModal.value = !showCustomerSelectModal.value;
};

const checkCustomerActiveStatus = (customer) => customer.status === "ACTIVE";
</script>
