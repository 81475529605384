<template>
  <div class="sm:hidden">
    <label for="tabs" class="sr-only select-none">Select a tab</label>
    <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
    <select
      id="tabs"
      name="tabs"
      class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-600 focus:outline-none focus:ring-primary-600 sm:text-sm select-none"
    >
      <option
        v-for="tab in tabs"
        :key="tab"
        :selected="tab === props.activeTab"
      >
        {{ tab.name }}
      </option>
    </select>
  </div>
  <div class="hidden sm:block">
    <div
      v-if="props.variant === 'basic'"
      class="border-b border-gray-200 overflow-x-auto overflow-y-hidden"
    >
      <nav class="-mb-px flex space-x-8" aria-label="Tabs">
        <VRow
          gap="1"
          v-for="{ name, icon } in tabs"
          :key="name"
          @click="!props.disabled && emit('update:modelValue', name)"
          :class="[tabClass(name)]"
        >
          <VIcon
            v-if="name === props.modelValue && props.checkWhenSelected"
            name="check"
            filled
            small
          />
          <VIcon
            v-if="icon"
            :name="icon"
            filled
            :small="props.includeNameWithIcon"
          />
          <p v-if="!icon || props.includeNameWithIcon">
            {{ name }}
          </p>
        </VRow>
      </nav>
    </div>
    <div v-else-if="props.variant === 'semi-button'" class="flex">
      <VRow
        gap="1"
        v-for="({ name, icon }, index) in tabs"
        :key="name"
        @click="!props.disabled && emit('update:modelValue', name)"
        class="px-2"
        :class="[tabSemiButtonClass(name, index)]"
      >
        <VIcon
          v-if="name === props.modelValue && props.checkWhenSelected"
          name="check"
          class="-mr-2"
          small
          filled
        />
        <VIcon
          v-if="icon"
          :name="icon"
          filled
          :small="props.includeNameWithIcon"
          :class="{
            'mx-2': props.checkWhenSelected && name !== props.modelValue,
          }"
        />
        <p v-if="!icon || props.includeNameWithIcon">
          {{ name }}
        </p>
      </VRow>
    </div>
    <div v-else-if="props.variant === 'button'" class="flex overflow-x-auto">
      <nav class="flex space-x-6" aria-label="Tabs">
        <VRow
          gap="1"
          v-for="{ name, icon } in tabs"
          :key="name"
          @click="!props.disabled && emit('update:modelValue', name)"
          :class="[tabButtonClass(name)]"
          aria-current="page"
        >
          <VIcon
            v-if="name === props.modelValue && props.checkWhenSelected"
            name="check"
            small
            filled
          />
          <VIcon
            v-if="icon"
            :name="icon"
            filled
            :small="props.includeNameWithIcon"
          />
          <p v-if="!icon || props.includeNameWithIcon" class="select-none">
            {{ name }}
          </p>
        </VRow>
      </nav>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable sonarjs/no-duplicate-string */

const props = defineProps({
  tabs: {
    type: Array,
    default: () => [],
  },
  includeNameWithIcon: {
    type: Boolean,
    default: false,
  },
  checkWhenSelected: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    validator: (value) => {
      const validVariants = ["basic", "semi-button", "button"];
      if (!validVariants.includes(value)) {
        console.warn(`Invalid variant: ${value}. Defaulting to "basic".`);
        return false;
      }
      return true;
    },
    default: "basic",
  },
});

const emit = defineEmits(["update:modelValue"]);

const tabClass = (tab) => {
  let result = [
    "whitespace-nowrap",
    "py-4 px-1",
    "text-sm",
    "font-medium",
    "items-center",
  ];

  if (tab === props.modelValue)
    result = [
      ...result,
      "border-b-2",
      "border-primary-600",
      "text-primary-600",
    ];
  else {
    result = [...result, "border-transparent", "text-gray-500"];
    if (!props.disabled) {
      result = [
        ...result,
        "cursor-pointer",
        "hover:border-gray-900",
        "hover:text-gray-800",
      ];
    } else {
      result = [...result, "hover:!bg-transparent"];
    }
  }
  return result.join(" ");
};

const tabSemiButtonClass = (tab, index) => {
  let result = [
    "whitespace-nowrap",
    "py-[6px]",
    "px-1",
    "text-sm",
    "font-medium",
    "btn",
    "btn-secondary",
    "items-center",
  ];

  if (index === 0) result = [...result, "rounded-r-none"];
  else if (index === props.tabs.length - 1)
    result = [...result, "rounded-l-none", "border-l-0"];
  else {
    result = [...result, "rounded-none", "border-l-0"];
  }

  if (tab === props.modelValue)
    result = [...result, "!bg-primary-20", "!text-primary-600"];
  else {
    result = [...result, "border-transparent", "text-gray-500"];
    if (!props.disabled) {
      result = [...result, "cursor-pointer", "hover:text-gray-800"];
    } else {
      result = [...result, "hover:!bg-white"];
    }
  }
  return result.join(" ");
};

const tabButtonClass = (tab) => {
  let result = [
    "py-2",
    "rounded-lg",
    "px-3",
    "text-sm",
    "font-medium",
    "items-center",
  ];

  if (tab === props.modelValue)
    result = [...result, "bg-google-blue-200", "text-google-blue-700"];
  else {
    result = [...result, "text-gray-500"];
    if (!props.disabled) {
      result = [...result, "cursor-pointer", "hover:text-gray-700"];
    } else {
      result = [...result, "hover:!bg-transparent"];
    }
  }
  return result.join(" ");
};
</script>
