/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BigQueryJobResponse } from '../models/BigQueryJobResponse';
import type { BulkAction } from '../models/BulkAction';
import type { BulkActionsListResponse } from '../models/BulkActionsListResponse';
import type { BulkActionUpdateRequest } from '../models/BulkActionUpdateRequest';
import type { CreateBulkActionRequest } from '../models/CreateBulkActionRequest';
import { PageSizeEnum } from '../models/PageSizeEnum';
import type { SortOrderEnum } from '../models/SortOrderEnum';
import type { src__helpers__bulk_action_helper__SortByEnum } from '../models/src__helpers__bulk_action_helper__SortByEnum';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BulkActionsService {

    /**
     * List Bulk Actions
     * List bulk actions
     *
     * Args:
     * customer_id(str): Customer id
     * pagination(PaginationParams): Pagination params
     * filters(BulkActionFilters): Bulk action filters
     *
     * Returns:
     * BulkActionsListResponse: Bulk actions list response
     * @param customerId
     * @param pageSize
     * @param pageToken
     * @param query
     * @param sortBy
     * @param sortOrder
     * @returns BulkActionsListResponse Successful Response
     * @throws ApiError
     */
    public static listBulkActions(
        customerId: string,
        query?: string,
        pageSize?: PageSizeEnum,
        pageToken?: string,
        sortBy?: src__helpers__bulk_action_helper__SortByEnum,
        sortOrder?: SortOrderEnum,
    ): CancelablePromise<BulkActionsListResponse> {
        // Enforce getting all bulk actions
      // FIXME: MOVE TO OTHER PLACE TO FIX THIS
        const DEFAULT_LARGE_VALUE = PageSizeEnum._999999
        return __request(OpenAPI, {
            method: 'GET',
            url: '/security-auditing/{customer_id}/bulk-actions',
            path: {
                'customer_id': customerId,
            },
            query: {
              'query': query,
              'pageSize': DEFAULT_LARGE_VALUE,
              'pageToken': pageToken,
              'sortBy': sortBy,
              'sortOrder': sortOrder,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create Bulk Action
     * Create Bulk Action
     *
     * - **customer_id**: The customer id
     * - **create_bulk_action**: The create_bulk_action
     * - **current_user**: The current user
     * @param customerId
     * @param requestBody
     * @returns BulkAction Successful Response
     * @throws ApiError
     */
    public static createBulkAction(
        customerId: string,
        requestBody?: CreateBulkActionRequest,
    ): CancelablePromise<BulkAction> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/security-auditing/{customer_id}/bulk-actions',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get Bulk Action
     * Get bulk action by id
     *
     * Args:
     * customer_id(str): Customer id
     * bulk_action_id(int): Bulk action id
     *
     * Returns:
     * BulkAction: Bulk action
     * @param customerId
     * @param bulkActionId
     * @returns BulkAction Successful Response
     * @throws ApiError
     */
    public static getBulkAction(
        customerId: string,
        bulkActionId: number,
    ): CancelablePromise<BulkAction> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/security-auditing/{customer_id}/bulk-actions/{bulk_action_id}',
            path: {
                'customer_id': customerId,
                'bulk_action_id': bulkActionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Delete Bulk Action
     * Delete bulk action
     *
     * Args:
     * customer_id(str): Customer id
     * bulk_action_id(int): Bulk action id
     * current_user(AuthenticatedUser): Current user
     *
     * Returns:
     * None
     * @param customerId
     * @param bulkActionId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteBulkAction(
        customerId: string,
        bulkActionId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/security-auditing/{customer_id}/bulk-actions/{bulk_action_id}',
            path: {
                'customer_id': customerId,
                'bulk_action_id': bulkActionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update Bulk Action
     * Update bulk action
     *
     * Args:
     * customer_id(str): Customer id
     * bulk_action_id(int): Bulk action id
     * bulk_action_update_request(BulkActionUpdateRequest): Bulk action update request
     * current_user(AuthenticatedUser): Current user
     *
     * Returns:
     * BulkAction: Bulk action
     * @param customerId
     * @param bulkActionId
     * @param requestBody
     * @returns BulkAction Successful Response
     * @throws ApiError
     */
    public static updateBulkAction(
        customerId: string,
        bulkActionId: number,
        requestBody?: BulkActionUpdateRequest,
    ): CancelablePromise<BulkAction> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/security-auditing/{customer_id}/bulk-actions/{bulk_action_id}',
            path: {
                'customer_id': customerId,
                'bulk_action_id': bulkActionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get Bulk Action Analysis
     * Get bulk action analysis
     *
     * Args:
     * customer_id(str): Customer id
     * bulk_action_id(int): Bulk action id
     *
     * Returns:
     * BigQueryJobResponse: BigQuery job response
     * @param customerId
     * @param bulkActionId
     * @param query
     * Allowed operators:<br>=, ==, !=, <=, >=, <, >, IN, LIKE.<br>
     * (LIKE is by default %value%. Use \% at the beginning or end of the value to change the position of the wildcard.)
     * <br><br>
     * Example query:<br>id_fieldIN(id1ORid2),field=value,struct_field.struct_param=value,repeated_struct_field.struct_param=value,fieldLIKEvalue<br>
     *
     * @returns BigQueryJobResponse Successful Response
     * @throws ApiError
     */
    public static getBulkActionAnalysis(
        customerId: string,
        bulkActionId: number,
        query?: string,
    ): CancelablePromise<BigQueryJobResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/security-auditing/{customer_id}/bulk-actions/{bulk_action_id}/analysis',
            path: {
                'customer_id': customerId,
                'bulk_action_id': bulkActionId,
            },
            query: {
                'query': query,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
