<template>
  <component :is="layout">
    <RouterView v-slot="{ Component }">
      <Transition name="fade" mode="out-in">
        <div :key="route.path" class="flex flex-col h-full">
          <component
            :is="Component"
            :key="route.path"
            v-if="!useOrgUnitsStore().globalLoading"
          />
        </div>
      </Transition>
    </RouterView>
  </component>
</template>

<script setup>
import { RouterView, useRoute, useRouter } from "vue-router";
import { shallowRef, onMounted } from "vue";
import { useIntercom } from "@/composables/intercom";
import { useOpenReplay } from "@/composables/openreplay";

// IMPORT LAYOUTS
import LayoutDefault from "@/components/layout/LayoutDefault.vue";
import LayoutAuth from "@/components/layout/LayoutAuth.vue";
import { useOrgUnitsStore } from "./stores/app-management/org-unit-store";

// SETUP
const route = useRoute();
const router = useRouter();
const intercom = useIntercom();

// init openreplay
const DEV_ENV_MODES = ["development", "localdev"];
if (!DEV_ENV_MODES.includes(import.meta.env.MODE)) {
  useOpenReplay().init();
}

// INIT LAYOUTS
const DEFAULT_LAYOUT = "layout-default";

const layouts = {};
layouts[DEFAULT_LAYOUT] = LayoutDefault;
layouts["layout-auth"] = LayoutAuth;
const layout = shallowRef(LayoutDefault);

// watch for route changes
onMounted(() => {
  layout.value = layouts[route.meta.layout || DEFAULT_LAYOUT];
  router.beforeEach((to) => {
    if (!to.meta.layout) {
      layout.value = layouts[DEFAULT_LAYOUT];
    } else {
      layout.value = layouts[to.meta.layout];
    }
  });
});

// INTERCOM REFRESH
onMounted(() => {
  intercom.initRefreshIntercom();
});
</script>
