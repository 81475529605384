export function useArrayHelper() {
  const getItemProperty = (item, property) =>
    property?.split(".").reduce((list, value) => list?.[value], item);

  const isEquivalent = (a, b, strict = false) => {
    if (!a || !b || a.length !== b.length) {
      return false;
    }

    if (strict) {
      return a.every((element, index) => element === b[index]);
    } else {
      const sortedArr1 = a.slice().sort();
      const sortedArr2 = b.slice().sort();
      return sortedArr1.every(
        (element, index) => element === sortedArr2[index]
      );
    }
  };

  const sortString = (a, b, sortOrder = "asc") => {
    if (sortOrder === "desc") {
      return a.toLowerCase() < b.toLowerCase() ? 1 : -1;
    } else {
      return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
    }
  };

  const sortNumber = (a, b, sortOrder) => {
    if (sortOrder === "desc") {
      return a < b ? 1 : -1;
    } else {
      return a > b ? 1 : -1;
    }
  };

  const sort = (items, sortBy = "", sortOrder = "asc") => {
    items.sort((a, b) => {
      const propA = getItemProperty(a, sortBy) || ""; // Use an empty string as the default value
      const propB = getItemProperty(b, sortBy) || ""; // Use an empty string as the default value

      if (typeof propA === "string" && typeof propB === "string") {
        return sortString(propA, propB, sortOrder);
      }

      // If the property is a number
      if (typeof propA === "number" && typeof propB === "number") {
        return sortNumber(propA, propB, sortOrder);
      }

      // else ignore sorting
      return 0;
    });
  };

  const sortBy = (array, name) =>
    array.sort((a, b) => {
      if (a.role === name) return -1;
      if (b.role === name) return 1;
      return 0;
    });

  const deduplicate = (array) => [...new Set(array)];

  return {
    getItemProperty,
    isEquivalent,
    sort,
    sortString,
    sortNumber,
    sortBy,
    deduplicate,
  };
}
