import { useTextFormat } from "@/composables/text-format";

export function useFilterHelper() {
  const findComponentByKey = (id, key, apiConverter) =>
    id?.includes(key) ||
    (apiConverter && apiConverter(key, null, "id")?.id?.includes(key));

  const formatTimeFilterToApi = (filters) => {
    let formattedFilters = [];
    let selectionComponent;
    let timeComponent;
    let dateComponent;

    const createTimeFilter = () => {
      const selectionIsLessThan = selectionComponent
        ? selectionComponent.value === "less_than" ||
          selectionComponent.value === "less than"
        : timeComponent.is === "less than";

      const newTimeCriteria = {
        id: timeComponent.id.split(".")[0],
        operator: selectionIsLessThan ? "<" : ">",
        value: `${timeComponent.value}-${dateComponent.value}`,
      };

      selectionComponent = null;
      timeComponent = null;
      dateComponent = null;

      return newTimeCriteria;
    };

    for (const filter of filters) {
      if (filter.id.includes(".time")) {
        timeComponent = filter;
      } else if (filter.id.includes(".date")) {
        dateComponent = filter;
      } else if (filter.id.includes(".selection")) {
        selectionComponent = filter;
      }

      if (timeComponent && dateComponent) {
        formattedFilters.push(createTimeFilter());
      } else if (!selectionComponent && !timeComponent && !dateComponent) {
        formattedFilters.push(filter);
      }
    }
    return formattedFilters;
  };

  const formatApiFilterPerBlock = (apiFilters) => {
    return apiFilters.reduce((acc, filter) => {
      let operator;

      // Define a mapping of filter values to operators
      const operatorMap = {
        contains: "CONTAINS",
        "does not contain": "NOT_CONTAINS",
        "is not": "!=",
        "matches any selected": "IN",
        "matches all selected": "MATCHES_ALL",
        MATCHES_ANY: "IN", // Added to handle filter.operator cases
      };

      // Determine the operator based on filter value
      operator =
        operatorMap[filter.is] ||
        operatorMap[filter.operator] ||
        filter.operator;

      // 'Not contains' on the 'OR' statement search file_id_or_search_name gives unwanted results, as the file id field
      // most of the time does not contain (part of) a filename. So to prevent this, we will only query the 'name' field.
      if (
        filter.is === "does not contain" &&
        filter.id === "file_id_or_search_name"
      ) {
        filter.id = "name";
      }

      const formattedFilter = {
        ...filter,
        operator: operator,
      };

      const existingFilterIndex = acc.findIndex(
        ({ containerId, id }) =>
          id === formattedFilter.id &&
          containerId === formattedFilter.containerId
      );

      if (
        existingFilterIndex !== -1 &&
        acc[existingFilterIndex].operator !== "IN" &&
        acc[existingFilterIndex].operator !== "MATCHES_ALL"
      ) {
        const currentValue = acc[existingFilterIndex].value;
        const isCurrentValueArray = Array.isArray(currentValue);

        acc[existingFilterIndex].operator = "IN";
        if (!isCurrentValueArray) {
          acc[existingFilterIndex].value = [currentValue];
        }
        acc[existingFilterIndex].value.push(formattedFilter.value);
      } else {
        acc.push(formattedFilter);
      }

      return acc;
    }, []);
  };

  const generateApiFilter = (apiFilters, criterium, filters, id, condition) => {
    let {
      id: criteriumId,
      hidden,
      ignore,
      value,
      is,
      apiConverter,
      component,
      ...rest
    } = criterium;
    // No component means that the component is 'hidden' and its value relates to the parent selection,
    // thus always true (eg: trusted network criteria)
    if (!component) {
      value = true;
    }

    const ignoreCondition =
      !ignore ||
      (ignore === "partial" && apiConverter && apiConverter(value)?.value);

    const isValueTruthy =
      value &&
      value !== false &&
      !(typeof value === "string" && value.trim() === "") &&
      !(Array.isArray(value) && value.length === 0);

    if (
      ignoreCondition &&
      (!hidden?.(filters) || criterium?.bulkActionIgnoreHiddenCheck) &&
      isValueTruthy &&
      (!condition || (condition && is && is.replace(/_/g, " ") === condition))
    ) {
      apiFilters.push({
        ...rest,
        containerId: id,
        id: criteriumId,
        value,
        apiConverter,
        is,
      });
    }
    return apiFilters;
  };

  const generateNewContainer = (
    id,
    components,
    containerId = null,
    actionConverter = null
  ) => ({
    id: containerId ?? useTextFormat().generateRandomId(),
    value: [
      {
        factor: id,
        components: components,
        actionConverter: actionConverter,
      },
    ],
  });

  const INRegex = /^\(.*\)$/;

  const separateINValue = (value) =>
    value.substring(1, value.length - 1).split("-OR-");

  const setupCriteriaTimeFromQuery = (key, operator, value, list) => {
    let [time, date] = value.split("-").slice(-2);
    let selection =
      value.split("-").length === 3 ? value.split("-")[0] : undefined;

    const setupTimeComponent = (component) => {
      const isSelection = component.id.includes(".selection");
      const isTime = component.id.includes(".time");
      const isDate = component.id.includes(".date");

      let isMoreThan = operator === ">";

      if (!["<", ">"].includes(operator) && selection) {
        isMoreThan = selection === "more_than";
      }

      if (isSelection) {
        return {
          ...component,
          value: isMoreThan ? "more_than" : "less_than",
        };
      } else if (isTime) {
        return {
          ...component,
          is: isMoreThan ? "more than" : "less than",
          value: time,
        };
      } else if (isDate) {
        return {
          ...component,
          value: date,
        };
      }
    };

    const components = list.components.map((component) => {
      if (!component.id.includes(key)) return component;

      if (component.subComponents && component.component === "VCheckbox") {
        return {
          ...component,
          value: true,
          subComponents: component.subComponents.map((subComponent) =>
            setupTimeComponent(subComponent)
          ),
        };
      }
      return setupTimeComponent(component);
    });

    return generateNewContainer(list.id, [...components]);
  };

  const setupCriteriaDriveIdFromQuery = (key, value, list, is) => {
    const locationComponent = list.components.find(
      ({ id }) => id === "location"
    );
    let driveComponentIndex = locationComponent.subComponents.findIndex(
      ({ id }) => id === "driveId"
    );
    let parentComponentIndex = locationComponent.subComponents.findIndex(
      ({ id }) => id === "parent"
    );

    const formatComponent = (component, is, value) => ({
      ...component,
      is,
      operator: INRegex.test(value) ? "IN" : component.operator,
      value: INRegex.test(value) ? separateINValue(value) : value,
    });

    switch (key) {
      case "driveId":
        locationComponent.value = "shared_drive";
        locationComponent.subComponents[driveComponentIndex] = formatComponent(
          locationComponent.subComponents[driveComponentIndex],
          is,
          value
        );
        break;
      case "parent":
        locationComponent.value = "folder";
        locationComponent.subComponents[parentComponentIndex] = formatComponent(
          locationComponent.subComponents[parentComponentIndex],
          is,
          INRegex.test(value) ? separateINValue(value)[0] : value
        );
        break;
      default:
        break;
    }

    return generateNewContainer(list.id, [locationComponent]);
  };

  const setupCriteriaINFromQuery = (key, value, list, is) => {
    const componentIndex = list.components.findIndex(({ id }) => id === key);

    const separatedValue = separateINValue(value);

    list.components[componentIndex].is = is;
    list.components[componentIndex].value = separatedValue;

    // separatedValue.forEach((singleValue, index) => {
    //   if (index === 0) {
    //     list.components[componentIndex].is = is;
    //     list.components[componentIndex].value = singleValue;
    //   } else {
    //     list.components.push({
    //       ...list.components[componentIndex],
    //       value: singleValue,
    //     });
    //   }
    // });

    return generateNewContainer(list.id, [...list.components]);
  };

  const setupCriteriaLabelsFromQuery = (value, list, is) => {
    const labelsComponent = list.components.find(
      ({ id }) => id === "labels_filter"
    );

    let labelSelectionComponentIndex;
    labelSelectionComponentIndex = labelsComponent.subComponents.findIndex(
      ({ id }) => id === "labels.label_id"
    );

    const formatComponent = (component, is, value) => ({
      ...component,
      is,
      operator: INRegex.test(value) ? "IN" : component.operator,
      value: INRegex.test(value) ? separateINValue(value) : value,
    });

    labelsComponent.value = "labels_selection";
    labelsComponent.subComponents[labelSelectionComponentIndex] =
      formatComponent(
        labelsComponent.subComponents[labelSelectionComponentIndex],
        is,
        value
      );

    return generateNewContainer(list.id, [labelsComponent]);
  };

  const assignCriteriaComponentValueFromQuery = (
    criteriaComponentFromList,
    key,
    operator,
    value,
    is
  ) => {
    const assignedProperty = ["user_properties.permission.type"].includes(key)
      ? "key"
      : "id";

    const criteriaApiConverter = criteriaComponentFromList.apiConverter
      ? criteriaComponentFromList.apiConverter(key, operator, assignedProperty)
      : null;

    if (Array.isArray(criteriaComponentFromList.value)) {
      const values = value.split(",");
      values.forEach((value) => {
        criteriaComponentFromList.value.push(
          criteriaApiConverter?.key ?? value
        );
      });
    } else {
      criteriaComponentFromList.value = criteriaApiConverter?.key ?? value;
    }
    criteriaComponentFromList.is = is;

    criteriaComponentFromList.value = useTextFormat().parseBoolean(
      criteriaComponentFromList.value
    );

    return criteriaComponentFromList;
  };

  const assignCriteriaValueFromQuery = (
    key,
    operator,
    value,
    is,
    criteriaFromList
  ) => {
    const criteriaComponentFromList = criteriaFromList.components.find(
      ({ id, apiConverter, subComponents }) =>
        findComponentByKey(id, key, apiConverter) ||
        subComponents?.find(({ id, apiConverter }) =>
          findComponentByKey(id, key, apiConverter)
        )
    );

    const { id, apiConverter } = criteriaComponentFromList;
    if (findComponentByKey(id, key, apiConverter)) {
      return assignCriteriaComponentValueFromQuery(
        criteriaComponentFromList,
        key,
        operator,
        value,
        is
      );
    } else {
      const criteriaSubComponentIndex =
        criteriaComponentFromList.subComponents?.findIndex(
          ({ id, apiConverter }) => findComponentByKey(id, key, apiConverter)
        );

      criteriaComponentFromList.subComponents[criteriaSubComponentIndex] =
        assignCriteriaComponentValueFromQuery(
          criteriaComponentFromList.subComponents[criteriaSubComponentIndex],
          key,
          operator,
          value,
          is
        );

      return criteriaComponentFromList;
    }
  };

  const attachCriteriaToExistingFromQuery = (
    reducer,
    key,
    criteriaComponentFromList
  ) => {
    const firstKeyId = key.split(".")[0];
    const lastContainerIndex = reducer.length - 1;

    let matchingValue = reducer[lastContainerIndex].value.findIndex(
      ({ components }) => components.some(({ id }) => id.includes(firstKeyId))
    );

    if (matchingValue === -1) {
      matchingValue = reducer[lastContainerIndex].value.length - 1;
    }

    reducer[lastContainerIndex].value[matchingValue].components.push(
      criteriaComponentFromList
    );

    return reducer;
  };

  // used for all filters also bulk action criteria
  const setupCriteriaFromQuery = (reducer, criteria, list, is = null) => {
    const [key, operator, value] = criteria.split(
      /(=|==|!=|<=|>=|<|>|NOT_IN|IN|LIKE|CONTAINS|NOT_CONTAINS|MATCHES_ALL)/g
    );

    const criteriaFromList = list.find(
      ({ id, components }) =>
        id?.includes(key) ||
        components.find(
          ({ id, apiConverter, subComponents }) =>
            findComponentByKey(id, key, apiConverter) ||
            subComponents?.find(({ id, apiConverter }) =>
              findComponentByKey(id, key, apiConverter)
            )
        )
    );
    if (criteriaFromList) {
      if (
        [
          "user_properties.permission.email_address",
          "user_properties.permission.allow_file_discovery",
        ].includes(key)
      ) {
        // check if key already exists in previous reducer element
        // if so set the value of the key
        const keyIndex = reducer[
          reducer.length - 1
        ].value[0].components[0].subComponents.findIndex(
          (component) => component.id === key
        );
        reducer[reducer.length - 1].value[0].components[0].subComponents[
          keyIndex
        ].value = value;
      } else if (
        ["last_viewed", "modified_time", "created_time"].includes(key)
      ) {
        // check if key already exists in reducer
        const existingCriteriaIndex = reducer.findIndex(({ value }) => {
          return (
            value[0].components[0].id === criteriaFromList.components[0].id
          );
        });

        // if key already exists, push new criteria to existing key
        if (existingCriteriaIndex > -1) {
          const updateCriteria = setupCriteriaTimeFromQuery(
            key,
            operator,
            value,
            criteriaFromList
          );

          let matchingValue = updateCriteria.value[0].components.find(
            (component) => component.id == key
          );

          // update reducer with new criteria
          const keyIndex = reducer[
            existingCriteriaIndex
          ].value[0].components.findIndex((component) => component.id == key);

          reducer[existingCriteriaIndex].value[0].components[keyIndex] =
            matchingValue;
        } else {
          // if key does not exist, create new key

          reducer.push(
            setupCriteriaTimeFromQuery(key, operator, value, criteriaFromList)
          );
        }
      } else if (["driveId", "parent"].includes(key)) {
        reducer.push(
          setupCriteriaDriveIdFromQuery(key, value, criteriaFromList, is)
        );
      } else if (
        key === "labels.label_id" &&
        !criteriaFromList.is_file_management_criteria
      ) {
        reducer.push(setupCriteriaLabelsFromQuery(value, criteriaFromList, is));
      } else if (INRegex.test(value)) {
        reducer.push(
          setupCriteriaINFromQuery(key, value, criteriaFromList, is)
        );
      } else {
        const criteriaComponentFromList = assignCriteriaValueFromQuery(
          key,
          operator,
          value,
          is,
          criteriaFromList
        );

        if (criteriaComponentFromList.hidden) {
          attachCriteriaToExistingFromQuery(
            reducer,
            key,
            criteriaComponentFromList
          );
        } else {
          reducer.push(
            generateNewContainer(criteriaFromList.id, [
              { ...criteriaComponentFromList },
            ])
          );
        }
      }
    }
    return reducer;
  };

  const handleSubComponentsClearance = (filters) => {
    return filters.map(({ section, components }) => ({
      section,
      components: components.map(({ subComponents, value, ...rest }) => {
        if (!subComponents) {
          return {
            ...rest,
            value,
          };
        } else {
          return {
            ...rest,
            value,
            subComponents:
              value || value?.length // boolean or array
                ? subComponents
                : subComponents.map((subComponent) => ({
                    ...subComponent,
                    value: subComponent.defaultValue ?? null,
                  })),
          };
        }
      }),
    }));
  };

  const getResultFromApiConverter = (
    results,
    value,
    assignedProperty,
    operator = null
  ) =>
    results.find((result) => {
      const isCorrectProperty =
        result[assignedProperty]?.toString() === value?.toString();
      const isCorrectOperator = operator ? result.operator === operator : true;
      return isCorrectProperty && isCorrectOperator;
    }) ??
    results.find(({ key }) => key === "default") ??
    {};

  return {
    generateApiFilter,
    formatTimeFilterToApi,
    formatApiFilterPerBlock,
    getResultFromApiConverter,
    generateNewContainer,
    setupCriteriaFromQuery,
    separateINValue,
    handleSubComponentsClearance,
  };
}
