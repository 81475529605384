<template>
  <div class="flex h-screen bg-background-dark">
    <TheNewNavigation />
    <!-- Content area -->
    <div class="flex min-w-0 flex-1 flex-col overflow-hidden">
      <main
        class="margin-transition flex flex-1 flex-col overflow-hidden max-h-full pb-1 md:ml-0"
      >
        <div class="h-full w-full px-10 pb-[125px]">
          <TheTopBar />
          <div class="mt-6 w-full h-full">
            <slot></slot>
          </div>
        </div>
      </main>
    </div>
  </div>
  <VLoadingOverlay
    v-model="authStore.refreshLoading"
    loadingMessage="Refreshing your session"
    :persistent="true"
  >
  </VLoadingOverlay>
</template>

<script setup>
import TheNewNavigation from "@/components/layout/TheNewNavigation.vue";
import TheTopBar from "@/components/layout/TheTopBar.vue";
import { useAuthStore } from "@/stores/app-management/auth-store";

const authStore = useAuthStore();
</script>

<style scoped>
.slide-enter-from {
  /* opacity: 0; */
  transform: translateX(-16rem);
}

.slide-enter-active {
  transition: all 0.3s ease;
}
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-leave-to {
  /* opacity: 0; */
  transform: translateX(-16rem);
}

.margin-transition {
  transition: margin 0.3s ease;
}
</style>
