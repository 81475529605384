/* eslint-disable no-unused-vars */
import { ref, computed, watch } from "vue";
import {
  useErrorStore,
  useCustomerStore,
  useNotificationStore,
} from "@/stores";
import {
  ref as firebaseRef,
  child,
  get,
  push,
  update,
  remove,
  getDatabase,
} from "firebase/database";

export function useAPIWrapper(
  storeId,
  services,
  returnObjectKey,
  clientSideFiltering = false,
  clientSidePagination = false,
  listServiceParams = []
) {
  const errorStore = useErrorStore();
  const customerStore = useCustomerStore();
  const notificationStore = useNotificationStore();
  const fetchedItems = ref([]); // items fetched from the API (used as a cache of all items before filtering)
  const processedItems = ref(null); // items that are filtered and sorted
  const items = computed(() => {
    // if there are processed items, return them, otherwise return the fetched items
    return processedItems.value !== null
      ? processedItems.value
      : fetchedItems.value;
  });
  const filters = ref([]);
  const pageToken = ref(null);
  const loading = ref(false);
  const queryJobId = ref(null);
  const totalRows = ref(null);

  // FIREBASE
  const dbRef = firebaseRef(getDatabase());

  function withAPIErrorHandling(fn, operation) {
    return async (...args) => {
      try {
        return await fn(...args);
      } catch (error) {
        const errorContext = {
          errorType: error.name,
          message: error.message,
          source: `api-wrapper - ${storeId}`,
          function: operation,
          data: {
            body: error.body,
            status: error.status,
            name: error.name,
            request: error.request,
            statusText: error.statusText,
            url: error.url,
            stack: error.stack,
          },
        };

        const message = args.find(
          (arg) =>
            typeof arg === "object" &&
            arg !== null &&
            "success" in arg &&
            "error" in arg
        );

        const userMessage = message?.error
          ? message.error
          : `Failed to ${operation}`;

        errorStore.addError({
          error: error,
          errorContext: errorContext,
          userMessage: userMessage,
        });

        loading.value = false;
      }
    };
  }

  // ===================== //
  // ======= LIST ======== //
  // ===================== //

  const totalItems = computed(() => {
    if (totalRows.value) {
      return totalRows.value;
    }
    if (pageToken.value) {
      return null;
    }
    return items.value.length;
  });

  const activeFilters = computed(() => {
    return filters.value;
  });

  const addFilter = async (filter) => {
    // check if filter already exists and remove it

    await removeFilter(filter.id);
    filters.value.push(filter);
  };

  const removeFilter = async (filterId) => {
    // when we change a filter we need to reset the params
    resetStore();

    const index = filters.value.findIndex((filter) => filter.id === filterId);
    if (index !== -1) {
      filters.value.splice(index, 1);
    }
  };

  const reset = () => {
    resetStore();
    filters.value = [];
  };

  const resetStore = () => {
    fetchedItems.value = [];
    processedItems.value = null;
    pageToken.value = null;
    queryJobId.value = null;
    totalRows.value = null;
  };

  // Reset the pageToken when filters change
  watch(filters, () => {
    pageToken.value = null;
  });

  const sortItems = (sortBy, sortOrder) => {
    if (sortBy) {
      processedItems.value.sort((a, b) => {
        if (sortOrder === "desc") {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        } else {
          return a[sortBy] > b[sortBy] ? 1 : -1;
        }
      });
    }
  };

  // used to filter items on the client side
  const filterItems = (filters) => {
    if (filters.length > 0) {
      return fetchedItems.value.filter((item) => {
        return filters.every((filter) => {
          return filter.fn(item);
        });
      });
    }
    return fetchedItems.value;
  };

  const listItems = withAPIErrorHandling(
    async ({
      pageSize = null,
      sortBy = null,
      sortOrder = null,
      forceRefresh = false,
    } = {}) => {
      if (loading.value) {
        return;
      }

      loading.value = true;

      let res = [];
      const demoDataRef = child(
        dbRef,
        `customers/${customerStore.activeCustomerId}/data/${storeId}`
      );

      try {
        const snapshot = await get(demoDataRef);

        if (snapshot.exists()) {
          // Convert the data to an array
          const rawData = snapshot.val();
          res = Object.keys(rawData).map((key) => {
            return {
              id: key, // This is the unique Firebase ID
              ...rawData[key], // This spreads the properties of each item
            };
          });
        } else {
          console.log("No demo data available");
        }
      } catch (error) {
        console.error("Failed to retrieve demo data: ", error);
      }

      // If no items or empty, reset items
      if (!res || res.length === 0) {
        fetchedItems.value = [];
        loading.value = false;

        return [];
      }

      // If no pageSize, overwrite the items, otherwise append
      fetchedItems.value = pageSize ? [...fetchedItems.value, ...res] : res;

      // If clientSidePagination, apply client-side filtering and sorting
      if (clientSidePagination) {
        clientSideFiltering
          ? (processedItems.value = filterItems(filters.value))
          : (processedItems.value = fetchedItems.value);
        sortItems(sortBy, sortOrder);
      }
      loading.value = false;

      return items.value;
    },
    "list items"
  );

  // ===================== //
  // ======= GET ========= //
  // ===================== //

  const getItem = withAPIErrorHandling(
    async (itemId, forceFetch = false, message = {}) => {
      const demoDataRef = child(
        dbRef,
        `customers/${customerStore.activeCustomerId}/data/${storeId}/${itemId}`
      );
      let res = null;

      try {
        const snapshot = await get(demoDataRef);
        if (snapshot.exists()) {
          res = snapshot.val();
        } else {
          console.log("No data for the provided item ID");
        }
      } catch (error) {
        console.error("Failed to retrieve demo data: ", error);
      }

      return res;
    },
    "get"
  );

  // ===================== //
  // ====== CREATE ======= //
  // ===================== //

  const createItem = withAPIErrorHandling(async (item, message = {}) => {
    const demoDataRef = child(
      dbRef,
      `customers/${customerStore.activeCustomerId}/data/${storeId}`
    );
    try {
      await push(demoDataRef, item);
      fetchedItems.value.push(item);
      notificationStore.addNotification("Item created successfully", "success");
    } catch (error) {
      console.error("Failed to create demo data item: ", error);
    }
  }, "create");

  // ===================== //
  // ====== UPDATE ======= //
  // ===================== //

  const updateItem = withAPIErrorHandling(
    async (itemId, item, message = {}) => {
      const demoDataRef = child(
        dbRef,
        `customers/${customerStore.activeCustomerId}/data/${storeId}/${itemId}`
      );
      try {
        await update(demoDataRef, item);
        fetchedItems.value = fetchedItems.value.map((i) => {
          if (i.id === itemId) {
            return item;
          }
          return i;
        });
        notificationStore.showToast("updated successfully", "success");
      } catch (error) {
        console.error("Failed to update demo data item: ", error);
      }
    },
    "update"
  );

  // ===================== //
  // ====== DELETE ======= //
  // ===================== //

  const deleteItem = withAPIErrorHandling(async (itemId, message = {}) => {
    const demoDataRef = child(
      dbRef,
      `customers/${customerStore.activeCustomerId}/data/${storeId}/${itemId}`
    );
    try {
      await remove(demoDataRef);
      fetchedItems.value = fetchedItems.value.filter(
        (item) => item.id !== itemId
      );
      notificationStore.showToast("deleted successfully", "success");
    } catch (error) {
      console.error("Failed to delete demo data item: ", error);
    }
  }, "delete");

  return {
    // General
    loading,
    reset,
    // List
    items,
    addFilter,
    removeFilter,
    listItems,
    pageToken,
    activeFilters,
    totalItems,

    // Get
    getItem,

    // Create
    createItem,

    // Update
    updateItem,

    // Delete
    deleteItem,
  };
}
