import { defineStore } from "pinia";
import { useErrorStore, useCustomerStore } from "@/stores";
import { useAPIWrapper } from "@/composables/api-wrapper";
import { PoliciesService } from "@/apis/security-auditing";
const storeId = "policies-store";

export const useBulkAutomatedPoliciesStore = defineStore(storeId, () => {
  // ===================== //
  // ======= INIT ======== //
  // ===================== //

  const customerStore = useCustomerStore();
  const errorStore = useErrorStore();
  const errorMessages = {
    listItems: `Failed to retrieve your automated policies. ${errorStore.defaultErrorMessage}`,
  };

  // Define the services that are used by the API wrapper
  const services = {
    listItemsService: PoliciesService.listPolicies,
    getItemService: PoliciesService.getPolicy,
    createItemService: PoliciesService.createPolicy,
    updateItemService: PoliciesService.updatePolicy,
    deleteItemService: PoliciesService.deletePolicy,
  };

  const returnObjectKey = "policies";
  const listServiceParams = ["customerId", "query", "sortBy", "sortOrder"];

  // set the values to determine if the filtering and pagination should be done on the client or server side
  const clientSideFiltering = true;
  const clientSidePagination = true;

  // orgUnitFilter is used to determine if the orgUnit filter should be used
  const useOrgUnitFilter = false;

  const run = async (policyId) => {
    try {
      await PoliciesService.forceRunPolicy(
        customerStore.activeCustomerId,
        policyId
      );
    } catch (error) {
      errorStore.addError({
        message: `Failed to run the automated policy. ${errorStore.defaultErrorMessage}`,
        error,
      });
    }
  };

  // use the API wrapper composable to handle filtering, sorting, pagination and listing of items
  const {
    // filters
    activeFilters,
    activeAdvancedFilters,
    clearAllFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    removeFilter,
    updateFilter,
    generateFilterParams,
    // list
    items,
    listItems,
    totalItems,
    // general
    resetStore,
    reset,
    loading,
    // crud
    createItem,
    updateItem,
    getItem,
    deleteItem,
  } = useAPIWrapper(
    storeId,
    services,
    returnObjectKey,
    clientSideFiltering,
    clientSidePagination,
    listServiceParams,
    useOrgUnitFilter,
    null,
    errorMessages
  );

  return {
    // filters
    activeFilters,
    useOrgUnitFilter,
    activeAdvancedFilters,
    clearAllFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    removeFilter,
    updateFilter,
    generateFilterParams,

    // custom execute policy
    run,

    // list
    items,
    listItems,
    totalItems,
    clientSidePagination,

    // general
    resetStore,
    reset,
    loading,

    // crud
    // get,
    updateItem,
    createItem,
    getItem,
    deleteItem,
  };
});
