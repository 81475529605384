<template>
  <div
    class="mt-0.5 h-5 text-xs text-error"
    :class="[props.validation && 'shake-animation']"
  >
    <div class="flex justify-between px-1">
      <div class="h-5">
        <!-- Display error message if there is one -->
        <span v-if="props.validation && props.validation !== true">
          {{ props.validation }}
        </span>
      </div>
      <!-- Counter based errors -->
      <div
        v-if="props.inputValue?.length != 0 && props.counter?.minCounter?.value"
        class="my-auto text-xs text-gray-500"
        :class="[isInputTooShort && '!text-error']"
      >
        {{ props.inputValue?.length }}
      </div>
      <div
        v-else-if="props.counter.maxCounter.value"
        class="my-auto text-xs text-gray-500"
        :class="[isInputTooLong && '!text-error']"
      >
        {{ props.inputValue?.length }}/{{ props.counter.maxCounter.value }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
const props = defineProps({
  inputValue: { type: String, default: "" },
  validation: { type: [Boolean, String], default: false },
  counter: { type: Object, default: null },
});

const isInputTooShort = computed(
  () =>
    props.counter.minCounter &&
    props.inputValue?.length < props.counter.minCounter.value
);

const isInputTooLong = computed(
  () =>
    props.counter.maxCounter &&
    props.inputValue?.length > props.counter.maxCounter.value
);
</script>

<style scoped>
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-1px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.shake-animation {
  animation: shake 0.6s ease-in-out;
  animation-iteration-count: 0.5;
}
</style>
