<template>
  <VSelect
    :placeholder="ouLabel || 'All organizational units'"
    :items="orgUnits"
    hideInput
    v-model="selectedOrgUnit"
    item-text="name"
    item-value="orgUnitId"
    small
    full-options-width
    :disabled="!isSyncCompleted"
  >
    <template #options="{ setShowOptions }">
      <VTreeSelect
        :items="treeOrgUnits"
        @handle-click="handleTreeSelectClick($event, setShowOptions)"
        :has-prefix-icon="false"
      />
    </template>
  </VSelect>
  <div class="-mb-1" v-if="includeSubOrgUnits !== null">
    <VCheckbox
      v-model="includeSubOrgUnits"
      label="Include sub-organizational units"
      xs
      :disabled="selectedOrgUnit === 'ALL' || !isSyncCompleted"
    />
  </div>
  <VSkeleton v-else-if="orgUnitsStore.loading" :number-of-lines="1" />
  <VModal variant="confirmation" v-model="showRestrictedPrivilegeModal">
    <template #title>Access denied</template>
    <template #description>
      <p class="mb-4">
        You do not have the required privileges from
        <span class="font-bold">{{ modalSelectedOrgUnit.label }}</span> to view
        the current page.
      </p>
      <p>
        You may have to select a different organizational unit in order to
        access this page.
      </p>
      <p>If you believe this is an error, please contact your administrator.</p>
    </template>
    <template #footer>
      <VButton
        variant="primary"
        label="Understood"
        @click="showRestrictedPrivilegeModal = false"
      />
    </template>
  </VModal>
</template>

<script setup>
import {
  useAuthStore,
  useCustomerStore,
  useOrgUnitsStore,
  useUserPreferenceStore,
} from "@/stores/";
import { ref, onMounted, watch, computed } from "vue";
import { useRoute } from "vue-router";
const orgUnitsStore = useOrgUnitsStore();
const customerStore = useCustomerStore();
const authStore = useAuthStore();
const userPreferenceStore = useUserPreferenceStore();
const showRestrictedPrivilegeModal = ref(false);
const selectedOrgUnit = ref(null);
const includeSubOrgUnits = ref(null);
const modalSelectedOrgUnit = ref(null);
const treeOrgUnits = ref(null);
const orgUnits = ref([]);
const route = useRoute();

const { hasGlobalPrivilege } = authStore;

const activeOrgUnits = computed(() =>
  orgUnitsStore.getHiddenChildrenFromList(
    authStore.flatPrivilegesOrgUnits,
    true
  )
);

const isSyncCompleted = computed(
  () => customerStore.activeCustomer.initSyncStatus === "COMPLETED"
);
const handleTreeSelectClick = (value, setShowOptionsFunction) => {
  setShowOptionsFunction(false);
  checkOrgUnitPrivileges(value);
};

const ouLabel = computed(() => {
  const ouPath = orgUnits.value.find(
    ({ orgUnitId }) => orgUnitId === selectedOrgUnit.value
  )?.orgUnitPath;
  const treeOrgUnitLabel = treeOrgUnits.value?.find(
    ({ id }) => id === selectedOrgUnit.value
  )?.label;

  if (!ouPath || ouPath === "/") return treeOrgUnitLabel;

  const ouPathArray = ouPath.split("/");
  ouPathArray.shift();

  if (ouPathArray.length === 1) return ouPathArray[0];

  if (ouPathArray.length > 1)
    return `${ouPathArray[0]}${ouPathArray.length > 2 ? "/... /" : "/"}${
      ouPathArray[ouPathArray.length - 1]
    }`;

  return ouPathArray;
});

const setupOrgUnits = async () => {
  orgUnits.value = hasGlobalPrivilege
    ? orgUnitsStore.items
    : orgUnitsStore.items.filter(({ orgUnitId }) =>
        activeOrgUnits.value.includes(orgUnitId)
      );

  if (!orgUnits.value?.[0]) return;

  const orgUnitsWithChildren = orgUnitsStore.getHiddenChildrenFromList(
    orgUnits.value
  );
  const treeOrgUnitsWithChildren = orgUnitsStore.formatOrgUnitsToVTreeSelect(
    orgUnitsWithChildren.map((orgUnit) => ({
      ...orgUnit,
      canClick: true,
    }))
  );

  treeOrgUnits.value = [
    { label: "All organizational units", id: "ALL", canClick: true },
    ...treeOrgUnitsWithChildren,
  ];

  orgUnits.value = orgUnitsWithChildren;

  await setupOrgUnitSelection();
  await selectOrgUnit(selectedOrgUnit.value);
};

const checkOrgUnitPrivileges = (value) => {
  if (
    !authStore.hasPrivilege(route?.meta?.requiredPrivileges ?? [], value.id)
  ) {
    modalSelectedOrgUnit.value = value;
    showRestrictedPrivilegeModal.value = true;
  } else {
    showRestrictedPrivilegeModal.value = false;
    selectOrgUnit(value.id);
  }
};

const selectOrgUnit = async (value) => {
  if (value !== userPreferenceStore.selectedOrgUnit.orgUnitId) {
    await userPreferenceStore.saveSelectedOrgUnit(value);
  }
};

const setupOrgUnitSelection = async () => {
  if (!userPreferenceStore.selectedOrgUnit?.orgUnitId) {
    await userPreferenceStore.fetchSelectedOrgUnit();
  }

  const { orgUnitId, includeSubOrgUnits: storeIncludeSubOrgUnits } =
    userPreferenceStore.selectedOrgUnit;

  if (orgUnitId === selectedOrgUnit.value || userPreferenceStore.loading)
    return;

  if (orgUnitId) {
    selectedOrgUnit.value = orgUnitId;
  } else if (treeOrgUnits.value[0].canClick) {
    selectedOrgUnit.value = treeOrgUnits.value[0].id;
  } else {
    selectedOrgUnit.value = treeOrgUnits.value[0].children[0].id;
  }
  includeSubOrgUnits.value = storeIncludeSubOrgUnits ?? false;
};

watch(userPreferenceStore, setupOrgUnitSelection, {
  immediate: false,
  deep: true,
});

watch(
  [orgUnitsStore],
  async () => {
    if (orgUnitsStore.items[0]) {
      await setupOrgUnits();
    }
  },
  { immediate: false, deep: true }
);

watch([includeSubOrgUnits], () => {
  userPreferenceStore.saveIncludeSubOrgUnits(includeSubOrgUnits.value);
});

const init = async () => {
  if (isSyncCompleted.value && !orgUnitsStore?.items?.length) {
    await orgUnitsStore.listItems();
  } else if (orgUnitsStore?.items?.length) {
    await setupOrgUnits();
  }
};

watch([customerStore], init, { deep: true });

onMounted(init);
</script>
