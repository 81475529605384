<template>
  <div class="inline-block">
    <VSelect
      placeholder="Please select an option"
      :items="frequencyOptions"
      position="left"
      v-model="selectedFrequency"
      itemText="text"
      itemValue="value"
      class="inline-block"
    />

    <div v-if="selectedFrequency === 'YEARLY'" class="inline-block">
      <div class="inline-block ml-2">on</div>
      <VSelect
        placeholder="Please select an option"
        :items="monthNamesShort"
        position="left"
        v-model="selectedMonth"
        class="inline-block ml-2"
      >
      </VSelect>

      <VSelect
        placeholder="Please select an option"
        :items="daysForSelectedMonth"
        position="left"
        v-model="selectedDay"
        class="inline-block ml-2"
      >
      </VSelect>
    </div>

    <div v-if="selectedFrequency === 'MONTHLY'" class="inline-block">
      <div class="inline-block ml-2">on day</div>
      <VSelect
        placeholder="Please select an option"
        :items="daysForMonthly"
        position="left"
        v-model="selectedDay"
        class="inline-block ml-2"
      >
      </VSelect>
    </div>

    <div v-if="selectedFrequency === 'WEEKLY'" class="ml-2 inline-block">
      <template v-for="day in weekDays" :key="day">
        <VChip
          class="mr-2 cursor-pointer"
          :color="selectedWeekdays.includes(day.value) ? 'google-blue' : ''"
          @click="toggleWeekDay(day.value)"
          ><div
            class="w-3 text-center select-none"
            :class="selectedWeekdays.includes(day.value) ? 'font-semibold' : ''"
          >
            {{ day.text.charAt(0) }}
          </div>
        </VChip>
      </template>
    </div>

    <p
      class="my-5 text-xs text-text-othergrey"
      v-if="showSchedule || showNextRun"
    >
      <span v-if="showSchedule">Schedule: {{ rruleScheduleText }}</span> <br />
      <span v-if="showNextRun" class="mt-2"
        >Next run:
        {{ convertTimestampToLocaleDateString(rruleNextRunText) }}</span
      >
    </p>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from "vue";
import { useDateTimeHelper } from "@/composables/date-time-helper";
import { RRule } from "rrule";

const props = defineProps({
  modelValue: { type: String, default: null }, // v-model
  showSchedule: { type: Boolean, default: true },
  showNextRun: { type: Boolean, default: false },
});

const frequencyOptions = [
  {
    text: "every week on",
    value: "WEEKLY",
  },
  {
    text: "every month",
    value: "MONTHLY",
  },
  {
    text: "every year",
    value: "YEARLY",
  },
];

const weekDays = [
  {
    text: "Monday",
    value: "MO",
  },
  {
    text: "Tuesday",
    value: "TU",
  },
  {
    text: "Wednesday",
    value: "WE",
  },
  {
    text: "Thursday",
    value: "TH",
  },
  {
    text: "Friday",
    value: "FR",
  },
  {
    text: "Saturday",
    value: "SA",
  },
  {
    text: "Sunday",
    value: "SU",
  },
];

const {
  monthNamesShort,
  getDaysInMonth,
  convertMonthNamesShortToIndex,
  convertTimestampToLocaleDateString,
} = useDateTimeHelper();
const currentDate = new Date();
const daysForMonthly = new Array(31).fill(0).map((_, index) => index + 1);

const selectedFrequency = ref("MONTHLY");
const selectedMonth = ref("Jan");
const selectedDay = ref(1);
const selectedWeekdays = ref(["MO"]);
const rruleScheduleText = ref(null);
const rruleNextRunText = ref(null);

const daysForSelectedMonth = computed(() => {
  const nrOfDaysInMonth = getDaysInMonth(
    convertMonthNamesShortToIndex(selectedMonth.value),
    currentDate.getFullYear()
  );

  checkResetDays(nrOfDaysInMonth);
  return new Array(nrOfDaysInMonth).fill(0).map((_, index) => index + 1);
});

const checkResetDays = (nrOfDaysInMonth) => {
  if (selectedDay.value > nrOfDaysInMonth) {
    selectedDay.value = 1;
  }
};

const emit = defineEmits(["update:modelValue"]);

const toggleWeekDay = (day) => {
  if (selectedWeekdays.value.includes(day)) {
    // at least one day should be selected
    if (selectedWeekdays.value.length === 1) {
      return;
    }

    selectedWeekdays.value = selectedWeekdays.value.filter((d) => d !== day);
  } else {
    selectedWeekdays.value = [...selectedWeekdays.value, day];
  }
};

const updateRule = () => {
  let options = {
    freq: RRule[selectedFrequency.value],
  };

  // monthly -> bymonthday

  switch (selectedFrequency.value) {
    case "YEARLY":
      options.bymonth = convertMonthNamesShortToIndex(selectedMonth.value);
      options.bymonthday = parseInt(selectedDay.value, 10);
      break;
    case "MONTHLY":
      options.bymonthday = parseInt(selectedDay.value, 10);
      break;
    case "WEEKLY":
      options.byweekday = selectedWeekdays.value.map(
        (day) => RRule[day.toUpperCase()]
      );
      break;
  }

  const rrule = new RRule(options);
  rruleScheduleText.value = rrule.toText();

  if (rruleScheduleText.value.includes("on the")) {
    rruleScheduleText.value += " day";
  }

  rruleNextRunText.value = rrule.after(currentDate);

  emit("update:modelValue", rrule.toString());
};

watch(
  [selectedFrequency, selectedMonth, selectedDay, selectedWeekdays],
  updateRule
);

const convertRRuleFrequentyToSelectedFrequency = (frequency) => {
  // translate options.freq to selectedFrequency

  switch (frequency) {
    case RRule.YEARLY:
      selectedFrequency.value = "YEARLY";
      break;
    case RRule.MONTHLY:
      selectedFrequency.value = "MONTHLY";
      break;
    case RRule.WEEKLY:
      selectedFrequency.value = "WEEKLY";
      break;
  }

  return selectedFrequency.value;
};

const convertRRuleWeekdaysToWeekdays = (days) => {
  selectedWeekdays.value = [];

  for (const day of days) {
    selectedWeekdays.value.push(weekDays[day].value);
  }

  return selectedWeekdays.value;
};

onMounted(() => {
  // update the rule if the modelValue is set
  if (props.modelValue) {
    const rule = RRule.fromString(props.modelValue);
    const options = rule.options;

    selectedFrequency.value = convertRRuleFrequentyToSelectedFrequency(
      options.freq
    );

    switch (selectedFrequency.value) {
      case "YEARLY":
        selectedMonth.value = monthNamesShort[options.bymonth];
        selectedDay.value = options.bymonthday;
        break;
      case "MONTHLY":
        selectedDay.value = options.bymonthday;
        break;
      case "WEEKLY":
        selectedWeekdays.value = convertRRuleWeekdaysToWeekdays(
          options.byweekday
        );
        break;
    }
  }

  updateRule();
});
</script>
