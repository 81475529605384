import { defineStore } from "pinia";
import { useToast } from "vue-toastification";

export const useNotificationStore = defineStore("notification", () => {
  const toast = useToast();

  const showToast = (message, notificationType = "info", timeout = 3000) => {
    try {
      if (notificationType === "error") {
        toast.error(message, { timeout });
        return;
      }
      if (notificationType === "warning") {
        toast.warning(message, { timeout });
        return;
      }
      if (notificationType === "success") {
        toast.success(message, { timeout });
        return;
      }
      toast.info(message, { timeout });
    } catch (toastError) {
      console.error("Error while showing toast:", toastError);
    }
  };

  return {
    showToast,
  };
});
