<template>
  <div class="w-full animate-pulse h-full">
    <VRow class="w-full h-full">
      <!-- START Image -->
      <div
        class="flex items-center justify-center bg-gray-20 rounded sm:w-96 dark:bg-gray-50 h-full w-full"
        v-if="withImage"
      >
        <svg
          class="w-12 h-12 text-gray-100"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 640 512"
        >
          <path
            d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"
          />
        </svg>
      </div>
      <!-- END Image -->
      <VRow
        class="w-full h-full align-top justify-start"
        v-if="withTitle || !noText || withMainIcon"
      >
        <!-- START Main Icon -->
        <div class="flex items-center space-x-3 h-fit" v-if="withMainIcon">
          <svg
            class="text-gray-20 w-14 h-14 dark:text-gray-50"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <!-- END Main icon -->
        <VCol class="w-full h-full" v-if="!noText || withLineIcon || withTitle">
          <!-- START Title -->
          <div
            class="h-6 bg-gray-20 rounded-full dark:bg-gray-50 w-1/2 mb-4"
            v-if="withTitle"
          />
          <!-- END Title -->
          <div v-if="!noText">
            <VRow
              class="w-full items-center my-1"
              :class="calculateSpaceBetweenLines"
              v-for="(_, index) in Array.from({ length: numberOfLines })"
              :key="`vskeleton-${index}`"
            >
              <!-- START Line Icon -->
              <div
                class="flex items-center space-x-8 h-fit"
                v-if="withLineIcon"
              >
                <svg
                  class="text-gray-20 w-6 h-6 dark:text-gray-50"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <!-- END Line Icon -->
              <!-- START paragraph -->
              <div
                class="h-3 bg-gray-20 rounded-full dark:bg-gray-50 w-full"
              />
              <!-- END paragraph -->
            </VRow>
          </div>
        </VCol>
      </VRow>
    </VRow>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  noText: {
    type: Boolean,
    default: false,
  },
  withTitle: {
    type: Boolean,
    default: false,
  },
  withImage: {
    type: Boolean,
    default: false,
  },
  withMainIcon: {
    type: Boolean,
    default: false,
  },
  withLineIcon: {
    type: Boolean,
    default: false,
  },
  numberOfLines: {
    type: Number,
    default: 5,
  },
  spaceBetweenLines: {
    type: String,
    validator: (value) => {
      const validVariants = ["small", "base", "large"];
      if (!validVariants.includes(value)) {
        console.warn(`Invalid variant: ${value}. Defaulting to "base".`);
        return false;
      }
      return true;
    },
    default: "base",
  },
});

const calculateSpaceBetweenLines = computed(() => {
  const spaces = [
    { variant: "small", space: "my-0" },
    { variant: "base", space: "my-2" },
    { variant: "large", space: "my-4" },
  ];

  return spaces.find(({ variant }) => variant === props.spaceBetweenLines).space;
});
</script>
