/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BigQueryJobResponse } from '../models/BigQueryJobResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReportsService {

    /**
     * List files stats
     * List files reports
     * @param customerId
     * @param query
     * Allowed operators:<br>=, ==, !=, <=, >=, <, >, IN, LIKE.<br>
     * (LIKE is by default %value%. Use \% at the beginning or end of the value to change the position of the wildcard.)
     * <br><br>
     * Example query:<br>id_fieldIN(id1ORid2),field=value,struct_field.struct_param=value,repeated_struct_field.struct_param=value,fieldLIKEvalue<br>
     *
     * @returns BigQueryJobResponse Successful Response
     * @throws ApiError
     */
    public static listFilesStats(
        customerId: string,
        query?: string,
    ): CancelablePromise<BigQueryJobResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/security-auditing/{customer_id}/reports/files-stats',
            path: {
                'customer_id': customerId,
            },
            query: {
                'query': query,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * List shared drives stats
     * List shared drives reports
     * @param customerId
     * @param query
     * Allowed operators:<br>=, ==, !=, <=, >=, <, >, IN, LIKE.<br>
     * (LIKE is by default %value%. Use \% at the beginning or end of the value to change the position of the wildcard.)
     * <br><br>
     * Example query:<br>id_fieldIN(id1ORid2),field=value,struct_field.struct_param=value,repeated_struct_field.struct_param=value,fieldLIKEvalue<br>
     *
     * @returns BigQueryJobResponse Successful Response
     * @throws ApiError
     */
    public static listSharedDrivesStats(
        customerId: string,
        query?: string,
    ): CancelablePromise<BigQueryJobResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/security-auditing/{customer_id}/reports/shared-drives-stats',
            path: {
                'customer_id': customerId,
            },
            query: {
                'query': query,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * List label stats
     * List label reports
     * @param customerId
     * @param query
     * Allowed operators:<br>=, ==, !=, <=, >=, <, >, IN, LIKE.<br>
     * (LIKE is by default %value%. Use \% at the beginning or end of the value to change the position of the wildcard.)
     * <br><br>
     * Example query:<br>id_fieldIN(id1ORid2),field=value,struct_field.struct_param=value,repeated_struct_field.struct_param=value,fieldLIKEvalue<br>
     *
     * @returns BigQueryJobResponse Successful Response
     * @throws ApiError
     */
    public static listLabelStats(
        customerId: string,
        query?: string,
    ): CancelablePromise<BigQueryJobResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/security-auditing/{customer_id}/reports/label-stats',
            path: {
                'customer_id': customerId,
            },
            query: {
                'query': query,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * List orgunit label stats
     * List orgunits reports
     * @param customerId
     * @param query
     * Allowed operators:<br>=, ==, !=, <=, >=, <, >, IN, LIKE.<br>
     * (LIKE is by default %value%. Use \% at the beginning or end of the value to change the position of the wildcard.)
     * <br><br>
     * Example query:<br>id_fieldIN(id1ORid2),field=value,struct_field.struct_param=value,repeated_struct_field.struct_param=value,fieldLIKEvalue<br>
     *
     * @returns BigQueryJobResponse Successful Response
     * @throws ApiError
     */
    public static listOrgunitStats(
        customerId: string,
        query?: string,
    ): CancelablePromise<BigQueryJobResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/security-auditing/{customer_id}/reports/orgunits-stats',
            path: {
                'customer_id': customerId,
            },
            query: {
                'query': query,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Drive stats
     * List drive stats
     * @param customerId
     * @param query
     * Allowed operators:<br>=, ==, !=, <=, >=, <, >, IN, LIKE.<br>
     * (LIKE is by default %value%. Use \% at the beginning or end of the value to change the position of the wildcard.)
     * <br><br>
     * Example query:<br>id_fieldIN(id1ORid2),field=value,struct_field.struct_param=value,repeated_struct_field.struct_param=value,fieldLIKEvalue<br>
     *
     * @returns BigQueryJobResponse Successful Response
     * @throws ApiError
     */
    public static listDriveStats(
        customerId: string,
        query?: string,
    ): CancelablePromise<BigQueryJobResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/security-auditing/{customer_id}/reports/drive-stats',
            path: {
                'customer_id': customerId,
            },
            query: {
                'query': query,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * List top sharing users
     * List top sharing users
     * @param customerId
     * @param query
     * Allowed operators:<br>=, ==, !=, <=, >=, <, >, IN, LIKE.<br>
     * (LIKE is by default %value%. Use \% at the beginning or end of the value to change the position of the wildcard.)
     * <br><br>
     * Example query:<br>id_fieldIN(id1ORid2),field=value,struct_field.struct_param=value,repeated_struct_field.struct_param=value,fieldLIKEvalue<br>
     *
     * @returns BigQueryJobResponse Successful Response
     * @throws ApiError
     */
    public static listTopSharingUsers(
        customerId: string,
        query?: string,
    ): CancelablePromise<BigQueryJobResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/security-auditing/{customer_id}/reports/top-sharing-users',
            path: {
                'customer_id': customerId,
            },
            query: {
                'query': query,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * List sharing domains
     * List sharing domains
     * @param customerId
     * @param query
     * Allowed operators:<br>=, ==, !=, <=, >=, <, >, IN, LIKE.<br>
     * (LIKE is by default %value%. Use \% at the beginning or end of the value to change the position of the wildcard.)
     * <br><br>
     * Example query:<br>id_fieldIN(id1ORid2),field=value,struct_field.struct_param=value,repeated_struct_field.struct_param=value,fieldLIKEvalue<br>
     *
     * @returns BigQueryJobResponse Successful Response
     * @throws ApiError
     */
    public static listSharingDomains(
        customerId: string,
        query?: string,
    ): CancelablePromise<BigQueryJobResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/security-auditing/{customer_id}/reports/sharing-domains',
            path: {
                'customer_id': customerId,
            },
            query: {
                'query': query,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * List sharing with users
     * List shared with users
     * @param customerId
     * @param query
     * Allowed operators:<br>=, ==, !=, <=, >=, <, >, IN, LIKE.<br>
     * (LIKE is by default %value%. Use \% at the beginning or end of the value to change the position of the wildcard.)
     * <br><br>
     * Example query:<br>id_fieldIN(id1ORid2),field=value,struct_field.struct_param=value,repeated_struct_field.struct_param=value,fieldLIKEvalue<br>
     *
     * @returns BigQueryJobResponse Successful Response
     * @throws ApiError
     */
    public static listSharedWithUsers(
        customerId: string,
        query?: string,
    ): CancelablePromise<BigQueryJobResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/security-auditing/{customer_id}/reports/shared-with-users',
            path: {
                'customer_id': customerId,
            },
            query: {
                'query': query,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
