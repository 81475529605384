export function useObjectHelper() {
  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const isEquivalent = (a, b) => {
    if (a === b) {
      return true;
    }

    if (
      typeof a !== "object" ||
      typeof b !== "object" ||
      a === null ||
      b === null
    ) {
      return a === b;
    }

    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    if (keysA.length !== keysB.length) {
      return false;
    }

    return keysA.every((key) => isEquivalent(a[key], b[key]));
  };

  return {
    isEmpty,
    isEquivalent,
  };
}
