<template>
  <VRow>
    <VIcon
      :name="subItem.meta?.icon"
      :small="props.isOpen"
      class="sub-nav-item-icon flex-shrink-0"
      :class="{
        active: routesHelper.isCurrentRoute(route, subItem.name),
        'text-gray-50': !routesHelper.hasItemPrivilege(subItem),
      }"
      :filled="routesHelper.isCurrentRoute(route, subItem.name)"
    />

    <p
      v-if="props.isOpen"
      :class="{
        'text-gray-50': !routesHelper.hasItemPrivilege(subItem),
      }"
    >
      {{ subItem.meta?.pageTitle }}
    </p>
  </VRow>
</template>

<script setup>
import { useRoutesHelper } from "@/composables/routes-helper";
import { useRoute } from "vue-router";

const route = useRoute();
const routesHelper = useRoutesHelper();

const props = defineProps({
  subItem: {
    type: Object,
    default: () => {},
  },
  isOpen: {
    type: Boolean,
    default: true,
  },
});
</script>
