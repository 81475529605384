<template>
  <VMultipleValueGroup
    :model-value="selectedCurrentLayerIds"
    @update:model-value="handleCurrentLayerUpdate"
  >
    <div v-for="(child, index) in props.items" :key="child.id">
      <TreeSelectItem
        :xl="props.xl"
        :is-last="index < props.items.length - 1"
        :child="child"
        :is-open="getOpenedItem(child).open"
        :has-checkbox="!!props.modelValue"
        :is-indeterminate="checkForIndeterminateItem(child)"
        @handle-open="handleOpen"
        @handle-click="handleClick"
        :has-prefix-icon="hasPrefixIcon"
        :disabled="props.disabled"
      />
      <div
        v-if="hasChildren(child) && getOpenedItem(child).open"
        :class="childrenWrapperMargin"
      >
        <VTreeSelect
          :xl="props.xl"
          :modelValue="getSelectedChildren(child.id)"
          @update:modelValue="
            (newValues) => handleInnerLayerUpdate(child, newValues)
          "
          :items="child.children"
          @handle-click="handleClick"
          :has-prefix-icon="true"
          :disabled="props.disabled"
        />
      </div>
    </div>
  </VMultipleValueGroup>
</template>

<script setup>
import { computed, ref, watchEffect } from "vue";
import TreeSelectItem from "@/components/helpers/TreeSelectItem.vue";

// eslint-disable-next-line sonarjs/no-duplicate-string
const emit = defineEmits(["update:modelValue", "handleClick"]);

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: Array,
    default: undefined,
  },
  xl: {
    type: Boolean,
    default: false,
  },
  hasPrefixIcon: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const childrenWrapperMargin = computed(() =>
  props.modelValue ? "ml-11" : "ml-5"
);

// Gets all checked items from current layer
const getDirectParents = () => {
  const directParents = [];

  // Goes through all item ids from current and inner layers
  selectedItemIds?.value?.forEach((checkedId) => {
    // Gets item at current layer from selected id
    const checkedItem = props.items.find((item) => item.id === checkedId);
    if (checkedItem) {
      // Pushes selected id from current layer to array
      directParents.push(checkedId);
    }
  });

  return directParents;
};

// Gets all selected items from inner layer, providing a parent id
const getSelectedChildren = (id) => {
  if (!selectedItemIds.value || !props.items || !props.modelValue)
    return undefined;

  let selectedChildren = [];

  // Gets parent item from given id
  const item = props.items.find((item) => item.id === id);

  // Recursively goes through an item's children and checks if selected
  const goThroughNest = (items) => {
    items.forEach((child) => {
      if (selectedItemIds.value.includes(child.id)) {
        selectedChildren.push(child.id);
      } else if (child.children) {
        goThroughNest(child.children);
      }
    });
  };

  if (item.children) {
    /*
     ** If the parent itself is selected, all its children are added to the selection.
     ** Else, go through all children from the item.
     */
    if (selectedItemIds.value.includes(item.id)) {
      item.children.forEach((child) => {
        selectedChildren.push(child.id);
      });
    } else {
      goThroughNest(item.children);
    }
  }
  return selectedChildren;
};

const getAllChildrenFromItem = (items) => {
  const allChildrenIds = [];

  const goThroughNest = (items) => {
    items.forEach((child) => {
      allChildrenIds.push(child.id);
      child.children && goThroughNest(child.children);
    });
  };

  goThroughNest(items);

  return allChildrenIds;
};

/*
 ** Gets current checkboxgroup's values and filters current layer before submitting to parent layer
 */
const handleCurrentLayerUpdate = (newValues) => {
  // Get the ids of the current layer items
  const currentLayerChildren = props.items.map((item) => item.id);

  // Filter out the selected children that are not part of the current layer
  const selectedChildren = selectedItemIds.value.filter(
    (item) => !currentLayerChildren.includes(item)
  );

  // Update the result by concatenating newValues and selected children
  let result = [...newValues, ...selectedChildren];

  // Checks that if an item from current layer is selected, all related children are out of the array
  currentLayerChildren
    .filter((childId) => result.includes(childId))
    .map((itemId) => {
      const parent = props.items.find((parent) => parent.id === itemId);
      if (parent && parent.children) {
        result = result.filter(
          (item) => !getAllChildrenFromItem(parent.children).includes(item)
        );
      }
    });

  emit("update:modelValue", result);
};

/*
 ** When an item from inner layer is updated, it checks for auto-select from current layer
 ** As variable, the function receives an array with all selected items from an inner layer
 */
const handleInnerLayerUpdate = (parent, newItems) => {
  let trash = [];

  /*
   ** Step 1 -- Assuming we have the modified item from the current layer and the list of selected children from that item,
   ** this function catches all unselected children from the item and adds them to a trash array
   */
  const goThroughNest = (items) => {
    items.map((child) => {
      if (!newItems.includes(child.id)) {
        trash.push(child.id);
      }
      if (child.children) {
        goThroughNest(child.children);
      }
    });
  };

  goThroughNest(parent.children);

  trash = selectedItemIds.value.filter((item) => trash.includes(item));

  // Adds selected items from other items within the current and inner layers, excluding repeated items and trash items
  newItems = newItems.concat(
    selectedItemIds.value.filter(
      (selectedItemId) =>
        !newItems.includes(selectedItemId) && !trash.includes(selectedItemId)
    )
  );

  /*
   ** Step 2 -- Goes through the parent's children and checks if they are all selected
   */
  const checkedChildren = parent.children.filter((child) =>
    newItems.includes(child.id)
  );
  const isAllChildrenChecked =
    checkedChildren.length === parent.children.length;

  if (isAllChildrenChecked) {
    // If all children are selected, remove them and add the parent itself to the result
    newItems = newItems.filter(
      (item) => !parent.children.some((child) => child.id === item)
    );
    newItems.push(parent.id);
  } else if (newItems.includes(parent.id)) {
    // Else, remove the parent from the result
    const parentIndex = newItems.indexOf(parent.id);
    if (parentIndex !== -1) {
      newItems.splice(parentIndex, 1);
    }
  }

  emit("update:modelValue", newItems);
};

const openedItems = ref(
  props.items.map((item) => ({
    id: item.id,
    open: item.open ?? false,
  }))
);

const getOpenedItem = (selectedItem) =>
  openedItems.value.find((item) => selectedItem.id === item.id);
const handleOpen = (selectedItem) => {
  openedItems.value = openedItems.value.map((item) => ({
    ...item,
    open: item.id === selectedItem.id ? !item.open : item.open,
  }));
};

const handleClick = (item) => emit("handleClick", item);

const hasChildren = (item) => {
  return item.children && item.children.length > 0;
};

/*
 ** Checks if item has some selected children but not all, defining it's checkbox value as 'indeterminate'
 */
const checkForIndeterminateItem = (item) => {
  if (!item.children) return false;

  const selectedChildren = getSelectedChildren(item.id);

  return (
    selectedChildren &&
    item.children.some((child) => selectedChildren.includes(child.id)) &&
    !item.children.every((child) => selectedChildren.includes(child.id))
  );
};

// All selected items ids from the current and inner layers
const selectedItemIds = ref(props.modelValue);
// All selected items ids from the current layer only
const selectedCurrentLayerIds = ref(getDirectParents());

/*
 ** Called when the root modelValue is changed and reset checked values,
 ** selected items from current (through watch)/inner (through template) layers
 */
watchEffect(() => {
  selectedItemIds.value = props.modelValue;
  selectedCurrentLayerIds.value = getDirectParents();
});
</script>
