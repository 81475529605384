<template>
  <Menu
    as="div"
    class="relative inline-block text-left"
    data-testid="vmenu-wrapper"
  >
    <div ref="buttonRef">
      <MenuButton data-testid="vmenu-button" @click="calculateMenuPosition">
        <slot> </slot>
      </MenuButton>
    </div>

    <Teleport to="body">
      <div ref="menuRef" class="absolute z-10 w-56" data-testid="vmenu-items">
        <MenuItems
          class="rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div>
            <MenuItem
              as="div"
              v-for="menuItem in menuItems"
              :key="menuItem.name"
              v-slot="{ active }"
            >
              <slot
                :name="`menu-item-${menuItem.name}`"
                :menuItem="menuItem"
                :active="active"
              >
                <VMenuItem
                  :data-testid="`vmenu-item-${menuItem.name}`"
                  :menuItem="menuItem"
                  :active="active"
                  :item="props.item"
                >
                </VMenuItem>
              </slot>
            </MenuItem>
          </div>
        </MenuItems>
      </div>
    </Teleport>
  </Menu>
</template>

<script setup>
/* eslint-disable sonarjs/no-duplicate-string */
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { onMounted, ref } from "vue";

const props = defineProps({
  menuItems: {
    type: Array,
    required: true,
  },
  item: {
    type: Object,
    default: null,
  },
  position: {
    type: String,
    default: "bottom-left",
    validator: (value) => {
      const validVariants = [
        "top-left",
        "top-right",
        "bottom-left",
        "bottom-right",
        "left",
        "right",
      ];
      if (!validVariants.includes(value)) {
        console.warn(
          `Invalid position: ${value}. Defaulting to "bottom-left".`
        );
        return false;
      }
      return true;
    },
  },
});

const buttonRef = ref(null);
const menuRef = ref(null);

const getAbsolutePosition = (element) => {
  const rect = element.getBoundingClientRect();
  return {
    top: rect.top + window.scrollY,
    left: rect.left + window.scrollX,
  };
};

const calculateMenuPosition = () => {
  const child = buttonRef.value.children[0];
  if (!child) return;

  const buttonWidth = buttonRef.value.offsetWidth;
  const buttonHeight = buttonRef.value.offsetHeight;
  const optionsWidth = menuRef.value.offsetWidth;

  const childPosition = getAbsolutePosition(child);
  const splitPosition = props.position.split("-");
  const primaryPosition = splitPosition[0];
  const secondaryPosition = splitPosition[1] ?? "";

  switch (primaryPosition) {
    case "top":
      menuRef.value.style.top = `${
        childPosition.top - props.menuItems.length * 38
      }px`;
      break;
    case "bottom":
      menuRef.value.style.top = `${childPosition.top + buttonHeight + 5}px`;
      break;
    case "left":
      menuRef.value.style.top = `${
        childPosition.top + buttonHeight / 2 - props.menuItems.length * 18
      }px`;
      menuRef.value.style.left = `${childPosition.left - optionsWidth - 5}px`;
      break;
    case "right":
      menuRef.value.style.top = `${
        childPosition.top + buttonHeight / 2 - props.menuItems.length * 18
      }px`;
      menuRef.value.style.left = `${childPosition.left + buttonWidth + 5}px`;
      break;
  }

  switch (secondaryPosition) {
    case "right":
      menuRef.value.style.left = `${childPosition.left}px`;
      break;
    case "left":
      menuRef.value.style.left = `${
        childPosition.left - (optionsWidth - buttonWidth)
      }px`;
      break;
  }
};

onMounted(calculateMenuPosition);
</script>
