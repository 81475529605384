import { defineStore } from "pinia";
import { LabelService } from "@/apis/security-auditing/services/LabelService";
import { useAPIWrapper } from "@/composables/api-wrapper";
import { computed } from "vue";
import { useErrorStore } from "@/stores";

const storeId = "security-auditing-label-store";

export const useLabelStore = defineStore(storeId, () => {
  // ===================== //
  // ======= INIT ======== //
  // ===================== //
  const errorStore = useErrorStore();

  const errorMessages = {
    listItems: `Failed to retrieve your labels. ${errorStore.defaultErrorMessage}`,
  };

  const services = {
    listItemsService: LabelService.listLabels,
  };

  const returnObjectKey = "labels";
  const listServiceParams = ["customerId"];

  // set the values to determine if the filtering and pagination should be done on the client or server side
  const clientSideFiltering = true;
  const clientSidePagination = true;

  // use the API wrapper composable to handle filtering, sorting, pagination and listing of items
  const {
    items,
    addFilter,
    removeFilter,
    listItems: listItemsFromAPI,
    activeFilters,
    totalItems,
    loading,
  } = useAPIWrapper(
    storeId,
    services,
    returnObjectKey,
    clientSideFiltering,
    clientSidePagination,
    listServiceParams,
    null,
    null,
    errorMessages
  );

  // ===================== //
  // ======= LIST ======== //
  // ===================== //

  const listItems = async (params) => {
    await listItemsFromAPI(params);

    // update id from items to lowercase
    return (items.value = items.value.map((item) => {
      return {
        ...item,
        id: String(item.id).toLocaleLowerCase(),
      };
    }));
  };

  const labelsForSelect = computed(() => {
    return items.value.map((label) => {
      return {
        label: label.properties?.title,
        value: String(label.id).toLocaleLowerCase(),
      };
    });
  });

  const getLabelById = (id) => {
    return labelsForSelect.value.find((label) => label.value === id);
  };

  return {
    items,
    getLabelById,
    labelsForSelect,
    listItems,
    addFilter,
    removeFilter,
    activeFilters,
    totalItems,
    loading,
    clientSidePagination,
  };
});
