/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateRole } from '../models/CreateRole';
import type { Role } from '../models/Role';
import type { RoleListResponse } from '../models/RoleListResponse';
import type { SortByEnum } from '../models/SortByEnum';
import type { SortOrderEnum } from '../models/SortOrderEnum';
import type { UpdateRole } from '../models/UpdateRole';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RoleService {

    /**
     * List of roles
     * Get a list of roles.
     *
     * - **customer_id**: The customer id
     * - **query**: The query to filter the results (optional)
     * - **sortBy**: The field to sort the results by (optional)
     * - **sortOrder**: The order to sort the results by (optional)
     * @param customerId
     * @param query
     * @param sortBy
     * @param sortOrder
     * @returns RoleListResponse Successful Response
     * @throws ApiError
     */
    public static listRoles(
        customerId: string,
        query?: string,
        sortBy?: SortByEnum,
        sortOrder?: SortOrderEnum,
    ): CancelablePromise<RoleListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{customer_id}/roles',
            path: {
                'customer_id': customerId,
            },
            query: {
                'query': query,
                'sortBy': sortBy,
                'sortOrder': sortOrder,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create role
     * Creates a role.
     *
     * - **customer_id**: The customer id
     * - **role**: The role to create
     * @param customerId
     * @param requestBody
     * @returns Role Successful Response
     * @throws ApiError
     */
    public static createRole(
        customerId: string,
        requestBody: CreateRole,
    ): CancelablePromise<Role> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customers/{customer_id}/roles',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get role
     * Get a role.
     *
     * - **customer_id**: The customer id
     * - **role_id**: The role id
     * @param customerId
     * @param roleId
     * @returns Role Successful Response
     * @throws ApiError
     */
    public static getRole(
        customerId: string,
        roleId: number,
    ): CancelablePromise<Role> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{customer_id}/roles/{role_id}',
            path: {
                'customer_id': customerId,
                'role_id': roleId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Delete role
     * Deletes a role.
     *
     * - **customer_id**: The customer id
     * - **role_id**: The role id
     * @param customerId
     * @param roleId
     * @returns void
     * @throws ApiError
     */
    public static deleteRole(
        customerId: string,
        roleId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/customers/{customer_id}/roles/{role_id}',
            path: {
                'customer_id': customerId,
                'role_id': roleId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update role
     * Updates a role.
     *
     * - **customer_id**: The customer id
     * - **role_id**: The role id
     * - **updated_role**: The role details
     * @param customerId
     * @param roleId
     * @param requestBody
     * @returns Role Successful Response
     * @throws ApiError
     */
    public static updateRole(
        customerId: string,
        roleId: number,
        requestBody: UpdateRole,
    ): CancelablePromise<Role> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/customers/{customer_id}/roles/{role_id}',
            path: {
                'customer_id': customerId,
                'role_id': roleId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
