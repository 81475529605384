<template>
  <div
    @click.stop="() => (props.xl ? action() : null)"
    class="flex items-center select-none"
    :class="[
      isChecked && props.xl && 'bg-primary-25 border-primary-200',
      xlClass,
    ]"
    data-testid="vcheckbox-container"
  >
    <button
      @click.stop="action"
      :class="[buttonClass, xsClass, { 'mr-2': hasContent }]"
      class="rounded-sm border focus-within:border-primary-500 focus-within:ring-4 focus-within:ring-primary-500/20 hover:!border-primary-600 hover:!bg-primary-100 focus:!border-primary-100 focus:outline-none disabled:!border-gray-300 disabled:!bg-gray-50 disabled:text-gray-200"
      :disabled="props.disabled"
      data-testid="vcheckbox-button"
      data-cy="vcheckbox-button"
    >
      <!-- Checkbox -->
      <div v-if="props.radio === false">
        <VIcon
          v-if="checkedStatus && checkedStatus !== 'NONE'"
          :name="icon"
          :class="[iconColorClass, iconMarginClass]"
          data-testid="vcheckbox-check"
          :small="!props.xs"
          :mini="props.xs"
        />
      </div>
      <!-- Radio -->
      <div v-else>
        <div
          v-if="isChecked"
          class="mx-auto my-auto rounded-full"
          :class="{
            'bg-black': props.disabled,
            'bg-primary-700': !props.disabled,
            'h-2.5 w-2.5': !props.xs,
            'h-1 w-1': props.xs,
          }"
          data-testid="vcheckbox-radio"
        />
      </div>
    </button>
    <span class="my-auto ml-2 flex flex-grow flex-col" v-if="hasContent">
      <span
        @click.stop="action"
        class="text-sm font-medium"
        :class="{
          'cursor-pointer text-gray-900': !props.disabled,
          'text-gray-100': props.disabled,
        }"
      >
        <slot name="label">{{ props.label }}</slot>
      </span>
      <div
        class="text-xs"
        :class="{
          'text-gray-500': !props.disabled,
          'text-gray-50': props.disabled,
        }"
      >
        <slot name="description"><span v-html="props.description"></span></slot>
      </div>
    </span>
  </div>
</template>

<script setup>
/* eslint-disable sonarjs/no-duplicated-branches */
import { computed, inject, onMounted, onUpdated, ref, useSlots } from "vue";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  radio: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [Boolean, String],
  },
  value: {
    type: [String, Number],
    default: null,
  },
  forcedStatus: {
    type: [String, Boolean],
    default: undefined,
  },
  selectAll: {
    type: Boolean,
    default: false,
  },
  xl: {
    type: Boolean,
    default: false,
  },
  xs: {
    type: Boolean,
    default: false,
  },
});

const getInitialCheckedStatus = () => {
  if (props.forcedStatus) return props.forcedStatus;
  return props.selectAll ? "NONE" : props.modelValue;
};
const slots = useSlots();

const checkedStatus = ref(getInitialCheckedStatus());

const toggleCheckedStatus = () => {
  switch (checkedStatus.value) {
    case "ALL":
      checkedStatus.value = "NONE";
      return;
    case "NONE":
      checkedStatus.value = "ALL";
      return;
    case "INDETERMINATE":
      checkedStatus.value = "ALL";
      return;
    default:
      if (props.radio && checkedStatus.value === true) return;
      checkedStatus.value = !checkedStatus.value;
      return;
  }
};

const emitUpdateModelValue = () => {
  emit("update:modelValue", checkedStatus.value);
};

const action = () => {
  if (props.disabled) return;

  toggleCheckedStatus();
  emitUpdateModelValue();
  updateSubscriber({
    checkedStatus: checkedStatus.value,
    value: props.value,
    selectAll: props.selectAll,
    update: update,
  });
};

const hasContent = computed(
  () => props.label || props.description || slots.label || slots.description
);
const isChecked = computed(
  () => checkedStatus.value === true || checkedStatus.value === "ALL"
);

const xlClass = computed(() =>
  props.xl ? "w-full border rounded p-5 cursor-pointer" : "w-fit p-1"
);
const xsClass = computed(() => (props.xs ? "h-3 w-3" : "h-6 w-6 mr-2"));

const iconColorClass = computed(() =>
  props.disabled ? "text-black" : "text-primary-600"
);
const iconMarginClass = computed(() => props.xs && "-ml-[3px] -mt-[3px]");
const buttonClass = computed(() => {
  let classString = "";

  classString += isChecked.value
    ? "border-primary-600 bg-primary-50"
    : "bg-white border-gray-300";
  if (props.radio) {
    classString += " !rounded-full";
  }
  return classString;
});

const icon = computed(() => {
  if (checkedStatus.value === false || checkedStatus.value === "NONE") {
    return null;
  }
  if (isChecked.value === true || checkedStatus.value === "ALL") {
    return "check";
  }
  return "remove";
});

onUpdated(() => {
  if (props.forcedStatus) {
    checkedStatus.value = props.forcedStatus;
  }

  if (props.radio === false && subscribers?.value === undefined) {
    checkedStatus.value = getInitialCheckedStatus();
  }
});

const { addSubscriber, updateSubscriber, removeSubscriber, subscribers } =
  inject("group", {
    subscribers: {},
    updateSubscriber: () => {},
    addSubscriber: () => {},
    removeSubscriber: () => {},
  });

const update = (value) => {
  checkedStatus.value = value;
};

onMounted(() => {
  const subscriber = subscribers?.value?.[props.value];

  const subscriberValues = {
    checkedStatus: checkedStatus.value,
    value: props.value,
    selectAll: props.selectAll,
    update: update,
  };

  if (subscriber) {
    removeSubscriber(subscriberValues);
  }
  addSubscriber(subscriberValues);
});
</script>
