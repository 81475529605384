<template>
  <div
    class="tree-item"
    @click="!props.child.canClick && handleOpen()"
    :data-testid="dataTestId"
  >
    <div :class="[defineItemPreIconHeight, { 'tree-item-pre-icon': true }]">
      <VIcon
        v-if="iconName === 'chevron_right' || props.hasPrefixIcon"
        :name="iconName"
        class="text-gray-75 -ml-[5px]"
        :class="iconClass"
        data-testid="vtreeselect-icon"
        @click="props.child.canClick && handleOpen()"
      />
      <div v-else class="ml-[19px]" />
    </div>
    <span class="item-checkbox" v-if="props.hasCheckbox">
      <VCheckbox
        :value="props.child.id"
        :xs="!props.xl"
        :forced-status="isIndeterminate && 'INDETERMINATE'"
        :disabled="props.disabled"
      >
        <template #label>
          <p :class="textSize">
            {{ props.child.label }}
          </p>
        </template>
      </VCheckbox>
    </span>
    <p
      v-else
      :class="[
        textSize,
        { 'hover:bg-google-blue-100 rounded-xl px-1': props.child.canClick },
      ]"
      @click="props.child.canClick && handleClick()"
    >
      {{ props.child.label }}
    </p>
  </div>
</template>

<script setup>
import { computed } from "vue";

const emit = defineEmits(["handleOpen", "handleClick"]);
const props = defineProps({
  child: {
    type: Object,
    required: true,
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
  isLast: {
    type: Boolean,
    default: false,
  },
  hasPrefixIcon: {
    type: Boolean,
    default: true,
  },
  hasCheckbox: {
    type: Boolean,
    default: false,
  },
  isIndeterminate: {
    type: Boolean,
    default: false,
  },
  xl: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const hasChildren = () => {
  return props.child.children && props.child.children.length > 0;
};

const handleClick = () => emit("handleClick", props.child);

const handleOpen = () => {
  if (hasChildren()) {
    emit("handleOpen", props.child);
  }
};

const textSize = computed(() => (props.xl ? "text-base" : "text-sm"));
const iconName = computed(() => (hasChildren() ? "chevron_right" : "remove"));
const iconClass = computed(() =>
  props.isOpen ? "rotate-90 text-gray-400" : "text-gray-400"
);
const defineItemPreIconHeight = computed(() => {
  if (hasChildren()) return "";
  return props.isLast ? "tree-item-pre-icon-full" : "tree-item-pre-icon-half";
});
const dataTestId = `vtreeselect-container-${props.child.id}`;
</script>

<style scoped>
.tree-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.tree-item-pre-icon {
  position: relative;
  width: fit-content;
  height: 100%;
}

.tree-item-pre-icon:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5px;
  background: #a3a9b8;
}

.tree-item-pre-icon-half:after {
  height: 50%;
}

.tree-item-pre-icon-full:after {
  height: 100%;
}
</style>
