import { defineStore } from "pinia";
import { useRoute, useRouter } from "vue-router";
import {
  useAuthStore,
  useErrorStore,
  useCustomerStore,
  useNotificationStore,
} from "@/stores";
import { useAPIWrapper } from "@/composables/api-wrapper";
const storeId = "user-store";

// SERVICES
import { UserService } from "@/apis/app-management/services/UserService";
import { useRoutesHelper } from "@/composables/routes-helper";

export const useUserStore = defineStore(storeId, () => {
  // ===================== //
  // ======= INIT ======== //
  // ===================== //
  const errorStore = useErrorStore();
  const authStore = useAuthStore();
  const customerStore = useCustomerStore();
  const notificationStore = useNotificationStore();
  const router = useRouter();
  const route = useRoute();

  // Define the services that are used by the API wrapper
  const services = {
    listItemsService: UserService.listUsers,
    getItemService: UserService.getUser,
    createItemService: UserService.createUser,
    updateItemService: UserService.updateUser,
    deleteItemService: UserService.deleteUser,
  };

  const returnObjectKey = "users";
  const listServiceParams = [
    "customerId",
    "pageSize",
    "pageToken",
    "query",
    "sortBy",
    "sortOrder",
  ];

  // set the values to determine if the filtering and pagination should be done on the client or server side
  const clientSideFiltering = true;
  const clientSidePagination = true;

  // orgUnitFilter is used to determine if the orgUnit filter should be used
  const useOrgUnitFilter = false;

  // use the API wrapper composable to handle filtering, sorting, pagination and listing of items
  const {
    // filters
    activeFilters,
    activeAdvancedFilters,
    clearAllFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    removeFilter,
    updateFilter,
    // list
    items,
    listItems,
    totalItems,
    pageToken,
    // general
    resetStore,
    reset,
    loading,
    // crud
    updateItem,
    createItem,
    deleteItem,
  } = useAPIWrapper(
    storeId,
    services,
    returnObjectKey,
    clientSideFiltering,
    clientSidePagination,
    listServiceParams,
    useOrgUnitFilter
  );

  // ===================== //
  // ======= GET ========= //
  // ===================== //
  const get = errorStore.withErrorHandling(
    async (userId) => {
      return await UserService.getUser(customerStore.activeCustomerId, userId);
    },
    storeId,
    "get",
    "Failed to create user",
    [404]
  );

  // ===================== //
  // ======= CREATE ====== //
  // ===================== //
  const invite = errorStore.withErrorHandling(
    async (user) => {
      const message = {
        success: `Invitation sent to ${user.email}`,
        error: {
          409: `Invitation for user with email ${user.email} already exists`,
          default: `Failed to invite user with email ${user.email}`,
        },
      };
      return await createItem(user, null, message);
    },
    storeId,
    "invite",
    "Failed to invite user"
  );

  const resendInvitation = errorStore.withErrorHandling(async (user) => {
    return await UserService.resendInvite(customerStore.activeCustomerId, user)
      .then(() => {
        notificationStore.showToast("Invitation has been sent", "success");
      })
      .catch();
  }, "resend invitation");

  // ===================== //
  // ======= UPDATE ====== //
  // ===================== //
  const update = errorStore.withErrorHandling(
    async (userId, user) => {
      const userUpdate = {
        email: user.email,
        roleIds: user.roleIds,
        status: user.status,
        etag: user.etag,
      };

      const message = {
        success: `User updated`,
        error: `Failed to update user`,
      };

      return await updateItem(userId, userUpdate, null, message);
    },
    storeId,
    "update",
    "Failed to update user"
  );

  const activateUser = errorStore.withErrorHandling(
    async (googleCredentials, customerId, invitationId) => {
      await authStore.loginToFirebase();

      const response = await UserService.acceptUserInvitation(
        customerId,
        invitationId
      );
      if (response.status === 404) {
        return response;
      }
      await authStore.generalLogin(googleCredentials);
      await useRoutesHelper().handlePostLoginRedirection(router, route);
      return response;

      // TODO: set custom error message -- `Failed to accept invitation`
    },
    storeId,
    "activate",
    "Failed to activate user",
    [404]
  );
  // ===================== //
  // ======= DELETE ====== //
  // ===================== //

  const cancelInvitation = errorStore.withErrorHandling(
    async (user) => {
      const message = {
        success: `Invitation cancelled`,
        error: `Failed to cancel invitation`,
      };
      return await deleteItem(user.id, null, message);
    },
    storeId,
    "cancel invitation",
    "Failed to cancel invitation for user"
  );

  const deleteUser = errorStore.withErrorHandling(
    async (userId) => {
      const message = {
        success: `User deleted`,
        error: `Failed to delete user`,
      };

      return await deleteItem(userId, null, message);
    },
    storeId,
    "delete",
    "Failed to delete user"
  );

  return {
    // filters
    activeFilters,
    useOrgUnitFilter,
    activeAdvancedFilters,
    clearAllFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    removeFilter,
    updateFilter,

    // list
    items,
    listItems,
    totalItems,
    pageToken,
    clientSidePagination,

    // general
    resetStore,
    reset,
    loading,

    // crud
    get,
    update,
    invite,
    deleteUser,
    activateUser,
    cancelInvitation,
    resendInvitation,
  };
});
