import { ref, inject, onMounted } from "vue";
import { useErrorStore } from "@/stores/error-store";

// eslint-disable-next-line sonarjs/cognitive-complexity
export function useFormInput(props) {
  const errorStore = useErrorStore();
  const savedInputValue = ref("");
  const inputValue = ref(props.inputValue ?? props.modelValue ?? "");
  // =============================================
  // ================ Styling ====================
  // =============================================

  let inputStyling =
    "block h-6 w-full text-gray-900 placeholder-gray-400 focus:outline-none text-sm  disabled:cursor-not-allowed disabled:bg-disabled disabled:shadow-none";

  // =============================================
  // ================ Validation =================
  // =============================================

  const activeRules = ref([]);
  const validation = ref(null);
  const minCounter = ref(0);
  const maxCounter = ref(0);

  let validateInput = () => {};

  if (props.rules) {
    props.rules.forEach((rule) => {
      if (rule === "required") {
        activeRules.value.push((v) => !!v || "This field is required");
      } else if (rule === "alphabetic") {
        activeRules.value.push(
          (v) =>
            !v ||
            /^[a-zA-Z ]+$/.test(v) ||
            "Numeric and special characters are not accepted"
        );
      } else if (rule === "numeric") {
        activeRules.value.push(
          (v) =>
            !v || /^[0-9]+$/.test(v) || "Alphabetic characters are not accepted"
        );
      } else if (rule === "alphanumeric") {
        activeRules.value.push(
          (v) =>
            !v ||
            /^[a-zA-Z0-9 ]+$/.test(v) ||
            "Special characters are not accepted"
        );
      } else if (rule === "email") {
        activeRules.value.push(
          (v) =>
            !v || /.+@.+\..+/.test(v) || "Please enter a valid email address"
        );
      } else if (rule[0] === "minlength") {
        minCounter.value = rule[1];
        activeRules.value.push(
          (v) =>
            !v ||
            v.length >= rule[1] ||
            `This field must be at least ${rule[1]} characters`
        );
      } else if (rule[0] === "maxlength") {
        maxCounter.value = rule[1];
        activeRules.value.push(
          (v) =>
            !v ||
            v.length <= rule[1] ||
            `This field must be less than ${rule[1]} characters`
        );
      } else {
        activeRules.value.push(rule);
      }
    });

    validateInput = () => {
      validation.value = null;
      if (activeRules.value.length > 0) {
        activeRules.value.forEach((rule) => {
          let currentValidation = true;
          try {
            currentValidation = rule(inputValue.value);
          } catch (error) {
            const errorContext = {
              errorType: "COMPONENT_ERROR",
              message: `Error in rule ${rule} of input ${props.label}. the rule cannot be applied (example: v === null and rule is v.length > 0)`,
              source: "form-input.js",
              function: "validateInput",
              data: {
                stack: error.stack,
              },
            };
            errorStore.addError({ error, errorContext});
          }
          if (currentValidation !== true) {
            validation.value = currentValidation;
          }
        });
      }

      if (!validation.value) {
        savedInputValue.value = inputValue.value;
      } else if (props.forceRules) {
        inputValue.value = savedInputValue.value;
      }

      updateSubscriber.value({
        label: props.label,
        validInput: validation.value === null,
        validate: validateInput,
        inputReceived: true,
      });
    };

    const formValidation = inject("formValidation", {
      updateSubscriber: () => {},
      addSubscriber: () => {},
    });

    const addSubscriber = ref(formValidation.addSubscriber);
    const updateSubscriber = ref(formValidation.updateSubscriber);

    onMounted(() => {
      addSubscriber.value({
        label: props.label,
        validInput: validation.value === null,
        validate: validateInput,
        inputReceived: false,
      });
    });
  }

  // expose managed state as return value
  return {
    inputValue,
    // styling
    inputStyling,
    // Validation
    validation,
    validateInput,
    counter: { minCounter, maxCounter },
  };
}
