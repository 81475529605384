<template>
  <Bar
    v-if="data"
    :data="data"
    :options="options"
    :plugins="plugins"
    data-testid="vchartbar-bar"
    class="max-h-[150px]"
  />
  <ChartLegend
    v-if="props.separateLegend && data"
    :data="data"
    origin="bar"
    @click="props.isLegendInteractive && handleLegendClick"
    :donut-default-data-text="props.donutDefaultDataText"
  />
</template>

<script setup>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { computed, onMounted, ref, watch } from "vue";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ChartLegend from "@/components/helpers/ChartLegend.vue";
import {useTextFormat} from "@/composables/text-format";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
);

const props = defineProps({
  /*
   ** Data structure
   ** {
   **    label: String,
   **    backgroundColor: String,
   **    borderColor: String,
   **    active: String,
   **    data: {
   **             label: String,
   **             amount: Number,
   **          }
   ** }
   */
  data: {
    type: Array,
    required: true,
  },
  barColorHover: {
    type: String,
    default: undefined,
  },
  showGrid: {
    type: Boolean,
    default: false,
  },
  showFixedValues: {
    type: Boolean,
    default: false,
  },
  showTooltips: {
    type: Boolean,
    default: true,
  },
  barBorderRadius: {
    type: Number,
    default: 3,
  },
  barPercentage: {
    type: Number,
    default: 30,
  },
  borderWidth: {
    type: Number,
    default: 2,
  },
  minBarLength: {
    type: Number,
    default: 50,
  },
  maxBarThickness: {
    type: Number,
    default: 50,
  },
  stacked: {
    type: Boolean,
    default: false,
  },
  indexAxis: {
    type: String,
    default: "x",
    validator: (value) => {
      const validVariants = ["x", "y"];
      if (!validVariants.includes(value)) {
        console.warn(`Invalid position: ${value}. Defaulting to "x".`);
        return false;
      }
      return true;
    },
  },
  legendPosition: {
    type: String,
    default: "bottom",
  },
  separateLegend: {
    type: Boolean,
    default: true,
  },
  isLegendInteractive: {
    type: Boolean,
    default: true,
  },
  donutDefaultDataText: {
    type: String,
    default: "Other",
  },
});

const formatDataLabels = (_, context) => {
  return useTextFormat().shortenNumber(context.dataset.data[context.dataIndex]);
};

const plugins = [props.showFixedValues && ChartDataLabels];
const options = computed(() => ({
  indexAxis: props.indexAxis,
  scales: {
    x: {
      stacked: props.stacked,
      grid: {
        display: props.showGrid,
      },
    },
    y: {
      stacked: props.stacked,
      grid: {
        display: props.showGrid,
      },
    },
  },
  plugins: {
    tooltip: {
      enabled: props.showTooltips,
      callbacks: {
        label: (context) => {
          return (context.dataset.label + ': ' || '' ) + formatDataLabels(undefined, context);
        },
      },
    },
    legend: {
      display: !props.separateLegend,
      position: props.legendPosition ?? "bottom",
      align: "start",
      onHover,
    },
  },
}));

const propsData = ref(props.data);
const data = ref(null);

const onHover = (e) => {
  e.native.target.style.cursor = "pointer";
};


const getDataLabelsOptions = () => {
  const response = {}
  if (props.showFixedValues) {
    response.formatter = formatDataLabels;
    response.anchor = "end";
    response.align = props.indexAxis === "x" ? "top" : "left";
    response.color = "black";
    response.font = {
      // weight: "bold",
    };
  }
  return response;
}

const generateData = () => {
  const labels = [
    ...new Set(
      propsData.value.flatMap((dataValue) =>
        dataValue.data.flatMap((childData) => childData.label)
      )
    ),
  ];
  const datasets = propsData.value
    .filter(({ active }) => active)
    .map((dataValue) => ({
        label: dataValue.label,
        backgroundColor: dataValue.backgroundColor,
        borderColor: dataValue.borderColor,
        borderRadius: props.barBorderRadius,
        hoverBackgroundColor: props.barColorHover,
        categoryPercentage: 1,
        borderWidth: props.borderWidth,
        barPercentage: props.barPercentage,
        minBarLength: props.minBarLength,
        maxBarThickness: props.maxBarThickness,
        datalabels: getDataLabelsOptions(),
        data: dataValue.data.map(({ amount }) => amount),
      })
    );

  data.value = { labels, datasets };
};

const handleLegendClick = (index) => {
  propsData.value[index].active = !propsData.value[index].active;
  generateData();
};

watch([props], generateData);

onMounted(generateData);
</script>
