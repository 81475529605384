/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BulkAction } from '../models/BulkAction';
import type { CreatePolicyRequest } from '../models/CreatePolicyRequest';
import type { PoliciesListResponse } from '../models/PoliciesListResponse';
import type { Policy } from '../models/Policy';
import type { PolicyUpdateRequest } from '../models/PolicyUpdateRequest';
import type { SortOrderEnum } from '../models/SortOrderEnum';
import type { src__helpers__policy_helper__SortByEnum } from '../models/src__helpers__policy_helper__SortByEnum';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { PageSizeEnum } from '../models/PageSizeEnum';
import { request as __request } from '../core/request';

export class PoliciesService {

    /**
     * List Policies
     * List policies
     *
     * Args:
     * customer_id(str): Customer id
     * filters(PolicyFilters): Policy filters
     *
     * Returns:
     * PoliciesListResponse: Policies list response
     * @param customerId
     * @param pageSize
     * @param pageToken
     * @param query
     * @param sortBy
     * @param sortOrder
     * @returns PoliciesListResponse Successful Response
     * @throws ApiError
     */
    public static listPolicies(
        customerId: string,
        query?: string,
        pageSize?: PageSizeEnum,
        pageToken?: string,
        sortBy?: src__helpers__policy_helper__SortByEnum,
        sortOrder?: SortOrderEnum,
    ): CancelablePromise<PoliciesListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/security-auditing/{customer_id}/policies',
            path: {
                'customer_id': customerId,
            },
            query: {
                'query': query,
                'pageSize': PageSizeEnum._999999,
                'pageToken': pageToken,
                'sortBy': sortBy,
                'sortOrder': sortOrder,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create Policy
     * Create Policy
     *
     * Args:
     * customer_id(str): Customer ID
     * create_policy_request(CreatePolicyRequest): Policy request
     * current_user(AuthenticatedUser): Current user
     *
     * Returns:
     * Policy: Policy
     * @param customerId
     * @param requestBody
     * @returns Policy Successful Response
     * @throws ApiError
     */
    public static createPolicy(
        customerId: string,
        requestBody?: CreatePolicyRequest,
    ): CancelablePromise<Policy> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/security-auditing/{customer_id}/policies',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get Policy
     * Get policy by id
     *
     * Args:
     * customer_id(str): Customer id
     * policy_id(int): Policy id
     * current_user(AuthenticatedUser): Current user
     *
     * Returns:
     * Policy: Policy
     * @param customerId
     * @param policyId
     * @returns Policy Successful Response
     * @throws ApiError
     */
    public static getPolicy(
        customerId: string,
        policyId: number,
    ): CancelablePromise<Policy> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/security-auditing/{customer_id}/policies/{policy_id}',
            path: {
                'customer_id': customerId,
                'policy_id': policyId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Delete Policy
     * Delete bulk action
     *
     * Args:
     * customer_id(str): Customer id
     * bulk_action_id(int): Bulk action id
     * current_user(AuthenticatedUser): Current user
     *
     * Returns:
     * None
     * @param customerId
     * @param policyId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deletePolicy(
        customerId: string,
        policyId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/security-auditing/{customer_id}/policies/{policy_id}',
            path: {
                'customer_id': customerId,
                'policy_id': policyId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update Policy
     * Update policy
     *
     * Args:
     * customer_id(str): Customer id
     * policy_id(int): Policy id
     * policy_update_request(PolicyUpdateRequest): Policy update request
     * current_user(AuthenticatedUser): Current user
     *
     * Returns:
     * Policy: Policy
     * @param customerId
     * @param policyId
     * @param requestBody
     * @returns Policy Successful Response
     * @throws ApiError
     */
    public static updatePolicy(
        customerId: string,
        policyId: number,
        requestBody?: PolicyUpdateRequest,
    ): CancelablePromise<Policy> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/security-auditing/{customer_id}/policies/{policy_id}',
            path: {
                'customer_id': customerId,
                'policy_id': policyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Force run policy
     * Force run policy
     *
     * Args:
     * customer_id(str): Customer id
     * policy_id(int): Policy id
     * current_user(AuthenticatedUser): Current user
     *
     * Returns:
     * BulkAction: bulk action
     * @param customerId
     * @param policyId
     * @returns BulkAction Successful Response
     * @throws ApiError
     */
    public static forceRunPolicy(
        customerId: string,
        policyId: number,
    ): CancelablePromise<BulkAction> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/security-auditing/{customer_id}/policies/{policy_id}/force_run',
            path: {
                'customer_id': customerId,
                'policy_id': policyId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
