import { defineStore } from "pinia";
import { BigQueryJobsService } from "@/apis/security-auditing/services/BigQueryJobsService";
import { useAPIWrapper } from "@/composables/api-wrapper";
import { DriveFilePermissionsService } from "@/apis/security-auditing";
import { useCustomerStore, useNotificationStore } from "..";
import { useErrorStore } from "@/stores/error-store";

const storeId = "file-permissions-store";

export const useFilePermissionsStore = defineStore(storeId, () => {
  // ===================== //
  // ======= INIT ======== //
  // ===================== //
  const customerStore = useCustomerStore();
  const errorStore = useErrorStore();
  const notificationStore = useNotificationStore();

  const services = {
    createItemService: DriveFilePermissionsService.createPermissions,
    updateItemService: DriveFilePermissionsService.updatePermissions,
    deleteItemService: DriveFilePermissionsService.deletePermissions,
    getQueryJobStatusService: BigQueryJobsService.pollJobResult,
  };

  const sharedDrivePermissionMapping = [
    {
      name: "reader",
      displayName: "Viewer",
      icon: "visibility",
      color: "google-green-600",
    },
    {
      name: "commenter",
      displayName: "Commenter",
      icon: "chat_bubble",
      color: "google-blue-600",
    },
    {
      name: "writer",
      displayName: "Contributor",
      icon: "edit",
      color: "google-red-600",
    },
    {
      name: "fileOrganizer",
      displayName: "Content manager",
      icon: "notes",
      color: "google-purple-600",
    },
    {
      name: "organizer",
      displayName: "Manager",
      icon: "settings",
      color: "black",
    },
  ];

  const myDrivePermissionMapping = [
    { name: "owner", displayName: "Owner" },
    {
      name: "reader",
      displayName: "Viewer",
      icon: "visibility",
      color: "google-green-600",
    },
    {
      name: "commenter",
      displayName: "Commenter",
      icon: "chat_bubble",
      color: "google-blue-600",
    },
    {
      name: "writer",
      displayName: "Editor",
      icon: "edit",
      color: "google-red-600",
    },
  ];

  const returnObjectKey = "rows";

  const listServiceParams = ["customerId", "query", "sortBy", "sortOrder"];

  // use the API wrapper composable to handle filtering, sorting, pagination and listing of items
  const {
    items,
    createItem,
    updateItem,
    deleteItem,
    totalItems,
    loading,
    pageToken,
  } = useAPIWrapper(storeId, services, returnObjectKey, listServiceParams);

  // ===================== //
  // ======= CREATE ====== //
  // ===================== //
  const createPermission = errorStore.withErrorHandling(
    async (fileId, permissionType, permission) => {
      const expirationDate =
        permission.expirationTime && new Date(permission.expirationTime);

      let createdItem = {
        role: permission.role,
        type: permission.type,
      };

      if (permission.type === "domain") {
        createdItem = {
          ...createdItem,
          type: "domain",
          allowFileDiscovery: permission.allowFileDiscovery === 1,
          domain: permission.value,
        };
      } else {
        createdItem = {
          ...createdItem,
          emailAddress: permission.value,
          pendingOwner: true,
        };
      }
      if (permission.expirationTime) {
        createdItem = {
          ...createdItem,
          expirationTime: expirationDate.toISOString(),
        };
      }

      const permissionName = createdItem.name ?? "";

      try {
        const res = await DriveFilePermissionsService.createPermissions(
          customerStore.activeCustomerId,
          fileId,
          permissionType,
          createdItem
        );
        notificationStore.showToast(
          `Permission ${permissionName} has been created`,
          "success"
        );

        // add the newly created item to the list of items
        items.value.push(res);

        return res;
      } catch (error) {
        notificationStore.showToast(
          `Failed to create permission. This usually occurs because there is no user with the necessary privilege to share the file. If this involves a Shared Drive, you can try adding a "managing" user to the Shared Drive with the 'Manager' role, then attempt the operation again.`,
          "error"
        );
      }
    },
    storeId,
    "create",
    "Failed to create permission"
  );

  // ============================ //
  // ======= CREATE PUBLIC ====== //
  // ============================ //
  const createPublicPermission = errorStore.withErrorHandling(
    async (fileId, permissionType, permission) => {
      const expirationDate =
        permission?.expirationTime && new Date(permission.expirationTime);

      let createdItem = {
        role: permission.role,
        type: "anyone",
        allowFileDiscovery: permission.allowFileDiscovery === 1,
      };
      if (permission.expirationTime) {
        createdItem = {
          ...createdItem,
          expirationTime: expirationDate.toISOString(),
        };
      }

      const res = await DriveFilePermissionsService.createPermissions(
        customerStore.activeCustomerId,
        fileId,
        permissionType,
        createdItem
      );
      notificationStore.showToast(
        `Public permission has been created`,
        "success"
      );
      return res;
    },
    storeId,
    "update public",
    "Failed to update public permission"
  );

  // ===================== //
  // ======= UPDATE ====== //
  // ===================== //
  const updatePermission = errorStore.withErrorHandling(
    async (fileId, permissionType, permission) => {
      const expirationDate =
        permission.expirationTime && new Date(permission.expirationTime);

      let updatedItem = {
        role: permission.role,
      };

      if (permission.expirationTime) {
        updatedItem = {
          ...updatedItem,
          expirationTime: expirationDate.toISOString(),
        };
      }

      const permissionName = permission.name ?? "";

      const res = await DriveFilePermissionsService.updatePermissions(
        customerStore.activeCustomerId,
        fileId,
        permission.id,
        permissionType,
        updatedItem
      );
      notificationStore.showToast(
        `Permission ${permissionName} has been updated`,
        "success"
      );
      return res;
    },
    storeId,
    "update",
    "Failed to update permission"
  );

  // ===================== //
  // ======= DELETE ====== //
  // ===================== //
  const deletePermission = errorStore.withErrorHandling(
    async (fileId, permissionType, permission) => {
      const permissionName = permission.name ?? "";

      const res = await DriveFilePermissionsService.deletePermissions(
        customerStore.activeCustomerId,
        fileId,
        permission.id,
        permissionType
      );
      notificationStore.showToast(
        `Permission ${permissionName} has been deleted`,
        "success"
      );
      return res;
    },
    storeId,
    "delete",
    "Failed to delete permission"
  );

  // ======================= //
  // ======= TRANSFER ====== //
  // ======================= //
  const transferOwnership = errorStore.withErrorHandling(
    async (fileId, newOwner) => {
      return DriveFilePermissionsService.transferOwnership(
        customerStore.activeCustomerId,
        fileId,
        newOwner
      )
        .then(() => {
          notificationStore.showToast("Owner has been transferred", "success");
        })
        .catch((error) => {
          try {
            notificationStore.showToast(error.body.detail, "error");
          } catch {
            notificationStore.showToast(
              "Failed to transfer ownership",
              "error"
            );
          }
        });
    },
    storeId,
    "transfer ownership",
    "Failed to transfer ownership"
  );

  // ===================== //
  // ====== HELPERS ====== //
  // ===================== //

  // const formatSpecialRoles = (role) => {
  //   switch (role) {
  //     case "manager":
  //       return "organizer";
  //     case "content manager":
  //       return "fileOrganizer";
  //     default:
  //       return role;
  //   }
  // };

  return {
    myDrivePermissionMapping,
    sharedDrivePermissionMapping,
    items,
    createItem,
    updateItem,
    deleteItem,
    createPermission,
    createPublicPermission,
    updatePermission,
    deletePermission,
    transferOwnership,
    totalItems,
    loading,
    pageToken,
  };
});
