<template>
  <VRow
    class="items-center"
    gap="2"
    @click="onClick"
    :class="{ 'cursor-pointer': props.clickable }"
  >
    <p v-if="props.prefix" data-testid="vavatar-prefix">{{ props.prefix }}</p>
    <div
      class="flex justify-center items-center rounded-full text-sm focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-1 focus:ring-offset-google-blue"
      :class="[{ 'bg-primary': !props.photoUrl }, sizeClass]"
      data-testid="vavatar-container"
    >
      <span class="sr-only">Open user menu</span>
      <img
        v-if="props.photoUrl"
        class="h-full w-full rounded-full p-0.5"
        :src="props.photoUrl"
        :alt="props.initials"
        data-testid="vavatar-photourl"
      />
      <p v-else class="text-white" data-testid="vavatar-initials">
        {{ props.initials }}
      </p>
    </div>
    <p v-if="props.suffix" data-testid="vavatar-suffix">{{ props.suffix }}</p>
  </VRow>
</template>

<script setup>
import { computed } from "vue";

const emit = defineEmits(["click"]);

const props = defineProps({
  initials: {
    type: String,
    required: true,
  },
  photoUrl: {
    type: String,
    default: null,
  },
  prefix: {
    type: String,
    default: null,
  },
  suffix: {
    type: String,
    default: null,
  },
  xs: {
    type: Boolean,
    default: false,
  },
  sm: {
    type: Boolean,
    default: false,
  },
  lg: {
    type: Boolean,
    default: false,
  },
  xl: {
    type: Boolean,
    default: false,
  },
  clickable: {
    type: Boolean,
    default: false,
  },
});

const sizeClass = computed(() => {
  if (props.xs) return `w-4 h-4`;
  else if (props.sm) return `w-6 h-6`;
  else if (props.lg) return `w-16 h-16`;
  else if (props.xl) return `w-24 h-24`;
  return `w-10 h-10`;
});

const onClick = () => {
  props.clickable && emit("click");
};
</script>
