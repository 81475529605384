import { defineStore } from "pinia";

import { CustomerService } from "@/apis/app-management";
import { useErrorStore } from "@/stores";

const storeId = "free-scan-store";

export const useFreeScanStore = defineStore(storeId, () => {
  const errorStore = useErrorStore();

  function withErrorHandling(fn, functionName, errorReturnList) {
    return async (...args) => {
      try {
        return await fn(...args);
      } catch (error) {
        // if error in the errorReturnList, return the error
        if (errorReturnList?.includes(error.status)) {
          return error;
        }
        const errorContext = {
          errorType: error.name,
          message: error.message,
          source: storeId,
          function: functionName,
        };

        const userMessage = `Failed to ${functionName} free scan, please contact support`;

        errorStore.addError({
          error,
          errorContext,
          userMessage,
        });
      }
    };
  }

  const sendRequestToAdmin = withErrorHandling(
    async (partnerId, adminEmail) => {
      try {
        return await CustomerService.mailAdminOtsCustomer({
          partnerId: partnerId,
          adminEmail: adminEmail,
        });
      } catch (error) {
        return error.status;
      }
    },
    "send Request to Admin for"
  );

  const create = withErrorHandling(
    async (customer) => {
      try {
        return await CustomerService.createNewOtsCustomer({
          name: customer.name,
          partnerId: customer.partnerId,
          adminEmail: customer.adminEmail,
          agreedToTerms: customer.agreedToTerms,
        });
      } catch (error) {
        return error.status;
      }
    },
    "create",
    [409]
  );

  return {
    create,
    sendRequestToAdmin,
  };
});
