import { defineStore } from "pinia";
import { useAPIWrapper } from "@/composables/api-wrapper";
import {BigQueryJobsService, ReportsService} from "@/apis/security-auditing";
import {useErrorStore} from "@/stores";

const storeId = "top-sharing-store";

export const useTopSharingUsersStore = defineStore(storeId, () => {
  // ===================== //
  // ======= INIT ======== //
  // ===================== //
  const errorStore = useErrorStore();

  const errorMessages = {
    listItems: `Failed to retrieve your top user shares. ${errorStore.defaultErrorMessage}`,
    getQueryJobStatus: `Failed to retrieve your top user shares. ${errorStore.defaultErrorMessage}`,
  };

  const services = {
    listItemsService: ReportsService.listTopSharingUsers,
    getQueryJobStatusService: BigQueryJobsService.pollJobResult,
  };

  const returnObjectKey = "rows";
  const listServiceParams = ["customerId", "query", "sortBy", "sortOrder"];

  // set the values to determine if the filtering and pagination should be done on the client or server side
  const clientSideFiltering = false;
  const clientSidePagination = false;

  // orgUnitFilter is used to determine if the orgUnit filter should be used
  // const useOrgUnitFilter = {
  //   id: "orgUnit.orgUnitId",
  // };
  //
  // const useIncludeOrgUnitsFilter = {
  //   id: "includeSubOrgUnits",
  // };

  // use the API wrapper composable to handle filtering, sorting, pagination and listing of items
  const {
    // list
    items,
    listItems,
    loading,
  } = useAPIWrapper(
    storeId,
    services,
    returnObjectKey,
    clientSideFiltering,
    clientSidePagination,
    listServiceParams,
    null,
    null,
    errorMessages
  );

  return {
    // list
    items,
    listItems,
    clientSidePagination,
    loading,
  };
});
