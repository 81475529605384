<template>
  <BaseInputField
    :label="props.label"
    ref="inputField"
    :area="true"
    :error="validation && validation !== true"
    :disabled="props.disabled"
  >
    <template #default>
      <textarea
        type="text"
        v-model="inputValue"
        :placeholder="placeholder"
        :class="inputStyling"
        class="!h-full"
        :rows="rows"
        @input="validateInput"
        @focusout="validateInput"
        :disabled="props.disabled"
      />
    </template>
    <template #info>
      <div class="flex justify-between">
        <div class="h-5">
          <span v-if="validation && validation !== true">
            {{ validation }}
          </span>
        </div>

        <div
          v-if="inputValue.length != 0 && counter.minCounter.value"
          class="my-auto text-xs text-gray-500"
          :class="
            counter.minCounter && inputValue.length < counter.minCounter.value
              ? '!text-error'
              : ''
          "
        >
          {{ inputValue?.length }}
        </div>
        <div
          v-else-if="counter.maxCounter.value"
          class="my-auto text-xs text-gray-500"
          :class="
            counter.maxCounter && inputValue.length > counter.maxCounter.value
              ? '!text-error'
              : ''
          "
        >
          {{ inputValue?.length }}/{{ counter.maxCounter.value }}
        </div>
      </div>
    </template>
  </BaseInputField>
</template>

<script setup>
import BaseInputField from "@/components/helpers/BaseInputField.vue";
import { watch } from "vue";
import { useFormInput } from "@/composables/form-input";

const props = defineProps({
  modelValue: { type: String, default: "" },
  label: { type: String, default: "Date" },
  placeholder: { type: String, default: "" },
  rows: { type: Number, default: 3 },
  rules: { type: Array, default: () => [] },
  disabled: { type: Boolean, default: false },
});

// use validation and styling from composable
const { inputValue, validation, validateInput, inputStyling, counter } =
  useFormInput(props);

const emit = defineEmits(["update:modelValue"]);

watch(
  () => inputValue.value,
  (value) => {
    emit("update:modelValue", value);
  }
);
</script>
