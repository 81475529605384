<template>
  <div
    class="border relative bottom-center-center -ml-3 w-[60rem] max-w-sm rounded border-gray-200 bg-white shadow"
    ref="optionsElement"
  >
    <div class="p-2 px-3">
      <div class="mt-4 flex items-center">
        <DatePickerOptionsIconButton
          @click="previousMonth"
          icon="chevron-left"
        />

        <div class="flex w-full flex-grow justify-center text-base">
          <div class="text-center text-gray-700">
            {{ selectedMonthName }} {{ selectedYear }}
          </div>
        </div>

        <DatePickerOptionsIconButton @click="nextMonth" icon="chevron-right" />

        <div class="flex-grow"></div>
        <div>
          <button
            class="-my-1.5 mr-1 flex flex-none items-center justify-center rounded border-2 p-1.5 px-3 text-sm font-medium text-gray-600 hover:text-gray-700"
            @click="goToToday()"
          >
            Today
          </button>
        </div>
      </div>

      <div
        class="mt-6 grid grid-cols-7 text-center text-sm leading-6 text-gray-700"
      >
        <div v-for="day in weekDays" :key="day">{{ day }}</div>
      </div>

      <div class="font-body mt-3 grid grid-cols-7 text-sm">
        <div v-for="day in days" :key="day.date" :class="dayClasses(day)">
          <button
            :disabled="!isDayBetweenMinMax(day)"
            @click="selectDay(day)"
            type="button"
            class="mx-2 flex h-11 w-11 xl:w-12 xl:h-12 items-center justify-center rounded-full"
          >
            <time
              :datetime="day.date"
              :class="{ 'text-gray-50': !isDayBetweenMinMax(day) }"
              >{{ day.date.split("-").pop().replace(/^0/, "") }}</time
            >
          </button>
        </div>
      </div>
    </div>

    <DatePickerOptionsFooter
      v-if="!props.confirmOnSelection"
      @onConfirm="onConfirm"
      @cancel="cancel"
      :disable-submit="isSubmitDisabled"
    />
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";

import DatePickerOptionsIconButton from "@/components/helpers/DatePickerOptionsIconButton.vue";
import DatePickerOptionsFooter from "@/components/helpers/DatePickerOptionsFooter.vue";
import { useDateTimeHelper } from "@/composables/date-time-helper";
import { onClickOutside } from "@vueuse/core";

const weekDays = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

// get required functions & constants from the date-time composable
const { monthNames, getDaysForCalendar } = useDateTimeHelper();
const currentDate = new Date();

// eslint-disable-next-line sonarjs/no-duplicate-string
const emit = defineEmits(["update:modelValue", "close"]);

const props = defineProps({
  modelValue: { type: [String, Array], default: null },
  range: { type: Boolean, default: false },
  modalTop: { type: Boolean, default: false },
  confirmOnSelection: { type: Boolean, default: false },
  persistent: { type: Boolean, default: false },
  min: { type: Date, default: null },
  max: { type: Date, default: null },
});

const optionsElement = ref(null);

const selectedDates = ref(props.modelValue);

const previousSelectedDates = ref(props.modelValue);

const datesInRange = computed(() => getAllDatesInRange());
const selectedMonthName = computed(() => monthNames[selectedMonth.value]);

const isMultipleDates = computed(
  () => Array.isArray(selectedDates.value) && props.range
);

const isSubmitDisabled = computed(
  () =>
    (isMultipleDates.value && selectedDates.value.length !== 2) ||
    (!isMultipleDates.value && selectedDates.value === null)
);

const selectedFirstDate = isMultipleDates.value
  ? props.modelValue[0]
  : props.modelValue;

const selectedMonth = ref(
  selectedFirstDate
    ? new Date(selectedFirstDate).getMonth()
    : currentDate.getMonth()
);
const selectedYear = ref(
  selectedFirstDate
    ? new Date(selectedFirstDate).getFullYear()
    : currentDate.getFullYear()
);

const days = ref(getDaysForCalendar(selectedMonth.value, selectedYear.value));

const isDayBetweenMinMax = (day) => {
  const targetDate = new Date(day.date);
  const minDate = props.min && new Date(props.min);
  const maxDate = props.max && new Date(props.max);

  targetDate.setHours(12, 0, 0);
  minDate?.setHours(0, 0, 0);
  maxDate?.setHours(23, 0, 0);

  return (
    (!minDate || targetDate >= minDate) && (!maxDate || targetDate <= maxDate)
  );
};

const getAllDatesInRange = () => {
  if (!isMultipleDates.value) {
    return [selectedDates.value];
  } else if (selectedDates.value?.length !== 2) {
    return selectedDates.value;
  }

  return useDateTimeHelper().getAllDatesInRange(
    selectedDates.value[0],
    selectedDates.value[1]
  );
};

const selectDay = (day) => {
  if (isMultipleDates.value) {
    const index = selectedDates.value?.indexOf(day.date);
    const shouldArrayReset =
      selectedDates.value?.length === 2 ||
      (selectedDates.value?.length === 1 &&
        useDateTimeHelper().isSecondDateBeforeFirstDate(
          selectedDates.value[0],
          day.date
        ));

    if (shouldArrayReset) {
      selectedDates.value = [];
    }
    if (index !== -1) {
      selectedDates.value?.splice(index, 1);
    } else {
      selectedDates.value?.push(day.date);
    }
  } else {
    selectedDates.value = day.date;
  }
};

const setMonth = (month) => {
  if (month < 0) {
    selectedMonth.value = 11;
    selectedYear.value--;
  } else if (month > 11) {
    selectedMonth.value = 0;
    selectedYear.value++;
  } else {
    selectedMonth.value = month;
  }
  days.value = getDaysForCalendar(selectedMonth.value, selectedYear.value);
};

const previousMonth = () => setMonth(selectedMonth.value - 1);
const nextMonth = () => setMonth(selectedMonth.value + 1);

// set selected month and year to current month and year and select today
const goToToday = () => {
  selectedMonth.value = currentDate.getMonth();
  selectedYear.value = currentDate.getFullYear();
  days.value = getDaysForCalendar(selectedMonth.value, selectedYear.value);

  const today = days.value.find((d) => d.isToday);
  selectDay(today);
};

// check if the day is selected
const isSelected = (day) => {
  if (!props) return false;

  if (isMultipleDates.value) {
    return datesInRange.value.includes(day.date);
  }
  return day.date === selectedDates.value;
};

const dayClasses = (day) => {
  const dayToString = day.date;
  const isSelectedDay = isSelected(day);
  const isToday = day.isToday;
  const isCurrentMonth = day.isCurrentMonth;
  const isSelectedLengthGreaterThanOne = selectedDates.value?.length > 1;

  const isFirstDateSelected = datesInRange.value[0] === dayToString;
  const isLastDateSelected =
    datesInRange.value[datesInRange.value.length - 1] === dayToString;

  const isSelectedEdgeStart =
    isSelectedDay && isSelectedLengthGreaterThanOne && isFirstDateSelected;
  const isSelectedEdgeEnd =
    isSelectedDay && isSelectedLengthGreaterThanOne && isLastDateSelected;

  const classes = [
    isSelectedDay && "text-white",
    !isSelectedDay && isToday && "!border-2 !border-gray-100 rounded-full",
    !isSelectedDay && !isToday && isCurrentMonth && "text-gray-900",
    !isSelectedDay && !isToday && !isCurrentMonth && "text-gray-400",
    isSelectedDay && isToday && "!border-0 !bg-primary-600",
    isSelectedDay && !isToday && "!bg-primary-600",
    !isSelectedDay && "hover:bg-gray-25 hover:rounded-full",
    isSelectedDay &&
      (!isMultipleDates.value || selectedDates.value?.length === 1) &&
      "rounded-full",
    isSelectedEdgeStart && "rounded-l-full",
    isSelectedEdgeEnd && "rounded-r-full",
    "mx-auto flex h-11 w-11 xl:w-full xl:h-full items-center justify-center",
  ];

  return classes.filter(Boolean).join(" ");
};
const onConfirm = () => {
  emit("update:modelValue", selectedDates.value);
  emit("close");
};

const cancel = () => {
  emit("update:modelValue", previousSelectedDates.value);
  emit("close");
};

// watch if the modelValue prop changes and update the month and year
watch([selectedDates], (value) => {
  // convert dateString to date object
  value = new Date(value);

  selectedMonth.value = value.getMonth();
  selectedYear.value = value.getFullYear();
  days.value = getDaysForCalendar(selectedMonth.value, selectedYear.value);

  if (props.confirmOnSelection) {
    onConfirm();
  }
});

// watch to determine the position of the options element
watch(
  optionsElement,
  (newVal) => {
    if (newVal) {
      const inputField = newVal.parentElement;

      const inputFieldRect = inputField.getBoundingClientRect();
      const optionsElementRect = optionsElement.value.getBoundingClientRect();

      if (
        inputFieldRect.bottom + optionsElementRect.height >
          window.innerHeight &&
        props.modalTop
      ) {
        optionsElement.value.style.top = `${-optionsElementRect.height - 50}px`;
      }
    }
  },
  { immediate: true }
);

// watch optionsElement for changes because it is not available on initial render
watch(
  optionsElement,
  (newVal) => {
    if (newVal) {
      // set inputfield as parent element of the options element
      const inputField = newVal.parentElement;

      onClickOutside(inputField, () => {
        if (!props.persistent) {
          cancel();
        }
      });
    }
  },
  { immediate: true }
);
</script>
