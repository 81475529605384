import config from "@/config";

const MODULE = config.MODULES.SECURITY_AND_AUDITING;

export default [
  {
    path: "/security-auditing/drive-audit-overview",
    name: "drive-audit-overview",
    meta: {
      module: MODULE,
      pageTitle: "Drive audit overview",
      icon: "visibility",
      requiredPrivileges: ["SECURITY_AND_AUDITING_REPORTS_READ"],
    },
    component: () =>
      import("@/views/security-auditing/DriveAuditOverviewView.vue"),
  },
  {
    path: "/security-auditing/high-risk-files",
    name: "high-risk-files",
    meta: {
      module: MODULE,
      pageTitle: "High risk files",
      icon: "warning",
      requiredPrivileges: ["SECURITY_AND_AUDITING_REPORTS_READ"],
    },
    component: () => import("@/views/security-auditing/HighRiskFilesView.vue"),
  },
  {
    path: "/security-auditing/labels",
    name: "labels",
    meta: {
      module: MODULE,
      pageTitle: "Labels",
      tabTitle: "By label",
      icon: "label",
      requiredPrivileges: ["SECURITY_AND_AUDITING_REPORTS_READ"],
    },
    component: () => import("@/views/security-auditing/LabelsView.vue"),
  },
  {
    path: "/security-auditing/labels/access",
    name: "labels-access",
    meta: {
      parent: "labels",
      module: MODULE,
      tabTitle: "By access",
      requiredPrivileges: ["SECURITY_AND_AUDITING_REPORTS_READ"],
    },
    component: () => import("@/views/security-auditing/LabelsView.vue"),
  },
  {
    path: "/security-auditing/labels/organizational-units",
    name: "labels-org-units",
    meta: {
      parent: "labels",
      module: MODULE,
      tabTitle: "By organizational units",
      requiredPrivileges: ["SECURITY_AND_AUDITING_REPORTS_READ"],
    },
    component: () => import("@/views/security-auditing/LabelsView.vue"),
  },
  {
    path: "/security-auditing/drive-audit-files",
    name: "drive-audit",
    meta: {
      module: MODULE,
      pageTitle: "Audit files",
      icon: "view_list",
      requiredPrivileges: ["SECURITY_AND_AUDITING_DRIVE_FILES_READ"],
    },
    component: () =>
      import("@/views/security-auditing/audit-files/DriveAuditView.vue"),
  },
  {
    path: "/security-auditing/drive-audit/:id",
    name: "file-details",
    meta: {
      parent: "drive-audit",
      pageTitle: "File details",
      requiredPrivileges: ["SECURITY_AND_AUDITING_DRIVE_FILES_READ"],
    },
    component: () =>
      import("@/views/security-auditing/audit-files/DriveAuditFileView.vue"),
    props: true,
    // other possible way of passing props
    // props: (route) => ({...route.params, id: parseInt(route.params.id) }),
    // this example will convert the id to a number instead of a string
  },
  {
    path: "/security-auditing/access-contributors",
    name: "access-contributors",
    meta: {
      module: MODULE,
      pageTitle: "Access contributors",
      tabTitle: "Domains with access",
      icon: "shield_lock",
      requiredPrivileges: ["SECURITY_AND_AUDITING_REPORTS_READ"],
    },
    component: () =>
      import(
        "@/views/security-auditing/reports/AccessContributorsReportView.vue"
      ),
  },
  {
    path: "/security-auditing/access-contributors/external-users",
    name: "external-users-with-access",
    meta: {
      parent: "access-contributors",
      module: MODULE,
      tabTitle: "External users with access",
      requiredPrivileges: ["SECURITY_AND_AUDITING_REPORTS_READ"],
    },
    component: () =>
      import(
        "@/views/security-auditing/reports/AccessContributorsReportView.vue"
      ),
  },
  {
    path: "/security-auditing/shared-drives",
    name: "shared-drives",
    meta: {
      module: MODULE,
      pageTitle: "Shared drives",
      icon: "folder",
      requiredPrivileges: ["SECURITY_AND_AUDITING_SHARED_DRIVE_READ"],
    },
    component: () =>
      import("@/views/security-auditing/shared-drives/SharedDrivesView.vue"),
  },
  {
    path: "/security-auditing/shared-drives/:id",
    name: "shared-drive-details",
    meta: {
      parent: "shared-drives",
      pageTitle: "Shared drive details",
      requiredPrivileges: ["SECURITY_AND_AUDITING_SHARED_DRIVE_READ"],
    },
    component: () =>
      import(
        "@/views/security-auditing/shared-drives/SharedDriveDetailsView.vue"
      ),
    props: true,
    // other possible way of passing props
    // props: (route) => ({...route.params, id: parseInt(route.params.id) }),
    // this example will convert the id to a number instead of a string
  },
  {
    path: "/security-auditing/bulk-file-management",
    name: "bulk-file-management",
    meta: {
      module: MODULE,
      pageTitle: "Bulk file management",
      icon: "edit_note",
      requiredPrivileges: ["SECURITY_AND_AUDITING_BULK_ACTIONS_READ"],
    },
    component: () =>
      import(
        "@/views/security-auditing/file-management/FileManagementView.vue"
      ),
  },
  {
    path: "/security-auditing/bulk-file-management/policies",
    name: "bulk-automated-policies",
    meta: {
      parent: "bulk-file-management",
      module: MODULE,
      tabTitle: "Automated policies",
      requiredPrivileges: ["SECURITY_AND_AUDITING_BULK_ACTIONS_READ"],
    },
    component: () =>
      import(
        "@/views/security-auditing/file-management/FileManagementView.vue"
      ),
  },
  {
    path: "/security-auditing/bulk-file-management/policies/:id",
    name: "policies-view",
    meta: {
      parent: "bulk-automated-policies",
      pageTitle: "Details",
      requiredPrivileges: ["SECURITY_AND_AUDITING_POLICIES_READ"],
    },
    component: () =>
      import(
        "@/views/security-auditing/file-management/FileManagementPolicyView.vue"
      ),
    props: true,
  },
  {
    path: "/security-auditing/bulk-file-management/policies/editor/:id",
    name: "policies-editor",
    meta: {
      parent: "bulk-automated-policies",
      pageTitle: "Manage files",
      requiredPrivileges: ["SECURITY_AND_AUDITING_POLICIES_READ"],
    },
    component: () =>
      import(
        "@/views/security-auditing/file-management/FileManagementEditorView.vue"
      ),
    props: true,
  },
  {
    path: "/security-auditing/bulk-file-management/editor/:id",
    name: "bulk-file-management-editor",
    meta: {
      parent: "bulk-file-management",
      pageTitle: "Manage files",
      requiredPrivileges: ["SECURITY_AND_AUDITING_BULK_ACTIONS_READ"],
    },
    component: () =>
      import(
        "@/views/security-auditing/file-management/FileManagementEditorView.vue"
      ),
    props: true,
  },
  {
    path: "/security-auditing/bulk-file-management/:id",
    name: "bulk-file-management-view",
    meta: {
      parent: "bulk-file-management",
      pageTitle: "Details",
      requiredPrivileges: ["SECURITY_AND_AUDITING_BULK_ACTIONS_READ"],
    },
    component: () =>
      import(
        "@/views/security-auditing/file-management/FileManagementViewView.vue"
      ),
    props: true,
  },
];
