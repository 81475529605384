<template>
  <VIcon
    :weigth="100"
    button
    :mini="props.mini"
    :name="copyIcon"
    :small="props.small"
    @click.stop="copyContentToClipboard"
  >
  </VIcon>
</template>

<script setup>
import { computed } from "vue";
import { useClipboard } from "@vueuse/core";
import { useNotificationStore } from "@/stores";

const emit = defineEmits(["copy"]);

const notificationStore = useNotificationStore();

const props = defineProps({
  content: {
    type: String,
    required: true,
  },
  mini: {
    type: Boolean,
    required: false,
    default: false,
  },
  small: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const { copy, copied, isSupported } = useClipboard({
  source: props.content,
});

const copyContentToClipboard = () => {
  if (!isSupported.value) {
    notificationStore.showToast(
      "Copy to clipboard is not supported by your browser",
      "error"
    );
  }
  copy(props.content);
  emit("copy");
};

const copyIcon = computed(() => {
  if (copied.value && !isSupported.value) {
    return "close";
  }
  if (copied.value) {
    return "check";
  }
  return "content_copy";
});
</script>
