import config from "@/config";

const MODULE = config.SETTING_GROUPS.SETTINGS;

export default [
  {
    path: "/settings/general",
    name: "general",
    meta: {
      module: MODULE,
      pageTitle: "General",
      icon: "person",
      requiredPrivileges: ["SUPER_ADMIN"],
    },
    component: () => import("@/views/settings/SettingsView.vue"),
  },
  {
    path: "/settings/users",
    name: "users",
    meta: {
      module: MODULE,
      pageTitle: "Users",
      icon: "group",
      requiredPrivileges: ["USERS_ADMIN"],
    },
    component: () => import("@/views/settings/SettingsView.vue"),
  },
  {
    path: "/settings/users/:id",
    name: "user-details",
    meta: {
      module: MODULE,
      parent: "users",
      pageTitle: "User details",
      requiredPrivileges: ["USERS_ADMIN"],
    },
    component: () => import("@/views/settings/UserDetailsView.vue"),
    props: true,
    // other possible way of passing props
    // props: (route) => ({...route.params, id: parseInt(route.params.id) }),
    // this example will convert the id to a number instead of a string
  },
  {
    path: "/settings/roles",
    name: "roles",
    meta: {
      module: MODULE,
      pageTitle: "Roles",
      icon: "UserGroup",
      requiredPrivileges: ["ROLES_ADMIN"],
    },
    component: () => import("@/views/settings/SettingsView.vue"),
  },
  {
    path: "/settings/roles/:id",
    name: "role-details",
    meta: {
      module: MODULE,
      parent: "roles",
      pageTitle: "Role details",
      requiredPrivileges: ["ROLES_ADMIN"],
    },
    component: () => import("@/views/settings/RoleDetailsView.vue"),
    props: true,
  },
  {
    path: "/settings/customer-domains",
    name: "customer-domains",
    meta: {
      module: MODULE,
      pageTitle: "Google Workspace domains",
      icon: "Database",
      requiredPrivileges: ["TRUSTED_NETWORK_ADMIN"],
    },
    component: () => import("@/views/settings/SettingsView.vue"),
  },
  {
    path: "/settings/trusted-network",
    name: "trusted-network",
    meta: {
      module: MODULE,
      pageTitle: "Trusted network",
      icon: "shield",
      requiredPrivileges: ["TRUSTED_NETWORK_ADMIN"],
    },
    component: () => import("@/views/settings/SettingsView.vue"),
  },
  {
    path: "/settings/trusted-network/:id",
    name: "trusted-network-details",
    meta: {
      parent: "trusted-network",
      pageTitle: "Trusted network details",
      requiredPrivileges: ["TRUSTED_NETWORK_ADMIN"],
    },
    component: () => import("@/views/settings/TrustedNetworkDetailsView.vue"),
    props: true,
    // other possible way of passing props
    // props: (route) => ({...route.params, id: parseInt(route.params.id) }),
    // this example will convert the id to a number instead of a string
  },
  // TODO add again later
  // {
  //   path: "/settings/subscriptions",
  //   name: "subscriptions",
  //   meta: {
  //     module: MODULE,
  //     pageTitle: "Subscriptions",
  //     icon: "CreditCard",
  //     requiredPrivileges: ["BILLING_ADMIN"],
  //   },
  //   component: () => import("@/views/settings/SubscriptionsView.vue"),
  // },

  // TODO add again later
  // {
  //   path: "/settings/quota",
  //   name: "quota",
  //   meta: {
  //     module: MODULE,
  //     pageTitle: "Quota",
  //     icon: "Database",
  //     requiredPrivileges: ["GENERAL_ACCOUNT_READ"],
  //   },
  //   component: () => import("@/views/settings/QuotaView.vue"),
  // },
];
