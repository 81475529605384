<template>
  <div ref="inputField">
    <BaseInputField :label="label" :disabled="props.disabled" no-error>
      <input
        type="text"
        readonly
        :value="selectedDatesText"
        @focus="showCalendar = true"
        :placeholder="placeholder"
        :class="inputStyling"
        :disabled="props.disabled"
      />
      <DatePickerOptions
        :class="{ 'z-20 absolute': props.overlay }"
        v-if="showCalendar"
        v-model="selectedDates"
        @close="showCalendar = false"
        :range="range"
        :modal-top="modalTop"
        :min="props.min"
        :max="props.max"
        :confirm-on-selection="props.confirmOnSelection"
        :persistent="props.persistent"
      />
    </BaseInputField>
  </div>
</template>

<script setup>
import BaseInputField from "@/components/helpers/BaseInputField.vue";
import DatePickerOptions from "@/components/helpers/DatePickerOptions.vue";
import { computed, ref, watch } from "vue";
import { useFormInput } from "@/composables/form-input";
import { useDateTimeHelper } from "@/composables/date-time-helper";

const { inputStyling } = useFormInput(props);

const props = defineProps({
  modelValue: { type: [String, Array], default: null },
  label: { type: String, default: null },
  placeholder: { type: String, default: "Select a date" },
  range: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  modalTop: { type: Boolean, default: false },
  min: { type: Date, default: null },
  max: { type: Date, default: null },
  overlay: { type: Boolean, default: false },
  confirmOnSelection: { type: Boolean, default: false },
  persistent: { type: Boolean, default: false },
});

const emit = defineEmits(["update:modelValue"]);

const showCalendar = ref(false);
const selectedDates = ref(
  props.range && !props.modelValue ? [] : props.modelValue
);
const selectedDatesText = computed(() => {
  if (props.range && selectedDates.value.length === 2) {
    return `From ${useDateTimeHelper().formatDate(
      selectedDates.value[0],
      "DD MMM YYYY"
    )} to ${useDateTimeHelper().formatDate(selectedDates.value[1], "DD MMM YYYY")}`;
  } else if (!props.range) {
    return selectedDates.value;
  }
  return null;
});

watch(selectedDatesText, (val) => {
  emit("update:modelValue", val);
});
</script>
