import { defineStore } from "pinia";
import { useAPIWrapper } from "@/composables/api-wrapper";
import { OrgUnitsService } from "@/apis/app-management/services/OrgUnitsService";
import { ref } from "vue";
import { useErrorStore } from "@/stores";

const storeId = "org-units-store";

export const useOrgUnitsStore = defineStore(storeId, () => {

  // ===================== //
  // ======= INIT ======== //
  // ===================== //
  const errorStore = useErrorStore();

  const errorMessages = {
    listItems: `Failed to retrieve organizational units. ${errorStore.defaultErrorMessage}`,
  };

  const services = {
    listItemsService: OrgUnitsService.listOrgUnits,
  };

  // const returnObjectKey = "driveFiles";
  const returnObjectKey = "organizationUnits";

  const listServiceParams = ["customerId", "query", "sortBy", "sortOrder"];

  // set the values to determine if the filtering and pagination should be done on the client or server side
  const clientSideFiltering = true;
  const clientSidePagination = true;

  // use the API wrapper composable to handle filtering, sorting, pagination and listing of items
  const {
    items,
    addFilter,
    removeFilter,
    listItems,
    getItem,
    activeFilters,
    totalItems,
    loading,
  } = useAPIWrapper(
    storeId,
    services,
    returnObjectKey,
    clientSideFiltering,
    clientSidePagination,
    listServiceParams,
    null,
    null,
    errorMessages
  );

  const getAllOrgUnitIdsInList = (list) =>
    list.flatMap(({ orgUnitId }) => orgUnitId);

  const getHiddenChildrenFromList = (list, isFlat = false) => {
    const allOrgUnitIdsInList = isFlat ? list : getAllOrgUnitIdsInList(list);
    const filteredItems = items.value.filter(
      (item) =>
        allOrgUnitIdsInList?.includes(item.parentOrgUnitId) &&
        !allOrgUnitIdsInList?.includes(item.orgUnitId)
    );

    filteredItems.forEach((item) => {
      list.push(isFlat ? item.orgUnitId : item);
    });
    if (filteredItems[0]) {
      return getHiddenChildrenFromList(list);
    }
    return list;
  };

  const formatOrgUnitsToVTreeSelect = (list = items.value ?? []) => {
    const getChildren = (parentId) =>
      list
        .filter((item) => item.parentOrgUnitId === parentId)
        .sort((a, b) => (a.name < b.name ? -1 : 1))
        .map((item) => ({
          id: item.orgUnitId,
          label: item.name,
          canClick: item.canClick ?? false,
          children: getChildren(item.orgUnitId),
        }));

    // Get highest order org units and recursively build children from there
    return list
      .filter(
        (item) =>
          !item.parentOrgUnitId ||
          !getAllOrgUnitIdsInList(list).includes(item.parentOrgUnitId)
      )
      .map((item) => ({
        id: item.orgUnitId,
        label: item.name,
        canClick: item.canClick ?? false,
        children: getChildren(item.orgUnitId),
      }));
  };

  const getById = (id) => {
    return items.value.find((item) => item.orgUnitId === id);
  };

  const globalLoading = ref(false);

  return {
    items,
    getById,
    listItems,
    getItem,
    addFilter,
    removeFilter,
    activeFilters,
    totalItems,
    loading,
    clientSidePagination,
    clientSideFiltering,
    formatOrgUnitsToVTreeSelect,
    getHiddenChildrenFromList,
    globalLoading,
  };
});
