/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCustomer } from '../models/CreateCustomer';
import type { CreateOTSCustomer } from '../models/CreateOTSCustomer';
import type { Customer } from '../models/Customer';
import type { CustomerSetting } from '../models/CustomerSetting';
import type { CustomerSettings } from '../models/CustomerSettings';
import type { RequestOTSCustomer } from '../models/RequestOTSCustomer';
import type { UpdateApproveAdmin } from '../models/UpdateApproveAdmin';
import type { UpdateCustomer } from '../models/UpdateCustomer';
import type { UpdateCustomerAdmin } from '../models/UpdateCustomerAdmin';
import type { WorkspaceDomainListResponse } from '../models/WorkspaceDomainListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerService {

    /**
     * Create customer
     * Create customer.
     *
     * - **customer**: The customer to create
     * @param requestBody
     * @returns Customer Successful Response
     * @throws ApiError
     */
    public static createNewCustomer(
        requestBody: CreateCustomer,
    ): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customers',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create one time scan customer
     * Create customer.
     *
     * - **customer**: The customer to create
     * @param requestBody
     * @returns Customer Successful Response
     * @throws ApiError
     */
    public static createNewOtsCustomer(
        requestBody: CreateOTSCustomer,
    ): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customers/one-time-scan',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * request a admin to register for the one time scan
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static mailAdminOtsCustomer(
        requestBody: RequestOTSCustomer,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customers/request-one-time-scan',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get customer
     * Get customer details.
     *
     * - **customer_id**: The customer id
     * @param customerId
     * @returns Customer Successful Response
     * @throws ApiError
     */
    public static getCustomer(
        customerId: string,
    ): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update customer details
     * Update customer details.
     *
     * - **customer_id**: The customer id
     * - **customer_update**: The customer details to update
     * @param customerId
     * @param requestBody
     * @returns Customer Successful Response
     * @throws ApiError
     */
    public static updateCustomer(
        customerId: string,
        requestBody: UpdateCustomer,
    ): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/customers/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Activate customer
     * Activate customer.
     * @param customerId
     * @returns Customer Successful Response
     * @throws ApiError
     */
    public static activateCustomer(
        customerId: string,
    ): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/customers/{customer_id}/activate',
            path: {
                'customer_id': customerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update customer admin user
     * Update customer details.
     *
     * - **customer_id**: The customer id
     * - **customer_admin_update**: The customer admin details to update
     * @param customerId
     * @param requestBody
     * @returns Customer Successful Response
     * @throws ApiError
     */
    public static updateCustomerAdmin(
        customerId: string,
        requestBody: UpdateCustomerAdmin,
    ): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/customers/{customer_id}/admin',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Approve new admin user
     * Update customer details.
     *
     * - **customer_id**: The customer id
     * - **customer_admin_approve_update**: The customer admin details to update
     * @param customerId
     * @param requestBody
     * @returns Customer Successful Response
     * @throws ApiError
     */
    public static approveAdmin(
        customerId: string,
        requestBody: UpdateApproveAdmin,
    ): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/customers/{customer_id}/admin/approve',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * list workspace domains
     * List workspace domains
     * @param customerId
     * @returns WorkspaceDomainListResponse Successful Response
     * @throws ApiError
     */
    public static listWorkspaceDomains(
        customerId: string,
    ): CancelablePromise<WorkspaceDomainListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{customer_id}/workspace-domains',
            path: {
                'customer_id': customerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get customer settings
     * Get customer details.
     *
     * - **customer_id**: The customer id
     * @param customerId
     * @returns CustomerSettings Successful Response
     * @throws ApiError
     */
    public static getCustomerSettings(
        customerId: string,
    ): CancelablePromise<CustomerSettings> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{customer_id}/setting',
            path: {
                'customer_id': customerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update customer setting
     * Update customer setting.
     *
     * - **customer_id**: The customer id
     * - **customer_update**: The customer details to update
     * @param customerId
     * @param requestBody
     * @returns Customer Successful Response
     * @throws ApiError
     */
    public static updateCustomerSetting(
        customerId: string,
        requestBody: CustomerSetting,
    ): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/customers/{customer_id}/setting',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
