<template>
  <div class="flex mb-10 w-full">
    <div class="flex min-h-fit">
      <VIcon large button name="chevron_left" @click="changeStep(-1)"></VIcon>
    </div>
    <div class="mx-4 min-h-[350px] flex bg-background rounded-md shadow-md p-6">
      <slot name="step" :step="steps[currentStep]"></slot>
    </div>
    <VIcon large button name="chevron_right" @click="changeStep(+1)"></VIcon>
  </div>
  <nav
    v-if="showProgress"
    class="flex items-center justify-center"
    aria-label="Progress"
  >
    <ol role="list" class="flex items-center space-x-5">
      <li v-for="(step, stepIdx) in steps" :key="step.name">
        <a
          v-if="stepIdx === currentStep"
          :href="step.href"
          class="relative flex items-center justify-center"
          aria-current="step"
        >
          <span class="absolute flex h-5 w-5 p-px" aria-hidden="true">
            <span class="h-full w-full rounded-full bg-primary-100" />
          </span>
          <span
            class="relative block h-2.5 w-2.5 rounded-full bg-primary"
            aria-hidden="true"
          />
          <span class="sr-only">{{ step.name }}</span>
        </a>
        <a
          v-else
          :href="step.href"
          class="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400"
        >
          <span class="sr-only">{{ step.name }}</span>
        </a>
      </li>
    </ol>
  </nav>
</template>

<script setup>
import { ref } from "vue";
const props = defineProps({
  steps: {
    type: Array,
    required: true,
  },
  showProgress: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const currentStep = ref(0);

const changeStep = (value) => {
  currentStep.value += value;
  if (currentStep.value < 0) currentStep.value = props.steps.length - 1;
  if (currentStep.value > props.steps.length - 1) currentStep.value = 0;
};
</script>
