<template>
  <div class="w-full" :class="props.label ? 'h-15' : height.box">
    <label
      v-if="props.label"
      for="name"
      class="inline-block px-1 focus:ring-0"
      :class="[color.label, height.text]"
      >{{ props.label }}</label
    >
    <div class="flex">
      <div class="my-auto mx-2 pt-0.5" v-if="hasPrependOuter">
        <p v-if="props.prependTextOuter">{{ props.prependTextOuter }}</p>
        <VIcon
          v-if="props.prependIconOuter"
          :class="[onClickPrependOuter && 'cursor-pointer', color.icon]"
          :small="props.small"
          :name="props.prependIconOuter"
          @click.stop="emit('clickPrependOuter')"
        />
      </div>
      <div
        class="w-full rounded-md border focus-within:border-google-blue-500 focus-within:ring-2 focus-within:ring-google-blue-500/20"
        :class="[
          color.box,
          color.border,
          props.area ? ' h-auto' : height.box,
          props.disabled && 'cursor-not-allowed border-gray-25',
          validation && !props.forceRules
            ? 'border-error focus-within:!border-error focus-within:!ring-error-500/20'
            : '',
        ]"
      >
        <div class="flex h-full">
          <div class="my-auto ml-2" v-if="hasPrepend">
            <p v-if="props.prependText">{{ props.prependText }}</p>
            <VIcon
              v-if="props.prependIcon"
              :class="[onClickPrepend && 'cursor-pointer', color.icon]"
              :small="props.small"
              :name="props.prependIcon"
              @click.stop="emit('clickPrepend')"
            />
          </div>
          <!-- === INPUT === -->
          <input
            :type="props.password && !isInputVisible ? 'password' : 'text'"
            v-model="inputValue"
            :placeholder="placeholder"
            class="h-full px-2 rounded-md block w-full placeholder-gray-200 focus:outline-none disabled:cursor-not-allowed disabled:bg-white disabled:shadow-none disabled:placeholder-gray-50"
            :class="[height.inputField, height.text, color.inputField]"
            @input="validateInput"
            @focusout="focusOut"
            @focusin="focusIn"
            :disabled="props.disabled"
          />
          <!-- === INPUT END === -->
          <!-- === EYE PASSWORD ICON === -->
          <div class="my-auto mx-2" v-if="props.password">
            <VIcon
              class="text-gray-500 cursor-pointer"
              :name="isInputVisible ? 'visibility' : 'visibility_off'"
              @click.stop="handleIsInputVisible"
              :small="props.small"
            />
          </div>
          <!-- === EYE PASSWORD ICON END === -->
          <!-- === CLEAR ICON === -->
          <div class="my-auto mx-2" v-if="props.clearable">
            <VIcon
              v-show="inputValue?.length > 0"
              class="text-gray-500 cursor-pointer"
              name="close"
              @click.stop="clear"
              :small="props.small"
            />
          </div>
          <!-- === CLEAR ICON END === -->
          <slot name="appendIcon">
            <div class="my-auto mx-2" v-if="hasAppend">
              <p v-if="props.appendText">{{ props.appendText }}</p>
              <VIcon
                v-if="props.appendIcon"
                :class="[onClickAppend && 'cursor-pointer', color.icon]"
                :name="props.appendIcon"
                @click.stop="emit('clickAppend')"
                :small="props.small"
              />
            </div>
          </slot>
        </div>
      </div>
      <div class="my-auto mx-2 pt-0.5" v-if="hasAppendOuter">
        <p v-if="props.appendTextOuter">{{ props.appendTextOuter }}</p>
        <VIcon
          v-if="props.appendIconOuter"
          :class="[onClickAppendOuter && 'cursor-pointer', color.icon]"
          :small="props.small"
          :name="props.appendIconOuter"
          @click.stop="emit('clickAppendOuter')"
        />
      </div>
    </div>
    <!-- === HINT === -->
    <div
      v-if="props.hint /* || !validation || validation === true */"
      class="mt-0.5 h-5 text-xs text-gray-200"
    >
      <slot name="hint">
        <div class="flex justify-between px-1">
          <div class="h-5">
            <span v-if="props.hint">{{ props.hint }}</span>
          </div>
        </div>
      </slot>
    </div>
    <!-- === HINT END === -->
    <!-- === ERROR === -->
    <InputError
      v-if="props.rules && !props.forceRules"
      :inputValue="inputValue"
      :validation="validation"
      :counter="counter"
    />
    <!-- === ERROR END === -->
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useFormInput } from "@/composables/form-input";
import InputError from "@/components/helpers/InputError.vue";
// eslint-disable-next-line sonarjs/no-duplicate-string
const emit = defineEmits(["update:modelValue", "update", "focusOut", "clear"]);

const props = defineProps({
  modelValue: { type: String, default: null }, // v-model
  label: { type: String, default: "" }, // label, shown above input
  placeholder: { type: String, default: "" }, // placeholder, shown inside input when empty
  hint: { type: String, default: null }, // hint, shown below input
  rules: { type: Array, default: () => null }, // validation rules (see composable)
  forceRules: { type: Boolean, default: false }, // prevents input from overcoming rules
  disabled: { type: Boolean, default: false }, // disabled state
  icon: { type: String, default: "" }, // icon to show before input
  clearable: { type: Boolean, default: false }, // show clear icon
  password: { type: Boolean, default: false }, // provides password input settings (hidden text, eye icon)
  prependText: { type: String, default: "" }, // show text before input
  appendText: { type: String, default: "" }, // show text after input
  prependTextOuter: { type: String, default: "" }, // show text before inputbox
  appendTextOuter: { type: String, default: "" }, // show text after inputbox
  prependIcon: { type: String, default: "" }, // show icon before input
  appendIcon: { type: String, default: "" }, // show icon after input
  prependIconOuter: { type: String, default: "" }, // show icon before inputbox
  appendIconOuter: { type: String, default: "" }, // show icon after inputbox
  // on click events for icons (through props to check if they are set)
  onClickPrepend: { type: Function, default: null },
  onClickAppend: { type: Function, default: null },
  onClickPrependOuter: { type: Function, default: null },
  onClickAppendOuter: { type: Function, default: null },
  small: { type: Boolean, default: false }, // small version
  color: { type: String, default: null }, // color
});

// use validation from composable
const { inputValue, validation, validateInput, counter } = useFormInput(props);

const hasPrependOuter = props.prependTextOuter || props.prependIconOuter;
const hasPrepend = props.prependText || props.prependIcon;
const hasAppendOuter = props.appendTextOuter || props.appendText;
const hasAppend = props.appendText || props.appendIcon;

const isInputVisible = ref(!props.password);

const handleIsInputVisible = () =>
  (isInputVisible.value = !isInputVisible.value);

const clear = () => {
  inputValue.value = "";
  emit("update:modelValue", "");
  emit("clear");
};

watch(
  () => inputValue.value,
  (value) => {
    emit("update:modelValue", value);
  }
);

// watch modelValue and update input value
watch(
  () => props.modelValue,
  (value) => {
    inputValue.value = value;
  }
);

const atFocusValue = ref("");

const focusIn = () => {
  atFocusValue.value = inputValue.value;
};

const focusOut = () => {
  if (inputValue.value === "") return;
  if (atFocusValue.value === inputValue.value) return;
  validateInput();
  emit("focusOut", inputValue.value);
};

// styling
const height = computed(() => {
  return {
    text: props.small ? "text-xs" : "text-sm",
    box: props.small ? "h-7" : "h-10",
    inputField: props.small ? "h-6" : "h-9",
  };
});

const color = computed(() => {
  if (!props.color)
    return {
      label: props.disabled ? "text-gray-50" : "text-gray-500",
      // eslint-disable-next-line sonarjs/no-duplicate-string
      box: props.disabled ? "bg-disabled" : "bg-white",
      inputField: props.disabled ? "bg-white" : "bg-white text-gray-900",
      border: !props.disabled && !validation ? "border-gray-50" : "",
      icon: "text-gray-500",
    };
  return {
    label: props.disabled ? "text-gray-50" : `text-${props.color}-500`,
    box: props.disabled ? "bg-disabled" : `bg-${props.color}-100`,
    inputField: props.disabled ? "bg-disabled" : `bg-${props.color}-100`,
    border:
      !props.disabled && !validation
        ? `border-gray-50`
        : `border-${props.color}-300`,
    icon: `text-${props.color}-500`,
  };
});
</script>

<style scoped>
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-1px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.shake-animation {
  animation: shake 0.6s ease-in-out;
  animation-iteration-count: 0.5;
}
</style>
