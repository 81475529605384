import { defineStore } from "pinia";
import { TrustedSourceService } from "@/apis/security-auditing/services/TrustedSourceService";
import { useAPIWrapper } from "@/composables/api-wrapper";
import { useErrorStore } from "@/stores";
const storeId = "trusted-sources-store";

export const useTrustedSourceStore = defineStore(storeId, () => {
  // ===================== //
  // ======= INIT ======== //
  // ===================== //
  const errorStore = useErrorStore();
  const errorMessages = {
    listItems: `Failed to retrieve your trusted sources. ${errorStore.defaultErrorMessage}`,
  };

  const services = {
    listItemsService: TrustedSourceService.listTrustedSources,
    getItemService: TrustedSourceService.getTrustedSource,
    createItemService: TrustedSourceService.createTrustedSource,
    updateItemService: TrustedSourceService.updateTrustedSource,
    deleteItemService: TrustedSourceService.deleteTrustedSource,
  };

  const returnObjectKey = "trustedSources";
  const listServiceParams = ["customerId", "sortBy", "sortOrder"];

  // set the values to determine if the filtering and pagination should be done on the client or server side
  const clientSideFiltering = false;
  const clientSidePagination = false;

  // orgUnitFilter is used to determine if the orgUnit filter should be used
  const useOrgUnitFilter = false;

  // use the API wrapper composable to handle filtering, sorting, pagination and listing of items
  const {
    // filters
    activeFilters,
    activeAdvancedFilters,
    clearAllFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    removeFilter,
    updateFilter,
    // list
    items,
    listItems,
    totalItems,
    // general
    resetStore,
    reset,
    loading,
    // crud
    getItem,
    createItem,
    updateItem,
    deleteItem,
  } = useAPIWrapper(
    storeId,
    services,
    returnObjectKey,
    clientSideFiltering,
    clientSidePagination,
    listServiceParams,
    useOrgUnitFilter,
    null,
    errorMessages
  );

  // // ===================== //
  // // ======= CREATE ====== //
  // // ===================== //

  const createTrustedSource = async (TrustedSource) => {
    const message = {
      success: `Trusted network ${TrustedSource.name} created`,
      error: `Failed to create trusted network ${TrustedSource.name}`,
    };

    return await createItem(TrustedSource, null, message);
  };

  // ===================== //
  // ======= GET ========= //
  // ===================== //

  const get = async (trustedSourceId, forceFetch = false) => {
    trustedSourceId = Number(trustedSourceId);

    const message = {
      // success: `User with ID ${userId} fetched`,
      error: `Failed to get role with ID ${trustedSourceId}`,
    };

    return await getItem(trustedSourceId, forceFetch, message);
  };

  // ===================== //
  // ======= UPDATE ====== //
  // ===================== //

  const updateTrustedSource = async (trustedSourceId, TrustedSource) => {
    trustedSourceId = Number(trustedSourceId);

    const message = {
      success: `Trusted network ${TrustedSource.name} updated`,
      error: `Failed to update Trusted network ${TrustedSource.name}`,
    };

    return await updateItem(trustedSourceId, TrustedSource, null, message);
  };

  // ===================== //
  // ======= DELETE ====== //
  // ===================== //

  const deleteTrustedSource = async (trustedSourceId) => {
    trustedSourceId = Number(trustedSourceId);

    const message = {
      success: `Trusted network deleted`,
      error: `Failed to delete Trusted network`,
    };

    return await deleteItem(trustedSourceId, null, message);
  };

  return {
    // filters
    activeFilters,
    useOrgUnitFilter,
    activeAdvancedFilters,
    clearAllFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    removeFilter,
    updateFilter,

    // list
    items,
    listItems,
    totalItems,
    clientSidePagination,

    // general
    resetStore,
    reset,
    loading,

    // crud
    get,
    createTrustedSource,
    updateTrustedSource,
    deleteTrustedSource,
  };
});
