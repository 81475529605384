import { defineStore } from "pinia";
import { ref } from "vue";
import {
  useAuthStore,
  useCustomerStore,
  useErrorStore,
  useOrgUnitsStore,
} from "@/stores";
import {
  ref as firebaseRef,
  child,
  get,
  update,
  getDatabase,
} from "firebase/database";
import { useRoute, useRouter } from "vue-router";

export const useUserPreferenceStore = defineStore("userPreference", () => {
  const dbRef = firebaseRef(getDatabase());

  const authStore = useAuthStore();
  const customerStore = useCustomerStore();
  const defaultIncludeSubOrgUnits = true;
  const selectedOrgUnit = ref({
    orgUnitId: null,
    includeSubOrgUnits: defaultIncludeSubOrgUnits,
  });
  const errorStore = useErrorStore();

  const route = useRoute();
  const router = useRouter();

  const loading = ref(true);

  const fetchSelectedOrgUnit = async () => {
    loading.value = true;
    const userSelectedOrgUnitRef = child(
      dbRef,
      `customers/${customerStore.activeCustomerId}/users/${authStore.authCurrentUser.uid}/user-preferences/selected-org-unit`
    );
    try {
      const snapshot = await get(userSelectedOrgUnitRef);
      if (snapshot.exists() && snapshot.val().orgUnitId) {
        selectedOrgUnit.value = snapshot.val();
        return snapshot.val();
      }
      selectedOrgUnit.value = {
        orgUnitId: "ALL",
        includeSubOrgUnits: false,
      };
      return selectedOrgUnit.value;
    } catch (error) {
      errorStore.addError({
        error: error,
        errorContext: {
          errorType: error.name,
          message: error.message,
          source: "user-preference-store - fetchSelectedOrgUnit",
          function: "fetchSelectedOrgUnit",
        },
        userMessage: null,
      });
    } finally {
      loading.value = false;
    }
  };

  const saveIncludeSubOrgUnits = async (includeSubOrgUnits) => {
    const userSelectedOrgUnitRef = child(
      dbRef,
      `customers/${customerStore.activeCustomerId}/users/${authStore.authCurrentUser.uid}/user-preferences/selected-org-unit`
    );
    try {
      selectedOrgUnit.value = {
        orgUnitId: selectedOrgUnit.value.orgUnitId,
        includeSubOrgUnits: includeSubOrgUnits ?? defaultIncludeSubOrgUnits,
      };
      await update(userSelectedOrgUnitRef, selectedOrgUnit.value);
    } catch (error) {
      errorStore.addError({
        error: error,
        errorContext: {
          errorType: error.name,
          message: error.message,
          source: "user-preference-store - saveIncludeSubOrgUnits",
          function: "saveIncludeSubOrgUnits",
        },
        userMessage: null,
      });
    }
  };

  const saveSelectedOrgUnit = async (orgUnitId) => {
    const userSelectedOrgUnitRef = child(
      dbRef,
      `customers/${customerStore.activeCustomerId}/users/${authStore.authCurrentUser.uid}/user-preferences/selected-org-unit`
    );
    try {
      selectedOrgUnit.value = {
        orgUnitId,
        includeSubOrgUnits:
          selectedOrgUnit.value.includeSubOrgUnits ?? defaultIncludeSubOrgUnits,
      };
      await update(userSelectedOrgUnitRef, selectedOrgUnit.value);

      // If new privileges does not match the current route requirements, redirect to home
      const hasPrivilege = route?.meta?.requiredPrivileges
        ? authStore.hasPrivilege(route.meta.requiredPrivileges)
        : true;
      const actualRouteIsForbidden = route?.name === "not-found";

      if (!hasPrivilege && !actualRouteIsForbidden) {
        await router.push({ name: "not-found" });
      }
    } catch (error) {
      errorStore.addError({
        error: error,
        errorContext: {
          errorType: error.name,
          message: error.message,
          source: "user-preference-store - saveSelectedOrgUnit",
          function: "saveSelectedOrgUnit",
        },
        userMessage: null,
      });
    }
  };

  const updateStoreWithOrgUnits = (
    addFilter,
    activeFilters = [],
    filterId = "orgUnitId",
    value = selectedOrgUnit.value.orgUnitId
  ) => {
    if (
      selectedOrgUnit.value &&
      activeFilters.find(({ id }) => id === filterId)?.value !== value
    ) {
      addFilter({
        operator: "=",
        id: filterId,
        value: value,
        fn: (item, value) => value ?? item,
      });
    }
  };

  const getColumnPreferences = async (id) => {
    const userColumnsRef = child(
      dbRef,
      `customers/${customerStore.activeCustomerId}/users/${authStore.authCurrentUser.uid}/user-preferences/columns/${id}`
    );
    try {
      const snapshot = await get(userColumnsRef);
      if (snapshot.exists()) {
        return snapshot.val();
      }
      return null;
    } catch (error) {
      errorStore.addError({
        error: error,
        errorContext: {
          errorType: error.name,
          message: error.message,
          source: "user-preference-store - getColumnPreferences",
          function: "getColumnPreferences",
        },
        userMessage: null,
      });
    }
  };

  const saveColumnPreferences = async (id, preferences) => {
    const userColumnsRef = child(
      dbRef,
      `customers/${customerStore.activeCustomerId}/users/${authStore.authCurrentUser.uid}/user-preferences/columns/${id}`
    );
    try {
      await update(userColumnsRef, preferences);
    } catch (error) {
      errorStore.addError({
        error: error,
        errorContext: {
          errorType: error.name,
          message: error.message,
          source: "user-preference-store - getColumnPreferences",
          function: "saveColumnPreferences",
        },
        userMessage: null,
      });
    }
  };

  const getTableTypePreferences = async (id) => {
    const userTableTypeRef = child(
      dbRef,
      `customers/${customerStore.activeCustomerId}/users/${authStore.authCurrentUser.uid}/user-preferences/table-display/${id}`
    );
    try {
      const snapshot = await get(userTableTypeRef);
      if (snapshot.exists()) {
        return snapshot.val();
      }
      return null;
    } catch (error) {
      errorStore.addError({
        error: error,
        errorContext: {
          errorType: error.name,
          message: error.message,
          source: "user-preference-store - getTableTypePreferences",
          function: "getTableTypePreferences",
        },
        userMessage: null,
      });
    }
  };

  const saveTableTypePreferences = async (id, preferences) => {
    const userTableTypeRef = child(
      dbRef,
      `customers/${customerStore.activeCustomerId}/users/${authStore.authCurrentUser.uid}/user-preferences/table-display/${id}`
    );
    try {
      await update(userTableTypeRef, preferences);
    } catch (error) {
      errorStore.addError({
        error: error,
        errorContext: {
          errorType: error.name,
          message: error.message,
          source: "user-preference-store - saveTableTypePreferences",
          function: "saveTableTypePreferences",
        },
        userMessage: null,
      });
    }
  };

  return {
    getColumnPreferences,
    saveColumnPreferences,
    getTableTypePreferences,
    saveTableTypePreferences,
    fetchSelectedOrgUnit,
    saveSelectedOrgUnit,
    saveIncludeSubOrgUnits,
    selectedOrgUnit,
    updateStoreWithOrgUnits,
    loading,
  };
});
