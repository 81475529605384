<template>
  <div class="mx-auto px-auto flex-1" :class="nameClass">
    {{ step.name }}
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  step: {
    type: Object,
    required: true,
  },
  status: {
    type: String,
    required: true,
  },
  last: {
    type: Boolean,
    required: true,
  },
  first: {
    type: Boolean,
    required: true,
  },
});

const nameClass = computed(() => {
  let classString = "";
  if (props.first) {
    classString += "text-left";
  } else if (props.last) {
    classString += "text-right";
  } else {
    classString += "text-center";
  }
  return classString;
});
</script>
