<template>
  <Doughnut
    v-if="data"
    :data="data"
    :options="options"
    :plugins="plugins"
    data-testid="vchartdonut-donut"
    class="max-h-[250px]"
  />
  <ChartLegend
    v-if="props.separateLegend && data"
    :data="data"
    origin="donut"
    @click="handleLegendClick"
    :is-interactive="props.isLegendInteractive"
    :donut-default-data-text="props.donutDefaultDataText"
  />
</template>

<script setup>
import { Doughnut } from "vue-chartjs";

import {
  Chart as ChartJS,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { computed, onMounted, ref, watch } from "vue";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ChartLegend from "@/components/helpers/ChartLegend.vue";
import { useArrayHelper } from "@/composables/array-helper";
import { useTextFormat } from "@/composables/text-format";

ChartJS.register(
  ArcElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

const props = defineProps({
  /*
   ** Data structure:
   ** {
   **    label: String,
   **    data: {
   **        label: String,
   **        backgroundColor: String,
   **        borderColor: String,
   **        active: Boolean,
   **        amount: Number,
   **    }
   ** }
   */
  data: {
    type: Array,
    required: true,
  },
  showFixedValues: {
    type: Boolean,
    default: false,
  },
  circumference: {
    type: Number,
    default: 360,
  },
  spacing: {
    type: Number,
    default: 0,
  },
  radius: {
    type: String,
    default: "100%",
  },
  legendPosition: {
    type: String,
    default: "bottom",
  },
  separateLegend: {
    type: Boolean,
    default: true,
  },
  isLegendInteractive: {
    type: Boolean,
    default: true,
  },
  donutDefaultDataText: {
    type: String,
    default: "Other",
  },
});

const formatDataLabels = (_, context) => {
  return useTextFormat().shortenNumber(context.dataset.data[context.dataIndex]);
};

const plugins = [props.showFixedValues && ChartDataLabels];
const options = computed(() => ({
  aspectRatio: 1,
  plugins: {
    tooltip: {
      callbacks: {
        label: (context) => {
          return (
            (context.dataset.label ? context.dataset.label + ": " : " ") +
            formatDataLabels(undefined, context)
          );
        },
      },
    },
    legend: {
      display: !props.separateLegend,
      position: props.legendPosition ?? "bottom",
      align: "start",
      onHover,
    },
  },
}));

const propsData = ref(props.data);
const data = ref(null);

const onHover = (e) => {
  e.native.target.style.cursor = "pointer";
};

const getDataLabelsOptions = () => {
  const response = {};
  if (props.showFixedValues) {
    response.formatter = formatDataLabels;
    response.color = "black";
  }
  return response;
};

const generateData = () => {
  const legendLabels = [
    ...new Set(
      propsData.value.flatMap((dataValue) =>
        dataValue.data.flatMap((childData) => childData.label)
      )
    ),
  ];

  const labels = [
    ...new Set(
      propsData.value.flatMap((dataValue) =>
        dataValue.data
          .filter(({ active }) => active)
          .flatMap((childData) => childData.label)
      )
    ),
  ];

  const datasets = propsData.value.map((dataValue) => ({
    label: dataValue.label,
    circumference: props.circumference,
    spacing: props.spacing,
    radius: props.radius,
    backgroundColor: dataValue.data
      .filter(({ active }) => active)
      .map(({ backgroundColor }) => backgroundColor),
    borderColor: dataValue.data
      .filter(({ active }) => active)
      .map(({ borderColor }) => borderColor),

    data: dataValue.data
      .filter(({ active }) => active)
      .map(({ amount }) => amount),
    datalabels: getDataLabelsOptions(),
  }));

  data.value = {
    legendLabels,
    labels,
    datasets,
  };
};

const handleLegendClick = (index) => {
  propsData.value.map((dataValue) => {
    dataValue.data[index].active = !dataValue.data[index].active;
  });
  generateData();
};

watch(
  [props],
  () => {
    if (!useArrayHelper().isEquivalent(props.data, propsData.value)) {
      propsData.value = props.data;
    }
    generateData();
  },
  { deep: true }
);

onMounted(generateData);
</script>
