<template>
  <!-- 25-7-2024 NW: removed w-full -->
  <div :class="props.label ? 'h-15' : 'h-fit'" class="select-none">
    <label
      v-if="props.label"
      for="name"
      class="inline-block px-1 focus:ring-0"
      :class="[props.disabled ? 'text-gray-50' : 'text-gray-500', height.text]"
      data-testid="combobox-label"
      >{{ props.label }}</label
    >
    <div class="flex">
      <div class="my-auto mx-2 pt-0.5" v-if="hasPrependOuter">
        <p v-if="props.prependTextOuter">{{ props.prependTextOuter }}</p>
        <VIcon
          v-if="props.prependIconOuter"
          class="text-gray-500"
          :class="{
            'cursor-pointer': onClickPrependOuter,
          }"
          :name="props.prependIconOuter"
          @click.stop="emit('clickPrependOuter')"
          :small="props.small"
        />
      </div>
      <div
        ref="selectBox"
        class="w-full rounded-md border bg-white focus-within:border-google-blue-500 focus-within:ring-2 focus-within:ring-google-blue-500/20"
        :class="[
          props.disabled && 'cursor-not-allowed !bg-disabled border-gray-25',
          validation &&
            'border-error focus-within:!border-error focus-within:!ring-error-500/20',
          !props.disabled && !validation && 'border-gray-50',
        ]"
      >
        <div
          @focus="setShowOptions(true)"
          @focusout="onFocusOut"
          @click.stop="setShowOptions(!showOptions)"
          class="flex h-full items-center"
          :class="height.box.minHeight"
        >
          <div class="my-auto ml-2" v-if="hasPrepend">
            <p v-if="props.prependText">{{ props.prependText }}</p>
            <VIcon
              v-if="props.prependIcon"
              class="text-gray-500"
              :class="{
                'cursor-pointer': onClickPrepend,
              }"
              :name="props.prependIcon"
              @click.stop="emit('clickPrepend')"
              :small="props.small"
            />
          </div>

          <!-- === INPUT === -->
          <div
            @focus="setShowOptions(true)"
            class="flex w-full h-full flex-row flex-wrap m-0.5 px-2 rounded-md text-gray-900 placeholder-gray-200 focus:outline-none hover:cursor-pointer"
            :class="[
              height.inputField,
              height.text,
              height.padding,
              props.disabled && 'cursor-not-allowed',
              { 'whitespace-nowrap': props.whitespaceNowrap },
            ]"
          >
            <div v-if="props.multiple" class="flex flex-wrap w-auto">
              <span
                v-for="(selection, allSelectionIndex) in allSelections"
                :key="allSelectionIndex"
              >
                <VChip
                  v-for="(item, index) in selection"
                  :key="
                    props.itemValue
                      ? getItemObject(item, props.itemValue)
                      : item
                  "
                  class="mr-1 rounded-xl"
                  :class="props.small ? 'h-4 my-0' : 'h-6 my-0.5'"
                  :label="
                    props.itemText ? getItemObject(item, props.itemText) : item
                  "
                  :small="props.small"
                  :rounded="false"
                  outlined
                  :clearable="!props.disabled"
                  @clear="removeItem(item)"
                  :color="getChipColor(allSelectionIndex, index)"
                  :icon="
                    props.itemIcon ? getItemObject(item, props.itemIcon) : null
                  "
                />
              </span>
            </div>
            <div v-else-if="!props.hideInput" class="flex">
              <div
                class="my-auto flex pr-2"
                v-for="item in selection"
                :key="
                  props.itemValue ? getItemObject(item, props.itemValue) : item
                "
              >
                <VIcon
                  v-if="props.itemIcon"
                  :name="
                    props.itemIcon ? getItemObject(item, props.itemIcon) : null
                  "
                  class="mr-2"
                  :small="props.small"
                />
                <VCol class="space-y-1">
                  <p>
                    {{
                      truncateSelection(
                        props.itemText
                          ? getItemObject(item, props.itemText)
                          : item
                      )
                    }}
                  </p>
                  <p
                    v-if="props.itemSubText && props.showSubTextInput"
                    class="text-xs font-light text-text-othergrey"
                  >
                    {{
                      truncateSelection(getItemObject(item, props.itemSubText))
                    }}
                  </p>
                  <p
                    v-if="props.extraSubText"
                    class="text-xs font-light text-text-othergrey"
                  >
                    {{ props.extraSubText }}
                  </p>
                </VCol>
              </div>
              <div
                class="my-auto flex pr-2"
                v-for="item in customSelection"
                :key="item"
              >
                <p>{{ item }}</p>
              </div>
            </div>
            <div v-else class="flex">
              <div class="my-auto flex pr-2">
                <p>
                  {{ props.placeholder }}
                </p>
              </div>
            </div>
            <!-- ==== Text input field === -->
            <input
              v-if="showInput"
              type="text"
              ref="selectInput"
              :readonly="!props.combobox"
              :placeholder="
                !selection[0] || props.persistPlaceholder ? placeholder : null
              "
              :class="[
                !props.combobox && 'cursor-pointer',
                height.inputField,
                height.text,
              ]"
              class="my-auto flex-grow text-gray-900 placeholder-gray-400 focus:outline-none disabled:cursor-not-allowed disabled:bg-disabled disabled:shadow-none"
              v-model="input"
              @input="() => onChangeInput()"
              @keyup.space="handleEnterKey"
              @keyup.enter="handleEnterKey"
              @keydown.tab="handleEnterKey"
              @blur="handleEnterKey"
              :disabled="props.disabled"
              data-testid="combobox-input"
            />
          </div>
          <!-- === INPUT END === -->
          <!-- === CLEAR ICON === -->
          <div class="my-auto" v-if="props.clearable && !props.disabled">
            <VIcon
              v-show="selection[0]"
              class="text-gray-500"
              :class="{
                'cursor-pointer': props.clearable,
              }"
              name="close"
              :small="props.small"
              @click.stop="clear"
            />
          </div>
          <!-- === CLEAR ICON END === -->
          <!-- === DROPDOWN ICON === -->
          <div class="my-auto mr-2">
            <VIcon
              class="transform transition-transform duration-300 ease-in-out text-gray-500"
              :class="{
                'cursor-pointer': !props.disabled,
                'text-gray-500': !props.disabled,
                'text-gray-50': props.disabled,
                'transform rotate-180': showOptions,
              }"
              name="expand_more"
              :small="props.small"
            />
          </div>
          <!-- === DROPDOWN ICON END === -->
          <div class="my-auto mx-2" v-if="hasAppend">
            <p v-if="props.appendText">{{ props.appendText }}</p>
            <VIcon
              v-if="props.appendIcon"
              class="text-gray-500"
              :class="{
                'cursor-pointer': onClickAppend,
              }"
              :name="props.appendIcon"
              @click.stop="emit('clickAppend')"
              :small="props.small"
            />
          </div>
        </div>

        <!-- === SELECT OPTIONS === -->
        <SelectOptions
          :width="optionsWidth"
          v-if="showOptions"
          v-model="selection"
          @update:model-value="updateSelection"
          @close="setShowOptions(false)"
          :items="filteredItems"
          :item-key="props.itemKey"
          :item-text="props.itemText"
          :item-sub-text="props.itemSubText"
          :item-value="props.itemValue"
          :item-icon="props.itemIcon"
          :multiple="props.multiple"
          :clearable="props.multiple ? false : props.clearable"
          :position="props.position"
          :loading-items="props.loadingItems"
          :loading-items-text="props.loadingItemsText"
          :has-input-limit="input.length < 3"
          @scrollEnd="emit('scrollEnd')"
        >
          <template #options>
            <slot name="options" :setShowOptions="setShowOptions"></slot>
          </template>
          <template
            #noItems
            v-if="filteredItems.length === 0 && !props.loadingItems"
          >
            <slot name="noItems">{{ props.noItemsText }}</slot>
          </template>
          <template #loadingItems v-if="props.loadingItems">
            <slot name="loadingItems">{{ props.loadingItemsText }}</slot>
          </template>
        </SelectOptions>
      </div>
      <div class="my-auto mx-2 pt-0.5" v-if="hasAppendOuter">
        <p v-if="props.appendTextOuter">{{ props.appendTextOuter }}</p>
        <VIcon
          v-if="props.appendIconOuter"
          class="text-gray-500"
          :class="{
            'cursor-pointer': onClickAppendOuter,
          }"
          :name="props.appendIconOuter"
          @click.stop="emit('clickAppendOuter')"
          :small="props.small"
        />
      </div>
    </div>

    <!-- === HINT === -->
    <div v-if="props.hint" class="mt-0.5 h-fit text-xs text-gray-200">
      <slot name="hint">
        <div class="flex justify-between px-1">
          <span>{{ props.hint }}</span>
        </div>
      </slot>
    </div>

    <!-- === HINT END === -->

    <!-- === ERROR === -->
    <InputError
      v-if="props.rules && !props.forceRules"
      :input="input"
      :validation="validation"
      :counter="counter"
    />
    <!-- === ERROR END === -->
  </div>
</template>

<script setup>
import SelectOptions from "@/components/helpers/SelectOptions.vue";
import { useFormInput } from "@/composables/form-input";

import { computed, nextTick, onMounted, ref, watch } from "vue";
import InputError from "@/components/helpers/InputError.vue";
import { useArrayHelper } from "@/composables/array-helper";

// eslint-disable-next-line sonarjs/no-duplicate-string
const emit = defineEmits([
  "update:modelValue",
  "update:input",
  "update:validation",
  "focusOut",
  "clear",
  "scrollEnd",
]);

const props = defineProps({
  inputValue: { type: String, default: null }, // input, linked with combobox and rules for validation
  modelValue: { type: [String, Number, Object, Array], default: null }, // v-model
  label: { type: String, default: "" },
  placeholder: { type: String, default: "" },
  combobox: { type: Boolean, default: false },
  hint: { type: String, default: null }, // hint, shown below input
  disabled: { type: Boolean, default: false },
  iconAppend: { type: Boolean, default: true },
  clearable: { type: Boolean, default: false },
  prependText: { type: String, default: "" }, // show text before input
  appendText: { type: String, default: "" }, // show text after input
  prependTextOuter: { type: String, default: "" }, // show text before inputbox
  appendTextOuter: { type: String, default: "" }, // show text after inputbox
  prependIcon: { type: String, default: "" }, // show icon before input
  appendIcon: { type: String, default: "" }, // show icon after input
  prependIconOuter: { type: String, default: "" }, // show icon before inputbox
  appendIconOuter: { type: String, default: "" }, // show icon after inputbox
  // on click events for icons (through props to check if they are set)
  onClickPrepend: { type: Function, default: null },
  onClickAppend: { type: Function, default: null },
  onClickPrependOuter: { type: Function, default: null },
  onClickAppendOuter: { type: Function, default: null },
  items: { type: Array, default: () => [] },
  itemKey: { type: String, default: null },
  itemText: { type: String, default: null },
  itemSubText: { type: String, default: null },
  itemValue: { type: String, default: null },
  itemIcon: { type: String, default: null },
  extraSubText: { type: String, default: null },
  showSubTextInput: { type: Boolean, default: true },
  prefilteredItems: { type: Array, default: () => [] },
  multiple: { type: Boolean, default: false },
  custom: { type: Boolean, default: false },
  fullWidth: { type: Boolean, default: true },
  fullOptionsWidth: { type: Boolean, default: false },
  persistPlaceholder: { type: Boolean, default: false },
  hideInput: { type: Boolean, default: false },
  noItemsText: { type: String, default: "No items found" },
  loadingItemsText: { type: String, default: "Loading items" },
  loadingItems: { type: Boolean, default: false },
  defaultFocus: { type: Boolean, default: false },
  selectionTruncateSize: { type: Number, default: 0 },
  rules: { type: Array, default: () => null }, // validation rules (see composable)
  forceRules: { type: Boolean, default: false }, // prevents input from overcoming rules
  position: {
    type: String,
    default: "none",
    validator: (value) => {
      const validVariants = ["right", "left", "none"];
      if (!validVariants.includes(value)) {
        console.warn(`Invalid position: ${value}. Defaulting to "none".`);
        return false;
      }
      return true;
    },
  },
  small: { type: Boolean, default: false }, // small version
  whitespaceNowrap: { type: Boolean, default: false }, // no wrap for text
});

const {
  validation,
  counter,
  inputValue: ruleInput,
  validateInput,
} = useFormInput(props);

const input = ref(props.inputValue ?? "");
const selectBox = ref(null);
const selectInput = ref(null);

const showOptions = ref(false);
const optionsWidth = ref("auto");

const hasPrependOuter = props.prependTextOuter || props.prependIconOuter;
const hasPrepend = props.prependText || props.prependIcon;
const hasAppendOuter = props.appendTextOuter || props.appendIconOuter;
const hasAppend = props.appendText || props.appendIcon;

function setOptionsWidth() {
  if (
    showOptions.value &&
    selectBox.value &&
    props.fullWidth &&
    props.fullOptionsWidth &&
    props.position === "none"
  ) {
    optionsWidth.value = `${selectBox.value.offsetWidth}px`;
  }
}

// styling
const height = computed(() => {
  return {
    text: props.small ? "text-xs" : "text-sm",
    box: {
      minHeight: props.small ? "min-h-[26px]" : "min-h-[38px]",
    },
    padding: props.small ? "py-[3px]" : "py-[5px]",
    inputField: props.small ? "h-3" : "h-6",
  };
});

watch(
  () => showOptions.value,
  (val) => {
    if (val) {
      setOptionsWidth();
    }
  }
);

const setupSelection = () => {
  if (Array.isArray(props.modelValue)) {
    return props.modelValue;
  } else {
    return props.modelValue ? [props.modelValue] : [];
  }
};

const selection = ref(setupSelection());

const customSelection = ref([]);

const errorIndexes = ref([]);

const allSelections = computed(() => [selection.value, customSelection.value]);
const fullSelection = ref([]);

const checkValidation = (list, traceErrors = false) => {
  if (traceErrors) {
    errorIndexes.value = [];
  }
  if (list[0]) {
    return list.every((item, index) => {
      ruleInput.value = item;
      validateInput();

      if (validation.value && traceErrors) {
        errorIndexes.value.push(index);
      }
      return !validation.value;
    });
  }
  return true;
};
const emitUpdate = () => {
  let sentSelection;
  if (props.multiple) {
    sentSelection = allSelections.value.flat();
  } else if (input.value.length) {
    sentSelection = input.value;
  } else {
    sentSelection = customSelection.value[0] ?? selection.value[0] ?? [];
  }

  if (
    !checkValidation(selection.value, true) ||
    !checkValidation(customSelection.value)
  ) {
    emit("update:validation", validation);
    return;
  }

  emit("update:validation", validation);
  emit("update:modelValue", sentSelection);
};

const getItemObject = (item, property) => {
  if (!item) return;

  if (typeof item === "object") {
    item = useArrayHelper().getItemProperty(item, property);
  }

  let filteredItem = [...filteredItems.value, ...fullSelection.value].find(
    (i) =>
      useArrayHelper().getItemProperty(i, props.itemValue ?? "value") ===
        item || i[props.itemValue ?? "value"] === item
  );

  if (!filteredItem) {
    return item;
  }
  return (
    useArrayHelper().getItemProperty(filteredItem, property) ??
    filteredItem[property]
  );
};

const removeItem = (item) => {
  if (customSelection.value.find((i) => i === item)) {
    customSelection.value = customSelection.value.filter((i) => i !== item);
  } else {
    selection.value = selection.value.filter((i) => i !== item);
  }
  showOptions.value = false;
};

const updateCustomSelection = (value) => {
  customSelection.value = value;
};

const updateSelection = (value) => {
  input.value = "";
  if (!props.multiple) {
    customSelection.value = [];
  }

  selection.value = value;
  fullSelection.value = value.reduce((list, val) => {
    if (props.itemText) {
      const foundItem = filteredItems.value.find(
        (item) =>
          useArrayHelper().getItemProperty(item, props.itemValue) === val
      );

      const itemText = useArrayHelper().getItemProperty(
        foundItem,
        props.itemText
      );
      const itemSubText = useArrayHelper().getItemProperty(
        foundItem,
        props.itemSubText
      );

      if (itemText) {
        list.push({
          [props.itemText]: itemText,
          [props.itemSubText]: itemSubText,
          [props.itemValue]: val,
        });
      }
    } else {
      list.push({
        [props.itemText]: val,
        [props.itemSubText]: val,
        [props.itemValue]: val,
      });
    }
    return list;
  }, []);
};

const truncateSelection = (selection) =>
  selection &&
  props.selectionTruncateSize > 0 &&
  selection.length > props.selectionTruncateSize
    ? `${selection.substring(0, props.selectionTruncateSize)}...`
    : selection;

const handleEnterKey = () => {
  if (props.custom && !validation.value) {
    if (props.multiple && input.value !== "") {
      updateCustomSelection([...customSelection.value, input.value]);
      input.value = "";
    } else {
      selection.value = [];
    }
  }
};

const setShowOptions = (val) => {
  if (!props.disabled) showOptions.value = val;
};

const clear = () => {
  selection.value = [];
  showOptions.value = false;
  emit("clear", selection.value);
};

const getChipColor = (allSelectionIndex, index) => {
  if (errorIndexes.value.includes(index)) return "google-red";
  return allSelectionIndex === 0 ? "google-blue" : "gray";
};

// ========================================
// ================ Search ================
// ========================================
const onFocusOut = () => {
  // wait short time to make sure the user has selected the correct value
  setTimeout(() => {
    emit("focusOut", input.value);
  }, 200);
};

const adaptRuleInputToSelection = () => {
  ruleInput.value = input.value;
  validateInput();
};

const filteredItems = computed(() => {
  if (props.prefilteredItems?.length > 0) {
    return useArrayHelper().deduplicate(props.prefilteredItems);
  }

  if (input.value === "") {
    return useArrayHelper().deduplicate(props.items);
  } else {
    return useArrayHelper().deduplicate(
      props.items.filter((item) => {
        if (props.itemText) {
          return item[props.itemText]
            .toLowerCase()
            .includes(input.value.toLowerCase());
        }
        return item.toLowerCase().includes(input.value.toLowerCase());
      })
    );
  }
});

const onChangeInput = () => {
  validateInput();
  showOptions.value = true;
};

const showInput = computed(
  () =>
    (props.combobox ||
      selection.value?.length === 0 ||
      props.persistPlaceholder) &&
    !props.hideInput
);

const handleDefaultFocus = () => {
  if (selectInput.value) {
    if (props.defaultFocus) {
      selectInput.value.focus();
    } else {
      selectInput.value.blur();
    }
  }
};

watch(input, () => {
  if (input.value !== "") {
    adaptRuleInputToSelection();
  }
  emit("update:input", input.value);
});

const compareSelectionWithModel = (model) => {
  if (!useArrayHelper().isEquivalent(model, selection.value) && !props.custom) {
    selection.value = model;
  }
};

watch(
  () => allSelections,
  () => {
    emitUpdate();
  },
  { deep: true }
);

watch(
  () => props.modelValue,
  () => {
    compareSelectionWithModel(
      Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]
    );
  },
  { deep: true }
);

onMounted(() => {
  nextTick(() => handleDefaultFocus);
});
</script>
