<template>
  <div
    :class="{ 'p-4': (popout && !isOpen) || (inset && isOpen) }"
    data-testid="vexpansion-panel-container"
  >
    <div
      class="cursor-pointer"
      :class="[
        isOpen ? 'rounded-t-lg ' : 'rounded-lg',
        'flex w-full justify-between bg-google-blue-100 items-center gap-x-3 py-2 px-3 text-left leading-6 text-google-blue-800',
      ]"
      @click="() => action()"
      data-testid="vexpansion-panel-top"
    >
      <VRow gap="2">
        <p class="text-sm" data-testid="vexpansion-panel-title">
          <slot name="title">{{ props.title }}</slot>
        </p>
        <VTooltip v-if="props.tooltip">
          <template #tooltip>
            <div class="max-w-[500px]">
              {{ props.tooltip }}
            </div>
          </template>
          <template #default>
            <VIcon
              :class="props.tooltipColor"
              :name="props.tooltipIcon"
              small
            />
          </template>
        </VTooltip>
      </VRow>
      <div v-if="slots.icon" data-testid="vexpansion-panel-custom-icon">
        <slot name="icon"></slot>
      </div>
      <VRow
        class="ml-auto h-6 shrink-0 items-center"
        v-else
        data-testid="vexpansion-panel-default-icon"
      >
        <VCol
          v-if="props.count"
          class="w-[25px] h-[25px] rounded-xl bg-google-blue-400 text-white justify-center items-center"
        >
          <p>{{ props.count }}</p>
        </VCol>
        <VIcon
          :class="isOpen ? 'rotate-90 text-gray-500' : 'text-gray-400'"
          name="chevron_right"
        />
      </VRow>
    </div>
    <div
      v-show="isOpen"
      data-testid="vexpansion-panel-content"
      class="px-3 pt-1 pb-3 border-b-2 border-x-2 rounded-b-xl border-google-blue-100"
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script setup>
import { inject, onMounted, ref, useSlots, watch } from "vue";
import VCol from "@/components/common/VCol.vue";

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  defaultOpen: {
    type: Boolean,
    default: true,
  },
  value: {
    type: [String, Number],
    default: null,
  },
  popout: {
    type: Boolean,
    default: false,
  },
  inset: {
    type: Boolean,
    default: false,
  },
  persistent: {
    type: Boolean,
    default: true,
  },
  count: {
    type: Number,
    default: null,
  },
  tooltip: {
    type: String,
    default: null,
  },
  tooltipIcon: {
    type: String,
    default: "info",
  },
  tooltipColor: {
    type: String,
    default: "text-google-blue",
  },
});

const isOpen = ref(props.defaultOpen);
const { addSubscriber, updateSubscriber } = inject("expansionPanelGroup", {
  updateSubscriber: () => update(),
  addSubscriber: () => {},
});
const slots = useSlots();

const update = () => {
  if (props.persistent) return;
  isOpen.value = !isOpen.value;
};

watch(
  () => props.defaultOpen,
  (val) => {
    isOpen.value = val;
  }
);

const action = () => {
  updateSubscriber &&
    updateSubscriber({
      isOpen: !isOpen.value,
      value: props.value,
      update: update,
    });
};

onMounted(() => {
  addSubscriber &&
    addSubscriber({
      isOpen: isOpen.value,
      value: props.value,
      update: update,
    });
});
</script>
