import config from "@/config";

const MODULE = config.MODULES.HOME;
const LAYOUTS = config.LAYOUTS;

export default [
  {
    path: "/",
    name: "home",
    meta: {
      layout: LAYOUTS.DEFAULT_LAYOUT,
      module: MODULE,
      pageTitle: "Home",
    },
    component: () => import("@/views/HomeView.vue"),
  },
  { path: "/home", redirect: { name: "home" } },
  {
    path: "/not-permission",
    name: "no-permission",
    meta: {
      layout: LAYOUTS.DEFAULT_LAYOUT,
      module: MODULE,
      pageTitle: "No permissions",
    },
    component: () => import("@/views/NoPermissionView.vue"),
  },
  {
    path: "/activate/:customerId/:invitationId",
    name: "activate",
    component: () => import("@/views/ActivateView.vue"),
    meta: {
      layout: LAYOUTS.AUTH_LAYOUT,
      module: MODULE,
      icon: "logout",
      publicPage: true,
      pageTitle: "Activate",
    },
  },
  {
    path: "/scan",
    name: "activate free scan",
    component: () => import("@/views/FreeScanView.vue"),
    meta: {
      layout: LAYOUTS.AUTH_LAYOUT,
      module: MODULE,
      publicPage: true,
      pageTitle: "Request Free Scan",
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("@/views/LogoutView.vue"),
    meta: {
      layout: LAYOUTS.AUTH_LAYOUT,
      module: MODULE,
      icon: "logout",
      publicPage: true,
      pageTitle: "Logout",
    },
  },
];
