<template>
  <VRow v-if="emailAddress" gap="1" class="items-center">
    <p class="truncate" :class="`w-[${props.maxWidth}px]`">
      {{ emailAddress }}
    </p>
    <VCopyButton class="text-gray-200" :content="emailAddress" mini />
  </VRow>
  <span v-else>-</span>
</template>

<script setup>
import { computed } from "vue";
import { useUserStore } from "@/stores";

const props = defineProps({
  email: {
    type: String,
    required: false,
  },
  userId: {
    type: String,
    required: false,
  },
  maxWidth: {
    type: Number,
    required: false,
    default: 150,
  },
});

const userStore = useUserStore();

const emailAddress = computed(() => {
  return props.email || getUserById(props.userId)?.email;
});

const getUserById = (userId) => {
  return userStore.items.find((item) => item.id === userId);
};
</script>
