/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ScopeCheckInputModel } from '../models/ScopeCheckInputModel';
import type { ScopeCheckResponseModel } from '../models/ScopeCheckResponseModel';
import type { ScopeEntryModel } from '../models/ScopeEntryModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ScopesService {

    /**
     * List of scopes per solution
     * Get a list of scopes per solution.
     *
     * - **customer_id**: The customer id
     * @param customerId
     * @returns ScopeEntryModel Successful Response
     * @throws ApiError
     */
    public static listScopes(
        customerId: string,
    ): CancelablePromise<Array<ScopeEntryModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scopes/{customer_id}/all',
            path: {
                'customer_id': customerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Check if the customer has allowed to the provided scope
     * Check if the customer has allowed all the required scopes.
     *
     * - **customer_id**: The customer id
     * @param customerId
     * @param requestBody
     * @returns ScopeCheckResponseModel Successful Response
     * @throws ApiError
     */
    public static checkScopes(
        customerId: string,
        requestBody: ScopeCheckInputModel,
    ): CancelablePromise<ScopeCheckResponseModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/scopes/{customer_id}/check',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * List of general scopes
     * Get a list of general scopes.
     * @param customerId
     * @returns ScopeEntryModel Successful Response
     * @throws ApiError
     */
    public static listGeneralScopes(
        customerId: string,
    ): CancelablePromise<Array<ScopeEntryModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scopes/{customer_id}/general',
            path: {
                'customer_id': customerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * List of admin automation scopes
     * Get a list of admin automation scopes.
     * @param customerId
     * @returns ScopeEntryModel Successful Response
     * @throws ApiError
     */
    public static listAdminAutomationScopes(
        customerId: string,
    ): CancelablePromise<Array<ScopeEntryModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scopes/{customer_id}/admin_automation',
            path: {
                'customer_id': customerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * List of security and auditing readonly scopes
     * Get a list of security and auditing readonly scopes.
     * @param customerId
     * @returns ScopeEntryModel Successful Response
     * @throws ApiError
     */
    public static listSecurityAndAuditingReadonlyScopes(
        customerId: string,
    ): CancelablePromise<Array<ScopeEntryModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scopes/{customer_id}/security_and_auditing_readonly',
            path: {
                'customer_id': customerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * List of security and auditing write scopes
     * Get a list of security and auditing write scopes.
     * @param customerId
     * @returns ScopeEntryModel Successful Response
     * @throws ApiError
     */
    public static listSecurityAndAuditingWriteScopes(
        customerId: string,
    ): CancelablePromise<Array<ScopeEntryModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scopes/{customer_id}/security_and_auditing_write',
            path: {
                'customer_id': customerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * List of signature management scopes
     * Get a list of signature management scopes.
     * @param customerId
     * @returns ScopeEntryModel Successful Response
     * @throws ApiError
     */
    public static listSignatureManagementScopes(
        customerId: string,
    ): CancelablePromise<Array<ScopeEntryModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/scopes/{customer_id}/email_signature_management',
            path: {
                'customer_id': customerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
