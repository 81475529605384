import { version } from "../package.json";
import App from "./App.vue";
import { createApp } from "vue";
import { createPinia } from "pinia";
import router from "./router";
import vue3GoogleLogin from "vue3-google-login";
import { log } from "@/components/helpers/logger-helper";
import Toast from "vue-toastification";

import { initializeApp } from "firebase/app";

// IMPORT STYLES
import "./index.css";
import "./assets/main.scss";
// Import the toastification CSS or use your own!
import "vue-toastification/dist/index.css";

// IMPORT BASE COMPONENTS
import VButton from "@/components/common/VButton.vue";
import VModal from "@/components/common/VModal.vue";
import VDatePicker from "@/components/common/VDatePicker.vue";
import VRRuleBuilder from "@/components/common/VRRuleBuilder.vue";
import VTextField from "@/components/common/VTextField.vue";
import VTextArea from "@/components/common/VTextArea.vue";
import VNumberInput from "@/components/common/VNumberInput.vue";
import VToggle from "@/components/common/VToggle.vue";
import VCheckbox from "@/components/common/VCheckbox.vue";
import VRadioButton from "@/components/common/VRadioButton.vue";
import VIcon from "@/components/common/VIcon.vue";
import VForm from "@/components/common/VForm.vue";
import VFormSection from "@/components/common/VFormSection.vue";
import VSelect from "@/components/common/VSelect.vue";
import VChip from "@/components/common/VChip.vue";
import VComplexChip from "@/components/common/VComplexChip.vue";
import VStatusChip from "@/components/common/VStatusChip.vue";
import VCopyButton from "@/components/common/VCopyButton.vue";
import VPageTabs from "@/components/common/VPageTabs.vue";
import VAvatar from "@/components/common/VAvatar.vue";
import VAlert from "@/components/common/VAlert.vue";
import VDropbox from "@/components/common/VDropbox.vue";
import VStepper from "@/components/common/VStepper.vue";
import VLoading from "@/components/common/VLoading.vue";
import VTooltip from "@/components/common/VTooltip.vue";
import VContainer from "@/components/common/VContainer.vue";
import VRow from "@/components/common/VRow.vue";
import VCol from "@/components/common/VCol.vue";
import VExpansionPanel from "@/components/common/VExpansionPanel.vue";
import VExpansionPanelGroup from "@/components/common/VExpansionPanelGroup.vue";
import VProgressBar from "@/components/common/VProgressBar.vue";
import VLoadingBlur from "@/components/common/VLoadingBlur.vue";
import VLoadingOverlay from "@/components/common/VLoadingOverlay.vue";
import VDotMenu from "@/components/common/VDotMenu.vue";
import VMenuItem from "@/components/common/VMenuItem.vue";
import VSkeleton from "@/components/common/VSkeleton.vue";
import VTreeSelect from "@/components/common/VTreeSelect.vue";
import VSelectMenu from "@/components/common/VSelectMenu.vue";
import VCard from "@/components/common/VCard.vue";
import VFlatCard from "@/components/common/VFlatCard.vue";
import VChartLine from "@/components/common/VChartLine.vue";
import VChartBar from "@/components/common/VChartBar.vue";
import VMultipleValueGroup from "@/components/common/VMultipleValueGroup.vue";
import VSingleValueGroup from "@/components/common/VSingleValueGroup.vue";
import VTable from "@/components/common/VTable.vue";
import VCarousel from "@/components/common/VCarousel.vue";
import VMenu from "@/components/common/VMenu.vue";
import VIdentitySnippet from "@/components/common/VIdentitySnippet.vue";
import VUserEmail from "@/components/common/VUserEmail.vue";
import VPageSection from "@/components/common/VPageSection.vue";
import VUnmountModal from "@/components/common/VUnmountModal.vue";
import VChartDonut from "@/components/common/VChartDonut.vue";

// ====================
// ==== CREATE APP ====
// ====================
const app = createApp(App);

// ADD BASE COMPONENTS
app
  .component("VButton", VButton)
  .component("VModal", VModal)
  .component("VDatePicker", VDatePicker)
  .component("VTextField", VTextField)
  .component("VTextArea", VTextArea)
  .component("VNumberInput", VNumberInput)
  .component("VToggle", VToggle)
  .component("VCheckbox", VCheckbox)
  .component("VRadioButton", VRadioButton)
  .component("VRRuleBuilder", VRRuleBuilder)
  .component("VIcon", VIcon)
  .component("VForm", VForm)
  .component("VFormSection", VFormSection)
  .component("VSelect", VSelect)
  .component("VChip", VChip)
  .component("VComplexChip", VComplexChip)
  .component("VStatusChip", VStatusChip)
  .component("VCopyButton", VCopyButton)
  .component("VPageTabs", VPageTabs)
  .component("VAlert", VAlert)
  .component("VAvatar", VAvatar)
  .component("VDropbox", VDropbox)
  .component("VStepper", VStepper)
  .component("VLoading", VLoading)
  .component("VLoadingBlur", VLoadingBlur)
  .component("VTooltip", VTooltip)
  .component("VContainer", VContainer)
  .component("VRow", VRow)
  .component("VCol", VCol)
  .component("VExpansionPanel", VExpansionPanel)
  .component("VExpansionPanelGroup", VExpansionPanelGroup)
  .component("VMultipleValueGroup", VMultipleValueGroup)
  .component("VSingleValueGroup", VSingleValueGroup)
  .component("VProgressBar", VProgressBar)
  .component("VLoadingOverlay", VLoadingOverlay)
  .component("VDotMenu", VDotMenu)
  .component("VMenuItem", VMenuItem)
  .component("VSkeleton", VSkeleton)
  .component("VTreeSelect", VTreeSelect)
  .component("VSelectMenu", VSelectMenu)
  .component("VCard", VCard)
  .component("VFlatCard", VFlatCard)
  .component("VChartLine", VChartLine)
  .component("VChartBar", VChartBar)
  .component("VChartDonut", VChartDonut)
  .component("VTable", VTable)
  .component("VCarousel", VCarousel)
  .component("VMenu", VMenu)
  .component("VIdentitySnippet", VIdentitySnippet)
  .component("VUserEmail", VUserEmail)
  .component("VPageSection", VPageSection)
  .component("VUnmountModal", VUnmountModal);

app.use(createPinia());
app.use(router);
app.use(Toast, {
  position: "top-right",
  closeOnClick: false,
  filterBeforeCreate: (toast, toasts) => {
    // Return a single toast if the content is the same
    if (
      toasts.some(
        (t) =>
          t.content === undefined ||
          toast.content === undefined ||
          t.content === toast.content
      )
    ) {
      return false;
    }

    // Return a general toast multiple toasts are present
    if (toasts.length > 0) {
      let general_toast = toasts[0];
      general_toast.content =
        "Please refresh the page and try again later. If the error persists, please contact support@florbs.io";

      return general_toast;
    }

    if (toast.content == undefined) {
      return false;
    }

    // return the toast if we are not on /login route
    if (
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/logout" &&
      window.location.pathname !== "/scan"
    ) {
      return toast;
    }
  },
});

// INIT FIREBASE
const firebaseConfig = {
  apiKey: import.meta.env.VITE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

// app.config.globalProperties.$firebase = firebaseApp;

// INIT GOOGLE LOGIN
app.use(vue3GoogleLogin, {
  clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,

  scope: "profile email openid",
  idConfiguration: {
    ux_mode: "popup",
    context: "signin",
    itp_support: true,
  },
  buttonConfig: {
    text: "continue_with",
    locale: "en_EN",
    width: 285,
  },
});

// INIT WINDOW
app.config.globalProperties.window = window;

// INIT ERROR HANDLER
// captures errors that were not caught by any other error handler
app.config.errorHandler = (err, instance, info) => {
  const context = {
    errorType: "GLOBAL",
    message: err.message,
    source: "main.js",
    function: "app.config.errorHandler",
    data: {
      stack: err.stack,
      info: info,
      instance: instance,
    },
  };
  log.error(err, { meta: context });
};

// INIT OPENAPI
import { OpenAPI as AppManagementAPI } from "./apis/app-management";
import { OpenAPI as SecurityAuditingAPI } from "@/apis/security-auditing";

AppManagementAPI.BASE = import.meta.env.VITE_APP_MANAGEMENT_BASE_URL;
SecurityAuditingAPI.BASE = import.meta.env.VITE_SECURITY_AUDITING_BASE_URL;

if (import.meta.env.MODE === "development") {
  const url = window.location.href;
  const port = new URL(url).port;
  const isCypress =
    port === "4173" || (typeof Cypress !== "undefined" && Cypress.env);

  AppManagementAPI.BASE = isCypress
    ? Cypress.env("VITE_APP_MANAGEMENT_BASE_URL")
    : import.meta.env.VITE_APP_MANAGEMENT_BASE_URL;
}

// START INIT INTERCOM
const APP_ID = import.meta.env.VITE_INTERCOM_APP_ID;

if (APP_ID && APP_ID !== "") {
  (function () {
    const w = window;
    const ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      const d = document;
      const i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      const l = function () {
        const s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/" + APP_ID;
        const x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
  // END INIT INTERCOM
}

const appVersion = version;
app.provide("appVersion", appVersion);

// MOUNT APP
app.mount("#app");
