export function useTextFormat() {
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  };

  const getInitials = (s) => {
    if (typeof s !== "string") return "";
    const nameParts = s.split(" ");
    const initials = nameParts.map((part) => part.charAt(0));

    return initials.join("").toUpperCase();
  };

  const kebabToPascalCase = (s) => {
    if (typeof s !== "string") return "";
    return s
      .split("-")
      .map((word) => capitalize(word))
      .join("");
  };

  const camelToSentence = (s) => {
    var sentence = s.replace(/([A-Z])/g, " $1").toLowerCase();

    return capitalize(sentence);
  };

  const shortenNumber = (number, decimals = 10) => {
    const suffixes = ["", "K", "M", "B"];
    let suffixIndex = 0;

    // Determine the appropriate suffix and divide the number accordingly
    while (number >= 1000 && suffixIndex < suffixes.length - 1) {
      number /= 1000;
      suffixIndex++;
    }

    // Check if the number has decimal places
    const roundedNumber = Math.round(number * decimals) / decimals;
    let formattedNumber =
      roundedNumber === Math.floor(roundedNumber)
        ? Math.floor(roundedNumber)
        : roundedNumber;
    formattedNumber += suffixes[suffixIndex];

    return formattedNumber;
  };

  const pluralize = (word, count) => (count === 1 ? word : `${word}s`);

  // Never send random ids to the api
  const generateRandomId = () =>
    Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substring(2, 10);

  const parseBoolean = (str) => {
    if (typeof str !== "string") return str;

    const lowerCaseStr = str.toLowerCase();

    if (lowerCaseStr === "true") {
      return true;
    } else if (lowerCaseStr === "false") {
      return false;
    }

    return str;
  };

  return {
    capitalize,
    getInitials,
    kebabToPascalCase,
    camelToSentence,
    shortenNumber,
    pluralize,
    generateRandomId,
    parseBoolean,
  };
}
