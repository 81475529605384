<template>
  <Transition name="fade" mode="out-in">
    <div :key="currentNavigationItem?.name">
      <nav class="flex" aria-label="Breadcrumb">
        <ol role="list" class="flex items-center space-x-2">
          <li v-for="(item, index) in items" :key="index">
            <div class="flex items-center">
              <VIcon
                name="chevron_right"
                class="flex-shrink-0 text-gray-400"
                mini
              />
              <router-link
                v-if="index < items.length - 1"
                :to="item.path"
                class="ml-2 text-sm font-medium first-letter:capitalize text-gray-400 hover:text-gray-500"
              >
                {{ item.label }}
              </router-link>
              <span
                v-else
                class="ml-2 text-sm font-medium first-letter:capitalize text-gray-500"
              >
                {{ item.label }}
              </span>
            </div>
          </li>
        </ol>
      </nav>
    </div>
  </Transition>
</template>

<script setup>
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const getRouteByName = (name) => {
  return router.getRoutes().find((item) => item.name === name);
};

const currentNavigationItem = computed(() => {
  return route.meta.parent;
});

const items = computed(() => {
  const items = [];

  const breadcrumbs = [];

  const getBreadcrumbs = (route, isRoot = false) => {
    if (route?.meta?.tabTitle && isRoot) {
      items.push({
        label: route.meta.tabTitle,
        path: route.path,
      });
    }

    if (route?.meta?.pageTitle) {
      items.push({
        label: route.meta.pageTitle,
        path: route.path,
      });
    }

    if (route?.meta?.parent) {
      const parentRoute = getRouteByName(route.meta.parent);
      getBreadcrumbs(parentRoute);
    }

    return breadcrumbs;
  };

  getBreadcrumbs(route, true);

  return items.reverse();
});
</script>
