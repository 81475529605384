// Intercom implementation
// https://developers.intercom.com/installing-intercom/docs/intercom-javascript#section-intercomupdate

import { usePrivilegeStore, useCustomerStore } from "@/stores";

export function useIntercom() {
  const APP_ID = import.meta.env.VITE_INTERCOM_APP_ID;
  const customersStore = useCustomerStore();
  const refreshIntervalSeconds = 90;

  const intercomConfigReady = () => {
    return APP_ID && window.Intercom;
  };

  /**
   * Refresh intercom
   * Intercom allows 20 refreshses per 30 minutes
   *
   * Checks if there are new messages, you can add user
   * settings to update the user settings in intercom.
   * We choose to call only the update function
   */
  const initRefreshIntercom = () => {
    if (!intercomConfigReady()) {
      return;
    }

    setInterval(() => {
      window.Intercom("update");
    }, refreshIntervalSeconds * 1000);
  };

  /**
   * Function to load Intercom
   *
   * Sets the privileges in intercom used to show/hide features
   * in the intercom messenger or product tours etc.
   *
   * When the user changes the active customer, this function should
   * be called again to update the privileges.
   *
   * @param {*} user
   */
  const setActiveUser = async (user) => {
    if (!intercomConfigReady()) {
      return;
    }

    // check if user has privileges for active customer
    if (
      !customersStore.activeCustomer ||
      user.privileges[customersStore.activeCustomer.customerId] == undefined
    ) {
      return;
    }

    // read all possible privileges
    const privileges = await usePrivilegeStore().listItems();
    const capabilities = {};

    privileges.forEach((element) => {
      // set all privileges to false
      capabilities[element.privilegeId.toLowerCase()] = false;

      // check if the user has the privilege for the active customer
      if (
        user.privileges[customersStore.activeCustomer.customerId][
          element.privilegeId
        ]
      ) {
        capabilities[element.privilegeId.toLowerCase()] = true;
      }
    });

    let intercom_user_details = {
      app_id: APP_ID,
      user_hash: user.intercomHash,
      email: user.email,
      name: user.displayName,
      avatar: user.picture,
      head_domain: user.headDomain,
      google_user_id: user.googleUserId,
      firebase_user_id: user.firebaseUserId,
      active_customer_id: customersStore.activeCustomer.customerId,
      ...capabilities,
      userAppVersion: user.appVersion,
      latestVersion: user.latestVersion,
      newAppVersionAvailable: user.newAppVersionAvailable || false,
    };

    window.Intercom("boot", intercom_user_details);
  };

  /**
   * Shutdown intercom
   *
   * If you have the Inbox product (combined with another product like Messages)
   * you should call the Intercom shutdown method to clear your users’
   * conversations anytime they logout of your application. Otherwise,
   * the cookie we use to track who was most recently logged in on a given
   * device or computer will keep these conversations in the Messenger for
   * one week. This method will effectively clear out any user data that
   * you have been passing through the JS API.
   */
  const logout = () => {
    if (!intercomConfigReady()) {
      return;
    }

    window.Intercom("shutdown");
  };

  /**
   * Hide intercom messenger main panel
   */
  const hideMessenger = () => {
    if (!intercomConfigReady()) {
      return;
    }

    window.Intercom("hide");
  };

  /**
   * Show intercom messenger main panel
   * This will show the Messenger. If there are no
   * new conversations, it will open to the Messenger
   * Home. If there are, it will open with the message list
   */
  const showMessenger = () => {
    if (!intercomConfigReady()) {
      return;
    }

    window.Intercom("show");
  };

  /**
   * Show intercom new message
   * This will open the Messenger with the new message
   * composer open. If you pass a message, it will be
   * pre-populated in the composer
   * @param {*} message
   * - Optional
   * - String
   * - Max length: 1000 characters
   * - Default: null
   * - Example: "Hi there, I have a question"
   */
  const showNewMessage = (message = null) => {
    if (!intercomConfigReady()) {
      return;
    }

    window.Intercom("showNewMessage", message);
  };

  /**
   * Show intercom space
   * This will show the specified space in the Messenger,
   * if that space is enabled
   *
   * @param {*} spaceName
   *  - Allowed values:
   *    - "home"
   *    - "messages"
   *    - "help"
   *    - "news"
   *    - "tasks"
   */
  const _showSpace = (spaceName) => {
    window.Intercom("showSpace", spaceName);
  };

  /**
   * Show intercom home space
   */
  const showHomeSpace = () => {
    if (!intercomConfigReady()) {
      return;
    }

    _showSpace("home");
  };

  /**
   * Show intercom help space
   */
  const showHelpSpace = () => {
    if (!intercomConfigReady()) {
      return;
    }

    _showSpace("help");
  };

  /**
   * Show intercom messages space
   */
  const showMessagesSpace = () => {
    if (!intercomConfigReady()) {
      return;
    }

    _showSpace("messages");
  };

  /**
   * Show intercom news space
   */
  const showNewsSpace = () => {
    if (!intercomConfigReady()) {
      return;
    }

    _showSpace("news");
  };

  /**
   * Show intercom tasks space
   */
  const showTasksSpace = () => {
    if (!intercomConfigReady()) {
      return;
    }

    _showSpace("tasks");
  };

  /**
   * Track an event
   * You can submit an event using the trackEvent method.
   * This will associate the event with the currently
   * logged in user and send it to Intercom.
   *
   * @param {*} eventName
   * @param {*} metadata
   * Metadata example:
   *    const metadata = {
   *      invitee_email: 'pi@example.org',
   *      invite_code: 'ADDAFRIEND'
   *     };
   */
  const trackEvent = (eventName, metadata) => {
    if (!intercomConfigReady()) {
      return;
    }

    window.Intercom("trackEvent", eventName, metadata);
  };

  /**
   * Start a product tour
   * If you would like to trigger a tour based on an action
   * a user or visitor takes in your site or application,
   * you can use this API method. You need to call this
   * method with the id of the tour you wish to show. The
   * id of the tour can be found in the “Use tour everywhere”
   * section of the tour editor.
   *
   * Please note that tours shown via this API must be published
   * and the “Use tour everywhere” section must be turned on.
   * If you're calling this API using an invalid tour id,
   * nothing will happen.
   *
   * @param {*} tourId
   */
  const startProductTour = (tourId) => {
    if (!intercomConfigReady()) {
      return;
    }

    window.Intercom("startTour", tourId);
  };

  /**
   * Show an article
   * If  you would like to trigger an article in the Messenger,
   * you can use the showArticle method. The article will be
   * shown within the Messenger, and clicking the Messenger back
   * button will return to the previous context.
   *
   * If the Messenger is closed when the method is called, it will
   * be opened first and then the article will be shown.
   *
   * @param {*} articleId
   * The id of the article you wish to show. The id of the article
   * can be found in the “Use article everywhere” section of the
   * article editor.
   */
  const showArticle = (articleId) => {
    if (!intercomConfigReady()) {
      return;
    }

    window.Intercom("showArticle", articleId);
  };

  /**
   * Show a news item
   * If you would like to trigger a news item in the Messenger,
   * you can use the showNews method. The news item will be shown
   * within the Messenger, and clicking the Messenger back button
   * will return to the previous context.
   *
   * If the Messenger is closed when the method is called, it will
   * be opened first and then the news item will be shown.
   *
   * @param {*} newsId
   */
  const showNews = (newsId) => {
    if (!intercomConfigReady()) {
      return;
    }

    window.Intercom("showNews", newsId);
  };

  /**
   * Start a survey
   * If you would like to trigger a survey in the Messenger, you
   * can use the startSurvey method. The id of the survey can be
   * found in the “Additional ways to share your survey” section
   * of the survey editor as well as in the URL of the editor.
   *
   * If you're calling this API using an invalid survey id,
   * nothing will happen.
   * @param {*} surveyId
   */
  const startSurvey = (surveyId) => {
    if (!intercomConfigReady()) {
      return;
    }

    window.Intercom("startSurvey", surveyId);
  };

  /**
   * Start a checklist
   * If you would like to trigger a checklist in the Messenger,
   * you can use the startChecklist method. The id of the checklist
   * can be found in the “Additional ways to share your checklist”
   * section of the checklist editor as well as in the URL of the
   * editor.
   *
   * If you're calling this API using an invalid checklist id,
   * nothing will happen.
   */
  const startChecklist = (checklistId) => {
    if (!intercomConfigReady()) {
      return;
    }

    window.Intercom("startChecklist", checklistId);
  };

  return {
    initRefreshIntercom,
    setActiveUser,
    logout,
    hideMessenger,
    showMessenger,
    showNewMessage,
    showHomeSpace,
    showHelpSpace,
    showMessagesSpace,
    showNewsSpace,
    showTasksSpace,
    trackEvent,
    startProductTour,
    showArticle,
    showNews,
    startSurvey,
    startChecklist,
  };
}
