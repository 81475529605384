<template>
  <SwitchGroup as="div" class="flex items-center justify-between">
    <Switch
      v-model="toggleStatus"
      :class="[
        toggleStatus
          ? 'bg-primary-600 hover:bg-primary-700 disabled:bg-primary-25'
          : 'bg-gray-100 hover:bg-gray-200 disabled:bg-gray-25',
        ' group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer items-center justify-center rounded-full transition-colors duration-100 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-100',
      ]"
      :disabled="props.disabled"
      @update:model-value="changeValue"
    >
      <span class="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        :class="[
          toggleStatus
            ? 'translate-x-5 disabled:bg-gray-50'
            : 'translate-x-1 disabled:bg-gray-50',
          'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
        ]"
        :disabled="props.disabled"
      />
    </Switch>
    <span class="ml-2 flex flex-grow flex-col">
      <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive>
        <slot name="label">
          {{ props.label }}
        </slot>
      </SwitchLabel>
      <div class="text-sm text-gray-500">
        <slot name="description">
          <SwitchDescription v-if="props.description" as="span">
            {{ props.description }}
          </SwitchDescription>
        </slot>
      </div>
    </span>

    <slot>
      <div v-if="!props.label"></div>
      <div v-else class="mt-5 flex flex-grow flex-col"></div>
    </slot>
  </SwitchGroup>
</template>

<script setup>
import { ref, watch } from "vue";
import {
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
} from "@headlessui/vue";

const props = defineProps({
  label: {
    type: String,
    required: false,
    default: "",
  },
  description: {
    type: String,
    required: false,
    default: "",
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  modelValue: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const toggleStatus = ref(props.modelValue);
const emit = defineEmits(["update:modelValue"]);

const changeValue = (value) => {
  emit("update:modelValue", value);
};

watch(
  () => props.modelValue,
  () => {
    toggleStatus.value = props.modelValue;
  }
);
</script>
