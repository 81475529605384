<template>
  <div class="flex items-center relative" :class="size.height">
    <button
      type="button"
      class="btn"
      :class="[
        !props.label ? `btn-icon ${buttonClass}` : buttonClass,
        props.wrap && 'w-fit',
        props.wrap ? size.buttonPaddingX : '',

        size.buttonHeight,
        size.buttonPaddingY,
      ]"
      :disabled="props.disabled"
      @click="action"
    >
      <slot>
        <div
          v-if="props.label"
          class="btn-label"
          :class="[size.text, props.bold ? 'font-semibold' : 'font-normal']"
        >
          {{ props.label }}
        </div>
      </slot>
      <div v-if="props.icon" :class="iconClass">
        <VIcon :name="props.icon" :mini="props.small" />
      </div>
    </button>
    <div v-if="hasAdornment" class="absolute -top-4 -right-2">
      <slot name="adornment">
        <div v-if="props.adornment" class="btn-label">
          <VChip small :label="props.adornment" :color="adornmentColor" />
        </div>
      </slot>
    </div>
  </div>
</template>

<script setup>
import { computed, useSlots } from "vue";

const slots = useSlots();
const emit = defineEmits(["click", "update:modelValue"]);

const props = defineProps({
  label: {
    type: String,
    required: false,
    default: null,
  },
  iconTrailing: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  icon: {
    type: String,
    required: false,
    default: "",
  },
  adornment: {
    type: String,
    default: null,
  },
  adornmentColor: {
    type: String,
    default: null,
  },
  variant: {
    type: String,
    validator: (value) => {
      const validVariants = [
        "primary",
        "secondary",
        "tertiary",
        "destructive",
        "basic",
      ];
      if (!validVariants.includes(value)) {
        console.warn(`Invalid variant: ${value}. Defaulting to "basic".`);
        return false;
      }
      return true;
    },
    default: "basic",
  },
  wrap: {
    type: Boolean,
    required: false,
    default: false,
  },
  small: {
    type: Boolean,
    required: false,
    default: false,
  },
  bold: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const buttonClass = computed(() => {
  switch (props.variant) {
    case "primary":
      return "btn-primary";
    case "secondary":
      return "btn-secondary";
    case "tertiary":
      return "btn-tertiary";
    case "destructive":
      return "btn-destructive";
    default:
      return "btn-default";
  }
});

const iconClass = computed(() => {
  if (props.iconTrailing) {
    return "ml-2";
  }
  if (props.label) {
    return "-order-1 mr-2";
  }
  return "-order-1";
});

const hasAdornment = computed(() => slots.adornment || props.adornment);
const handleEmits = () => {
  emit("click");
};

const size = computed(() => {
  return {
    height: props.small ? "h-8" : "h-fit",
    text: props.small ? "!text-xs" : "!text-sm",
    buttonHeight: props.small ? "min-h-[28px]" : "min-h-[40px]",
    buttonPaddingY: props.small ? "py-1" : "py-1.5",
    buttonPaddingX: props.wrap
      ? "px-[10px]"
      : props.small
      ? "px-[20px]"
      : "px-[28px]",
  };
});

const action = () => {
  if (props.disabled) return;

  handleEmits();
};
</script>
