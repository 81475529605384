import { defineStore } from "pinia";
import { ref } from "vue";

import { ScopesService } from "@/apis/app-management";
import { useCustomerStore } from "@/stores";

const storeId = "scopes-store";

export const useScopesStore = defineStore(storeId, () => {
  const customerStore = useCustomerStore();

  const testedScopes = ref([]);

  const resetTestedScopes = () => {
    testedScopes.value = [];
  };

  const getScopes = async () => {
    const securityAndAuditingRead =
      await ScopesService.listSecurityAndAuditingReadonlyScopes(
        customerStore.activeCustomerId
      );
    const securityAndAuditingWrite =
      await ScopesService.listSecurityAndAuditingWriteScopes(
        customerStore.activeCustomerId
      );

    const scopes = {
      general: {
        label: "General",
        scopes: await ScopesService.listGeneralScopes(
          customerStore.activeCustomerId
        ),
      },
      securityAndAuditing: {
        label: "File security for Google Drive",
        scopes: [...securityAndAuditingRead, ...securityAndAuditingWrite].sort(
          (a, b) => a.scope.localeCompare(b.scope)
        ),
      },
    };

    // deduplicate scopes for each category and sort them by required scopes
    Object.values(scopes).forEach((scopeCategory) => {
      const scopeCategoryScopes = scopeCategory.scopes;
      const deduplicatedScopes = [];
      scopeCategoryScopes.forEach((scope) => {
        const scopeIndex = deduplicatedScopes.findIndex(
          (deduplicatedScope) => deduplicatedScope.scope === scope.scope
        );
        if (scopeIndex === -1) {
          deduplicatedScopes.push(scope);
        } else {
          deduplicatedScopes[scopeIndex].requiredScopes = [
            ...deduplicatedScopes[scopeIndex].requiredScopes,
            ...scope.requiredScopes,
          ];
        }
      });
      scopeCategory.scopes = deduplicatedScopes.sort((a, b) =>
        a.scope.localeCompare(b.scope)
      );
    });

    return scopes;
  };

  const checkScope = async (scope) => {
    return ScopesService.checkScopes(customerStore.activeCustomerId, {
      scope: scope,
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

  const addTestedScope = (scope, activated) => {
    const scopeIndex = testedScopes.value.findIndex(
      (testedScope) => testedScope.scope === scope
    );
    if (scopeIndex === -1) {
      testedScopes.value.push({ scope, activated });
    } else {
      testedScopes.value[scopeIndex].activated = activated;
    }
  };

  const getTestedScope = (scope) => {
    return testedScopes.value.find(
      (testedScope) => testedScope.scope === scope
    );
  };

  return {
    getScopes,
    checkScope,
    resetTestedScopes,
    addTestedScope,
    getTestedScope,
  };
});
