/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckoutRequest } from '../models/CheckoutRequest';
import type { CheckoutSession } from '../models/CheckoutSession';
import type { CreatePortalSessionRequest } from '../models/CreatePortalSessionRequest';
import type { Invoice } from '../models/Invoice';
import type { InvoiceListResponse } from '../models/InvoiceListResponse';
import type { PortalSession } from '../models/PortalSession';
import type { UpcomingInvoiceRequest } from '../models/UpcomingInvoiceRequest';
import type { UpdateActiveUsersResponse } from '../models/UpdateActiveUsersResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BillingService {

    /**
     * Create checkout session
     * @param customerId
     * @param requestBody
     * @returns CheckoutSession Successful Response
     * @throws ApiError
     */
    public static createCheckoutSession(
        customerId: string,
        requestBody?: CheckoutRequest,
    ): CancelablePromise<CheckoutSession> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/customers/{customer_id}/billing/create-checkout-session',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create portal session
     * @param customerId
     * @param requestBody
     * @returns PortalSession Successful Response
     * @throws ApiError
     */
    public static createPortalSession(
        customerId: string,
        requestBody?: CreatePortalSessionRequest,
    ): CancelablePromise<PortalSession> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customers/{customer_id}/billing/create-portal-session',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * list invoices
     * @param customerId
     * @returns InvoiceListResponse Successful Response
     * @throws ApiError
     */
    public static listInvoices(
        customerId: string,
    ): CancelablePromise<InvoiceListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{customer_id}/billing/invoices',
            path: {
                'customer_id': customerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get upcoming invoice
     * @param customerId
     * @param requestBody
     * @returns Invoice Successful Response
     * @throws ApiError
     */
    public static getUpcomingInvoice(
        customerId: string,
        requestBody?: UpcomingInvoiceRequest,
    ): CancelablePromise<Invoice> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customers/{customer_id}/billing/upcoming-invoice',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update all customers active users
     * @param customerId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static updateActiveUsers(
        customerId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{customer_id}/subscription/update-active-users',
            path: {
                'customer_id': customerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update customer active users
     * Update customer active users
     * @param customerId
     * @returns UpdateActiveUsersResponse Successful Response
     * @throws ApiError
     */
    public static updateCustomerActiveUsers(
        customerId: string,
    ): CancelablePromise<Array<UpdateActiveUsersResponse>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customers/{customer_id}/subscription/update-customer-active-users',
            path: {
                'customer_id': customerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
