<template>
  <div class="w-full cursor-pointer h-10">
    <div class="flex">
      <div class="my-auto mx-2 pt-0.5" v-if="props.prependIconOuter">
        <VIcon
          class="text-gray-500"
          :class="{
            'cursor-pointer': onClickPrependOuter,
          }"
          :name="props.prependIconOuter"
          @click.stop="emit('clickPrependOuter')"
        />
      </div>
      <div
        ref="selectBox"
        @focus="setShowOptions(true)"
        class="w-full rounded-md border bg-white focus-within:border-google-blue-500 focus-within:ring-2 focus-within:ring-google-blue-500/20"
        :class="[
          props.area ? ' h-auto' : 'h-10',
          props.disabled && 'cursor-not-allowed !bg-disabled border-gray-25',
          !props.disabled && 'border-gray-50',
        ]"
        :placeholder="label"
        @click.stop="setShowOptions(!showOptions)"
      >
        <div class="flex h-full items-center">
          <div
            class="my-auto"
            v-if="props.prependIcon"
            :class="{ 'ml-2': props.label, 'mx-2': !props.label }"
          >
            <VIcon
              class="text-gray-500"
              :class="{
                'cursor-pointer': onClickPrepend,
              }"
              :name="props.prependIcon"
              @click.stop="emit('clickPrepend')"
            />
          </div>
          <div
            v-if="props.label"
            class="h-9 m-0.5 px-2 font-semibold rounded-md py-2 block w-full text-gray-900 placeholder-gray-200 focus:outline-none text-sm disabled:cursor-not-allowed disabled:bg-white disabled:shadow-none disabled:placeholder-gray-50"
          >
            <div class="my-auto">
              {{ props.label }}
            </div>
          </div>

          <div class="my-auto mx-2" v-if="props.appendIcon">
            <VIcon
              class="text-gray-500"
              :class="{
                'cursor-pointer': onClickAppend,
              }"
              :name="props.appendIcon"
              @click.stop="emit('clickAppend')"
            />
          </div>
        </div>
      </div>
      <div class="my-auto mx-2 pt-0.5" v-if="props.appendIconOuter">
        <VIcon
          class="text-gray-500"
          :class="{
            'cursor-pointer': onClickAppendOuter,
          }"
          :name="props.appendIconOuter"
          @click.stop="emit('clickAppendOuter')"
        />
      </div>
    </div>

    <SelectOptions
      :width="optionsWidth"
      v-if="showOptions"
      v-model="selection"
      @close="setShowOptions(false)"
      :items="props.items"
      :item-text="props.itemText"
      :item-value="props.itemValue"
      :item-icon="props.itemIcon"
      :item-disabled="props.itemDisabled"
      multiple
      :clearable="false"
      full-width
      position="right"
    />
  </div>
</template>

<script setup>
import SelectOptions from "@/components/helpers/SelectOptions.vue";
import { ref, watch } from "vue";

// eslint-disable-next-line sonarjs/no-duplicate-string
const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  modelValue: { default: null },
  label: { type: String, default: null },
  disabled: { type: Boolean, default: false },
  iconAppend: { type: Boolean, default: true },
  prependIcon: { type: String, default: "" }, // show icon before input
  appendIcon: { type: String, default: "" }, // show icon after input
  prependIconOuter: { type: String, default: "" }, // show icon before inputbox
  appendIconOuter: { type: String, default: "" }, // show icon after inputbox
  // on click events for icons (through props to check if they are set)
  onClickPrepend: { type: Function, default: null },
  onClickAppend: { type: Function, default: null },
  onClickPrependOuter: { type: Function, default: null },
  onClickAppendOuter: { type: Function, default: null },
  items: { type: Array, default: () => [] },
  itemText: { type: String, default: null },
  itemValue: { type: String, default: null },
  itemIcon: { type: String, default: null },
  itemDisabled: { type: String, default: null },
});

const selectBox = ref(null);
const showOptions = ref(false);
const optionsWidth = ref(0);

watch(
  () => showOptions.value,
  (val) => {
    if (val) {
      optionsWidth.value = selectBox.value.offsetWidth;
    }
  }
);

const selection = ref([]);

watch(
  () => selection.value,
  (value) => {
    emit("update:modelValue", value);
  },
  { deep: true, immediate: true }
);

watch(
  () => props.modelValue,
  (value) => {
    selection.value = value;
  },
  { deep: true, immediate: true }
);

const setShowOptions = (val) => !props.disabled && (showOptions.value = val);

defineExpose({
  setShowOptions,
  showOptions,
});
</script>
