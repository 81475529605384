/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BigQueryJobResponse } from '../models/BigQueryJobResponse';
import type { PageSizeEnum } from '../models/PageSizeEnum';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BigQueryJobsService {

    /**
     * Get Job Result
     * Get drive files
     * @param customerId
     * @param jobId
     * @param requiresAll
     * @param pageSize
     * @param pageToken
     * @returns BigQueryJobResponse Successful Response
     * @throws ApiError
     */
    public static pollJobResult(
        customerId: string,
        jobId: string,
        requiresAll: boolean = false,
        pageSize?: PageSizeEnum,
        pageToken?: string,
    ): CancelablePromise<BigQueryJobResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/security-auditing/{customer_id}/jobs/{job_id}',
            path: {
                'customer_id': customerId,
                'job_id': jobId,
            },
            query: {
                'requires_all': requiresAll,
                'pageSize': pageSize,
                'pageToken': pageToken,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
