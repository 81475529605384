<template>
  <div
    class="mt-4 bg-background rounded-lg border border-gray-25 shadow-sm h-16 justify-between items-center flex pl-5 pr-5"
  >
    <TheBreadcrumb />
    <div class="flex items-center">
      <div class="min-w-[350px] mr-10">
        <GlobalOrgUnitSelect
          v-if="customerStore.activeCustomer?.googleCustomerId"
        />
      </div>
      <VRow v-if="authStore.user" class="items-center">
        <CustomerAvatar />
        <UserAvatar />
      </VRow>
      <VSkeleton v-else with-main-icon no-text />
    </div>
  </div>
</template>

<script setup>
import TheBreadcrumb from "@/components/helpers/TheBreadcrumb.vue";
import CustomerAvatar from "@/components/helpers/CustomerAvatar.vue";
import UserAvatar from "@/components/helpers/UserAvatar.vue";
import GlobalOrgUnitSelect from "@/components/helpers/GlobalOrgUnitSelect.vue";
import { useAuthStore, useCustomerStore } from "@/stores";

const customerStore = useCustomerStore();
const authStore = useAuthStore();
</script>
