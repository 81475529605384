import { defineStore } from "pinia";
import { BigQueryJobsService } from "@/apis/security-auditing/services/BigQueryJobsService";
import { useAPIWrapper } from "@/composables/api-wrapper";
import { ReportsService } from "@/apis/security-auditing";
import { ref, watch } from "vue";
import { useTextFormat } from "@/composables/text-format";

const storeId = "security-auditing-report-label-stats-access-store";

export const useReportLabelStatsAccessStore = defineStore(storeId, () => {
  // ===================== //
  // ======= INIT ======== //
  // ===================== //

  const services = {
    listItemsService: ReportsService.listLabelStats,
    getQueryJobStatusService: BigQueryJobsService.pollJobResult,
  };

  const returnObjectKey = "rows";

  const listServiceParams = ["customerId", "query", "sortBy", "sortOrder"];

  // set the values to determine if the filtering and pagination should be done on the client or server side
  const clientSideFiltering = true;
  const clientSidePagination = true;

  // orgUnitFilter is used to determine if the orgUnit filter should be used
  const useOrgUnitFilter = {
    id: "orgUnitId",
  };

  const useIncludeOrgUnitsFilter = {
    id: "includeSubOrgUnits",
  };
  // ===================== //
  // ======= INIT ======== //
  // ===================== //

  // use the API wrapper composable to handle filtering, sorting, pagination and listing of items
  const {
    // filters
    activeFilters,
    activeAdvancedFilters,
    clearAllFilters,
    addFilter: apiAddFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    updateFilter,

    // list
    listItems,
    items,
    totalItems,
    pageToken,

    // general
    resetStore,
    reset,
    loading,
  } = useAPIWrapper(
    storeId,
    services,
    returnObjectKey,
    clientSideFiltering,
    clientSidePagination,
    listServiceParams,
    useOrgUnitFilter,
    useIncludeOrgUnitsFilter
  );

  const localFilters = ref([]);

  const sortedItems = ref();

  const savedItems = ref(null);
  const processedItems = ref(null);
  const processedItemsWithDisabled = ref(null);
  const showDisabled = ref(false);

  // ======================= //
  // ======= FORMAT ======== //
  // ======================= //

  const getAccessProps = () => {
    const accessItems = [
      {
        key: "sharedWithPersonalAccounts",
        label: "Personal account access",
      },
      {
        key: "sharedOutsideTrustedList",
        label: "Access outside trusted network",
      },
      {
        key: "sharedWithAnyoneWriter",
        label: "Publicly editable",
      },
      {
        key: "sharedWithAnyoneReader",
        label: "Publicly viewable",
      },
      {
        key: "sharedWithAnyoneCommenter",
        label: "Publicly commentable",
      },
      {
        key: "sharedWithDomainsReader",
        label: "Viewable domain-wide",
      },
      {
        key: "sharedWithDomainsCommenter",
        label: "Commentable domain-wide",
      },
      {
        key: "sharedWithDomainsWriter",
        label: "Editable domain-wide",
      },
      {
        key: "sharedWithAnyoneFileDiscovery",
        label: "Searchable",
      },
    ];
    if (!processedItems.value[0].orgUnitId) return processedItems.value;
    const accessData = processedItems.value.reduce((list, item) => {
      item.labelStats?.map((labelStats) => {
        Object.entries(labelStats).forEach(([k, v]) => {
          // Available rows
          if (!accessItems.map(({ key }) => key).includes(k)) return;

          const readableKey = accessItems.find(({ key }) => key === k).label;

          let existingStat = list.findIndex(
            ({ title }) => title === readableKey
          );
          if (existingStat !== -1) {
            list[existingStat] = {
              ...list[existingStat],
              [labelStats.title]: v,
            };
          } else {
            list.push({
              title: readableKey,
              [labelStats.title]: v,
            });
          }
        });
      });
      return list;
    }, []);

    accessData.map((row, index) => {
      accessData[index].total = Object.values(row).reduce(
        (sum, v) => Number.isInteger(v) && sum + v,
        0
      );
    });
    return [...new Set(accessData)];
  };
  const applyItemsFromProcessed = () => {
    if (!showDisabled.value) {
      items.value = processedItems.value;
    } else {
      items.value = processedItemsWithDisabled.value;
    }
  };

  const handleDisabledItems = () => {
    processedItemsWithDisabled.value = processedItems.value;

    const disabledRow = processedItems.value.find(
      ({ title }) => title === "Is disabled"
    );

    if (!disabledRow) return;

    const disabledItems = Object.entries(disabledRow)
      .filter(([key, value]) => key !== "title" && value)
      .map(([key]) => key);

    processedItems.value = processedItems.value.map((item) => {
      const filteredItem = {};
      for (const key in item) {
        if (!disabledItems.includes(key)) {
          filteredItem[key] = item[key];
        }
      }
      return filteredItem;
    });
  };

  const resetProcessedItems = () => {
    processedItems.value = [...savedItems.value];
    processedItems.value = getAccessProps();
    handleLocalFilters();
    handleDisabledItems();
    applyItemsFromProcessed();
  };

  // ======================== //
  // ======= FILTERS ======== //
  // ======================== //

  const filterByTitle = (filterValue) => {
    processedItems.value = processedItems.value.filter(({ title }) =>
      title.toLowerCase().includes(filterValue.toLowerCase())
    );
    processedItemsWithDisabled.value = processedItemsWithDisabled.value.filter(
      ({ title }) => title.toLowerCase().includes(filterValue.toLowerCase())
    );
  };

  const handleLocalFilters = () => {
    localFilters.value
      .filter(({ value }) => value?.length > 0)
      .forEach(({ id: filterId, value }) => {
        localFiltersList.find(({ id }) => id === filterId)?.fn(value);
      });
  };

  const addFilter = (filter) => {
    if (localFiltersList.map(({ id }) => id).includes(filter.id)) {
      removeFilter(filter.id);
      localFilters.value.push(filter);
    } else {
      apiAddFilter(filter);
    }
  };

  const removeFilter = (filterId) => {
    localFilters.value = localFilters.value.filter(({ id }) => id !== filterId);
  };

  const localFiltersList = [
    {
      id: "access.title",
      fn: filterByTitle,
    },
  ];

  // ====================== //
  // ======= WATCH ======== //
  // ====================== //

  watch([localFilters], resetProcessedItems, { deep: true });

  watch(
    [loading],
    async () => {
      if (
        !loading.value &&
        items.value[0] &&
        items.value !== sortedItems.value &&
        items.value !== processedItems.value &&
        items.value !== processedItemsWithDisabled.value
      ) {
        if (!savedItems.value) {
          savedItems.value = items.value;
        }
        resetProcessedItems();
      }
    },
    { deep: true }
  );

  watch(showDisabled, applyItemsFromProcessed);

  return {
    // filters
    activeFilters,
    useOrgUnitFilter,
    activeAdvancedFilters,
    clearAllFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    removeFilter,
    updateFilter,
    filterByTitle,

    // list
    items,
    listItems,
    totalItems,
    pageToken,
    clientSidePagination,

    showDisabled,

    // general
    resetStore,
    reset,
    loading,
  };
});
