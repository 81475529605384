/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PrivilegeListResponse } from '../models/PrivilegeListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PrivilegeService {

    /**
     * List of privileges
     * Get a list of privileges.
     * @param customerId
     * @returns PrivilegeListResponse Successful Response
     * @throws ApiError
     */
    public static getPrivileges(
        customerId: string,
    ): CancelablePromise<PrivilegeListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{customer_id}/privileges',
            path: {
                'customer_id': customerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * List of privileges
     * Get a list of privileges.
     * @param customerId
     * @returns PrivilegeListResponse Successful Response
     * @throws ApiError
     */
    public static getPrivilegesFlat(
        customerId: string,
    ): CancelablePromise<PrivilegeListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{customer_id}/privileges/flat',
            path: {
                'customer_id': customerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * List of privileges
     * Get a list of privileges.
     * @param customerId
     * @returns PrivilegeListResponse Successful Response
     * @throws ApiError
     */
    public static getPrivilegesFormatted(
        customerId: string,
    ): CancelablePromise<PrivilegeListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{customer_id}/privileges/formatted',
            path: {
                'customer_id': customerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
