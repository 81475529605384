<template>
  <div
    class="flex grow flex-col gap-y-5 overflow-y-auto bg-background shadow-sm border-r border-gray-25 px-6"
    :class="openWidth"
  >
    <VRow
      class="h-16 shrink-0 items-end space-x-0 justify-start"
      :gap="!isOpen ? '0' : '4'"
    >
      <img
        @click="() => router.push({ name: 'home' })"
        class="mr-auto w-auto h-10 cursor-pointer"
        :src="isOpen ? logoFull : logoIcon"
        alt="Florbs Logo"
        data-cy="florbs-logo"
      />
      <VIcon :name="openIcon" class="cursor-pointer mb-2" @click="handleOpen" />
    </VRow>

    <nav class="mt-4 flex flex-1 flex-col">
      <ul role="list" class="flex flex-1 flex-col">
        <li>
          <ul role="list" class="-mx-2 space-y-1">
            <li v-if="authStore.user" v-for="item in modules" :key="item.name">
              <div v-if="routesHelper.getModuleRoutes(item.value).length > 0">
                <div
                  v-if="isOpen"
                  :class="[
                    route.meta?.module?.value === item.value ? 'active' : '',
                    'nav-item mt-4 items-center gap-x-3 rounded-md p-2 text-left leading-6',
                  ]"
                >
                  <span>
                    {{ item.name }}
                  </span>
                </div>
                <ul class="mt-1 px-2">
                  <li
                    v-for="subItem in routesHelper.getModuleRoutes(item.value)"
                    :key="subItem.name"
                  >
                    <RouterLink
                      v-if="useRoutesHelper().hasItemPrivilege(subItem)"
                      :to="{ name: subItem.name }"
                    >
                      <VTooltip
                        :show="!isOpen"
                        position="right"
                        :tooltip="subItem.meta?.pageTitle"
                        force-recalculate-position-on-mouse-enter
                      >
                        <NavigationItem
                          class="nav-item clickable sub-nav-item flex py-3 pl-3"
                          :class="{
                            active: useRoutesHelper().isCurrentRoute(
                              route,
                              subItem.name
                            ),
                          }"
                          :sub-item="subItem"
                          :isOpen="isOpen"
                        />
                      </VTooltip>
                    </RouterLink>
                    <div v-else>
                      <VTooltip
                        :show="!isOpen"
                        position="right"
                        :tooltip="subItem.meta?.pageTitle"
                        force-recalculate-position-on-mouse-enter
                      >
                        <NavigationItem
                          class="nav-item clickable sub-nav-item flex py-3 pl-4 hover:bg-background cursor-default"
                          :sub-item="subItem"
                          :isOpen="isOpen"
                        />
                      </VTooltip>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li v-else>
              <div class="mt-8">
                <VSkeleton
                  with-title
                  :number-of-lines="6"
                  v-for="(_, index) in 3"
                  :key="index"
                  class="mt-8"
                  with-line-icon
                />
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <div class="absolute bottom-4 text-xs">v{{ appVersion }}</div>
  </div>
</template>

<script setup>
import { computed, inject, ref } from "vue";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores";
import { useRoutesHelper } from "@/composables/routes-helper";
import config from "@/config";
import router from "@/router";
import NavigationItem from "@/components/helpers/NavigationItem.vue";
import logoFull from "@/assets/logo-lq.png";
import logoIcon from "@/assets/loading.svg";

const appVersion = inject("appVersion");
const route = useRoute();
const routesHelper = useRoutesHelper();
const authStore = useAuthStore();

const modules = computed(() => {
  return config.MODULES;
});

const isOpen = ref(true);
const openIcon = computed(() =>
  !isOpen.value ? "chevron_right" : "chevron_left"
);

const openWidth = computed(() => (isOpen.value ? "max-w-xs" : "max-w-[97px]"));

const handleOpen = () => {
  isOpen.value = !isOpen.value;
};
</script>
