import { defineStore } from "pinia";
import { BigQueryJobsService } from "@/apis/security-auditing/services/BigQueryJobsService";
import { useAPIWrapper } from "@/composables/api-wrapper";
import { useErrorStore } from "@/stores";
import { BulkActionsService } from "@/apis/security-auditing";

const storeId = "security-auditing-bulk-action-impact-analysis-store";

export const useImpactAnalysisStore = defineStore(storeId, () => {
  // ===================== //
  // ======= INIT ======== //
  // ===================== //

  const errorStore = useErrorStore();

  const services = {
    listItemsService: BulkActionsService.getBulkActionAnalysis,
    getQueryJobStatusService: BigQueryJobsService.pollJobResult,
  };

  const errorMessages = {
    listItems: `Failed to retrieve the impact analysis. ${errorStore.defaultErrorMessage}`,
    // getItem: `Failed to retrieve file. ${errorStore.defaultErrorMessage}`,
    // updateItem: `Failed to update file. ${errorStore.defaultErrorMessage}`,
    getQueryJobStatus: `Failed to retrieve the impact analysis. ${errorStore.defaultErrorMessage}`,
  };

  const returnObjectKey = "rows";
  const listServiceParams = [
    "customerId",
    "bulkActionId",
    "query",
    "sortBy",
    "sortOrder",
  ];

  // set the values to determine if the filtering and pagination should be done on the client or server side
  const clientSideFiltering = false;
  const clientSidePagination = false;

  // use the API wrapper composable to handle filtering, sorting, pagination and listing of items
  const {
    // filters
    activeFilters,
    activeAdvancedFilters,
    clearAllFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    removeFilter,
    updateFilter,
    // list
    items,
    listItems,
    getItem,
    totalItems,
    pageToken,
    // general
    resetStore,
    reset,
    loading,
    // crud
    // getItem,
  } = useAPIWrapper(
    storeId,
    services,
    returnObjectKey,
    clientSideFiltering,
    clientSidePagination,
    listServiceParams,
    null,
    null,
    errorMessages
  );

  return {
    // filters
    activeFilters,
    // useOrgUnitFilter,
    activeAdvancedFilters,
    clearAllFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    removeFilter,
    updateFilter,

    // list
    items,
    listItems,
    getItem,
    totalItems,
    pageToken,
    clientSidePagination,

    // general
    resetStore,
    reset,
    loading,

    // crud
    // updateItem,
    // getItem,
  };
});
