/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Defines a page size.
 */
export enum PageSizeEnum {
    '_1' = 1,
    '_15' = 15,
    '_30' = 30,
    '_50' = 50,
    '_100' = 100,
    '_999999' = 999999,
}
