<template>
  <div class="card" :class="props.padding">
    <div v-if="hasTitle || hasSubtitle" class="mb-5">
      <div class="text-lg font-bold mb-4" v-if="hasTitle">
        <slot name="title">{{ title }}</slot>
      </div>
      <div class="text-sm font-light text-text-othergrey" v-if="hasSubtitle">
        <slot name="subtitle">{{ subtitle }}</slot>
      </div>
    </div>
    <div v-if="hasContent" class="h-full">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { useSlots } from "vue";

const slots = useSlots();

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  subtitle: {
    type: String,
    default: null,
  },
  padding: {
    type: String,
    default: "p-6",
  },
});

const hasContent = !!slots.default;
const hasTitle = !!slots.title || props.title;
const hasSubtitle = !!slots.subtitle || props.subtitle;
</script>

<style scoped lang="scss">
.card {
  @apply flex flex-col justify-between w-full h-full bg-background rounded-md shadow-md;
}
</style>
