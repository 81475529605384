<template>
  <div v-if="legend" class="max-h-[200px] overflow-y-scroll">
    <VRow
      v-for="{ label, index, active, color } in legend"
      :key="label"
      class="my-1"
      :class="{ 'cursor-pointer': props.isInteractive }"
      @click="() => props.isInteractive && handleLegendClick(index)"
    >
      <div
        class="w-[40px] h-[15px]"
        :style="{ background: color, opacity: !active ? 0.3 : 1 }"
      />
      <div class="w-full">
        <p
          class="text-xs"
          :class="{ 'line-through text-text-othergrey': !active }"
        >
          {{ label }}
        </p>
      </div>
    </VRow>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";

const emit = defineEmits(["click"]);

const props = defineProps({
  data: {
    type: [Array, Object],
    required: true,
  },
  origin: {
    type: String,
    required: true,
    validator: (value) => {
      const validVariants = ["bar", "line", "donut"];
      if (!validVariants.includes(value)) {
        console.warn(`Invalid position: ${value}. Defaulting to "bar".`);
        return false;
      }
      return true;
    },
    default: "bar",
  },
  donutDefaultDataText: {
    type: String,
    default: "Other",
  },
  isInteractive: {
    type: Boolean,
    default: true,
  },
});

const isOriginSingleDataArray = ["donut"].includes(props.origin);

const legend = ref(null);

const generateLegend = () => {
  const labelList = isOriginSingleDataArray
    ? props.data.legendLabels
    : props.data.datasets.map(({ label }) => label);
  legend.value = labelList
    ?.filter((label) => !label?.includes(props.donutDefaultDataText))
    ?.map((label, index) => {
      const color = isOriginSingleDataArray
        ? props.data.datasets[0].backgroundColor[index]
        : props.data.datasets[index]?.backgroundColor;

      return {
        label,
        index,
        active: true,
        color,
      };
    });
};

const handleLegendClick = (index) => {
  legend.value[index].active = !legend.value[index].active;
  emit("click", index);
};

watch(() => props.data, generateLegend);
onMounted(generateLegend);
</script>
