import { defineStore } from "pinia";
import { useErrorStore } from "@/stores";
import { useAPIWrapper } from "@/composables/api-wrapper";
import { BulkActionsService } from "@/apis/security-auditing";
const storeId = "bulk-actions-store";

// import { useRoutesHelper } from "@/composables/routes-helper";

export const useBulkActionStore = defineStore(storeId, () => {
  // ===================== //
  // ======= INIT ======== //
  // ===================== //
  const errorStore = useErrorStore();
  const errorMessages = {
    listItems: `Failed to retrieve your bulk actions. ${errorStore.defaultErrorMessage}`,
  };

  // Define the services that are used by the API wrapper
  const services = {
    listItemsService: BulkActionsService.listBulkActions,
    getItemService: BulkActionsService.getBulkAction,
    createItemService: BulkActionsService.createBulkAction,
    updateItemService: BulkActionsService.updateBulkAction,
    deleteItemService: BulkActionsService.deleteBulkAction,
  };

  const returnObjectKey = "bulkActions";
  const listServiceParams = ["customerId", "query", "sortBy", "sortOrder"];

  // set the values to determine if the filtering and pagination should be done on the client or server side
  const clientSideFiltering = true;
  const clientSidePagination = true;

  // orgUnitFilter is used to determine if the orgUnit filter should be used
  const useOrgUnitFilter = false;

  // use the API wrapper composable to handle filtering, sorting, pagination and listing of items
  const {
    // filters
    activeFilters,
    activeAdvancedFilters,
    clearAllFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    removeFilter,
    updateFilter,
    generateFilterParams,
    // list
    items,
    listItems,
    totalItems,
    // general
    resetStore,
    reset,
    loading,
    // crud
    createItem,
    updateItem,
    getItem,
    deleteItem,
  } = useAPIWrapper(
    storeId,
    services,
    returnObjectKey,
    clientSideFiltering,
    clientSidePagination,
    listServiceParams,
    useOrgUnitFilter,
    null,
    errorMessages
  );

  return {
    // filters
    activeFilters,
    useOrgUnitFilter,
    activeAdvancedFilters,
    clearAllFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    removeFilter,
    updateFilter,
    generateFilterParams,

    // list
    items,
    listItems,
    totalItems,
    clientSidePagination,

    // general
    resetStore,
    reset,
    loading,

    // crud
    // get,
    updateItem,
    createItem,
    getItem,
    deleteItem,
  };
});
