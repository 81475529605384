<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton ref="menuButton" class="flex items-center">
        <VAvatar
          v-if="user"
          :initials="useTextFormat().getInitials(user.displayName)"
          :photo-url="user.photoUrl"
        />
      </MenuButton>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute right-0 z-30 mt-2 w-56 origin-top-right divide-y divide-gray-50 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="px-4 py-3">
          <p class="text-sm">Signed in as</p>
          <p class="mt-2 truncate text-sm font-medium">
            {{ user.displayName }}
          </p>
          <p class="truncate text-sm">
            {{ user.email }}
          </p>
        </div>
        <div>
          <MenuItem v-if="hasAtLeastOneAdminPrivilege" v-slot="{ active }">
            <RouterLink
              v-if="hasAtLeastOneAdminPrivilege"
              @click="closeMenu"
              :to="settingRoute"
              :class="[
                active ? 'bg-google-blue-100 text-gray-900' : 'text-gray-700',
                'block px-4 py-2 text-sm flex',
              ]"
              ><VIcon name="settings" small class="mr-2" />Settings</RouterLink
            >
          </MenuItem>
          <div v-if="isDevOrLocal" class="bg-gray-20">
            <MenuItem v-slot="{ active }">
              <RouterLink
                @click="closeMenu"
                :to="{ name: 'development-overview' }"
                :class="[
                  active ? 'bg-google-blue-100 text-gray-900' : 'text-gray-700',
                  'justify-between w-full text-left text-sm flex py-2 px-4',
                ]"
              >
                Development Overview <VChip label="DEV" small />
              </RouterLink>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <RouterLink
                @click="closeMenu"
                :to="{ name: 'user-profile' }"
                :class="[
                  active ? 'bg-google-blue-100 text-gray-900' : 'text-gray-700',
                  'justify-between w-full text-left text-sm flex py-2 px-4',
                ]"
                >Profile data <VChip label="DEV" small
              /></RouterLink>
            </MenuItem>
          </div>
        </div>
        <div>
          <form method="POST" action="#">
            <MenuItem v-slot="{ active }">
              <button
                @click="logout"
                :class="[
                  active ? 'bg-google-blue-100 text-gray-900' : 'text-gray-700',
                  'justify-between w-full text-left text-sm flex py-2 px-4',
                ]"
              >
                Sign out
                <VIcon name="logout" class="h-5" />
              </button>
            </MenuItem>
          </form>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import { useAuthStore } from "@/stores";
import VAvatar from "@/components/common/VAvatar.vue";
import { useTextFormat } from "@/composables/text-format";
import settingsRoutes from "@/router/settings-routes";

const router = useRouter();

const authStore = useAuthStore();
const user = computed(() => authStore.user);

const hasAtLeastOneAdminPrivilege = computed(() =>
  authStore.hasPrivilege([
    "SUPER_ADMIN",
    "TRUSTED_NETWORK_ADMIN",
    "ROLES_ADMIN",
    "CUSTOMER_ADMIN",
    "USERS_ADMIN",
  ])
);

const menuButton = ref(null);

const isDevOrLocal = computed(() => {
  return (
    import.meta.env.MODE === "development" ||
    import.meta.env.MODE === "localdev"
  );
});

const closeMenu = () => {
  menuButton.value.$el.click();
};

const settingRoute = computed(() => {
  let route = "general";
  const routes = [...settingsRoutes];
  routes.reverse().forEach(({ name, meta }) => {
    if (authStore.hasPrivilege(meta.requiredPrivileges)) {
      route = name;
    }
  });
  return { name: route };
});

const logout = () => {
  router.push({ name: "logout" });
};
</script>
