<template>
  <div>
    <slot></slot>
  </div>
</template>

<script setup>
import { ref, provide, watch, onMounted } from "vue";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  modelValue: {
    default: null,
  },
});

const subscribers = ref({});

// function to add a subscriber to the subscribers object
const addSubscriber = (component) => {
  if (subscribers.value[component.value]) {
    console.warn(
      `Panel with id ${component.value} already exists in this ExpansionPanelGroup, choose an unique value for each panel in a group`
    );
  }
  subscribers.value[component.value] = component;
};

// We need to update the subscriber object when the component changes
const updateSubscriber = (component) => {
  openedId.value = !component.isOpen ? null : component.value;
  Object.values(subscribers.value).forEach((subscriber) => {
    const isOtherPanel = subscriber.value !== component.value;
    if (
      (isOtherPanel &&
        component.isOpen === true &&
        subscriber.isOpen === true) ||
      !isOtherPanel
    ) {
      subscriber.isOpen = !subscriber.isOpen;
      subscriber.update();
    }
  });
};

const openedId = ref(props.modelValue);

watch(
  openedId,
  (newIds) => {
    emit("update:modelValue", newIds);
  },
  { deep: true }
);

onMounted(() => {
  if (!props.modelValue) return;
  Object.values(subscribers.value)
    .filter((subscriber) => props.modelValue === subscriber.value)
    .forEach((subscriber) => {
      subscriber.isOpen = true;
      subscriber.update();
    });
});

provide("expansionPanelGroup", {
  addSubscriber,
  updateSubscriber,
});
</script>
