/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthenticatedUser } from '../models/AuthenticatedUser';
import type { SetActiveCustomerResponseModel } from '../models/SetActiveCustomerResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * Login
     * Login
     * @returns AuthenticatedUser Successful Response
     * @throws ApiError
     */
    public static login(): CancelablePromise<AuthenticatedUser> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/login',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Set active customer
     * Set active customer
     * @param customerId
     * @returns SetActiveCustomerResponseModel Successful Response
     * @throws ApiError
     */
    public static setActiveCustomer(
        customerId: string,
    ): CancelablePromise<SetActiveCustomerResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{customer_id}/set-active-customer',
            path: {
                'customer_id': customerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
