import { defineStore } from "pinia";
import { RoleService } from "@/apis/app-management/services/RoleService";
import { useAPIWrapper } from "@/composables/api-wrapper";
import { useErrorStore } from "@/stores";
const storeId = "role-store";

export const useRoleStore = defineStore(storeId, () => {
  // ===================== //
  // ======= INIT ======== //
  // ===================== //
  const errorStore = useErrorStore();
  const errorMessages = {
    listItems: `Failed to retrieve your roles. ${errorStore.defaultErrorMessage}`,
  };

  const services = {
    listItemsService: RoleService.listRoles,
    getItemService: RoleService.getRole,
    createItemService: RoleService.createRole,
    updateItemService: RoleService.updateRole,
    deleteItemService: RoleService.deleteRole,
  };

  const returnObjectKey = "roles";
  const listServiceParams = ["customerId", "query", "sortBy", "sortOrder"];

  // set the values to determine if the filtering and pagination should be done on the client or server side
  const clientSideFiltering = true;
  const clientSidePagination = true;

  // orgUnitFilter is used to determine if the orgUnit filter should be used
  const useOrgUnitFilter = false;

  // use the API wrapper composable to handle filtering, sorting, pagination and listing of items
  const {
    // filters
    activeFilters,
    activeAdvancedFilters,
    clearAllFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    removeFilter,
    updateFilter,
    // list
    items,
    listItems,
    totalItems,
    pageToken,
    // general
    resetStore,
    reset,
    loading,
    // crud
    getItem,
    createItem,
    updateItem,
    deleteItem,
  } = useAPIWrapper(
    storeId,
    services,
    returnObjectKey,
    clientSideFiltering,
    clientSidePagination,
    listServiceParams,
    useOrgUnitFilter,
    null,
    errorMessages
  );

  // ===================== //
  // ======= LIST ======== //
  // ===================== //

  // ===================== //
  // ======= GET ========= //
  // ===================== //

  const get = async (roleId, forceFetch = false) => {
    roleId = Number(roleId);

    const message = {
      // success: `User with ID ${userId} fetched`,
      error: `Failed to get role with ID ${roleId}`,
    };

    return await getItem(roleId, forceFetch, message);
  };

  // ===================== //
  // ======= CREATE ====== //
  // ===================== //

  const createRole = async (role) => {
    const message = {
      success: `Role ${role.name} created`,
      error: `Failed to create role ${role.name}`,
    };

    const res = await createItem(role, null, message);

    if (res?.id) {
      return res;
    }
  };

  // ===================== //
  // ======= UPDATE ====== //
  // ===================== //

  const updateRole = async (roleId, role) => {
    roleId = Number(roleId);

    const message = {
      success: `Role ${role.name} updated`,
      error: `Failed to update role ${role.name}`,
    };

    return await updateItem(roleId, role, null, message);
  };

  // ===================== //
  // ======= DELETE ====== //
  // ===================== //

  const deleteRole = async (id) => {
    const message = {
      success: `Role with ID ${id} deleted`,
      error: `Failed to delete role with ID ${id}`,
    };

    return await deleteItem(id, null, message);
  };

  return {
    // filters
    activeFilters,
    useOrgUnitFilter,
    activeAdvancedFilters,
    clearAllFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    removeFilter,
    updateFilter,

    // list
    items,
    listItems,
    totalItems,
    pageToken,
    clientSidePagination,

    // general
    resetStore,
    reset,
    loading,

    // crud
    get,
    createRole,
    updateRole,
    deleteRole,
  };
});
