<template>
  <div class="flex flex-col" :class="columnClasses">
    <slot></slot>
  </div>
</template>

<script setup>
import { computed } from "vue";
const props = defineProps({
  xs: { type: String, default: "12" },
  sm: { type: String, default: null },
  md: { type: String, default: null },
  lg: { type: String, default: null },
  xl: { type: String, default: null },
  "2xl": { type: String, default: null },
});

const columnClasses = computed(() => {
  let classString = "";

  if (props.xs) classString += `col-span-${props.xs} `;
  if (props.sm) classString += `sm:col-span-${props.sm} `;
  if (props.md) classString += `md:col-span-${props.md} `;
  if (props.lg) classString += `lg:col-span-${props.lg} `;
  if (props.xl) classString += `xl:col-span-${props.xl} `;
  if (props["2xl"]) classString += `2xl:col-span-${props["2xl"]} `;

  return classString;
});
</script>
