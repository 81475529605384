/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthenticatedUser } from '../models/AuthenticatedUser';
import type { InviteUserRequest } from '../models/InviteUserRequest';
import type { UpdateUserRequest } from '../models/UpdateUserRequest';
import type { UserListResponse } from '../models/UserListResponse';
import type { UserResponse } from '../models/UserResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Get user details
     * Get user details.
     *
     * - **customer_id**: The customer id
     * - **user_id**: The user id
     * @param customerId
     * @param userId
     * @returns UserResponse Successful Response
     * @throws ApiError
     */
    public static getUser(
        customerId: string,
        userId: string,
    ): CancelablePromise<UserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{customer_id}/users/{user_id}',
            path: {
                'customer_id': customerId,
                'user_id': userId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Delete user
     * Deletes a user.
     *
     * - **customer_id**: The customer id
     * - **user_id**: The user id
     * @param customerId
     * @param userId
     * @returns void
     * @throws ApiError
     */
    public static deleteUser(
        customerId: string,
        userId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/customers/{customer_id}/users/{user_id}',
            path: {
                'customer_id': customerId,
                'user_id': userId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update user
     * Update user.
     *
     * - **customer_id**: The customer id
     * - **user_id**: The user id
     * @param customerId
     * @param userId
     * @param requestBody
     * @returns UserResponse Successful Response
     * @throws ApiError
     */
    public static updateUser(
        customerId: string,
        userId: string,
        requestBody: UpdateUserRequest,
    ): CancelablePromise<UserResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/customers/{customer_id}/users/{user_id}',
            path: {
                'customer_id': customerId,
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * List users
     * List users.
     *
     * - **customer_id**: The customer id
     * - **filters**: The filters
     * @param customerId
     * @returns UserListResponse Successful Response
     * @throws ApiError
     */
    public static listUsers(
        customerId: string,
    ): CancelablePromise<UserListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{customer_id}/users',
            path: {
                'customer_id': customerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Invite an user
     * Invite a user.
     *
     * - **customer_id**: The customer id
     * - **user**: The user
     * @param customerId
     * @param requestBody
     * @returns UserResponse Successful Response
     * @throws ApiError
     */
    public static createUser(
        customerId: string,
        requestBody: InviteUserRequest,
    ): CancelablePromise<UserResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/customers/{customer_id}/users',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Resend invite an user
     * Resend user invite.
     *
     * - **customer_id**: The customer id
     * - **user_id**: The user id
     * @param customerId
     * @param userId
     * @returns void
     * @throws ApiError
     */
    public static resendInvite(
        customerId: string,
        userId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{customer_id}/users/{user_id}/resend-invitation',
            path: {
                'customer_id': customerId,
                'user_id': userId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Accept an user invitation
     * Accept invitation.
     *
     * - **customer_id**: The customer id
     * - **user_invitation_hash**: The user invitation hash
     * @param customerId
     * @param userInvitationHash
     * @returns AuthenticatedUser Successful Response
     * @throws ApiError
     */
    public static acceptUserInvitation(
        customerId: string,
        userInvitationHash: string,
    ): CancelablePromise<AuthenticatedUser> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{customer_id}/users/invitations/{user_invitation_hash}/accept',
            path: {
                'customer_id': customerId,
                'user_invitation_hash': userInvitationHash,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
