import { createRouter, createWebHistory } from "vue-router";
import {
  useAuthStore,
  useOrgUnitsStore,
  useUserPreferenceStore,
  useCustomerStore,
} from "@/stores";
import { useTitle } from "@vueuse/core";
import authRoutes from "./auth-routes";
import securityAndAuditingRoutes from "./security-auditing-routes";
import settingsRoutes from "./settings-routes";
import developmentRoutes from "./development-routes";
import homeRoutes from "./home-routes";

const title = useTitle();

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...homeRoutes,
    ...authRoutes,
    ...securityAndAuditingRoutes,
    ...settingsRoutes,
    ...developmentRoutes,
    // catch all redirect to home page
    {
      path: "/:pathMatch(.*)*",
      name: "not-found",
      component: () => import("@/views/NotFoundView.vue"),
    },
  ],
});

const hasPrivilegesForPage = async (to) => {
  const customerStore = useCustomerStore();
  if (!customerStore.activeCustomer?.googleCustomerId) return true;
  const orgUnitsStore = useOrgUnitsStore();
  const userPreferenceStore = useUserPreferenceStore();
  if (to.meta?.requiredPrivileges) {
    if (orgUnitsStore?.items?.length === 0) {
      await orgUnitsStore.listItems();
      await userPreferenceStore.fetchSelectedOrgUnit();
    }
    const authStore = useAuthStore();
    return authStore.hasPrivilege(to.meta.requiredPrivileges);
  }
  return true;
};

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();

  if (!authStore.authCurrentUser) {
    authStore.logout(false);

    if (!to.meta.publicPage) {
      return next({ name: "login", query: { redirect: to.fullPath } });
    }
  }

  if (!to.meta.publicPage) {
    let isLoggedIn = false;
    if (localStorage.getItem("googleUser")) {
      // TODO fix null string #2947
      if (
        (!localStorage.getItem("googleCustomerId") ||
          !localStorage.getItem("initSyncStatus") ||
          localStorage.getItem("initSyncStatus") !== "COMPLETED") &&
        to.name !== "general" &&
        to.name !== "user-profile"
      ) {
        return next({ name: "general" });
      }
      isLoggedIn = await authStore.handleNonPublicRouteChange();
    }
    if (!isLoggedIn.id && isLoggedIn !== true) {
      authStore.logout(false); // To make sure we clear all session data (redirect false to keep the redirect url)
      return next({ name: "login", query: { redirect: to.fullPath } });
    } else {
      if (!(await hasPrivilegesForPage(to))) {
        return next({ name: "not-found" });
      }
      return next();
    }
  }
  return next();
});

router.afterEach((to) => {
  const pageName =
    to.meta.pageTitle || to.name || "File security for Google Drive";
  title.value = `Florbs - ${pageName}`;
});

export default router;
