<template>
  <BaseModal
    v-model="props.modelValue"
    @close="close"
    :title="props.title"
    :description="props.description"
    :persistent="props.persistent"
    :width="props.width"
  >
    <template #actions>
      <slot name="actions"></slot>
    </template>
    <template #title>
      <slot name="title">{{ props.title }}</slot>
    </template>
    <template #description>
      <slot name="description">{{ props.description }}</slot>
    </template>
    <template #icon>
      <slot name="icon">
        <VIcon
          v-if="isVariantDestructive"
          class="text-google-red-600"
          name="warning"
          large
          data-testid="vmodal-destructive-icon"
        />
      </slot>
    </template>
    <slot></slot>
    <template #footer>
      <slot name="footer" :confirm="confirm">
        <VButton
          variant="secondary"
          label="Close"
          @click="() => close()"
          data-testid="vmodal-button-close"
        />
        <VButton
          v-if="props.confirmButtonLabel"
          :variant="isVariantDestructive ? 'destructive' : 'primary'"
          :label="props.confirmButtonLabel"
          @click="() => (isVariantForm ? submitForm() : confirm())"
          :disabled="(isVariantForm && !formValid) || props.isSubmitDisabled"
          data-testid="vmodal-button-confirm"
        />
      </slot>
    </template>
  </BaseModal>
</template>

<script setup>
import { computed, ref } from "vue";
import BaseModal from "@/components/helpers/BaseModal.vue";

const emit = defineEmits(["close", "confirm"]);
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  title: {
    type: String,
    required: false,
  },
  description: {
    type: String,
    required: false,
  },
  confirmButtonLabel: {
    type: String,
    default: "Confirm",
  },
  persistent: {
    type: Boolean,
    required: false,
    default: false,
  },
  isSubmitDisabled: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    validator: (value) => {
      const validVariants = ["confirmation", "destructive", "form", "basic"];
      if (!validVariants.includes(value)) {
        console.warn(`Invalid variant: ${value}. Defaulting to "basic".`);
        return false;
      }
      return true;
    },
    default: "basic",
  },
  width: {
    type: Number,
    default: null,
  },
});

const isVariantDestructive = computed(() => props.variant === "destructive");
const isVariantForm = computed(() => props.variant === "form");

const vForm = ref(null);
const formValid = computed(() => {
  return vForm?.value?.formValid;
});

const submitForm = () => {
  vForm?.value?.submit(); // we call submit on the form which will trigger the validation and set the formValid value
  if (formValid.value) {
    // if the form is valid we emit the primaryAction event
    emit("confirm");
  }
};

// emit close event
const close = () => {
  emit("close");
};

// emit confirm event
const confirm = () => {
  emit("confirm");
};
</script>
