import { defineStore } from "pinia";
import { BigQueryJobsService } from "@/apis/security-auditing/services/BigQueryJobsService";
import { useAPIWrapper } from "@/composables/api-wrapper";
import { ReportsService } from "@/apis/security-auditing";
import { useErrorStore } from "@/stores";

const storeId = "security-auditing-report-drive-stats-store";

export const useReportDriveStatsStore = defineStore(storeId, () => {
  // ===================== //
  // ======= INIT ======== //
  // ===================== //
  const errorStore = useErrorStore();

  const errorMessages = {
    listItems: `Failed to retrieve your drive statistics. ${errorStore.defaultErrorMessage}`,
    getQueryJobStatus: `Failed to retrieve your drive statistics. ${errorStore.defaultErrorMessage}`,
  };

  const services = {
    listItemsService: ReportsService.listDriveStats,
    getQueryJobStatusService: BigQueryJobsService.pollJobResult,
  };

  // orgUnitFilter is used to determine if the orgUnit filter should be used
  const useOrgUnitFilter = {
    id: "org_unit_id",
  };

  const useIncludeSubOrgUnitFilter = {
    id: "includeSubOrgUnits",
  };

  const returnObjectKey = "rows";

  const listServiceParams = ["customerId", "query", "sortBy", "sortOrder"];

  // set the values to determine if the filtering and pagination should be done on the client or server side
  const clientSideFiltering = false;
  const clientSidePagination = false;

  // use the API wrapper composable to handle filtering, sorting, pagination and listing of items
  const {
    items,
    addFilter,
    removeFilter,
    listItems,
    getItem,
    activeFilters,
    totalItems,
    loading,
  } = useAPIWrapper(
    storeId,
    services,
    returnObjectKey,
    clientSideFiltering,
    clientSidePagination,
    listServiceParams,
    useOrgUnitFilter,
    useIncludeSubOrgUnitFilter,
    errorMessages
  );

  const isRowEmpty = (row) => !row || row.length === 0;

  return {
    items,
    listItems,
    getItem,
    addFilter,
    removeFilter,
    activeFilters,
    totalItems,
    loading,
    clientSidePagination,
    isRowEmpty,
  };
});
