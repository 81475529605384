import { defineStore } from "pinia";
import { ref } from "vue";
import { PrivilegeService } from "@/apis/app-management/services/PrivilegeService";
import { useAPIWrapper } from "@/composables/api-wrapper";
import { useTextFormat } from "@/composables/text-format";
import { useCustomerStore, useErrorStore } from "@/stores";

const storeId = "privileges";

export const usePrivilegeStore = defineStore(storeId, () => {
  const services = {
    listItemsService: PrivilegeService.getPrivilegesFlat,
    listItemsFormattedService: PrivilegeService.getPrivilegesFormatted,
  };

  const formattedItems = ref(null);

  const customerStore = useCustomerStore();
  const errorStore = useErrorStore();

  const returnObjectKey = "privileges";

  const listServiceParams = ["customerId"];

  // set the values to determine if the filtering and pagination should be done on the client or server side
  const clientSideFiltering = true;
  const clientSidePagination = true;

  // use the API wrapper composable to handle filtering, sorting, pagination and listing of items
  const { items, listItems } = useAPIWrapper(
    storeId,
    services,
    returnObjectKey,
    clientSideFiltering,
    clientSidePagination,
    listServiceParams
  );

  const adaptName = (name) => {
    if (/^[A-Z_]+$/.test(name)) {
      return useTextFormat().capitalize(name);
    }
    const words = name.split(/(?=[A-Z])|_/);

    return words.map((word) => useTextFormat().capitalize(word)).join(" ");
  };

  const adaptFormattedItems = async () => {
    if (!formattedItems.value) {
      return null;
    }

    const adaptObject = (obj, recursiveFormatting = false) =>
      Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
          adaptName(key),
          recursiveFormatting ? adaptObject(value) : value,
        ])
      );

    formattedItems.value = adaptObject(formattedItems.value, true);
  };

  const listFormattedItems = errorStore.withErrorHandling(
    async () => {
      try {
        formattedItems.value = await services
          .listItemsFormattedService(customerStore.activeCustomerId)
          .then((res) => res.privileges);

        await adaptFormattedItems();
        return formattedItems.value;
      } catch (error) {
        return error.status;
      }
    },
    storeId,
    "listFormatted",
    "Failed to get privileges"
  );

  return {
    items,
    formattedItems,
    listItems,
    adaptFormattedItems,
    listFormattedItems,
    adaptName,
  };
});
