/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GoogleUserListResponse } from '../models/GoogleUserListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GoogleWorkspaceUserService {

    /**
     * Search for Google Workspace Users
     * Search google users.
     *
     * - **customer_id**: The customer id
     * - **search_string**: The search string
     * @param customerId
     * @param searchString
     * @returns GoogleUserListResponse Successful Response
     * @throws ApiError
     */
    public static searchGoogleUsers(
        customerId: string,
        searchString: string,
    ): CancelablePromise<GoogleUserListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{customer_id}/google-users/search',
            path: {
                'customer_id': customerId,
            },
            query: {
                'search_string': searchString,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
