/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePermission } from '../models/CreatePermission';
import type { PermissionCorpora } from '../models/PermissionCorpora';
import type { src__models__drive_models__Permission } from '../models/src__models__drive_models__Permission';
import type { TransferOwnershipRequest } from '../models/TransferOwnershipRequest';
import type { TransferOwnershipResponse } from '../models/TransferOwnershipResponse';
import type { UpdatePermission } from '../models/UpdatePermission';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DriveFilePermissionsService {

    /**
     * Create permissions
     * Create file permissions
     * @param customerId
     * @param fileId
     * @param fileOrSharedDrive
     * @param requestBody
     * @returns src__models__drive_models__Permission Successful Response
     * @throws ApiError
     */
    public static createPermissions(
        customerId: string,
        fileId: string,
        fileOrSharedDrive: PermissionCorpora,
        requestBody: CreatePermission,
    ): CancelablePromise<src__models__drive_models__Permission> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/security-auditing/{customer_id}/files/{file_id}/permissions',
            path: {
                'customer_id': customerId,
                'file_id': fileId,
            },
            query: {
                'file_or_shared_drive': fileOrSharedDrive,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Delete permissions
     * Delete file permissions
     * @param customerId
     * @param fileId
     * @param permissionId
     * @param fileOrSharedDrive
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deletePermissions(
        customerId: string,
        fileId: string,
        permissionId: string,
        fileOrSharedDrive: PermissionCorpora,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/security-auditing/{customer_id}/files/{file_id}/permissions/{permission_id}',
            path: {
                'customer_id': customerId,
                'file_id': fileId,
                'permission_id': permissionId,
            },
            query: {
                'file_or_shared_drive': fileOrSharedDrive,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update permissions
     * Update file permissions
     * @param customerId
     * @param fileId
     * @param permissionId
     * @param fileOrSharedDrive
     * @param requestBody
     * @returns src__models__drive_models__Permission Successful Response
     * @throws ApiError
     */
    public static updatePermissions(
        customerId: string,
        fileId: string,
        permissionId: string,
        fileOrSharedDrive: PermissionCorpora,
        requestBody: UpdatePermission,
    ): CancelablePromise<src__models__drive_models__Permission> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/security-auditing/{customer_id}/files/{file_id}/permissions/{permission_id}',
            path: {
                'customer_id': customerId,
                'file_id': fileId,
                'permission_id': permissionId,
            },
            query: {
                'file_or_shared_drive': fileOrSharedDrive,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Transfer ownership of file
     * Transfer ownership of file
     * @param customerId
     * @param fileId
     * @param requestBody
     * @returns TransferOwnershipResponse Successful Response
     * @throws ApiError
     */
    public static transferOwnership(
        customerId: string,
        fileId: string,
        requestBody: TransferOwnershipRequest,
    ): CancelablePromise<TransferOwnershipResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/security-auditing/{customer_id}/files/{file_id}/permissions/transfer-ownership',
            path: {
                'customer_id': customerId,
                'file_id': fileId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
