<template>
  <VRow gap="2" class="items-center">
    <VAvatar
      v-if="props.photoUrl"
      :initials="useTextFormat().getInitials(props.initials)"
      :photo-url="props.photoUrl"
      :sm="props.sm"
      :lg="props.lg"
      data-testid="videntitysnippet-avatar"
    />
    <div v-if="props.icon">
      <VIcon
        :name="props.icon"
        :small="props.sm"
        :large="props.lg"
        filled
      />
    </div>
    <VCol class="justify-center">
      <p
        :class="{
          'text-sm': props.sm,
          'text-lg': props.lg,
          'text-base': !props.sm && !props.lg,
        }"
        data-testid="videntitysnippet-value"
      >
        {{ truncateText(props.value) }}
      </p>
      <VRow v-if="props.subValue">
        <p
          class="text-gray-200"
          :class="{
            'text-xs': props.sm,
            'text-base': props.lg,
            'text-sm': !props.sm && !props.lg,
          }"
          data-testid="videntitysnippet-subvalue"
        >
          {{ truncateText(props.subValue) }}
        </p>
        <VCopyButton
          v-if="props.subValueCopy"
          class="!ml-1 text-gray-200"
          :content="props.subValue"
          :mini="props.sm"
          :small="!props.sm && !props.lg"
          data-testid="videntitysnippet-subvalue-copy"
        />
      </VRow>
    </VCol>
  </VRow>
</template>

<script setup>
import { useTextFormat } from "@/composables/text-format";

const props = defineProps({
  initials: {
    type: String,
  },
  value: {
    type: String,
    required: true,
  },
  photoUrl: {
    type: String,
    default: null,
  },
  icon: {
    type: String,
    default: null,
  },
  subValue: {
    type: String,
    default: null,
  },
  subValueCopy: {
    type: Boolean,
    default: false,
  },
  sm: {
    type: Boolean,
    default: false,
  },
  lg: {
    type: Boolean,
    default: false,
  },
  truncateSize: {
    type: Number,
    default: 0,
  },
});

const truncateText = (selection) =>
  props.truncateSize > 0 && selection?.length > props.truncateSize
    ? `${selection.substring(0, props.truncateSize)}...`
    : selection;
</script>
