import config from "../config";

const LAYOUTS = config.LAYOUTS;

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
    meta: {
      layout: LAYOUTS.AUTH_LAYOUT,
      publicPage: true,
    },
  },
];
