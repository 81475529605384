/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BigQueryJobResponse } from '../models/BigQueryJobResponse';
import type { File } from '../models/File';
import type { GetFileResponse } from '../models/GetFileResponse';
import type { SortOrderEnum } from '../models/SortOrderEnum';
import type { src__helpers__drive_file_helper__SortByEnum } from '../models/src__helpers__drive_file_helper__SortByEnum';
import type { UpdateFileRequest } from '../models/UpdateFileRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DriveFilesService {

    /**
     * List Drive Files
     * List drive files
     * @param customerId
     * @param query
     * Allowed operators:<br>=, ==, !=, <=, >=, <, >, IN, LIKE.<br>
     * (LIKE is by default %value%. Use \% at the beginning or end of the value to change the position of the wildcard.)
     * <br><br>
     * Example query:<br>id_fieldIN(id1ORid2),field=value,struct_field.struct_param=value,repeated_struct_field.struct_param=value,fieldLIKEvalue<br>
     *
     * @param sortBy
     * @param sortOrder
     * @returns BigQueryJobResponse Successful Response
     * @throws ApiError
     */
    public static listFiles(
        customerId: string,
        query?: string,
        sortBy?: src__helpers__drive_file_helper__SortByEnum,
        sortOrder?: SortOrderEnum,
    ): CancelablePromise<BigQueryJobResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/security-auditing/{customer_id}/files',
            path: {
                'customer_id': customerId,
            },
            query: {
                'query': query,
                'sortBy': sortBy,
                'sortOrder': sortOrder,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get stored and realtime file
     * Get file
     * @param customerId
     * @param fileId
     * @returns GetFileResponse Successful Response
     * @throws ApiError
     */
    public static getFile(
        customerId: string,
        fileId: string,
    ): CancelablePromise<GetFileResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/security-auditing/{customer_id}/files/{file_id}',
            path: {
                'customer_id': customerId,
                'file_id': fileId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update file
     * Update file
     * @param customerId
     * @param fileId
     * @param requestBody
     * @returns File Successful Response
     * @throws ApiError
     */
    public static updateFile(
        customerId: string,
        fileId: string,
        requestBody: UpdateFileRequest,
    ): CancelablePromise<File> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/security-auditing/{customer_id}/files/{file_id}',
            path: {
                'customer_id': customerId,
                'file_id': fileId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get file url
     * Get file url
     * @param customerId
     * @param fileId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getFileUrl(
        customerId: string,
        fileId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/security-auditing/{customer_id}/files/{file_id}/url',
            path: {
                'customer_id': customerId,
                'file_id': fileId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
