import config from "@/config";

const MODULE = config.SETTING_GROUPS.DEVELOPMENT;

export default [
  {
    path: "/user-profile",
    name: "user-profile",
    meta: {
      module: MODULE,
      pageTitle: "User profile",
      icon: "person",
    },
    component: () => import("@/views/development/UserProfileView.vue"),
  },
  {
    path: "/development/development-overview",
    name: "development-overview",
    meta: {
      module: MODULE,
      pageTitle: "Development overview",
      icon: "build",
    },
    component: () => import("@/views/development/DevelopmentOverviewView.vue"),
  },
];
