<template>
  <VModal v-model="showModal">
    <template #title>
      <h3 class="text-lg font-semibold">Unsaved Changes</h3>
    </template>
    <template #description>
      <p>You have unsaved changes. Are you sure you want to leave?</p>
    </template>
    <template #footer>
      <div class="flex justify-end space-x-2">
        <VButton @click="handleCancelAction">Stay</VButton>
        <VButton @click="handleConfirmAction" variant="destructive"
          >Leave</VButton
        >
      </div>
    </template>
  </VModal>
</template>

<script setup>
import { ref, onBeforeUnmount, watch, onMounted, computed } from "vue";
import { useRoute, onBeforeRouteLeave } from "vue-router";
import { useObjectHelper } from "@/composables/object-helper";

const route = useRoute();
const showModal = ref(false);
const userConfirmed = ref(false);
const initialState = ref(null);

const props = defineProps({
  dataChangesToWatch: {
    // any type is fine
    required: true,
  },
});

onMounted(() => {
  initialState.value = JSON.parse(JSON.stringify(props.dataChangesToWatch));
});

const hasUnsavedChanges = computed(
  () =>
    !useObjectHelper().isEquivalent(
      props.dataChangesToWatch,
      initialState.value
    )
);

watch(hasUnsavedChanges, (newValue) => {
  if (newValue) {
    window.addEventListener("beforeunload", beforeUnloadHandler);
  } else {
    window.removeEventListener("beforeunload", beforeUnloadHandler);
  }
});

function beforeUnloadHandler(event) {
  if (hasUnsavedChanges.value) {
    event.preventDefault();
    event.returnValue = "";
  }
}

onBeforeUnmount(() => {
  window.removeEventListener("beforeunload", beforeUnloadHandler);
});

watch(route, () => {
  if (hasUnsavedChanges.value && !userConfirmed.value) {
    showModal.value = true;
  }
});

const handleConfirmAction = () => {
  userConfirmed.value = true;
  showModal.value = false;
};

const handleCancelAction = () => {
  userConfirmed.value = false;
  showModal.value = false;
};

onBeforeRouteLeave((to, from, next) => {
  if (hasUnsavedChanges.value && !userConfirmed.value) {
    showModal.value = true;
    const unwatch = watch(showModal, (newValue) => {
      if (!newValue) {
        unwatch(); // Unsubscribe the watcher
        if (userConfirmed.value) {
          next(); // Proceed with the navigation
        } else {
          next(false); // Cancel the navigation
        }
      }
    });
  } else {
    next();
  }
});
</script>
