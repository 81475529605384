import { defineStore } from "pinia";
import { CustomerService } from "@/apis/app-management/services/CustomerService";
import { useAPIWrapper } from "@/composables/api-wrapper";
import { useErrorStore } from "@/stores";
import { ref, watch } from "vue";
const storeId = "customer-domain-store";

export const useCustomerDomainStore = defineStore(storeId, () => {
  // ===================== //
  // ======= INIT ======== //
  // ===================== //
  const errorStore = useErrorStore();
  const errorMessages = {
    listItems: `Failed to retrieve your domains. ${errorStore.defaultErrorMessage}`,
  };

  const services = {
    listItemsService: CustomerService.listWorkspaceDomains,
  };

  const returnObjectKey = "domains";
  const listServiceParams = ["customerId"];

  // set the values to determine if the filtering and pagination should be done on the client or server side
  const clientSideFiltering = true;
  const clientSidePagination = true;

  // orgUnitFilter is used to determine if the orgUnit filter should be used
  const useOrgUnitFilter = false;

  // use the API wrapper composable to handle filtering, sorting, pagination and listing of items
  const {
    // filters
    activeFilters,
    activeAdvancedFilters,
    clearAllFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    removeFilter,
    updateFilter,
    // list
    items,
    listItems,
    totalItems,
    pageToken,
    // general
    resetStore,
    reset,
    loading,
  } = useAPIWrapper(
    storeId,
    services,
    returnObjectKey,
    clientSideFiltering,
    clientSidePagination,
    listServiceParams,
    useOrgUnitFilter,
    null,
    errorMessages
  );

  // ===================== //
  // ======= LIST ======== //
  // ===================== //

  const addAnyoneWithLinkOnLoad = ref(false);

  const addAnyoneWithLink = () => {
    items.value = [
      {
        domainName: "Anyone with the link",
      },
      ...items.value,
    ];
  };

  watch(items, () => {
    const hasAnyoneWithTheLink = items.value?.some(
      ({ domainName }) => domainName === "Anyone with the link"
    );

    if (addAnyoneWithLinkOnLoad.value && !hasAnyoneWithTheLink) {
      addAnyoneWithLink();
    }
  });

  return {
    // filters
    activeFilters,
    useOrgUnitFilter,
    activeAdvancedFilters,
    clearAllFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    removeFilter,
    updateFilter,

    // list
    items,
    listItems,
    totalItems,
    pageToken,
    clientSidePagination,
    addAnyoneWithLinkOnLoad,
    addAnyoneWithLink,

    // general
    resetStore,
    reset,
    loading,
  };
});
