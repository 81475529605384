import { defineStore } from "pinia";
// import { DemoDataService} from "@/apis/demo-data/services/DemoDataService"; // ! activate when using API (change to correct service)
// import { useAPIWrapper } from "@/composables/api-wrapper"; // ! activate when using API
import { useAPIWrapper } from "@/composables/demo-data-wrapper"; // ! remove when using API

import {
  ref as firebaseRef,
  child,
  push,
  set,
  getDatabase,
  remove,
} from "firebase/database";
import { useCustomerStore } from "@/stores/index";

const storeId = "demo-data-store";

export const useDemoDataStore = defineStore(storeId, () => {
  const customerStore = useCustomerStore();

  // ===================== //
  // ======= INIT ======== //
  // ===================== //

  const services = {
    // ! activate and set services when using API
    // listItemsService: DemoDataService.listItems,
    // getItemService: DemoDataService.getItem,
    // createItemService: DemoDataService.createItem,
    // updateItemService: DemoDataService.updateItem,
    // deleteItemService: DemoDataService.deleteItem,
  };

  const returnObjectKey = "items";
  const listServiceParams = [
    // ! activate when using API
    // "customerId",
    // "pageSize",
    // "PageToken",
    // "query",
    // "sortBy",
    // "sortOrder",
  ];

  // set the values to determine if the filtering and pagination should be done on the client or server side
  const clientSideFiltering = false;
  const clientSidePagination = false;

  // use the API wrapper composable to handle filtering, sorting, pagination and listing of items
  const {
    items,
    addFilter,
    removeFilter,
    listItems,
    activeFilters,
    totalItems,
    loading,
    getItem,
    createItem,
    updateItem,
    deleteItem,
  } = useAPIWrapper(
    storeId,
    services,
    returnObjectKey,
    clientSideFiltering,
    clientSidePagination,
    listServiceParams
  );

  // DEMO DATA GENERATOR // ! remove when using API
  const dbRef = firebaseRef(getDatabase());

  const demoDataItem = {
    name: "string",
    // id: "string",
    totalShares: "number",
    totalFiles: "number",
    sharedWith: ["string"],
    linkType: ["string"],
    createdTime: "date",
    orgUnitId: "string",
    permissions: "permissions",
    accessOutsideDomainAllowed: "boolean",
    allowNonMembersToAccessFiles: "boolean",
    allowContentManagersToShareFolders: "boolean",
    allowViewersAndCommentersToDownloadPrintAndCopy: "boolean",
    searchable: "boolean",
  };

  const permissions = [
    {
      id: "06028209490393551256",
      type: "user",
      kind: "drive#permission",
      emailAddress: "mitchel@dev.makeithappengroup.com",
      displayName: "Mitchel Querreveld",
      photoLink:
        "https://lh3.googleusercontent.com/a-/ALV-UjULP4GFbnXUN0WuvuyJjqmvgqRfZgCQAYUhNrvPY1K5iQ=s64",
      role: "organizer", // manager
    },
    {
      id: "07427758402396522441",
      type: "user",
      kind: "drive#permission",
      emailAddress: "rogier@dev.makeithappengroup.com",
      displayName: "Rogier Schipper",
      photoLink:
        "https://lh3.googleusercontent.com/a-/ALV-UjULP4GFbnXUN0WuvuyJjqmvgqRfZgCQAYUhNrvPY1K5iQ=s64",
      role: "reader", // Viewer
    },
    {
      id: "10272984108875583742",
      type: "user",
      kind: "drive#permission",
      emailAddress: "fabien@dev.makeithappengroup.com",
      displayName: "Fabien Diaz",

      role: "reader", // viewer
    },
    {
      id: "14509313093954282769",
      type: "user",
      kind: "drive#permission",
      emailAddress: "ian@dev.makeithappengroup.com",
      displayName: "Ian Schon",
      photoLink:
        "https://lh3.googleusercontent.com/a-/ALV-UjULP4GFbnXUN0WuvuyJjqmvgqRfZgCQAYUhNrvPY1K5iQ=s64",
      role: "writer", // contributor
    },
    {
      id: "14618703739598282901",
      type: "user",
      kind: "drive#permission",
      emailAddress: "niek@dev.makeithappengroup.com",
      displayName: "Niek Waarbroek",
      photoLink:
        "https://lh3.googleusercontent.com/a-/ALV-UjULP4GFbnXUN0WuvuyJjqmvgqRfZgCQAYUhNrvPY1K5iQ=s64",
      role: "fileOrganizer", // content manager
    },
    {
      id: "16049105660341817519",
      type: "user",
      kind: "drive#permission",
      emailAddress: "florbstestuser@dev.makeithappengroup.com",
      displayName: "Florbs Testuser",
      photoLink:
        "https://lh3.googleusercontent.com/a-/ALV-UjULP4GFbnXUN0WuvuyJjqmvgqRfZgCQAYUhNrvPY1K5iQ=s64",
      role: "commenter", // commenter
    },
  ];

  const generateValue = (type) => {
    switch (type) {
      case "number":
        return Math.floor(Math.random() * 1000);
      case "string":
        return Math.random().toString(36).substring(2);
      case "permissions":
        return permissions;
      case "boolean":
        return Math.random() < 0.5;
      case "date":
        return new Date(
          new Date().getTime() - Math.random() * 1000 * 60 * 60 * 24 * 365
        ).toISOString();
      default:
        return "";
    }
  };
  const generateArrayValue = (item) => {
    if (Array.isArray(item)) {
      const randomSize = Math.floor(Math.random() * 4) + 1;
      let newArray = [];
      for (let i = 0; i < randomSize; i++) {
        if (typeof item[0] === "object" && !Array.isArray(item[0])) {
          newArray.push(generateObjectValue(item[0]));
        } else {
          newArray.push(generateValue(item[0]));
        }
      }
      return newArray;
    } else {
      return [generateValue(item)];
    }
  };

  const generateObjectValue = (obj) => {
    let newObj = {};
    for (let key in obj) {
      if (Array.isArray(obj[key])) {
        newObj[key] = generateArrayValue(obj[key]);
      } else if (typeof obj[key] === "object") {
        newObj[key] = generateObjectValue(obj[key]);
      } else {
        newObj[key] = generateValue(obj[key]);
      }
    }
    return newObj;
  };

  const generateDemoData = async (ids) => {
    for (let i = 0; i < ids.length; i++) {
      const newItem = generateObjectValue(demoDataItem);
      const demoDataItemRef = child(
        dbRef,
        `customers/${customerStore.activeCustomerId}/data/${storeId}/${ids[i]}`
      );
      try {
        await set(demoDataItemRef, newItem);
        console.log("Demo data item with ID", ids[i], "created successfully");
      } catch (error) {
        console.error("Failed to create demo data item: ", error);
      }
    }
  };

  const storeDemoDataToFirebase = async (ids) => {
    await generateDemoData(ids);
  };

  const removeDemoDataFromFirebase = () => {
    const demoDataRef = child(
      dbRef,
      `customers/${customerStore.activeCustomerId}/data/${storeId}`
    );
    remove(demoDataRef)
      .then(() => {
        console.log("Demo data removed successfully");
      })
      .catch((error) => {
        console.error("Failed to remove demo data: ", error);
      });
  };

  // removeDemoDataFromFirebase(); // ! use to remove demo data

  // ! only use once to store demo data
  // storeDemoDataToFirebase([
  //   "0AJzELqdMK2piUk9PVA",
  //   "0AA4FKjy-mHkoUk9PVA",
  //   "0ANj9FQvU3NmmUk9PVA",
  //   "0AFBB3-nQymfXUk9PVA",
  //   "0AKSEUvUTrX2eUk9PVA",
  //   "0AD0UhMzQPszqUk9PVA",
  //   "0APIlO2ugbz4RUk9PVA",
  //   "0AEq27y9uPIokUk9PVA",
  // ]);

  // ====================== //
  // = END DEMO GENERATOR = //
  // ====================== //

  return {
    listItems,
    deleteItem,
    items,
  };
});
